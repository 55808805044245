<template>
  <!-- START MASTER DATA -->
  <menu-dropdown
    nama="Manajemen User"
    label="/user"
    :class="!showMenu('USER') ? 'd-none' : ''"
  >
    <template #icon>
      <icon-user />
    </template>

    <template #items>
      <menu-item nama="Admin" url="/user-admin" />
      <menu-item nama="Dosen" url="/user-dosen" />
      <menu-item nama="Mahasiswa" url="/user-mahasiswa" />
    </template>
  </menu-dropdown>
  <menu-basic
    nama="Lembaga"
    url="/lembaga"
    :class="!showMenu('LEMBAGA') ? 'd-none' : ''"
  >
    <template #icon>
      <icon-institution />
    </template>
  </menu-basic>
  <menu-basic
    nama="Jurusan"
    url="/jurusan"
    :class="!showMenu('JURUSAN') ? 'd-none' : ''"
  >
    <template #icon>
      <icon-institution />
    </template>
  </menu-basic>
  <menu-basic
    nama="Program Studi"
    url="/program-studi"
    :class="!showMenu('PROGRAM_STUDI') ? 'd-none' : ''"
  >
    <template #icon>
      <icon-institution />
    </template>
  </menu-basic>
  <menu-basic
    nama="Pusat Kajian"
    url="/pusat-kajian"
    :class="!showMenu('PUSAT_KAJIAN') ? 'd-none' : ''"
  >
    <template #icon>
      <icon-institution />
    </template>
  </menu-basic>
  <menu-dropdown
    v-if="showMenu('PENILAIAN_UJIAN_ADMIN')"
    nama="Master Penilaian Ujian"
    label="/penilaian-ujian-admin"
  >
    <template #icon>
      <icon-calender />
    </template>
    <template #items>
      <menu-item
        :class="!showMenu('PENILAIAN_UJIAN_ADMIN') ? 'd-none' : ''"
        nama="Konfigurasi Nilai"
        url="/penilaian/konfigurasi/nilai"
      />
      <menu-item
        :class="!showMenu('PENILAIAN_UJIAN_ADMIN') ? 'd-none' : ''"
        nama="Konfigurasi Jadwal"
        url="/penilaian/konfigurasi/jadwal"
      />
    </template>
  </menu-dropdown>
  <!-- END MASTER DATA -->

  <!-- START PKM -->
  <menu-label
    :class="
      showMenu('PENELITIAN_ADMIN') ||
      showMenu('PENELITIAN_REVIEWER') ||
      showMenu('PENELITIAN_PENCAIRAN')
        ? ''
        : 'd-none'
    "
    nama="PKM"
  />
  <menu-dropdown nama="Penelitian dan PkM" label="/penelitian">
    <template #icon>
      <icon-flower />
    </template>

    <template #items>
      <menu-item
        nama="Validasi PPM"
        url="/penelitian"
        :class="!showMenu('PENELITIAN_ADMIN') ? 'd-none' : ''"
      />
      <menu-item
        nama="Validasi Luaran Tambahan"
        url="/luaran-tambahan"
        :class="!showMenu('PENELITIAN_ADMIN') ? 'd-none' : ''"
      />
      <menu-item
        nama="Validasi Kewajiban Tahunan"
        url="/validasi-kewajiban-tahunan"
        :class="!showMenu('PENELITIAN_ADMIN') ? 'd-none' : ''"
      />
      <menu-item
        nama="Reviewer"
        url="/penelitian-reviewer"
        :class="!showMenu('PENELITIAN_REVIEWER') ? 'd-none' : ''"
      />
      <menu-item
        nama="Pencairan"
        url="/penelitian-pencairan"
        :class="!showMenu('PENELITIAN_PENCAIRAN') ? 'd-none' : ''"
      />
      <menu-item
        nama="Pencairan Luaran Tambahan"
        url="/luaran-tambahan-pencairan"
        :class="!showMenu('PENELITIAN_PENCAIRAN') ? 'd-none' : ''"
      />
      <menu-item
        nama="Pencairan Kewajiban Tahunan"
        url="/pencairan-kewajiban-tahunan"
        :class="!showMenu('PENELITIAN_PENCAIRAN') ? 'd-none' : ''"
      />
    </template>
  </menu-dropdown>
  <!-- END PKM -->

  <!-- START ADMINISTRASI -->
  <menu-label
    :class="
      !showMenu('KONTRAK_PERKULIAHAN') ||
      !showMenu('PERWALIAN') ||
      !showMenu('IJAZAH')
        ? 'd-none'
        : ''
    "
    nama="Administrasi"
  />
  <menu-basic
    nama="Perwalian"
    url="/perwalian"
    :class="!showMenu('PERWALIAN') ? 'd-none' : ''"
  >
    <template #icon>
      <icon-bill />
    </template>
  </menu-basic>
  <menu-dropdown
    nama="Kontrak Perkuliahan"
    label="/kontrak-perkuliahan-new"
    v-if="showMenu('KONTRAK_PERKULIAHAN')"
  >
    <template #icon>
      <icon-dock />
    </template>
    <template #items>
      <menu-item nama="Monitoring" url="/kontrak-perkuliahan-new/monitoring" />
    </template>
  </menu-dropdown>
  <menu-dropdown
    nama="Ijazah"
    label="/ijazah"
    :class="!showMenu('IJAZAH') ? 'd-none' : ''"
  >
    <template #icon>
      <icon-file />
    </template>

    <template #items>
      <menu-item nama="Verifikasi Ijazah" url="/verifikasi-ijazah" />
      <!-- <menu-item nama="Arsip Ijazah" url="/arsip-ijazah" /> -->
    </template>
  </menu-dropdown>
  <!-- END ADMINISTRASI -->

  <!-- START PENILAIAN -->
  <menu-label
    :class="
      showMenu('PENILAIAN_UJIAN_PRODI') || showMenu('PENILAIAN_UJIAN_BAK')
        ? ''
        : 'd-none'
    "
    nama="Penilaian Hasil Belajar"
  />
  <menu-basic
    :class="!showMenu('PENILAIAN_UJIAN_BAK') ? 'd-none' : ''"
    nama="Pencairan UTS"
    url="/penilaian/uts/pencairan"
  >
    <template #icon>
      <icon-wallet />
    </template>
  </menu-basic>

  <menu-basic
    :class="!showMenu('PENILAIAN_UJIAN_BAK') ? 'd-none' : ''"
    nama="Pencairan UTS Susulan"
    url="/penilaian/uts-susulan/pencairan"
  >
    <template #icon>
      <icon-wallet />
    </template>
  </menu-basic>
  <menu-basic
    :class="!showMenu('PENILAIAN_UJIAN_BAK') ? 'd-none' : ''"
    nama="Pencairan UAS"
    url="/penilaian/uas/pencairan"
  >
    <template #icon>
      <icon-wallet />
    </template>
  </menu-basic>

  <menu-basic
    :class="!showMenu('PENILAIAN_UJIAN_BAK') ? 'd-none' : ''"
    nama="Pencairan UAS Susulan"
    url="/penilaian/uas-susulan/pencairan"
  >
    <template #icon>
      <icon-wallet />
    </template>
  </menu-basic>
  <menu-basic
    :class="!showMenu('PENILAIAN_UJIAN_PRODI') ? 'd-none' : ''"
    nama="Monitoring UTS"
    url="/penilaian/uts/monitoring"
  >
    <template #icon>
      <icon-uts />
    </template>
  </menu-basic>

  <menu-basic
    :class="!showMenu('PENILAIAN_UJIAN_PRODI') ? 'd-none' : ''"
    nama="Monitoring UTS Susulan"
    url="/penilaian/uts-susulan/monitoring"
  >
    <template #icon>
      <icon-uts-2 />
    </template>
  </menu-basic>
  <menu-basic
    :class="!showMenu('PENILAIAN_UJIAN_PRODI') ? 'd-none' : ''"
    nama="Monitoring UAS"
    url="/penilaian/uas/monitoring"
  >
    <template #icon>
      <icon-uas />
    </template>
  </menu-basic>

  <menu-basic
    :class="!showMenu('PENILAIAN_UJIAN_PRODI') ? 'd-none' : ''"
    nama="Monitoring UAS Susulan"
    url="/penilaian/uas-susulan/monitoring"
  >
    <template #icon>
      <icon-uas-2 />
    </template>
  </menu-basic>
  <menu-label />
  <!-- END PENILAIAN -->
</template>

<script>
// store
import { getUserData } from "@/store";

export default {
  data() {
    return {
      user: getUserData(),
    };
  },
  methods: {
    showMenu(akses) {
      if (!this.user.akses.includes("ALL")) {
        return this.user.akses.includes(akses);
      } else {
        return true;
      }
    },
  },
};
</script>
