<template>
    <container-app :container_loading="container_loading">
        <template #content>

            <container-double>
                <template #left>

                    <card-basic>
                        <template #content>
                            <div class="p-5 d-flex">
                                <div class="w-100">
                                    <form-icon>
                                        <template #icon>
                                            <icon-search />
                                        </template>
    
                                        <template #content>
                                            <input 
                                                type="text" 
                                                class="form-control ps-14 fw-normal"
                                                placeholder="Pencarian berdasarkan nim..."
                                                autofocus
                                                v-model="keyword"
                                                @keyup.enter="searchMahasiswa"
                                                >
                                        </template>
    
                                    </form-icon>
                                    <span class="d-block mt-1 text-gray-500 ms-14" v-if="keyword.length > 0">Tekan tombol enter untuk melakukan pencarian</span>
                                </div>
                                <button class="btn btn-sm btn-secondary ms-3 d-block d-md-none" data-bs-toggle="modal" data-bs-target="#modalFilter">
                                    <icon-filter />
                                </button>
                            </div>        

                            <table-basic>
                                <template #thead>
                                    <tr class="fw-bolder bg-light fs-7">
                                        <th class="rounded-start ps-5 w-md-50px">No.</th>
                                        <th>Nama Lengkap</th>
                                        <th class="rounded-end pe-5 w-md-200px">Aksi</th>
                                    </tr>
                                </template>

                                <template #tbody>
                                    <tr class="fs-7" v-for="(mahasiswa, i) in daftar_mahasiswa" :key="i">
                                        <td class="text-end ps-5">{{ i+1 }}</td>
                                        <td>
                                            <div class="d-flex symbol symbol-30px symbol-md-40px">
                                                <img :src="mahasiswa.foto == '-' ? '/img/blank.aad363d0.png' : mahasiswa.foto " />
                                                <div class="ms-3">
                                                    <span class="d-block fw-bold text-dark mb-1">{{ mahasiswa.nama }}</span>
                                                    <span class="d-block fs-8 text-muted">{{ mahasiswa.username }}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="pe-5">
                                            <button-icon class="btn-sm btn-secondary" name="Reset Password" @click="resetPassword(mahasiswa.username)">
                                                <template #icon>
                                                    <icon-reset />
                                                </template>
                                            </button-icon>
                                        </td>
                                    </tr>
                                </template>
                            </table-basic>
                        </template>
                    </card-basic>

                </template>

                <template #right>
                    <card-basic v-if="keyword.length == 0">
                        <template #content>
                            <div class="p-5">
                                
                                <form @submit.prevent="getsMahasiswa">
                                    <div class="mb-4">
                                        <label class="col-form-label p-0 pb-2">Program Studi</label>
                                        <select class="form-select form-select-solid" v-model="prodi">
                                            <option v-for="(prodi, i) in daftar_prodi" :key="i" :value="prodi.nmps">{{ prodi.nmps }}</option>
                                        </select>
                                    </div>
    
                                    <div class="mb-4">
                                        <label class="col-form-label p-0 pb-2">Tahun Masuk</label>
                                        <select class="form-select form-select-solid" v-model="tahun">
                                            <option v-for="(tahun, i) in daftar_tahun" :key="i" :value="tahun.substring(2, 4)">{{ tahun }}</option>
                                        </select>
                                    </div>

                                    <input type="submit" value="Filter" class="btn btn-primary w-100 mt-4">
                                </form>

                            </div>
                        </template>
                    </card-basic>
                </template>
            </container-double>

        </template>
    </container-app>

    <!-- begin::modal -->
    <!-- begin::modalFilter -->
    <modal-basic id="modalFilter" title="Filter">
        <template #content>

            <div class="mb-4">
                <label class="col-form-label p-0 pb-2">Program Studi</label>
                <select class="form-select form-select-solid" v-model="prodi">
                    <option v-for="(prodi, i) in daftar_prodi" :key="i" :value="prodi.nmps">{{ prodi.nmps }}</option>
                </select>
            </div>

            <div class="mb-4">
                <label class="col-form-label p-0 pb-2">Tahun Masuk</label>
                <select class="form-select form-select-solid" v-model="tahun">
                    <option v-for="(tahun, i) in daftar_tahun" :key="i" :value="tahun.substring(2, 4)">{{ tahun }}</option>
                </select>
            </div>

        </template>

        <template #button>
            <button-basic name="Filter" class="btn-primary fw-normal" @click="getsMahasiswa" />
        </template>
    </modal-basic>
    <!-- end::modalFilter -->
    <!-- end::modal -->
</template>

<script>
    // apis
    import { resetPassword } from '@/apis/user';
    import { getsProdi, getsTahun } from '@/apis/prodi';
    import { getsMahasiswa, searchMahasiswa } from '@/apis/mahasiswa';

    export default {
        beforeMount() {
            localStorage.setItem('menu', 'Manajemen User')
            localStorage.setItem('halaman', 'Mahasiswa')
        },
        data() {
            return {
                container_loading: false,
                daftar_mahasiswa: {},
                daftar_prodi: {},
                prodi: '',
                daftar_tahun: {},
                tahun: '',
                keyword: '',
            }
        },
        async mounted() {
            this.container_loading = true;
            await this.getsData();
            await this.getsTahun();
            await this.getsMahasiswa();
            this.container_loading = false;
        },
        watch: {
            prodi() {
                this.getsTahun();
            },
            keyword(newValue) {
                if( newValue.length > 0 ) {
                    this.daftar_mahasiswa = {};
                } else {
                    this.getsMahasiswa();
                }
            }
        },
        methods: {
            async getsData() {
                await this.getsProdi();
            },
            async getsProdi() {
                try {
                    const res = await getsProdi();
                    this.daftar_prodi = res.data.data;
                    this.prodi = this.daftar_prodi[0].nmps;

                } catch ( err ) {
                    console.error( err );
                }
            },
            async getsTahun() {
                try {
                    const res = await getsTahun(this.prodi);
                    this.daftar_tahun = res.data.data;
                    this.tahun = this.daftar_tahun[0].substring(2, 4);

                } catch ( err ) {
                    console.error( err );
                }
            },
            async getsMahasiswa() {
                this.container_loading = true;
                this.daftar_mahasiswa = {};
                try {
                    const res = await getsMahasiswa(this.prodi, this.tahun);
                    this.daftar_mahasiswa = res.data.data;
                } catch ( err ) {
                    console.error( err );
                }
                this.container_loading = false;
            },
            async searchMahasiswa() {
                this.container_loading = true;
                this.daftar_mahasiswa = {};
                try {
                    const res = await searchMahasiswa(this.keyword);
                    this.daftar_mahasiswa = res.data.data;
                } catch ( err ) {
                    console.error( err );
                }
                this.container_loading = false;
            },
            resetPassword(email) {
                Swal.fire({
                    icon: 'question',
                    text: 'Reset password mahasiswa dengan nim ' + email,
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                    confirmButtonText: 'Ya',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-secondary'
                    }
                }).then( async result => {
                    if( result.isConfirmed ) {
                        try {
                            const res = await resetPassword({email: email});

                            if( res.status === 200 ) {
                                Swal.fire({
                                    icon: 'success',
                                    text: 'Password berhasil di reset',
                                    showConfirmButton: false,
                                    timer: 5000
                                })
                            }
                        } catch ( err ) {
                            console.error( err );
                        }
                    }
                })
            },
        }
    }
</script>