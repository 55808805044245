<template>
    <container-app>
        <template #content>

            <div class="row">
                <div class="col-12 col-md-5">
                    <card-basic>
                        <template #content>

                            <div class="p-5 w-100" v-if="profil.nama.length > 0">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <div class="symbol symbol-100px symbol-lg-160px mb-3">
                                        <img :src="profil.foto" />
                                    </div>

                                    <button class="btn btn-white text-primary" data-bs-toggle="modal" data-bs-target="#modalEditFoto">Ganti Foto</button>
                                </div>

                                <div class="row mb-5 mt-7">
                                    <label class="col-lg-4 text-muted">Nama</label>
                                    <div class="col-lg-8">
                                        <span class="text-gray-800">{{ profil.nama }}</span>
                                    </div>
                                </div>

                                <div class="row mb-5">
                                    <label class="col-lg-4 text-muted">Email</label>
                                    <div class="col-lg-8">
                                        <span class="text-gray-800">{{ profil.email }}</span>
                                    </div>
                                </div>
                            </div>

                        </template>
                    </card-basic>
                </div>
            </div>
        </template>
    </container-app>
</template>

<script>
// apis
import { getUser } from '@/apis/user';

export default {
    data() {
        return {
            profil: {
                nama: ''
            }
        }
    },
    mounted() {
        this.getUser();
    },
    methods: {
        async getUser() {
            try {
                const response = await getUser();
                this.profil = response.data.data;
                
                this.profil.foto = this.profil.foto == '-' ? '/img/blank.aad363d0.png' : this.profil.foto;
                
            } catch ( err ) {
                console.error( err );
            }
        }
    }
}
</script>