<template>
    <!-- begin::container-app -->
    <container-app>
        <template #content>

            <container-double>
                <template #left>

                    <card-basic>
                        <template #content>
                            <div class="p-5">
                                <label-header label="Pusat Kajian" :value="data_pusat_kajian.nama">
                                    <template #control>
            
                                        <button-icon name="Ubah" class="btn-sm bg-secondary" data-bs-toggle="modal" data-bs-target="#modalPusatKajian" >
                                            <template #icon>
                                                <icon-edit />
                                            </template>
                                        </button-icon>

                                        <button-icon name="Tambah" class="btn-sm bg-secondary ms-2" data-bs-toggle="modal" data-bs-target="#modalKelompokKeahlian" >
                                            <template #icon>
                                                <icon-plus />
                                            </template>
                                        </button-icon>
                                        
                                    </template>
                                </label-header>
                            </div>
                            
                            <table-basic>
                                <template #thead>
                                    <tr class="fw-bolder bg-light fs-7">
                                        <th class="rounded-start ps-5 w-md-50px">No.</th>
                                        <th>Kelompok Keahlian</th>
                                        <th class="rounded-end pe-5 w-md-200px">Aksi</th>
                                    </tr>
                                </template>

                                <template #tbody>
                                    <tr class="fs-7" v-for="(kelompok_keahlian, i) in data_kelompok_keahlian" :key="i">
                                        <td class="ps-5">{{ i + 1}}</td>
                                        <td>
                                            {{ kelompok_keahlian.nama }}
                                        </td>
                                        <td class="pe-5">
                                            <!-- <button class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#modalDetail">Detail</button> -->
                                        </td>
                                    </tr>
                                </template>
                            </table-basic>
                            
                        </template>
                    </card-basic>

                </template>
            </container-double>

        </template>
    </container-app>
    <!-- end::container-app -->

    <!-- begin::modal -->
    <!-- begin::modalPusatKajian -->
    <modal-fullscreen-sm id="modalPusatKajian" title="Pusat Kajian">
        <template #content>
            <div class="p-1 pb-4 pt-0">
                <form @submit.prevent="storePusatKajian">
                    <div class="form-group mb-2">
                        <label for="nama" class="col-form-label pb-2">Nama</label>
                        <input type="text" name="nama" id="nama" class="form-control" v-model="form_pusat_kajian.nama" required>
                    </div>
    
                    <div class="form-group mb-2">
                        <label for="nama" class="col-form-label pb-2">Fokus Utama</label>
                        <textarea name="fokus_utama" id="fokus_utama" class="form-control" rows="3" v-model="form_pusat_kajian.fokus_utama" required></textarea>
                    </div>
        
                    <div class="mt-7">
                        <input type="submit" value="Simpan" class="btn btn-success d-block w-100 w-sm-auto">
                    </div>
                </form>
            </div>
        </template>
    </modal-fullscreen-sm>
    <!-- end::modalPusatKajian -->

    <!-- begin::modalKelompokKeahlian -->
    <modal-fullscreen-sm id="modalKelompokKeahlian" title="Kelompok Keahlian">
        <template #content>
            
            <div class="p-1 pb-4 pt-0">
                <form @submit.prevent="storeKelompokKeahlian">
                    <div class="form-group mb-2">
                        <label for="kelompok_keahlian_nama" class="col-form-label pb-2">Nama</label>
                        <input type="text" name="nama" id="kelompok_keahlian_nama" class="form-control" v-model="form_kelompok_keahlian.nama" required>
                    </div>

                    <div class="mt-7">
                        <input type="submit" value="Simpan" class="btn btn-success d-block w-100 w-sm-auto">
                    </div>
                </form>
            </div>
        </template>
    </modal-fullscreen-sm>
    <!-- end::modalKelompokKeahlian -->

    <!-- begin::modalDetail -->
    <modal-fullscreen-sm id="modalDetail" title="Detail Kelompok Keahlian">
        <template #content>
            
        </template>
    </modal-fullscreen-sm>
    <!-- end::modalDetail -->
    <!-- end::modal -->
</template>

<script>
// apis
import {storePusatKajian, programStudi} from '@/apis/pusatKajian';
import {storeKelompokKeahlian, indexKelompokKeahlian} from '@/apis/kelompokKeahlian';

export default {
    beforeUnmount() {
        localStorage.setItem('menu', 'AISnet');
        localStorage.setItem('halaman', 'Program Studi');
    },
    data() {
        return {
            form_pusat_kajian: {},
            data_pusat_kajian: {},
            form_kelompok_keahlian: {},
            data_kelompok_keahlian: {}
        }
    },
    mounted() {
        this.programStudi();
    },
    methods: {
        async programStudi() {
            try {
                const res = await programStudi(this.$route.params.id);

                this.data_pusat_kajian = res.data.data;
                this.form_pusat_kajian = res.data.data;

                this.indexKelompokKeahlian();
            } catch (err) {
                console.error( err );
            }
        },
        storePusatKajian() {
            Swal.fire({
                icon: 'question',
                text: 'Simpan data pusat kajian ?',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                confirmButtonText: 'Simpan',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-secondary'
                }
            }).then( async result => {
                if( result.isConfirmed ) {
                    try {
                        this.form_pusat_kajian.dm_program_studi_id = this.$route.params.id;
    
                        const res = await storePusatKajian(this.form_pusat_kajian);
    
                        if( res.status == 200 ) {
                            Swal.fire({
                                icon: 'success',
                                text: 'Data pusat kajian berhasil disimpan',
                                showConfirmButton: false,
                                timer: 5000
                            });
    
                            this.programStudi();
                        }
                    } catch ( err ) {
                        console.error( err );
                    }
                }
            })
        },
        async indexKelompokKeahlian() {
            try {
                const res = await indexKelompokKeahlian(this.data_pusat_kajian.id);

                this.data_kelompok_keahlian = res.data.data;
            } catch (error) {
                console.error( error )
            }
        },
        storeKelompokKeahlian() {
            Swal.fire({
                icon: 'question',
                text: 'Simpan data kelompok keahlian ?',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                confirmButtonText: 'Simpan',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-secondary'
                }
            }).then( async result => {
                if( result.isConfirmed ) {
                    try {
                        this.form_kelompok_keahlian.dm_pusat_kajian_id = this.data_pusat_kajian.id;
                        
                        const res = await storeKelompokKeahlian(this.form_kelompok_keahlian);
    
                        if( res.status == 200 ) {
                            Swal.fire({
                                icon: 'success',
                                text: 'Data kelompok keahlian berhasil disimpan',
                                showConfirmButton: false,
                                timer: 5000
                            });
    
                            this.programStudi();
                        }
                    } catch ( err ) {
                        console.error( err );
                    }
                }
            })
        }
    }
}

</script>