<template>
    <div class="d-flex align-items-center">
        <div class="border border-1 border-dashed p-2 symbol symbol-40px" :class="warna">
            <slot name="icon"></slot>
        </div>
        <div class="d-flex flex-column ms-4">
            <span class="fw-bold text-muted fs-8">{{ label }}</span>
            <span class="fw-bolder fs-7">{{ value }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['label', 'value', 'warna']
}
</script>