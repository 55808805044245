<template>
    <container-app :container_loading="container_loading">
        <template #content>

            <container-double>
                <template #left>
                    <card-basic>
                        <template #content>
                            <div class="p-5">
                                <label-header label="Semester/TA Sekarang" :value="ta.ket">
                                    <template #control>
            
                                        <button-icon name="Filter" class="btn-sm bg-secondary" data-bs-toggle="modal" data-bs-target="#modalFilter">
                                            <template #icon>
                                                <icon-filter />
                                            </template>
                                        </button-icon>
                                        
                                    </template>
                                </label-header>

                                <div class="mt-8 d-none d-md-block">
                                    <card-keuangan :informasi="informasi" />
                                </div>

                            </div>

                            <div class="d-none d-md-block">
                                <tables-rincian :daftar_rincian="daftar_rincian" />
                            </div>

                        </template>
                    </card-basic>

                    <div class="d-block d-md-none">
                        <card-2-keuangan :informasi="informasi" :daftar_rincian="daftar_rincian" />
                        <cards-riwayat :daftar_riwayat="daftar_riwayat" />
                    </div>
                </template>

                <template #right>
                    
                    <cards-riwayat :daftar_riwayat="daftar_riwayat" />
                    
                </template>
            </container-double>

        </template>
    </container-app>

    <!-- begin::modal -->
    <modal-basic id="modalFilter" title="Filter">
        <template #content>
            <label for="basic-url" class="form-label">Tahun Akademik</label>
            <div class="input-group mb-5">
                <select class="form-select form-select-solid" v-model="filter.thsms">
                    <option v-for="(ta, index) in daftar_ta" :key="index" :value="ta.thsms">Tahun {{ ta.ket }}</option>
                </select>
            </div>
        </template>

        <template #button>
            <button-basic class="bg-primary text-light" name="Simpan" @click="processFilter" />
        </template>
    </modal-basic>
    <!-- end::modal -->
</template>

<script>
    // components
    import CardKeuangan from '@/components/_keuangan/CardKeuangan.vue';
    import Card2Keuangan from '@/components/_keuangan/Card2Keuangan.vue';
    import TablesRincian from '@/components/_keuangan/TablesRincian.vue';
    import CardsRiwayat from '@/components/_keuangan/CardsRiwayat.vue';

    // apis
    import { getsTahunAkademik } from '@/apis/tahun';
    import { getPembayaran, getPembayaranRincian, getPembayaranRiwayat } from '@/apis/keuangan';
    
    export default {
        components: {
            CardKeuangan,
            Card2Keuangan,
            TablesRincian,
            CardsRiwayat
        },
        data() {
            return {
                daftar_ta : [],
                ta : {},
                filter : {},
                informasi : {
                    total: 0,
                    dibayar: 0,
                    tunggakan: 0
                },
                daftar_rincian: {},
                daftar_riwayat: {},
                container_loading: true
            }
        },
        beforeMount() {
            localStorage.setItem('menu', 'AISnet')
            localStorage.setItem('halaman', 'Keuangan')
        },
        mounted() {
            this.getData();
        },
        methods: {
            async getData() {
                await this.getsTahunAkademik();
                this.getPembayaran();
            },
            async getsTahunAkademik() {
                try {
                    const response = await getsTahunAkademik();
                    this.daftar_ta = response.data.data;
                    this.ta = this.daftar_ta[0];
                    this.filter.thsms = this.daftar_ta[0].thsms;
                    
                } catch ( err ) {
                    console.error( err );
                }
            },
            processFilter() {
                const filter = this.daftar_ta.filter( e => e.thsms == this.filter.thsms );
                this.ta = filter[0];
                this.getPembayaran();
            },
            async getPembayaran() {
                const wajib = await getPembayaran(this.ta.thsms);
                const rincian = await getPembayaranRincian(this.ta.thsms);
                const riwayat = await getPembayaranRiwayat(this.ta.thsms);
                this.container_loading = false;

                this.informasi = wajib.data.data;
                this.daftar_rincian = rincian.data.data;
                this.daftar_riwayat = riwayat.data.data;
            }
        }
    }
</script>