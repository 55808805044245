<template>
  <container-app>
    <template #content>
      <penelitian-admin v-if="user.peran == 'ADMIN'" />
      <penelitian-dosen v-else-if="user.peran == 'DOSEN'" />
    </template>
  </container-app>
</template>

<script>
// views
import PenelitianAdmin from "@/views/penelitian/PenelitianAdmin.vue";
import PenelitianDosen from "@/views/penelitian/PenelitianDosen.vue";

// store
import { getUserData } from "@/store";

export default {
  components: {
    PenelitianAdmin,
    PenelitianDosen,
  },
  data() {
    return {
      user: {
        peran: "-",
      },
    };
  },
  mounted() {
    this.user.peran = getUserData().peran;
  },
};
</script>
