import { api, headers } from "./api";

const RESOURCE = "kontrak-perkuliahan";

export const getJadwalKontrak = (tahun_semester) =>
  api.get(RESOURCE + `/jadwal?tahun_semester=${tahun_semester}`, headers);

export const getDosenMataKuliah = (tahun_semester) =>
  api.get(RESOURCE + `/dosen?tahun_semester=${tahun_semester}`, headers);

export const getDaftarMahasiswa = (params) =>
  api.get(RESOURCE + `/dosen/detail?${params}`, headers);

export const postDownloadDokumenKontrak = (payload) =>
  api.post(RESOURCE + `/download`, payload, {
    ...headers,
    responseType: "blob",
  });

export const getMahasiswaMataKuliah = (tahun_semester) =>
  api.get(RESOURCE + `/mahasiswa?tahun_semester=${tahun_semester}`, headers);

export const postValidasiMahasiswaKontrak = (payload) =>
  api.post(RESOURCE + `/mahasiswa/validasi`, payload, headers);

export const getMataKuliahProdi = (params) =>
  api.get(RESOURCE + `/prodi?${params}`, headers);

export const postValidasiKontrakProdi = (payload) =>
  api.post(RESOURCE + `/prodi/validasi`, payload, headers);

export const postBukaKontrakProdi = (payload) =>
  api.post(RESOURCE + `/prodi/buka`, payload, headers);

export const getListProdi = () => api.get(RESOURCE + `/prodi/list`, headers);
