import { daftar_hari } from "./main";

export const jadwal_kuliah = (data) => {
    let jadwal = []
    jadwal = daftar_hari.map(hari => {
        let jadwal = []

        data.map( j => {
            if( j.hari === hari ) {
                jadwal.push( j )
            }
        })
        
        return {
            hari,
            jadwal
        }
    })

    return jadwal.filter( j => j.jadwal.length > 0)
}