<template>
  <div class="alert d-flex align-items-center m-5 p-5" :class="'alert-' + type">
    <icon-alert class="d-none d-sm-block" />
    <div class="d-flex flex-column">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["type"],
};
</script>

<style></style>
