<template>
    <div class="d-flex justify-content-between">
        <label-basic name="Pengumuman" />
        <a href="pengumuman" class="text-muted fs-7">Lainnya</a>
    </div>
    <div class="row mt-3">
        <card-pengumuman 
            v-for="(pengumuman, i) in daftar_pengumuman" 
            :key="i" 
            :pengumuman="pengumuman"
            :class="i > 1 ? 'd-none d-md-block' : ''"
            data-bs-toggle="modal" data-bs-target="#modalDetailPengumuman"
            @click="getPengumuman(pengumuman)"
        />
    </div>

    <!-- begin::modal -->
    <modal-fullscreen-sm id="modalDetailPengumuman" title="Detail Pengumuman">
        <template #content>
            <get-pengumuman :pengumuman="pengumuman" />
        </template>
    </modal-fullscreen-sm>
    <!-- end::modal -->
</template>

<script>
// components
import CardPengumuman from './CardPengumuman.vue';
import GetPengumuman from './GetPengumuman.vue';

// apis
import { getsPengumuman } from '@/apis/pengumuman';

export default {
    components: {
        CardPengumuman,
        GetPengumuman
    },
    data() {
        return {
            daftar_pengumuman : {},
            pengumuman : {}
        }
    },
    emits: ['stop_loading'],
    mounted() {
        this.getsPengumuman();
    },
    methods: {
        async getsPengumuman() {
            try {
                const response = await getsPengumuman();
                this.daftar_pengumuman = response.data.data;
                this.$emit('stop_loading');
            }
            catch ( err ) {
                console.error( err )
            }
        },
        getPengumuman(pengumuman) {
            this.pengumuman = pengumuman;
        }
    }
}

</script>