<template>
    <div class="py-4 d-flex flex-lg-column mb-4">
        <div class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div class="text-dark">
                <span class="text-muted">© 2022 AISnet Web Institut Teknologi Garut (ITG)</span>
            </div>
            <div class="text-dark">
                <span class="text-muted">Lembaga Sistem Informasi dan Pangkalan Data (LSIPD)</span>
            </div>
        </div>
    </div>
</template>