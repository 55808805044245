import {api, headers} from './api';

const RESOURCE = 'skema_iuran';

export const indexSkemaIuran = () => api.get(RESOURCE, headers);

export const dataSkemaIuran = (jenis) => api.get(RESOURCE+"/data?jenis="+jenis, headers);

export const storeSkemaIuran = (data) => api.post(RESOURCE, data, headers);

export const deleteSkemaIuran = (id) => api.delete(RESOURCE+'/'+id, headers);