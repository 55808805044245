import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";

// day js
import VueDayjs from "vue3-dayjs-plugin";

// state management
const pinia = createPinia();

// import components
// alerts
import AlertBasic from "@/components/alerts/Basic.vue";
import AlertIcon from "@/components/alerts/Icon.vue";

// buttons
import ButtonBasic from "@/components/buttons/Basic.vue";
import ButtonIcon from "@/components/buttons/Icon.vue";
import ButtonLoading from "@/components/buttons/Loading.vue";

// cards
import CardBasic from "@/components/cards/Basic.vue";

// containers
import ContainerApp from "@/components/containers/App.vue";
import ContainerAuth from "@/components/containers/Auth.vue";
import ContainerDouble from "@/components/containers/Double.vue";

// form
import FormIcon from "@/components/form/Icon.vue";

// icons
import IconAlert from "@/components/icons/Alert.vue";
import IconArchive from "@/components/icons/Archive.vue";
import IconApp from "@/components/icons/App.vue";
import IconArrowBottom from "@/components/icons/ArrowBottom.vue";
import IconArrowRight from "@/components/icons/ArrowRight.vue";
import IconArrowLeft from "@/components/icons/ArrowLeft.vue";
import IconArrowUp from "@/components/icons/ArrowUp.vue";
import IconBill from "@/components/icons/Bill.vue";
import IconCalender from "@/components/icons/Calender.vue";
import IconChart from "@/components/icons/Chart.vue";
import IconChat from "@/components/icons/Chat.vue";
import IconChat2 from "@/components/icons/Chat2.vue";
import IconCheck from "@/components/icons/Check.vue";
import IconClose from "@/components/icons/Close.vue";
import IconCode from "@/components/icons/Code.vue";
import IconContract from "@/components/icons/Contract.vue";
import IconCross from "@/components/icons/Cross.vue";
import IconDock from "@/components/icons/Dock.vue";
import IconDone from "@/components/icons/Done.vue";
import IconEdit from "@/components/icons/Edit.vue";
import IconEraser from "@/components/icons/Eraser.vue";
import IconExam from "@/components/icons/Exam.vue";
import IconFile from "@/components/icons/File.vue";
import IconFilter from "@/components/icons/Filter.vue";
import IconFlower from "@/components/icons/Flower.vue";
import IconGuardPassword from "@/components/icons/GuardPassword.vue";
import IconGuardUser from "@/components/icons/GuardUser.vue";
import IconHistory from "@/components/icons/History.vue";
import IconInfo from "@/components/icons/Info.vue";
import IconInterselect from "@/components/icons/Interselect.vue";
import IconInstitution from "@/components/icons/Institution.vue";
import IconList from "@/components/icons/List.vue";
import IconLoading from "@/components/icons/Loading.vue";
import IconLogoAndroid from "@/components/icons/LogoAndroid.vue";
import IconMap from "@/components/icons/Map.vue";
import IconMark from "@/components/icons/Mark.vue";
import IconMoney from "@/components/icons/Money.vue";
import IconMore from "@/components/icons/More.vue";
import IconPlus from "@/components/icons/Plus.vue";
import IconReset from "@/components/icons/Reset.vue";
import IconSearch from "@/components/icons/Search.vue";
import IconStatus0 from "@/components/icons/Status0.vue";
import IconStatus1 from "@/components/icons/Status1.vue";
import IconStatus2 from "@/components/icons/Status2.vue";
import IconStatus3 from "@/components/icons/Status3.vue";
import IconUser from "@/components/icons/User.vue";
import IconUTS from "@/components/icons/UTS.vue";
import IconUTS2 from "@/components/icons/UTS2.vue";
import IconUAS from "@/components/icons/UAS.vue";
import IconUAS2 from "@/components/icons/UAS2.vue";
import IconWallet from "@/components/icons/Wallet.vue";
import IconDokumen from "@/components/icons/Dokumen.vue";
import IconUpload from "@/components/icons/FileUpload.vue";

// infos
import InfoBatas from "@/components/infos/Batas.vue";
import InfoLayanan from "@/components/infos/Layanan.vue";
import InfoStatus from "@/components/infos/Status.vue";
import InfoStatusMahasiswa from "@/components/infos/StatusMahasiswa.vue";
import InfoUser from "@/components/infos/User.vue";

// labels
import LabelBasic from "@/components/labels/Basic.vue";
import LabelHeader from "@/components/labels/Header.vue";
import LabelIcon from "@/components/labels/Icon.vue";
import LabelIcon2 from "@/components/labels/Icon2.vue";
import LabelIconWarna from "@/components/labels/IconWarna.vue";

// menus
import MenuBasic from "@/components/menus/Basic.vue";
import MenuDropdown from "@/components/menus/Dropdown.vue";
import MenuItem from "@/components/menus/Item.vue";
import MenuLabel from "@/components/menus/Label.vue";
import MenuSeparator from "@/components/menus/Separator.vue";

// modals
import ModalBasic from "@/components/modals/Basic.vue";
import ModalFullscreen from "@/components/modals/Fullscreen.vue";
import ModalFullscreenSm from "@/components/modals/FullscreenSm.vue";
import ModalScroll from "@/components/modals/Scroll.vue";
import ModalMedium from "@/components/modals/Medium.vue";
import ModalFullScreenLg from "@/components/modals/FullScreenLg.vue";

// tables
import TableBasic from "@/components/tables/Basic.vue";

const app = createApp(App);

// use components
// alerts
app.component("alert-basic", AlertBasic);
app.component("alert-icon", AlertIcon);

// buttons
app.component("button-basic", ButtonBasic);
app.component("button-icon", ButtonIcon);
app.component("button-loading", ButtonLoading);

// cards
app.component("card-basic", CardBasic);

// containers
app.component("container-app", ContainerApp);
app.component("container-auth", ContainerAuth);
app.component("container-double", ContainerDouble);

// form
app.component("form-icon", FormIcon);

// icons
app.component("icon-alert", IconAlert);
app.component("icon-archive", IconArchive);
app.component("icon-app", IconApp);
app.component("icon-arrow-bottom", IconArrowBottom);
app.component("icon-arrow-right", IconArrowRight);
app.component("icon-arrow-left", IconArrowLeft);
app.component("icon-arrow-up", IconArrowUp);
app.component("icon-bill", IconBill);
app.component("icon-calender", IconCalender);
app.component("icon-chart", IconChart);
app.component("icon-chat", IconChat);
app.component("icon-chat-2", IconChat2);
app.component("icon-check", IconCheck);
app.component("icon-close", IconClose);
app.component("icon-code", IconCode);
app.component("icon-contract", IconContract);
app.component("icon-cross", IconCross);
app.component("icon-dock", IconDock);
app.component("icon-done", IconDone);
app.component("icon-edit", IconEdit);
app.component("icon-eraser", IconEraser);
app.component("icon-exam", IconExam);
app.component("icon-file", IconFile);
app.component("icon-filter", IconFilter);
app.component("icon-flower", IconFlower);
app.component("icon-guard-password", IconGuardPassword);
app.component("icon-guard-user", IconGuardUser);
app.component("icon-history", IconHistory);
app.component("icon-info", IconInfo);
app.component("icon-interselect", IconInterselect);
app.component("icon-institution", IconInstitution);
app.component("icon-list", IconList);
app.component("icon-loading", IconLoading);
app.component("icon-logo-android", IconLogoAndroid);
app.component("icon-map", IconMap);
app.component("icon-mark", IconMark);
app.component("icon-money", IconMoney);
app.component("icon-more", IconMore);
app.component("icon-plus", IconPlus);
app.component("icon-reset", IconReset);
app.component("icon-search", IconSearch);
app.component("icon-status-0", IconStatus0);
app.component("icon-status-1", IconStatus1);
app.component("icon-status-2", IconStatus2);
app.component("icon-status-3", IconStatus3);
app.component("icon-uts", IconUTS);
app.component("icon-uts-2", IconUTS2);
app.component("icon-uas", IconUAS);
app.component("icon-uas-2", IconUAS2);
app.component("icon-user", IconUser);
app.component("icon-wallet", IconWallet);
app.component("icon-dokumen", IconDokumen);
app.component("icon-upload", IconUpload);

// infos
app.component("info-batas", InfoBatas);
app.component("info-layanan", InfoLayanan);
app.component("info-status", InfoStatus);
app.component("info-status-mahasiswa", InfoStatusMahasiswa);
app.component("info-user", InfoUser);

// labels
app.component("label-basic", LabelBasic);
app.component("label-header", LabelHeader);
app.component("label-icon", LabelIcon);
app.component("label-icon-2", LabelIcon2);
app.component("label-icon-warna", LabelIconWarna);

// menus
app.component("menu-basic", MenuBasic);
app.component("menu-dropdown", MenuDropdown);
app.component("menu-item", MenuItem);
app.component("menu-label", MenuLabel);
app.component("menu-separator", MenuSeparator);

// modals
app.component("modal-basic", ModalBasic);
app.component("modal-fullscreen", ModalFullscreen);
app.component("modal-fullscreen-sm", ModalFullscreenSm);
app.component("modal-medium", ModalMedium);
app.component("modal-scroll", ModalScroll);
app.component("modal-fullsceen-lg", ModalFullScreenLg);

// tables
app.component("table-basic", TableBasic);

app.use(pinia);
app.use(router);
app.use(VueDayjs);
app.mount("#app");
