<template>
    <card-basic>
        <template #content>
            <div class="p-5">
                <label-basic name="Validasi Status" />
                <div class="row mt-5">
                    <div class="col-6">
                        <label-icon-warna warna="bg-light-success border-success" label="Status Mahasiswa"
                            :value="status_mahasiswa">
                            <template #icon>
                                <icon-user />
                            </template>
                        </label-icon-warna>
                    </div>
                    <div class="col-6">
                        <label-icon-warna warna="bg-light-danger border-danger" label="Status Keuangan"
                            :value="status_keuangan == 0 ? 'Lunas' : status_keuangan == '-' ? '-' : 'Rp. '+status_keuangan">
                            <template #icon>
                                <icon-wallet />
                            </template>
                        </label-icon-warna>
                    </div>
                </div>
            </div>
        </template>
    </card-basic>
</template>

<script>
    export default {
        props: ['status_mahasiswa', 'status_keuangan']
    }
</script>