<template>
    <container-app :container_loading="container_loading">
        <template #content>

            <container-double>
                <template #left>
                    
                    <card-basic>
                        <template #content>
                            <div class="p-5">
                                <label-header label="Semester/TA Sekarang" :value="ta.ket">
                                    <template #control>

                                        <button-icon name="Filter" class="btn-sm bg-secondary" data-bs-toggle="modal"
                                            data-bs-target="#modalFilter">
                                            <template #icon>
                                                <icon-filter />
                                            </template>
                                        </button-icon>

                                    </template>
                                </label-header>
                            </div>

                            <tables-absen :daftar_absen="daftar_absen" class="d-none d-md-block" @modal-detail="modalDetail" />
                        </template>
                    </card-basic>

                </template>
            </container-double>

            <div class="d-block d-md-none">
                <cards-absen :daftar_absen="daftar_absen" @modal-detail="modalDetail" />
            </div>

        </template>
    </container-app>

    <!-- begin::modal -->
    <!-- begin::filter -->
    <modal-basic id="modalFilter" title="Filter">
        <template #content>
            <label for="basic-url" class="form-label">Tahun Akademik</label>
            <div class="input-group mb-5">
                <select class="form-select form-select-solid" v-model="filter.thsms">
                    <option v-for="(ta, index) in daftar_ta" :key="index" :value="ta.thsms">Tahun {{ ta.ket }}</option>
                </select>
            </div>
        </template>

        <template #button>
            <button-basic class="bg-primary text-light" name="Simpan" @click="processFilter" />
        </template>
    </modal-basic>
    <!-- end::filter -->

    <!-- begin::detail -->
    <modal-fullscreen-sm id="modalDetail" title="Detail Absensi Perkuliahan">
        <template #content>
            <card-basic>
                <template #content>
                    <div class="p-5 d-flex justify-content-between align-items-center">
                        <label-icon-2 :label="absen.kode_matkul" :value="absen.nama_matkul">
                            <template #icon>
                                <icon-info />
                            </template>
                        </label-icon-2>

                        <span class="fs-3 w-80px fw-bold text-end">{{ absen.presentasi }} %</span>
                    </div>
                </template>
            </card-basic>

            <card-status-absen :absen="absen" />
        </template>
    </modal-fullscreen-sm>
    <!-- end::detail -->
    <!-- end::modal -->
</template>

<script>
    // components
    import TablesAbsen from '@/components/_absen/TablesAbsen.vue';
    import CardStatusAbsen from '@/components/_absen/CardStatus.vue';
    import CardsAbsen from '@/components/_absen/CardsAbsen.vue';

    // apis
    import { getsTahunAkademik } from '@/apis/tahun';
    import { getsAbsen, getAbsen } from '@/apis/jadwal';

    export default {
        components: {
            TablesAbsen,
            CardsAbsen,
            CardStatusAbsen
        },
        data() {
            return {
                daftar_ta: [],
                ta: {},
                daftar_absen: [],
                filter: {},
                absen: {},
                container_loading: true
            }
        },
        beforeMount() {
            localStorage.setItem('menu', 'Kuliah')
            localStorage.setItem('halaman', 'Rekap Absen')
        },
        mounted() {
            this.getData();
        },
        methods: {
            async getData() {
                await this.getsTahunAkademik();
                this.getsAbsen();
            },
            async getsTahunAkademik() {
                try {
                    const response = await getsTahunAkademik();
                    this.daftar_ta = response.data.data;
                    this.ta = this.daftar_ta[0];
                    this.filter.thsms = this.daftar_ta[0].thsms;

                } catch (err) {
                    console.error(err);
                }
            },
            async getsAbsen() {
                try {
                    const response = await getsAbsen(this.ta.thsms);
                    this.daftar_absen = response.data.data;
                    this.container_loading = false;
                } catch ( err ) {
                    console.error(err);
                }
            },
            processFilter() {
                const filter = this.daftar_ta.filter(e => e.thsms == this.filter.thsms);
                this.ta = filter[0];
                this.getsAbsen();
            },
            async modalDetail(e) {
                try {
                    this.absen = {};
                    const response = await getAbsen(this.ta.thsms, e);

                    this.absen = response.data.data;
                } catch ( err ) {
                    console.error( err );
                }
            }
        }
    }
</script>