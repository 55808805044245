<template>
    <table-basic>
        <template #thead>
            <tr class="fw-bolder bg-light fs-7">
                <th class="ps-4 w-50px rounded-start">No</th>
                <th class="min-w-100px">Jadwal</th>
                <th>Mata Kuliah</th>
                <th class="min-w-100px">Kelas & Ruangan</th>
            </tr>
        </template>

        <template #tbody>
            <tr v-for="(jadwal, i) in daftar_jadwal" :key="i">
                <td class="ps-4 text-end">{{ i+1 }}</td>
                <td>
                    <span class="text-dark fw-bolder d-block mb-1 fs-7">{{ jadwal.hari }}, {{ jadwal.tanggal }}</span>
                    <span class="text-muted text-muted d-block fs-8">{{ jadwal.jam }}</span>
                </td>
                <td>
                    <span class="text-dark fw-bolder d-block mb-1 fs-7">{{ jadwal.nama_matkul }}</span>
                    <span class="text-muted text-muted d-block fs-8">{{ jadwal.kode_matkul}} -
                        {{ jadwal.sks }} SKS</span>
                </td>
                <td class="pe-4">
                    <span class="text-dark fw-bolder d-block mb-1 fs-7">Ruang {{ jadwal.ruang }}</span>
                    <span class="text-muted text-muted d-block fs-8">Kelas Ujian {{ jadwal.kelas }}</span>
                </td>
            </tr>
        </template>
    </table-basic>
</template>

<script>
export default {
    props: ['daftar_jadwal']
}
</script>