<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <container-double>
        <template #left>
          <card-basic>
            <template #content>
              <div class="p-5">
                <div class="d-flex align-items-center">
                  <div id="tombol-kembali" class="me-3">
                    <button-icon
                      @click="$router.go(-1)"
                      name="Kembali"
                      class="btn-sm btn-secondary"
                    >
                      <template #icon>
                        <icon-arrow-left />
                      </template>
                    </button-icon>
                  </div>
                  <div class="my-5">
                    <label-header value="Detail Kontrak Perkuliahan" />
                  </div>
                </div>

                <form-icon>
                  <template #icon>
                    <icon-search />
                  </template>

                  <template #content>
                    <input
                      type="text"
                      class="form-control ps-14 fw-normal"
                      placeholder="Masukkan NIM atau Nama kemudian tekan enter"
                      autofocus
                      v-model="keyword"
                      @keyup.enter="fetchDaftarMahasiswa(this.keyword)"
                    />
                  </template>
                </form-icon>
              </div>
              <table-basic>
                <template #thead>
                  <tr class="fw-bolder bg-light fs-7">
                    <th class="rounded-start ps-4 w-50px">No.</th>
                    <th>NIM & Nama</th>
                    <th class="rounded-end pe-5 text-center">Status</th>
                  </tr>
                </template>

                <template #tbody>
                  <tr v-for="(item, i) in data" :key="i">
                    <td class="text-center ps-5">{{ i + 1 }}</td>
                    <td>
                      <span class="text-dark fw-bolder d-block mb-1 fs-7">
                        {{ item.nama }}
                      </span>
                      <span class="text-muted text-muted d-block fs-8">
                        {{ item.nim }}
                      </span>
                    </td>
                    <td class="text-center align-middle">
                      <div v-if="item.is_approved">
                        <span class="badge badge-primary">
                          Mahasiswa Sudah Mengisi
                        </span>
                        <br />
                        <span class="text-primary">
                          {{
                            $date(item.approved_at)
                              .locale("id")
                              .format(" D-MM-YYYY HH:mm")
                          }}
                        </span>
                      </div>
                      <span v-else class="badge badge-danger">
                        Mahasiswa Belum Mengisi
                      </span>
                    </td>
                  </tr>
                </template>
              </table-basic>
            </template>
          </card-basic>
        </template>
        <template #right>
          <card-basic>
            <template #content>
              <div class="p-5">
                <label-basic name="Informasi Mata Kuliah" />
                <div class="row">
                  <div class="col">Kode Mata Kuliah</div>
                  <div class="col-6">: {{ $route.query.kode_mata_kuliah }}</div>
                </div>
                <div class="row">
                  <div class="col">Nama Mata Kuliah</div>
                  <div class="col-6">: {{ $route.query.nama_mata_kuliah }}</div>
                </div>
                <div class="row">
                  <div class="col">Program Studi</div>
                  <div class="col-6">: {{ $route.query.program_studi }}</div>
                </div>
                <div class="row">
                  <div class="col">Kelas</div>
                  <div class="col-6">: {{ $route.query.kelas }}</div>
                </div>
                <div class="row">
                  <div class="col">Dosen</div>
                  <div class="col-6">: {{ $route.query.dosen }}</div>
                </div>
                <div class="row">
                  <div class="col">Mahasiswa Pengontrak</div>
                  <div class="col-6">: {{ $route.query.jumlah }}</div>
                </div>
                <div class="row">
                  <div class="col">Mahasiswa Menyetujui</div>
                  <div class="col-6">: {{ $route.query.disetujui }}</div>
                </div>
                <div class="separator my-5"></div>
                <div>
                  <a
                    v-if="$route.query.is_upload == 1"
                    :href="`${baseUrl}public/${$route.query.doc_rps}`"
                    target="_blank"
                    class="me-2 btn btn-sm btn-secondary btn-active-primary"
                  >
                    Lihat RPS
                  </a>
                  <button
                    v-if="$route.query.is_upload == 1"
                    class="btn btn-sm btn-secondary btn-active-primary"
                    @click="postDownloadKontrakPerkuliahan"
                    :data-kt-indicator="download_loading"
                  >
                    <span class="indicator-label"> Download Kontrak </span>
                    <span class="indicator-progress">
                      Mohon tunggu...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      >
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </template>
          </card-basic>
        </template>
      </container-double>
    </template>
  </container-app>
</template>

<script>
// API
import {
  getDaftarMahasiswa,
  postDownloadDokumenKontrak,
} from "@/apis/kontrakPerkuliahanBaru";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      container_loading: false,
      keyword: "",
      data: [],
      download_loading: "off",
    };
  },
  async mounted() {
    await this.fetchDaftarMahasiswa();
  },
  methods: {
    async fetchDaftarMahasiswa(keyword) {
      try {
        const params = new URLSearchParams();
        params.append("tahun_semester", this.$route.query.tahun_semester);
        params.append("kode_mata_kuliah", this.$route.query.kode_mata_kuliah);
        params.append("kelas", this.$route.query.kelas);
        if (keyword) {
          params.append("query", keyword);
        }
        this.container_loading = true;
        const { data: response } = await getDaftarMahasiswa(params);
        if (response.success) {
          this.data = response.data;
        } else if (!response.success) {
          this.data = null;
        }
        this.container_loading = false;
      } catch (err) {
        this.container_loading = false;
      }
    },

    async postDownloadKontrakPerkuliahan() {
      try {
        this.download_loading = "on";

        const response = await postDownloadDokumenKontrak({
          tahun_semester: this.$route.query.tahun_semester,
          kode_mata_kuliah: this.$route.query.kode_mata_kuliah,
          kelas: this.$route.query.kelas,
        });

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Kontrak-Perkuliahan-${this.$route.query.tahun_semester}-${this.$route.query.kode_mata_kuliah}-${this.$route.query.nama_mata_kuliah}-${this.$route.query.kelas}.pdf`
        );
        document.body.appendChild(link);
        link.click();

        this.download_loading = "off";
      } catch (error) {
        this.download_loading = "off";
        console.error("Error downloading PDF report:", error);
      }
    },
  },
};
</script>
