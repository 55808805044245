import { daftar_semester } from "./main";

export const daftar_transkrip = (data) => {
    let semester = [];
    semester = daftar_semester.map( semester => {
        let transkrip = [];

        data.map(d => {
            if( d.semester === semester ) {
                transkrip.push( d )
            }
        });

        return {
            semester,
            transkrip
        }
    });

    return semester.filter( s => s.transkrip.length > 0);
}