<template>
    <card-basic>
        <template #content>

            <div class="p-5">
                <div class="d-flex align-items-center">
                    <div class="border border-1 border-dashed p-2 symbol symbol-40px border-dark bg-light-dark">
                        <icon-wallet />
                    </div>
                    <div class="d-flex flex-column ms-4 w-100">
                        <span class="fw-bold text-muted fs-8">Kewajiban Pembayaran</span>
                        <div class="d-flex justify-content-between align-items-end">
                            <span class="fw-bolder fs-4">Rp. {{ informasi.total }}</span>
                            <span 
                                data-bs-toggle="collapse" 
                                href="#detail" 
                                role="button" 
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                @click="handleDetail"
                                >
                                Detail
                                <icon-arrow-bottom v-if="!detail" />
                                <icon-arrow-up v-else/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="collapse" id="detail">
                <div class="text-gray-700 mt-4">
                    <tables-rincian :daftar_rincian="daftar_rincian" />
                </div>
            </div>

        </template>
    </card-basic>

    <card-basic>
        <template #content>
            <div class="row p-5">
                <div class="col-6">
                    <label-icon-warna warna="bg-light-success border-success" label="Telah Dibayar" :value="'Rp. '+informasi.dibayar">
                        <template #icon>
                            <icon-status-2 />
                        </template>
                    </label-icon-warna>
                </div>
                <div class="col-6">
                    <label-icon-warna warna="bg-light-danger border-danger" label="Tunggakan" :value="'Rp. '+informasi.tunggakan">
                        <template #icon>
                            <icon-status-0 />
                        </template>
                    </label-icon-warna>
                </div>
            </div>
        </template>
    </card-basic>
</template>

<script>
import TablesRincian from '@/components/_keuangan/TablesRincian.vue';

export default {
    props: ['informasi', 'daftar_rincian'],
    components: {
        TablesRincian
    },
    data() {
        return {
            detail: false
        }
    },
    methods: {
        handleDetail() {
            this.detail = !this.detail;
        }
    }
}
</script>