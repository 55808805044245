<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <!-- START CARD INFORMATION -->

          <alert-icon v-if="status.empty" type="primary">
            <template #body>
              <span>
                <span>
                  Jadwal Penilaian Ujian Akhir Semester (UAS) belum dibuat.
                </span>
              </span>
            </template>
          </alert-icon>

          <alert-icon v-if="status.buka" type="primary">
            <template #body>
              <span>
                <span>Penilaian Ujian Akhir Semester (UAS) dibuka pada </span>
                <span class="text-success fw-bold">
                  {{
                    $date(status.tgl_buka)
                      .locale("id")
                      .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                  }}</span
                >
                <span> s.d. </span>
                <span class="text-danger fw-bold">
                  {{
                    $date(status.tgl_tutup)
                      .locale("id")
                      .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                  }}
                </span>
              </span>
            </template>
          </alert-icon>

          <alert-icon v-if="status.berlangsung" type="warning">
            <template #body>
              <span>
                <span>
                  Penilaian Ujian Akhir Semester (UAS) sedang berlangsung dari
                </span>
                <span class="text-success fw-bold">
                  {{
                    $date(status.tgl_buka)
                      .locale("id")
                      .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                  }}
                </span>
                <span> s.d. </span>
                <span class="text-danger fw-bold">
                  {{
                    $date(status.tgl_tutup)
                      .locale("id")
                      .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                  }}
                </span>
              </span>
            </template>
          </alert-icon>

          <alert-icon v-if="status.tutup" type="danger">
            <template #body>
              <span>
                <span>
                  Penilaian Ujian Akhir Semester (UAS) telah ditutup pada
                </span>
                <span class="text-danger fw-bold">
                  {{
                    $date(status.tgl_tutup)
                      .locale("id")
                      .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                  }}
                </span>
              </span>
            </template>
          </alert-icon>
          <!-- END CARD INFORMATION -->
          <div
            id="kartu-header"
            class="p-5 d-flex align-items-center justify-content-between"
          >
            <div id="judul">
              <span class="text-gray-700 mb-2 fw-normal fs-8 d-block">
                Penilaian Hasil Belajar
              </span>
              <span class="fw-bolder fs-5">Ujian Akhir Semester (UAS)</span>
            </div>
            <div id="aksi" class="d-flex align-items-center">
              <div>
                <select
                  class="form-select form-select-solid"
                  data-placeholder="Pilih Tahun Semester"
                  v-model="selected.tahun_semester"
                  @change="onSelectedTahunSemesterChange($event)"
                >
                  <option
                    v-for="(item, i) in tahun_semester"
                    :key="i"
                    :value="item.thsms"
                  >
                    Tahun {{ item.ket }}
                  </option>
                </select>
              </div>

              <div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#bobot_nilai"
                  type="button"
                  class="btn btn-primary btn-sm ms-3"
                >
                  Bobot Nilai
                </button>
              </div>
            </div>
          </div>
          <div id="pencarian" class="mx-5 mb-5">
            <form-icon>
              <template #icon>
                <icon-search />
              </template>

              <template #content>
                <input
                  type="text"
                  class="form-control ps-14 fw-normal"
                  placeholder="Pencarian berdasarkan Mata Kuliah"
                  v-model="filters.nama_mata_kuliah"
                  @keydown.enter="onSearchMataKuliah"
                />
              </template>
            </form-icon>
            <small class="form-text text-muted">
              Tekan Enter untuk memulai pencarian</small
            >
          </div>
          <div id="tabel">
            <table-basic v-if="data.result > 0">
              <template #thead>
                <tr class="fw-bolder bg-light fs-7">
                  <th class="rounded-start w-50px ps-3">No</th>
                  <th class="min-w-100px">Mata Kuliah</th>
                  <th class="min-w-100px">Program Studi</th>
                  <th class="min-w-100px">Kelas</th>
                  <th class="min-w-100px">Kelas Ujian</th>
                  <th class="min-w-100px">Status</th>
                  <th class="rounded-end"></th>
                </tr>
              </template>
              <template #tbody>
                <tr v-for="(item, i) in data.data" :key="i">
                  <td class="text-center align-middle">{{ i + 1 }}</td>
                  <td class="align-middle">
                    <span class="text-dark fw-bolder d-block mb-1 fs-7">
                      {{ item.nama_mata_kuliah }}
                    </span>
                    <span class="text-muted text-muted d-block fs-8">
                      {{ item.kode_mata_kuliah }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span class="text-dark fw-bolder d-block mb-1 fs-7">
                      {{ item.program_studi }}
                    </span>
                    <span class="text-muted text-muted d-block fs-8">
                      Semester {{ item.semester }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span class="text-dark fw-bolder d-block mb-1 fs-7">
                      {{ item.kelas }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span class="text-dark fw-bolder d-block mb-1 fs-7">
                      {{ item.kelas_ujian }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span class="text-dark fw-bold d-block mb-1 fs-7">
                      Dosen Mengunci Nilai:
                      <span v-if="item.dikunci" class="text-success">
                        <icon-done />
                      </span>
                      <span v-else class="text-danger"><icon-cross /></span>
                    </span>

                    <span class="text-dark fw-bold d-block mb-1 fs-7">
                      Validasi Program Studi:
                      <span v-if="item.divalidasi" class="text-success">
                        <icon-done />
                      </span>
                      <span v-else class="text-danger"><icon-cross /></span>
                    </span>
                  </td>
                  <td class="align-middle">
                    <a
                      @click="onGetDetailUas(item)"
                      class="mt-5 mt-lg-0 btn btn-sm btn-secondary btn-active-primary"
                      :class="
                        status.berlangsung || status.tutup ? '' : 'disabled'
                      "
                    >
                      Detail
                    </a>
                  </td>
                </tr>
              </template>
            </table-basic>
            <!-- START CARD EMPTY STATE -->
            <div
              v-else
              class="alert alert-primary d-flex align-items-center mx-5 p-5"
            >
              <div class="d-flex flex-column">
                <span> Data UAS Tidak Tersedia! </span>
              </div>
            </div>
            <!-- END CARD EMPTY STATE -->
          </div>
        </template>
      </card-basic>
    </template>
  </container-app>

  <!-- START Modal Bobot Nilai -->
  <div class="modal fade" tabindex="-1" id="bobot_nilai">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Bobot & Instrumen Penilaian</h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <icon-close />
          </div>
        </div>
        <div class="modal-body">
          <div class="mb-6 p-3 border">
            <label-header class="mb-5" value="Bobot Nilai" />

            <table-basic>
              <template #thead>
                <tr class="fw-bolder bg-light fs-7">
                  <th class="ps-4 w-50px rounded-start">No</th>
                  <th class="min-w-100px">Nilai Huruf</th>
                  <th class="rounded-end min-w-100px">Rentang Nilai</th>
                </tr>
              </template>

              <template #tbody>
                <tr v-for="(item, i) in daftarNilaiHuruf" :key="i">
                  <td class="text-center align-middle">{{ i + 1 }}</td>
                  <td class="align-middle">
                    {{ item.nilai_huruf }}
                  </td>
                  <td class="align-middle">
                    {{ item.batas_bawah }} {{ item.batas_bawah_simbol }}
                    {{ item.nilai_huruf }} {{ item.batas_atas_simbol }}
                    {{ item.batas_atas }}
                  </td>
                </tr>
              </template>
            </table-basic>
          </div>
          <div class="p-3 border">
            <label-header class="mb-5" value="Instrumen Penilaian" />
            <table-basic>
              <template #thead>
                <tr class="fw-bolder bg-light fs-7">
                  <th class="ps-4 w-50px rounded-start">No</th>
                  <th class="min-w-100px">Nama Instrumen Nilai</th>
                  <th class="rounded-end min-w-100px text-center">
                    Persentase
                  </th>
                </tr>
              </template>

              <template #tbody>
                <tr v-for="(item, i) in daftarInstrumentNilai" :key="i">
                  <td class="text-center align-middle">{{ i + 1 }}</td>
                  <td class="align-middle">
                    {{ item.instrumen_nilai }}
                  </td>
                  <td class="align-middle text-center">
                    {{ item.persentase }}%
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td class="fw-bolder">Total</td>
                  <td class="fw-bolder align-middle text-center">
                    {{ totalPersentase }}%
                  </td>
                  <td></td>
                </tr>
              </template>
            </table-basic>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END Modal Bobot Nilai -->
</template>

<script>
// API
import {
  getTahunSemester,
  uasDaftar,
  uasCekJadwalStatus,
} from "../../apis/PenilaianHasilBelajar/uasSusulan";

import { getNilaiHuruf, getInstrumenNilai } from "@/apis/penilaianUjian";

// Store
import { useMatkulStore } from "@/stores/matkul";

export default {
  beforeMount() {
    localStorage.setItem("menu", "AISnet");
    localStorage.setItem("halaman", "Penilaian Hasil Belajar");
  },
  async mounted() {
    await this.fetchInstrumenNilai();
    await this.fetchNilaiHuruf();
    await this.tahunSemesterFetch();
    await this.statusFetch(this.selected.tahun_semester);
    await this.uasFetch(this.selected.tahun_semester, null);
  },
  setup() {
    const matkul = useMatkulStore();

    return {
      matkul,
    };
  },
  data() {
    return {
      container_loading: false,
      selected: {},
      status: {},
      filters: {},
      data: [],
      tahun_semester: [],
      daftarNilaiHuruf: [],
      daftarInstrumentNilai: [],
    };
  },

  methods: {
    async uasFetch(tahun_semester, nama_mata_kuliah) {
      this.container_loading = true;
      const { data } = await uasDaftar(tahun_semester, nama_mata_kuliah);

      if (data.success) {
        this.data = data;
        this.container_loading = false;
      } else {
        this.data = null;
        this.container_loading = false;
      }
    },
    async statusFetch(tahun_semester) {
      this.container_loading = true;
      const { data } = await uasCekJadwalStatus(tahun_semester);

      if (data.success) {
        this.status = data.data;
        this.container_loading = false;
      } else {
        this.status = data.data;
        this.container_loading = false;
      }
    },

    async tahunSemesterFetch() {
      try {
        const response = await getTahunSemester();
        this.tahun_semester = response.data.data;
        this.selected.tahun_semester = response.data.data[0].thsms;
      } catch (err) {
        console.error(err);
      }
    },

    async fetchNilaiHuruf() {
      try {
        this.container_loading = true;
        const response = await getNilaiHuruf();
        if (response.status === 200) {
          this.daftarNilaiHuruf = response.data.data;

          this.container_loading = false;
        } else if (response.status === 404) {
          Swal.fire({
            text: response.data.message,
            icon: "warning",
          });
          this.container_loading = false;
        }
      } catch (error) {
        this.container_loading = false;
        Swal.fire({
          text: error.response.data.message,
          icon: "error",
        });
      }
    },

    async fetchInstrumenNilai() {
      try {
        this.container_loading = true;
        const response = await getInstrumenNilai();
        if (response.status === 200) {
          this.daftarInstrumentNilai = response.data.data;

          this.container_loading = false;
        } else if (response.status === 404) {
          Swal.fire({
            text: response.data.message,
            icon: "warning",
          });
          this.container_loading = false;
        }
      } catch (error) {
        this.container_loading = false;
        Swal.fire({
          text: error.response.data.message,
          icon: "error",
        });
      }
    },

    onGetDetailUas(matkul) {
      const { kode_mata_kuliah, nama_mata_kuliah, kelas, kelas_ujian } = matkul;

      this.matkul.set(
        this.selected.tahun_semester,
        nama_mata_kuliah,
        kode_mata_kuliah,
        kelas,
        kelas_ujian
      );

      this.$router.push({
        path: `/penilaian/uas-susulan/detail`,
      });
    },
    onSelectedTahunSemesterChange(event) {
      this.statusFetch(event.target.value);
      this.uasFetch(event.target.value);
    },
    onSearchMataKuliah() {
      this.uasFetch(
        this.selected.tahun_semester,
        this.filters.nama_mata_kuliah
      );
    },
  },

  computed: {
    totalPersentase() {
      let total = 0;
      for (let item of this.daftarInstrumentNilai) {
        total += parseInt(item.persentase);
      }
      return total;
    },
  },
};
</script>

<style></style>
