<template>
    <container-app :container_loading="container_loading">
        <template #content>
            <container-double>
                <template #left>
                    <div v-if="message != ''">
                        <alert-basic
                            :class="informasi.status_perwalian == 'YA' || informasi.status_perwalian == 'MENUNGGU' ? 'alert-primary' : 'alert-danger'"
                            :message="message" />

                        <div v-if="!validasi">
                            <div v-if="informasi.status_perwalian != 'TIDAK'">
                                <perwalian-mahasiswa-validasi
                                    :status_mahasiswa="informasi.sisa_bayar == '-' ? 'Tidak Aktif' : 'Aktif'"
                                    :status_keuangan="informasi.sisa_bayar" />
                            </div>
                        </div>

                        <div>
                            <card-basic class="d-block d-md-none">
                                <template #content>
                                    <div class="p-5 d-flex justify-content-between align-items-center">
                                        <label-icon-2 label="Dosen Wali" :value="informasi_frs.dosen_nama">
                                            <template #icon>
                                                <icon-user />
                                            </template>
                                        </label-icon-2>
                                        <button class="btn btn-secondary btn-sm px-3 px-md-4 position-relative" data-bs-toggle="modal" data-bs-target="#modalKonsultasi" @click="getsKonsultasi">
                                            <icon-chat-2 />
                                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                {{ belum_dibaca }}
                                            </span>
                                        </button>
                                    </div>
                                </template>
                            </card-basic>

                            <div class="d-block d-md-none">
                                <card-basic>
                                    <template #content>
                                        <div class="px-5 py-3">
                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                <span>
                                                    Keterangan & Batas SKS
                                                </span>
                                                <span 
                                                    data-bs-toggle="collapse" 
                                                    href="#keteranganbatas" 
                                                    role="button" 
                                                    aria-expanded="false"
                                                    aria-controls="collapseExample"
                                                    @click="handleDetail"
                                                    >
                                                    Detail
                                                    <icon-arrow-bottom v-if="!detail" />
                                                    <icon-arrow-up v-else/>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="collapse" id="keteranganbatas">
                                            <div class="mt-2 pt-2 px-5 fs-7">
                                                <ul class="nav nav-tabs">
                                                    <li class="nav-item">
                                                        <a class="nav-link active" data-bs-toggle="tab" href="#keterangan">Keterangan</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" data-bs-toggle="tab" href="#batas">Batas SKS</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="tab-content" id="myTabContent">
                                                <div class="tab-pane fade show active p-5" id="keterangan" role="tabpanel">
                                                    <info-status-mahasiswa />
                                                </div>
                                                <div class="tab-pane fade" id="batas" role="tabpanel">
                                                    <info-batas />
                                                </div>
                                            </div>
                                        </div>
                    
                                    </template>
                                </card-basic>
                            </div>

                            <card-basic>
                                <template #content>
                                    <div class="p-5 d-flex justify-content-between align-items-center">
                                        <label-basic name="Form Rencana Studi (FRS)" />
                                        <div class="d-flex">
                                            <a 
                                                href="https://drive.google.com/drive/folders/1m2yZ_WmDFmNpDweZNX4PS1gkB4jTiNED?usp=sharing"
                                                class="btn btn-sm btn-secondary d-flex align-items-center px-3 px-md-4"
                                                target="_blank"
                                                >
                                                <icon-calender /><span class="d-none d-md-inline ms-2">Jadwal Kuliah</span>
                                            </a>
                                            <button-icon v-if="validasi" name="Tambah" class="btn-sm btn-secondary ms-3" data-bs-toggle="modal" data-bs-target="#modalMatakuliah">
                                                <template #icon>
                                                    <icon-plus />
                                                </template>
                                            </button-icon>
                                        </div>
                                    </div>

                                    <div class="row px-5 pb-5">
                                        <div class="col-6">
                                            <div class="d-flex align-items-center">
                                                <div
                                                    class="bg-light-primary border border-1 border-dashed border-primary p-2 symbol symbol-40px">
                                                    <icon-status-1 />
                                                </div>
                                                <div class="d-flex flex-column ms-4">
                                                    <span class="fw-bold text-muted fs-8">SKS Diajukan</span>
                                                    <span class="fw-bolder fs-7">{{ informasi_frs.jumlah_sks }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="d-flex align-items-center">
                                                <div
                                                    class="bg-light-primary border border-1 border-dashed border-primary p-2 symbol symbol-40px">
                                                    <icon-status-2 />
                                                </div>
                                                <div class="d-flex flex-column ms-4">
                                                    <span class="fw-bold text-muted fs-8">SKS Disetujui</span>
                                                    <span
                                                        class="fw-bolder fs-7">{{ informasi_frs.jumlah_disetujui  }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table align-middle gs-0 gy-4 table-row-dashed">
                                            <thead>
                                                <tr class="fw-bolder bg-light fs-7">
                                                    <th class="rounded-start ps-4 w-50px">No</th>
                                                    <th>Mata Kuliah</th>
                                                    <th class="w-auto w-50-px">SKS</th>
                                                    <th class="w-auto w-50-px">Status</th>
                                                    <th class="rounded-end pe-4">Aksi</th>
                                                </tr>
                                            </thead>

                                            <tbody v-for="(frs, i) in daftar_frs" :key="i">
                                                <tr class="text-center bg-dark text-light">
                                                    <td colspan="5">Semester {{ frs.semester }}</td>
                                                </tr>
                                                <tr class="fs-7" v-for="(matakuliah, l) in frs.transkrip" :key="l">
                                                    <td class="text-end">{{ l+1 }}</td>
                                                    <td>
                                                        <span
                                                            class="text-dark fw-bold d-block">{{ matakuliah.nakmk }}</span>
                                                        <span class="fs-8 d-block">{{ matakuliah.kdkmk }}</span>
                                                    </td>
                                                    <td>{{ matakuliah.sks }}</td>
                                                    <td>
                                                        <icon-status-2 v-if="matakuliah.status_kode == 2" />
                                                        <icon-status-1 v-else-if="matakuliah.status_kode == 1" />
                                                        <icon-status-0 v-else-if="matakuliah.status_kode == 3" />
                                                        <icon-status-3 v-else />
                                                    </td>
                                                    <td class="pe-4">
                                                        <button-icon name="Hapus" 
                                                            class="btn-sm btn-danger" 
                                                            v-if="matakuliah.status_kode != 1 && matakuliah.status_kode != 2" 
                                                            @click="deleteFrs(matakuliah.id)">
                                                            <template #icon>
                                                                <icon-eraser />
                                                            </template>
                                                        </button-icon>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <button class="btn btn-primary w-100 mt-5" :disabled="loading_ajukan" v-if="informasi_frs.dosen_status != 1 && validasi" @click="submitFrs">
                                        <icon-loading v-if="loading_ajukan" />
                                        <span v-else>
                                            Ajukan Mata Kuliah
                                        </span>
                                    </button>

                                </template>
                            </card-basic>
                        </div>
                    </div>
                </template>

                <template #right>
                    <!-- <card-basic v-if="validasi"> -->
                    <card-basic>
                        <template #content>
                            <div class="p-5 d-flex justify-content-between align-items-center">
                                <label-icon-2 label="Dosen Wali" :value="informasi_frs.dosen_nama">
                                    <template #icon>
                                        <icon-user />
                                    </template>
                                </label-icon-2>
                                <button class="btn btn-secondary btn-sm px-3 px-md-4 position-relative" 
                                    data-bs-toggle="modal" 
                                    data-bs-target="#modalKonsultasi" 
                                    @click="getsKonsultasi">
                                    <icon-chat-2 />
                                   <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                        {{ belum_dibaca }}
                                    </span>
                                </button>
                            </div>
                        </template>
                    </card-basic>
                    <card-basic>
                        <template #content>
                            <div class="p-5">
                                <info-status-mahasiswa />
                            </div>
                        </template>
                    </card-basic>
                    <card-basic>
                        <template #content>
                            <info-batas />
                        </template>
                    </card-basic>
                </template>
            </container-double>
        </template>
    </container-app>

    <!-- begin::modal -->
    <!-- begin::modalMatakuliah -->
    <modal-fullscreen-sm id="modalMatakuliah" title="Daftar Mata Kuliah">
        <template #content>

            <card-basic>
                <template #content>
                    <div class="px-5 py-3">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <span>
                                Keterangan Warna Mata Kuliah
                            </span>
                            <span 
                                data-bs-toggle="collapse" 
                                href="#batasSKS" 
                                role="button" 
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                @click="handleDetail2"
                                >
                                Detail
                                <icon-arrow-bottom v-if="!detail2" />
                                <icon-arrow-up v-else/>
                            </span>
                        </div>
                    </div>
                    <div class="collapse" id="batasSKS">
                        <div class="text-gray-700 mt-2 pt-4 border-top fs-7 p-5">
                            <ul class="ps-0 m-0">
                                <li class="py-3 ms-5 ps-4 bg-light-success">Mata Kuliah Wajib</li>
                                <li class="py-3 ms-5 ps-4 bg-light-warning">Mata Kuliah Pilihan</li>
                                <li class="py-3 ms-5 ps-4 bg-light-danger">Mata Kuliah Merdeka Belajar</li>
                            </ul>
                        </div>
                    </div>

                </template>
            </card-basic>

            <card-basic>
                <template #content>
                    <div class="p-5">
                        <div class="d-flex justify-content-between align-items-center">
                            <label-basic name="Daftar Mata Kuliah" />
                            <select v-model="semester" class="form-select form-select-solid w-auto"
                                @change="processSemester">
                                <option v-for="(smt, i) in daftar_semester" :key="i" :value="smt">Semester {{ smt }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table align-middle gs-0 gy-4 table-row-dashed">
                            <thead>
                                <tr class="fw-bolder bg-light fs-7">
                                    <th class="rounded-start ps-4 w-50px">No</th>
                                    <th>Mata Kuliah</th>
                                    <th class="w-auto w-50-px">SKS</th>
                                    <th class="rounded-end pe-4">Aksi</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr class="fs-7" v-for="(matakuliah, i) in daftar_matakuliah" :key="i"
                                    :class="matakuliah.jenis_matakuliah == 0 ? 'bg-light-warning' : matakuliah.jenis_matakuliah == 1 ? 'bg-light-success' : 'bg-light-danger'">
                                    <td class="text-end">{{i+1}}</td>
                                    <td>
                                        <span class="text-dark fw-bold d-block">{{ matakuliah.nakmk }}</span>
                                        <span class="fs-8 d-block">{{ matakuliah.kdkmk }}</span>
                                    </td>
                                    <td>{{ matakuliah.sksmk }}</td>
                                    <td class="pe-4">
                                        <button-icon name="Tambah" class="btn-sm btn-secondary" @click="kontrakMk(matakuliah)">
                                            <template #icon>
                                                <icon-plus />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </card-basic>

        </template>
    </modal-fullscreen-sm>
    <!-- end::modalMatakuliah -->

    <!-- begin::modalKonsultasi -->
    <modal-scroll id="modalKonsultasi" title="Konsultasi" body_id="containerKonsultasi">
        <template #body>
            <div class="d-flex flex-column">

                <div class="flex-fill mb-3 pt-5" style="min-height: 50vh">
                    <div class="text-center" v-if="loading_chat">
                        <icon-loading />
                    </div>

                    <div v-for="(chat, i) in daftar_chat" :key="i">
                        <div class="d-flex mx-5 mb-3" :class="chat.sender == 'Saya' ? 'justify-content-end' : 'justify-content-start'">
                            <div class="d-flex flex-column" :class="chat.sender == 'Saya' ? 'align-items-end' : 'align-items-start'">
                                <span class="fs-7 fw-bold text-gray-900 text-hover-primary mb-1">{{ chat.sender }}</span>
                                <div class="p-3 rounded border border-secondary bg-white text-dark mw-lg-400px fs-7 mb-1 text-start" v-html="chat.chat"></div>
                                <span class="text-muted fs-8">{{ chat.time }}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </template>

        <template #footer>
            <div class="d-flex w-100">
                <textarea 
                    class="form-control border border-secondary fw-normal bg-light form-control-flush rounded" 
                    id="formChat" 
                    v-model="chat" 
                    style="overflow-y: hidden;" 
                    rows="1" @keyup.enter="sendKonsultasi"></textarea>
                <button class="btn btn-primary flex-grow ms-3 align-self-end" @click="sendKonsultasi">
                    Kirim
                </button>
            </div>
        </template>
    </modal-scroll>
    <!-- end::modalKonsultasi -->
    <!-- end::modal -->
</template>

<script>
    // apis
    import { getActive, getsMatakuliah } from "@/apis/perwalian";
    import { getFrs, storeFrs, submitFrs, deleteFrs } from "@/apis/frs";
    import { getsKonsultasi, sendKonsultasi } from '@/apis/chatKonsultasi';

    // components
    import PerwalianMahasiswaValidasi from '@/components/_perwalianMahasiswa/Validasi.vue';

    // store
    import { getUserData } from "@/store";

    // helpers
    import { formatDate, formatSemester } from "@/helpers/waktu";
    import { daftar_transkrip } from '@/helpers/transkrip';
    import { daftar_semester } from "@/helpers/waktu";

    export default {
        components: {
            PerwalianMahasiswaValidasi
        },
        data() {
            return {
                user: {},
                informasi: {},
                validasi: false,
                message: '',
                informasi_frs: {},
                daftar_frs: {},
                daftar_semester: [],
                semester: '',
                daftar_matakuliah: {},
                detail: false,
                detail2: false,
                container_loading: true,
                containerKonsultasi: 0,
                daftar_chat: {},
                belum_dibaca: 0,
                chat: '',
                loading_ajukan: false,
                loading_chat: false
            }
        },
        beforeMount() {
            localStorage.setItem('menu', 'AISnet');
            localStorage.setItem('halaman', 'Perwalian');

        },
        async mounted() {
            this.user = await getUserData();
            await this.getActive();
            await this.getsKonsultasi();
            this.container_loading = false;
        },
        methods: {
            async getActive() {
                try {
                    const response = await getActive(this.user);
                    this.informasi = response.data;

                    const jenis = this.informasi.jenis == 'PERWALIAN' ? 'Pengisian' : 'Perubahan Perwalian/';
                    const angkatan = this.informasi.angkatan == '-' ? '' : ` untuk angkatan ${this.informasi.angkatan}`
                    
                    if (this.informasi.status_perwalian == 'MENUNGGU') {
                        this.message = jenis+" Formulir Rencana Studi (FRS) Semester "+ formatSemester(this.informasi
                                .thsms) + angkatan + "</span> dibuka pada hari <span style='color: #e67e22'>" + formatDate(this.informasi.waktu_mulai) +
                            "</span> dan ditutup pada hari <span class='text-danger'>" + formatDate(this.informasi.waktu_tutup)+"</span>";
                    } else if (this.informasi.status_perwalian == 'YA') {
                        this.message = jenis+' Formulir Rencana Studi (FRS) Semester ' + formatSemester(this.informasi
                            .thsms)  + angkatan + " ditutup pada hari <span class='text-danger'>" + formatDate(this.informasi.waktu_tutup)+"</span>";
                    } else if (this.informasi.status_perwalian == 'TIDAK') {
                        this.message = jenis+' Formulir Rencana Studi (FRS) Semester ' + formatSemester(this.informasi
                            .thsms)  + angkatan + " telah ditutup pada hari <span class='text-danger'>" + formatDate(this.informasi.waktu_tutup)+"</span>";
                    }

                    if (this.informasi.status_perwalian == 'YA') {
                        if (this.informasi.status_administrasi == 'YA') {
                            this.daftar_semester = daftar_semester(this.informasi.thsms);
                            this.semester = this.daftar_semester[0];
                            this.validasi = true;
                            
                            this.getsMatakuliah();
                        }
                    }
                    await this.getFrs();

                } catch (err) {
                    console.error(err);
                }
            },
            async getFrs() {
                try {
                    const response = await getFrs(this.user.nim);
                    this.informasi_frs = response.data.informasi;
                    this.daftar_frs = daftar_transkrip(response.data.data);
                } catch (err) {
                    console.error(err);
                }
            },
            async getsMatakuliah() {
                try {
                    const response = await getsMatakuliah(this.user.nim, this.semester);
                    this.daftar_matakuliah = response.data.data;
                } catch (err) {
                    console.error(err);
                }
            },
            processSemester() {
                this.getsMatakuliah();
            },
            async kontrakMk(matakuliah) {
                try {
                    let formData = new FormData();
                    formData.append('nim', this.user.nim);
                    formData.append('kdjen', matakuliah.kdjen);
                    formData.append('kdpst', matakuliah.kdpst);
                    formData.append('kdklm', matakuliah.kdklm);
                    formData.append('kdkmk', matakuliah.kdkmk);
                    formData.append('sks', matakuliah.sksmk);
                    formData.append('nodos', this.informasi_frs.dosen_nodos);

                    const response = await storeFrs(formData);
                    
                    if( response.data.status ) {
                        Swal.fire({
                            icon: 'success',
                            text: 'Mata Kuliah '+matakuliah.nakmk+' berhasil ditambahkan',
                            showConfirmButton: false,
                            timer: 5000
                        });

                        this.getFrs();
                        this.getsMatakuliah();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: response.data.pesan,
                            showConfirmButton: false,
                            timer: 5000
                        })
                    }

                } catch (err) {
                    console.error( err );
                }
            },
            submitFrs() {
                Swal.fire({
                    icon: 'question',
                    text: 'Ajukan Mata Kuliah ?',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                    confirmButtonText: 'Ya',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-secondary'
                    }
                }).then( async res => {
                    if( res.isConfirmed ) {
                        this.loading_ajukan = true;
                        try {
                            const response = await submitFrs(this.user.nim);

                            if( response.status == 200 ) {
                                this.getFrs();
                                
                                Swal.fire({
                                    icon: 'success',
                                    text: 'FRS berhasil diajukan kepada dosen wali',
                                    showConfirmButton: false,
                                    timer: 5000
                                });
                            }
                        } catch (err) {
                            console.error(err);
                            Swal.fire({
                                icon: 'error',
                                text: 'FRS gagal diajukan kepada dosen wali',
                                showConfirmButton: false,
                                timer: 5000
                            });
                        }
                        this.loading_ajukan = false;
                    }
                })
            },
            deleteFrs(id) {
                Swal.fire({
                    icon: 'question',
                    text: 'Hapus Mata Kuliah dari FRS ?',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                    confirmButtonText: 'Ya',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-secondary'
                    }
                }).then( async res => {
                    if( res.isConfirmed ) {
                        try {
                            const response = await deleteFrs(id);
    
                            if( response.data.status ) {
                                Swal.fire({
                                    icon: 'success',
                                    text: response.data.pesan,
                                    showConfirmButton: false,
                                    timer: 5000
                                });

                                this.getFrs();
                                this.getsMatakuliah();
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    text: response.data.pesan,
                                    showConfirmButton: false,
                                    timer: 5000
                                })
                            }
                        } catch (err) {
                            console.error(err);
                            Swal.fire({
                                icon: 'error',
                                text: 'Mata Kuliah gagal dihapus dari FRS',
                                showConfirmButton: false,
                                timer: 5000
                            });

                            this.getFrs();
                        }
                    }
                });
            },
            handleDetail() {
                this.detail = !this.detail;
            },
            handleDetail2() {
                this.detail2 = !this.detail2;
            },
            async getsKonsultasi() {
                this.daftar_chat = {};
                this.loading_chat = true;
                try {
                    const response = await getsKonsultasi(this.informasi_frs.dosen_nodos);
                    this.daftar_chat = response.data.data.map(e=> {
                        return {
                            sender : e.sender,
                            chat : e.chat,
                            time : formatDate(e.time),
                        }
                    });

                    this.belum_dibaca = response.data.belum_dibaca;

                    console.log( response.data );

                    let e = document.getElementById('containerKonsultasi');
                    setTimeout(() => {
                        e.scrollTop = e.scrollHeight;
                    }, 100);

                    document.getElementById("formChat").autofocus;
                } catch (err) {
                    console.error( err );
                }
                this.loading_chat = false;
            },
            async sendKonsultasi() {
                try {
                    let formData = new FormData();
                    formData.append('receiver_id', this.informasi_frs.dosen_nodos);
                    formData.append('chat', this.chat);

                    const response = await sendKonsultasi(formData);

                    if( response.status == 200 ) {
                        await this.daftar_chat.push({   
                            sender: 'Saya',
                            chat: this.chat,
                            time: formatDate(new Date())
                        })

                        let e = document.getElementById('containerKonsultasi');
                        e.scrollTop = e.scrollHeight;
                        this.belum_dibaca = 0;
                        document.getElementById("formChat").autofocus;

                        this.chat = '';
                    }
                } catch ( err ) {
                    console.error( err );
                }
            },
            addEnter() {
                this.chat = this.chat + '<br>';
            }
        }
    }
</script>

<style>
html {
    scroll-behavior: smooth;
}
</style>