<template>
    <div class="menu-item">
        <div class="menu-content pt-8 pb-2">
            <span class="menu-section text-muted text-uppercase fs-8 ls-1">{{ nama }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['nama']
    }
</script>