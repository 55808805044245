<template>
  <div class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <div
        id="kt_aside"
        class="aside aside-dark aside-hoverable"
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_mobile_toggle"
      >
        <!--begin::Brand-->
        <div class="aside-logo flex-column-auto" id="kt_aside_logo">
          <!--begin::Logo-->
          <a href="">
            <img alt="Logo" src="@/assets/logo.png" class="h-25px logo" />
          </a>
          <!--end::Logo-->
          <!--begin::Aside toggler-->
          <div
            id="kt_aside_toggle"
            class="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle active"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
          >
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr079.svg-->
            <span class="svg-icon svg-icon-1 rotate-180">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.5"
                  d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                  fill="currentColor"
                />
                <path
                  d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </div>
          <!--end::Aside toggler-->
        </div>
        <!--end::Brand-->
        <!--begin::Aside menu-->
        <div class="aside-menu flex-column-fluid">
          <!--begin::Aside Menu-->
          <div
            class="hover-scroll-overlay-y my-5 my-lg-5"
            id="kt_aside_menu_wrapper"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-wrappers="#kt_aside_menu"
            data-kt-scroll-offset="0"
          >
            <!--begin::Menu-->
            <div
              class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
              id="#kt_aside_menu"
              data-kt-menu="true"
              data-kt-menu-expand="false"
            >
              <Menu />
            </div>
            <!--end::Menu-->
          </div>
          <!--end::Aside Menu-->
        </div>
        <!--end::Aside menu-->
        <!--begin::Footer-->
        <div
          class="aside-footer flex-column-auto pt-5 pb-7 px-5"
          id="kt_aside_footer"
        >
          <a
            :href="panduan_penggunaan"
            target="_blank"
            class="btn btn-custom btn-primary w-100"
          >
            <span class="btn-label">Panduan Penggunaan</span>
            <span class="svg-icon btn-icon svg-icon-2">
              <icon-dock />
            </span>
            <!--end::Svg Icon-->
          </a>
        </div>
        <!--end::Footer-->
      </div>

      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <div id="kt_header" style="" class="header align-items-stretch">
          <!--begin::Container-->
          <div
            class="container-fluid d-flex align-items-stretch justify-content-between"
          >
            <!--begin::Aside mobile toggle-->
            <div
              class="d-flex align-items-center d-lg-none ms-n2 me-2"
              title="Show aside menu"
            >
              <div
                class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                id="kt_aside_mobile_toggle"
              >
                <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                <span class="svg-icon svg-icon-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </div>
            </div>
            <!--end::Aside mobile toggle-->
            <!--begin::Mobile logo-->
            <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
              <a href="" class="d-lg-none mx-auto">
                <img alt="Logo" src="@/assets/logo-dark.png" class="h-20px" />
              </a>
            </div>
            <!--end::Mobile logo-->
            <!--begin::Wrapper-->
            <div
              class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
            >
              <!--begin::Navbar-->
              <div class="d-flex align-items-center" id="kt_header_nav">
                <!--begin::Page title-->
                <div
                  data-kt-swapper="true"
                  data-kt-swapper-mode="prepend"
                  data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_header_nav'}"
                  class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                >
                  <!--begin::Title-->
                  <h1
                    class="d-flex text-dark fw-bolder fs-3 align-items-center my-1"
                  >
                    {{ menu }}
                  </h1>
                  <!--end::Title-->
                  <!--begin::Separator-->
                  <span class="h-20px border-gray-500 border-start mx-4"></span>
                  <!--end::Separator-->
                  <span class="text-gray-700">{{ halaman }}</span>
                </div>
                <!--end::Page title-->
              </div>
              <!--end::Navbar-->
              <!--begin::Toolbar wrapper-->
              <div class="d-flex align-items-stretch flex-shrink-0">
                <!--begin::User menu-->
                <div
                  class="d-flex align-items-center ms-1 ms-lg-3"
                  id="kt_header_user_menu_toggle"
                >
                  <!--begin::Menu wrapper-->
                  <div
                    class="cursor-pointer symbol symbol-30px symbol-md-40px d-flex"
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                  >
                    <img :src="user.foto" alt="user" />
                    <div class="d-flex flex-column d-none d-md-block ms-5">
                      <span
                        class="fw-bolder d-flex align-items-center fs-5 text-capitalize"
                        >{{ user_nama }}</span
                      >
                      <span class="fw-bold text-muted fs-7 text-capitalize">{{
                        user.peran
                      }}</span>
                    </div>
                  </div>
                  <!--begin::User account menu-->
                  <div
                    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                    data-kt-menu="true"
                  >
                    <!--begin::Menu item-->
                    <div class="menu-item px-3">
                      <div class="menu-content d-flex align-items-center px-3">
                        <!--begin::Avatar-->
                        <div class="symbol symbol-50px me-5">
                          <img alt="Logo" :src="user.foto" />
                        </div>
                        <!--end::Avatar-->
                        <!--begin::Username-->
                        <div class="d-flex flex-column">
                          <div
                            class="fw-bolder d-flex align-items-center fs-5 text-capitalize"
                          >
                            {{ user_nama }}
                          </div>
                          <span
                            class="fw-bold text-muted text-hover-primary fs-7 text-capitalize"
                            >{{ user.peran }}</span
                          >
                        </div>
                        <!--end::Username-->
                      </div>
                    </div>
                    <!--end::Menu item-->
                    <div class="separator my-2"></div>
                    <div class="menu-item px-5">
                      <a href="/profil" class="menu-link px-5">Profil</a>
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#modalUbahPassword"
                        class="menu-link px-5"
                        >Ubah Password</span
                      >
                      <span
                        class="menu-link px-5 text-hover-danger"
                        @click="logout"
                        >Keluar</span
                      >
                    </div>
                    <!--end::Menu item-->
                  </div>
                  <!--end::User account menu-->
                  <!--end::Menu wrapper-->
                </div>
                <!--end::User menu-->
                <!--begin::Header menu toggle-->
                <!--end::Header menu toggle-->
              </div>
              <!--end::Toolbar wrapper-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Container-->
        </div>

        <div
          class="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div class="post d-flex flex-column-fluid pb-0" id="kt_post">
            <div id="kt_content_container" class="container-xxl">
              <div
                class="d-flex justify-content-center align-items-center text-gray-700 mb-7"
                v-if="container_loading"
              >
                <div
                  class="spinner-border me-2"
                  role="status"
                  style="width: 1.3rem; height: 1.3rem"
                >
                  <span class="visually-hidden"></span>
                </div>
                <span class="fs-4 fw-bold"> Tunggu Sebentar </span>
              </div>

              <slot name="content"></slot>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  </div>

  <!-- begin::modal -->
  <!-- begin::UbahPassword -->
  <modal-fullscreen-sm id="modalUbahPassword" title="Ubah Password">
    <template #content>
      <card-basic>
        <template #content>
          <div class="p-5">
            <div class="mb-5">
              <label for="password" class="form-label">Password Baru</label>
              <div class="input-group">
                <input
                  type="password"
                  class="form-control form-control-solid"
                  placeholder="Password baru disini..."
                  v-model="form.password"
                />
              </div>
            </div>

            <div class="mb-5">
              <label for="password" class="form-label"
                >Konfirmasi Password Baru</label
              >
              <div class="input-group">
                <input
                  type="password"
                  class="form-control form-control-solid"
                  placeholder="Konfirmasi password baru disini..."
                  v-model="form.konfirmasi_password"
                />
              </div>
            </div>

            <button
              class="btn btn-primary d-block w-100 mt-5"
              @click="updatePassword"
            >
              Konfirmasi
            </button>
          </div>
        </template>
      </card-basic>
    </template>
  </modal-fullscreen-sm>
  <!-- end::UbahPassword -->
  <!-- end::modal -->
</template>

<script>
// components
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

import { clearState, getUserData, getAccessToken, setState } from "@/store";

// apis
import { getUser, updatePassword } from "@/apis/user";

// helpers
import { panduanPenggunaan } from "@/helpers/panduan";

export default {
  components: {
    Menu,
    Footer,
  },
  props: ["container_loading"],
  data() {
    return {
      menu: "-",
      halaman: "-",
      user: {
        nama: "-",
        peran: "-",
        foto: "-",
      },
      form: {
        password: "",
        konfirmasi_password: "",
      },
      panduan_penggunaan: panduanPenggunaan(),
    };
  },
  beforeMount() {
    this.getUserData();
    this.userData();
    this.menu = localStorage.getItem("menu");
    this.halaman = localStorage.getItem("halaman");
  },
  computed: {
    user_nama() {
      if (this.user.nama.length > 20) {
        return this.user.nama.substring(0, 17) + "...";
      } else {
        return this.user.nama;
      }
    },
  },
  methods: {
    logout() {
      Swal.fire({
        icon: "question",
        text: "Keluar dari AISnet Web ?",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary",
        },
      }).then((res) => {
        if (res.isConfirmed) {
          clearState();
          this.$router.push({ name: "auth.login" });
        }
      });
    },
    getUserData() {
      const user = getUserData();
      this.user.nama = user.nama.toLowerCase();
      this.user.email = user.email;
      this.user.foto = user.foto;
      this.user.peran = user.peran.toLowerCase();
      this.user.nodos = user.nodos;
    },
    async userData() {
      try {
        const data = await getUser();
        const user = data.data.data;
        const foto = user.foto == "-" ? "/img/blank.aad363d0.png" : user.foto;

        let status = true;
        status = this.user.nama == user.nama.toLowerCase() ? status : false;
        status = this.user.email == user.email ? status : false;
        status = this.user.foto == foto ? status : false;

        if (!status) {
          setState({
            accessToken: getAccessToken(),
            user: {
              nama: user.nama,
              email: user.email,
              nim: user.nim,
              peran: getUserData().peran,
              akses: getUserData().akses,
              foto: foto,
              nodos: user.nodos,
            },
          });

          window.location.reload();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updatePassword() {
      try {
        if (this.form.password === this.form.konfirmasi_password) {
          let formData = new FormData();
          formData.append("password", this.form.password);

          const response = await updatePassword(formData);

          if (response.status == 200) {
            Swal.fire({
              icon: "success",
              text: "Password berhasil diperbarui",
              showConfirmButton: false,
              timer: 5000,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: "Password gagal diperbarui",
              showConfirmButton: false,
              timer: 5000,
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            text: "Password tidak sama",
            showConfirmButton: false,
            timer: 5000,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
