<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <alert-icon
            v-if="jadwal_kontrak && !jadwal_kontrak.is_expired"
            type="warning"
          >
            <template #body>
              <span>
                Pengisian Kontrak Perkuliahan berlangsung dari
                <span class="fw-bold">
                  {{
                    $date(jadwal_kontrak.upload_start)
                      .locale("id")
                      .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                  }}
                </span>
                hingga
                <span class="fw-bold">
                  {{
                    $date(jadwal_kontrak.upload_deadline)
                      .locale("id")
                      .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                  }}
                </span>
              </span>
            </template>
          </alert-icon>
          <alert-icon
            v-else-if="jadwal_kontrak && jadwal_kontrak.is_expired"
            type="danger"
          >
            <template #body>
              <span>
                Pengisian Kontrak Perkuliahan sudah ditutup pada
                <span class="fw-bold">
                  {{
                    $date(jadwal_kontrak.upload_deadline)
                      .locale("id")
                      .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                  }}
                </span>
              </span>
            </template>
          </alert-icon>
          <alert-icon v-else type="primary">
            <template #body>
              <span> Jadwal Pengisian Kontrak Perkuliahan belum dibuat. </span>
            </template>
          </alert-icon>

          <div class="p-5">
            <label-header value="Kontrak Perkuliahan">
              <template #control>
                <select
                  class="form-select form-select-solid"
                  data-control="selected_tahun_semester"
                  data-placeholder="Select an option"
                  v-model="selected_tahun_semester"
                  @change="onTahunSemesterChange($event)"
                >
                  <option
                    v-for="(item, i) in tahun_semester"
                    :key="i"
                    :value="item.thsms"
                  >
                    Tahun {{ item.ket }}
                  </option>
                </select>
              </template>
            </label-header>
          </div>
          <table-basic>
            <template #thead>
              <tr class="fw-bolder bg-light fs-7">
                <th class="ps-4 w-50px rounded-start">No</th>
                <th class="min-w-100px">Mata Kuliah</th>
                <th class="min-w-100px">Kelas</th>
                <th class="min-w-100px">Program Studi</th>
                <th class="min-w-100px">Status</th>
                <th class="rounded-end"></th>
              </tr>
            </template>

            <template #tbody>
              <tr v-for="(item, i) in daftar_mata_kuliah" :key="i">
                <td class="text-center align-middle">{{ i + 1 }}</td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.nama_mata_kuliah }}
                  </span>
                  <span class="text-muted text-muted d-block fs-8">
                    {{ item.kode_mata_kuliah }}
                  </span>
                </td>

                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.kelas }}
                  </span>
                  <span class="text-muted text-muted d-block fs-8">
                    Disetujui {{ item.disetujui }}/{{ item.jumlah }}
                  </span>
                </td>
                <td class="align-middle">
                  {{ item.nama_program_studi }}
                  <span></span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bold d-block mb-1 fs-7">
                    Dosen Unggah RPS:
                    <span v-if="item.is_upload" class="text-success">
                      <icon-done /> |
                      {{
                        $date(item.upload_at)
                          .locale("id")
                          .format(" D-MM-YYYY HH:mm")
                      }}
                    </span>
                    <span v-else class="text-danger"><icon-cross /></span>
                  </span>

                  <span class="text-dark fw-bold d-block mb-1 fs-7">
                    Validasi Program Studi:
                    <span v-if="item.is_validation" class="text-success">
                      <icon-done /> | {{ item.validation_at }}
                    </span>
                    <span v-else class="text-danger"><icon-cross /></span>
                  </span>
                </td>
                <td class="align-middle">
                  <a
                    v-if="!jadwal_kontrak.is_expired"
                    class="me-md-3 btn btn-sm btn-secondary btn-active-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#contract_modal"
                    @click="
                      () => {
                        this.selected_id = item.id;
                      }
                    "
                  >
                    {{
                      item.is_upload ? "Ganti RPS" : "Isi Kontrak Perkuliahan"
                    }}
                  </a>

                  <a
                    v-if="item.is_open_upload"
                    class="me-md-3 btn btn-sm btn-secondary btn-active-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#contract_modal"
                    @click="
                      () => {
                        this.selected_id = item.id;
                      }
                    "
                  >
                    {{
                      item.is_upload ? "Ganti RPS" : "Isi Kontrak Perkuliahan"
                    }}
                  </a>
                  <a
                    v-if="item.doc_rps"
                    class="me-md-3 mt-5 mt-lg-0 btn btn-sm btn-secondary btn-active-primary"
                    :href="`${baseUrl}public/${item.doc_rps}`"
                    target="_blank"
                  >
                    Lihat RPS
                  </a>
                  <button
                    v-if="item.is_upload == 1"
                    class="me-md-3 mt-5 mt-lg-0 btn btn-sm btn-secondary btn-active-primary"
                    @click="postDownloadKontrakPerkuliahan(item)"
                    :data-kt-indicator="item.download_loading"
                  >
                    <span class="indicator-label"> Download Kontrak </span>
                    <span class="indicator-progress">
                      Mohon tunggu...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      >
                      </span>
                    </span>
                  </button>
                  <a
                    @click="goDetailKontrakPerkuliahan(item)"
                    class="mt-5 mt-lg-0 btn btn-sm btn-secondary btn-active-primary"
                  >
                    Detail
                  </a>
                </td>
              </tr>
            </template>
          </table-basic>
        </template>
      </card-basic>
      <!-- CONTRACT MODAL -->
      <div class="modal fade" tabindex="-1" id="contract_modal">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Kontrak Perkuliahan</h5>

              <!--begin::Close-->
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <icon-close />
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body">
              <p>
                Saya sudah menyampaikan isi kontrak perkuliahan yang terdiri
                dari:
              </p>

              <div class="d-flex flex-column">
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.capaianPembelajaran"
                      true-value="yes"
                      false-value="no"
                      id="capaianPembelajaran"
                    />
                    <label class="form-check-label" for="capaianPembelajaran">
                      Capaian Pembelajaran</label
                    >
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.rps"
                      true-value="yes"
                      false-value="no"
                      id="rps"
                    />
                    <label class="form-check-label" for="rps">
                      Rencana Pembelajaran Semester (RPS)
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.bahanAjar"
                      true-value="yes"
                      false-value="no"
                      id="bahanAjar"
                    />
                    <label class="form-check-label" for="bahanAjar">
                      Bahan Ajar
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.kisiPenilaian"
                      true-value="yes"
                      false-value="no"
                      id="kisiPenilaian"
                    />
                    <label class="form-check-label" for="kisiPenilaian">
                      Kisi-kisi Penilaian
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.pedomanPraktik"
                      true-value="yes"
                      false-value="no"
                      id="pedomanPraktik"
                    />
                    <label class="form-check-label" for="pedomanPraktik">
                      Pedoman Praktikum/Praktik*
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.pedomanPenugasan"
                      id="pedomanPenugasan"
                      true-value="yes"
                      false-value="no"
                    />
                    <label class="form-check-label" for="pedomanPenugasan">
                      Pedoman Penugasan
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.peraturanPerkuliahan"
                      true-value="yes"
                      false-value="no"
                      id="peraturanPerkuliahan"
                    />
                    <label class="form-check-label" for="peraturanPerkuliahan">
                      Peraturan Perkuliahan
                    </label>
                  </div>
                </li>
              </div>

              <div class="separator my-5"></div>
              <h5 class="modal-title mb-5">
                Upload RPS<span class="text-danger">*</span>
              </h5>
              <form action="#" method="post" id="uploadRps">
                <div class="fv-row">
                  <div class="dropzone" ref="dropzoneRef">
                    <div class="dz-message needsclick">
                      <icon-upload />
                      <div class="ms-4">
                        <h3 class="fs-5 fw-bold text-gray-900 mb-1">
                          Drag & drop file atau klik untuk mengunggah.
                        </h3>
                        <span class="fs-7 fw-semibold text-gray-500"
                          >Upload maks. 1 file pdf maks. 5 MB</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="separator my-5"></div>
              <p class="fw-bolder text-wrap">
                Saat submit kontrak perkuliahan ini, maka segala tugas dan
                kewajiban perkuliahan yang tertera di dalam isi kontrak
                perkuliahan ini akan menjadi tanggung jawab bersama dosen dan
                seluruh mahasiswa.
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Tutup
              </button>
              <button
                type="button"
                class="btn btn-success"
                :data-kt-indicator="submit_loading"
                @click="postUploadRPS"
                :disabled="isButtonDisable"
              >
                <span class="indicator-label"> Submit </span>
                <span class="indicator-progress">
                  Mohon tunggu...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- END CONTRACT MODAL -->
    </template>
  </container-app>
</template>

<script>
import { getAccessToken } from "@/store";
// API
import { getAcademicYearsLecturer } from "@/apis/kontrakPerkuliahan";

import {
  getJadwalKontrak,
  getDosenMataKuliah,
  postDownloadDokumenKontrak,
} from "@/apis/kontrakPerkuliahanBaru";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      container_loading: false,
      isButtonDisable: true,
      submit_loading: "off",
      jadwal_kontrak: null,
      myDropzone: null,
      selected_id: null,
      tahun_semester: [],
      daftar_mata_kuliah: [],
      selected_tahun_semester: "",
      checkbox: {
        capaianPembelajaran: "no",
        rps: "no",
        bahanAjar: "no",
        kisiPenilaian: "no",
        pedomanPraktik: "no",
        pedomanPenugasan: "no",
        peraturanPerkuliahan: "no",
        confirmCheck: "no",
      },
    };
  },

  async mounted() {
    await this.fetchTahunSemester();
    await this.fetchJadwalKontrak(this.selected_tahun_semester);
    await this.fetchDosenMataKuliah(this.selected_tahun_semester);
    this.initDropzone();
  },

  methods: {
    initDropzone() {
      this.myDropzone = new Dropzone(this.$refs.dropzoneRef, {
        url: this.getDropzoneUrl(),
        paramName: "rps",
        maxFiles: 1,
        maxFilesize: 5, // in MB
        addRemoveLinks: true,
        autoProcessQueue: false,
        headers: { Authorization: "Bearer " + getAccessToken() },
        acceptedFiles: ".pdf",
      });

      this.myDropzone.on("addedfile", () => {
        this.isButtonDisable = false;
      });

      this.myDropzone.on("removedfile", () => {
        this.isButtonDisable = true;
      });
    },

    getDropzoneUrl() {
      return `${process.env.VUE_APP_BASE_URL}kontrak-perkuliahan/dosen/upload/${
        this.selected_id || ""
      }`;
    },

    async postUploadRPS() {
      if (!this.selected_id) {
        console.error("Selected ID is not set.");
        return;
      }

      this.myDropzone.options.url = this.getDropzoneUrl();
      this.submit_loading = "on";

      try {
        this.myDropzone.processQueue();

        this.myDropzone.on("success", (file, response) => {
          if (response.success) {
            this.handleSuccess(response.message, file);
            this.fetchDosenMataKuliah(this.selected_tahun_semester);
            this.checkbox = {
              capaianPembelajaran: "no",
              rps: "no",
              bahanAjar: "no",
              kisiPenilaian: "no",
              pedomanPraktik: "no",
              pedomanPenugasan: "no",
              peraturanPerkuliahan: "no",
            };
          } else {
            console.error("Upload failed: ", response.message);
          }
        });
      } catch (error) {
        console.error("Error during file upload: ", error);
      }
    },

    handleSuccess(message, file) {
      Swal.fire({
        text: message,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });

      this.submit_loading = "off";
      $("#contract_modal").modal("hide");
      this.myDropzone.removeFile(file);
    },

    async fetchJadwalKontrak(tahun_semester) {
      try {
        this.container_loading = true;
        const { data: response } = await getJadwalKontrak(tahun_semester);
        if (response.success) {
          this.jadwal_kontrak = response.data;
        } else if (!response.success) {
          this.jadwal_kontrak = null;
        }
        this.container_loading = false;
      } catch (err) {
        this.container_loading = false;
      }
    },

    async fetchTahunSemester() {
      try {
        const response = await getAcademicYearsLecturer();
        this.tahun_semester = response.data.data;
        this.selected_tahun_semester = response.data.data[0].thsms;
      } catch (err) {
        console.error(err);
      }
    },

    async onTahunSemesterChange(event) {
      await this.fetchJadwalKontrak(event.target.value);
      await this.fetchDosenMataKuliah(event.target.value);
    },

    async fetchDosenMataKuliah(tahun_semester) {
      try {
        this.container_loading = true;
        const { data: response } = await getDosenMataKuliah(tahun_semester);
        if (response.success) {
          this.daftar_mata_kuliah = response.data;
        } else if (!response.success) {
          this.daftar_mata_kuliah = [];
        }
        this.container_loading = false;
      } catch (err) {
        this.container_loading = false;
      }
    },

    async postDownloadKontrakPerkuliahan(item) {
      try {
        item.download_loading = "on";

        const response = await postDownloadDokumenKontrak({
          tahun_semester: item.tahun_semester,
          kode_mata_kuliah: item.kode_mata_kuliah,
          kelas: item.kelas,
        });

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Kontrak-Perkuliahan-${item.tahun_semester}-${item.kode_mata_kuliah}-${item.nama_mata_kuliah}-${this.$route.query.kelas}.pdf`
        );
        document.body.appendChild(link);
        link.click();

        item.download_loading = "off";
      } catch (error) {
        item.download_loading = "off";
        console.error("Error downloading PDF report:", error);
      }
    },

    goDetailKontrakPerkuliahan(item) {
      this.$router.push({
        path: `/kontrak-perkuliahan-new/detail/${item.id}`,
        query: {
          tahun_semester: item.tahun_semester,
          nama_mata_kuliah: item.nama_mata_kuliah,
          kode_mata_kuliah: item.kode_mata_kuliah,
          program_studi: item.nama_program_studi,
          kelas: item.kelas,
          dosen: item.nama,
          disetujui: item.disetujui,
          jumlah: item.jumlah,
          is_upload: item.is_upload,
          doc_rps: item.doc_rps,
        },
      });
    },
  },
  beforeUnmount() {
    if (this.myDropzone) {
      this.myDropzone.off("addedfile");
      this.myDropzone.off("removedfile");
    }
  },
};
</script>
