<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <div class="p-5">
            <div class="alert alert-warning d-flex align-items-center p-5">
              <icon-alert />
              <div class="d-flex flex-column">
                <span
                  >Pengisian Kontrak Perkuliahan diisi paling lambat hari
                  <span class="fw-bold"
                    >Sabtu, 12 Oktober 2024 pukul 23:59 WIB</span
                  ></span
                >
              </div>
            </div>
            <div
              v-if="daftarKontrak.length === 0"
              class="alert alert-primary d-flex align-items-center p-5"
            >
              <div class="d-flex flex-column">
                <span>Kontrak Perkuliahan belum tersedia</span>
              </div>
            </div>
            <label-header value="Kontrak Perkuliahan">
              <template #control>
                <select
                  class="form-select form-select-solid"
                  data-control="selected_tahun_semester"
                  data-placeholder="Select an option"
                  v-model="selected_tahun_semester"
                >
                  <option
                    v-for="(item, i) in tahunAkademik"
                    :key="i"
                    :value="item.thsms"
                  >
                    Tahun {{ item.ket }}
                  </option>
                </select>
              </template>
            </label-header>
          </div>
          <table-basic>
            <template #thead>
              <tr class="fw-bolder bg-light fs-7">
                <th class="ps-4 w-50px rounded-start">No</th>
                <th class="min-w-100px">Mata Kuliah</th>
                <th class="min-w-100px">Kelas</th>
                <th class="min-w-100px">Dosen</th>
                <th class="min-w-100px">Status</th>
                <th class="rounded-end"></th>
              </tr>
            </template>

            <template #tbody>
              <tr v-for="(item, i) in daftarKontrak" :key="i">
                <td class="text-center align-middle">{{ i + 1 }}</td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.NAKMK }}
                  </span>
                  <span class="text-muted text-muted d-block fs-8">
                    {{ item.KDKM2 }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.KELAS }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.lecturer }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="badge" :class="getItemStatus(item.status)">
                    {{ item.status }}
                  </span>
                </td>
                <td class="align-middle">
                  <a
                    class="btn btn-sm btn-secondary btn-active-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#contract_modal"
                    @click="selected_id = item.id"
                  >
                    Isi Kontrak Perkuliahan
                  </a>
                  <a
                    :href="item.rps"
                    target="_blank"
                    class="mt-5 ms-md-3 mt-md-0 btn btn-sm btn-secondary btn-active-primary"
                  >
                    Lihat RPS
                  </a>
                </td>
              </tr>
            </template>
          </table-basic>
        </template>
      </card-basic>
      <!-- CONTRACT MODAL -->
      <div class="modal fade" tabindex="-1" id="contract_modal">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Kontrak Perkuliahan</h5>

              <!--begin::Close-->
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <icon-close />
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body">
              <p>
                Dengan ini Saya menyatakan menyepakati isi kontrak perkuliahan
                yang terdiri dari:
              </p>

              <div class="d-flex flex-column">
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.capaianPembelajaran"
                      true-value="yes"
                      false-value="no"
                      id="capaianPembelajaran"
                    />
                    <label class="form-check-label" for="capaianPembelajaran">
                      Capaian Pembelajaran</label
                    >
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.rps"
                      true-value="yes"
                      false-value="no"
                      id="rps"
                    />
                    <label class="form-check-label" for="rps">
                      Rencana Pembelajaran Semester (RPS)
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.bahanAjar"
                      true-value="yes"
                      false-value="no"
                      id="bahanAjar"
                    />
                    <label class="form-check-label" for="bahanAjar">
                      Bahan Ajar
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.kisiPenilaian"
                      true-value="yes"
                      false-value="no"
                      id="kisiPenilaian"
                    />
                    <label class="form-check-label" for="kisiPenilaian">
                      Kisi-kisi Penilaian
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.pedomanPraktik"
                      true-value="yes"
                      false-value="no"
                      id="pedomanPraktik"
                    />
                    <label class="form-check-label" for="pedomanPraktik">
                      Pedoman Praktikum/Praktik*
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.pedomanPenugasan"
                      id="pedomanPenugasan"
                      true-value="yes"
                      false-value="no"
                    />
                    <label class="form-check-label" for="pedomanPenugasan">
                      Pedoman Penugasan
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.peraturanPerkuliahan"
                      true-value="yes"
                      false-value="no"
                      id="peraturanPerkuliahan"
                    />
                    <label class="form-check-label" for="peraturanPerkuliahan">
                      Peraturan Perkuliahan
                    </label>
                  </div>
                </li>
              </div>
              <div class="separator my-5"></div>
              <p class="fw-bolder text-wrap">
                Sejak saat submit kontrak perkuliahan ini, maka segala tugas dan
                kewajiban perkuliahan yang tertera di dalam isi kontrak
                perkuliahan ini akan menjadi tanggung jawab bersama dosen dan
                seluruh mahasiswa.
              </p>
              <div
                class="form-check form-check-custom form-check-success form-check-solid form-check-sm"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="checkbox.confirmCheck"
                  id="confirmCheck"
                  true-value="yes"
                  false-value="no"
                />
                <label class="form-check-label fw-bolder" for="confirmCheck">
                  <span class="text-danger">*</span>Saya menyetujui kontrak
                  perkuliahan
                </label>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Tutup
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="studentContractsSubmit(selected_id)"
                :disabled="isButtonDisable"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- END CONTRACT MODAL -->
    </template>
  </container-app>
</template>

<script>
// STATE MANAGEMENT
import { getUserData } from "@/store";
// API
import { getsTahunAkademik } from "@/apis/tahun";
import {
  getStudentContracts,
  postStudentContracts,
} from "@/apis/kontrakPerkuliahan";

export default {
  data() {
    return {
      container_loading: false,
      daftarKontrak: [],
      tahunAkademik: [],
      selected_tahun_semester: "",
      selected_id: null,
      isButtonDisable: true,
      checkbox: {
        capaianPembelajaran: "no",
        rps: "no",
        bahanAjar: "no",
        kisiPenilaian: "no",
        pedomanPraktik: "no",
        pedomanPenugasan: "no",
        peraturanPerkuliahan: "no",
        confirmCheck: "no",
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      await this.getsTahunAkademik();
    },
    async getsTahunAkademik() {
      try {
        const response = await getsTahunAkademik();
        this.tahunAkademik = response.data.data;
        this.selected_tahun_semester = response.data.data[0].thsms;
      } catch (err) {
        console.error(err);
      }
    },

    async studentContractsFetch(nim, tahun_semester) {
      try {
        this.container_loading = true;
        const response = await getStudentContracts(nim, tahun_semester);
        this.daftarKontrak = response.data.data.data;
        this.container_loading = false;
      } catch (err) {
        console.error(err);
      }
    },
    async studentContractsSubmit(id) {
      try {
        const response = await postStudentContracts(id);
        if (response.data.message == "Kontrak Perkuliahan berhasil diisi") {
          this.studentContractsFetch(
            getUserData().nim,
            this.selected_tahun_semester
          );
          $("#contract_modal").modal("hide");
          Object.keys(this.checkbox).forEach((key) => {
            this.checkbox[key] = "no";
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    getItemStatus(status) {
      switch (status) {
        case "Dosen Belum Mengisi":
          return "badge badge-light-danger";
        case "Mahasiswa Belum Mengisi":
          return "badge badge-light-warning";
        case "Mahasiswa Telah Mengisi":
          return "badge badge-light-success";
        default:
          return "";
      }
    },
    isPrincipal(item) {
      if (
        item.course_id == "TIUWP4303" &&
        item.course_name == "Perancangan & Man. Organisasi Industri" &&
        item.class == "A"
      ) {
        return true;
      } else if (
        item.course_id == "TIUWP4303" &&
        item.course_name == "Perancangan & Man. Organisasi Industri" &&
        item.class == "B"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    selected_tahun_semester() {
      this.studentContractsFetch(
        getUserData().nim,
        this.selected_tahun_semester
      );
    },
    checkbox: {
      handler: function (newVal, oldVal) {
        this.isButtonDisable = !Object.values(newVal).every(
          (value) => value === "yes"
        );
      },
      deep: true,
    },
  },
};
</script>
