<template>
    <div class="row">
        <div class="col-12 col-md-8">
            <slot name="left"></slot>
        </div>

        <div class="d-none d-md-block col-md-4">
            <slot name="right"></slot>
        </div>
    </div>
</template>