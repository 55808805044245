<template>
    <div class="d-flex align-items-center">
        <div class="bg-light-primary border border-1 border-dashed border-primary p-2 symbol symbol-40px">
            <slot name="icon"></slot>
        </div>
        <div class="d-flex flex-column ms-4">
            <span class="fw-bolder fs-7">{{ value }}</span>
            <span class="fw-bold text-muted fs-8">{{ label }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['label', 'value']
}
</script>