import { getUserData } from "@/store";

export const panduanPenggunaan = () => {
    switch (getUserData().peran) {
        case 'DOSEN':
            return 'https://drive.google.com/drive/folders/1rn2StvyZ1jv9HuUvcFWVwgM4bB1-gXZW?usp=sharing';
            break;
    
        case 'MAHASISWA':
            return 'https://drive.google.com/drive/folders/1HiRbGJxX5NkhpWCL01BUBsuaQXZ4iOVA?usp=sharing';
            break;
    
        default:
            return '';
            break;
    }
}