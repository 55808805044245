import { api, headers } from "./api";

const RESOURCE = 'frs';

export const getFrs = (nim) => api.get(RESOURCE+'/'+nim, headers);

export const confirmFrs = (data) => api.post(RESOURCE+'/confirm', data, headers);

export const rejectFrs = (data) => api.post(RESOURCE+'/reject', data, headers);

export const getBatas = () => api.get('batasan', headers);

export const storeFrs = (data) => api.post(RESOURCE, data, headers);

export const submitFrs = (nim) => api.post(RESOURCE+'/'+nim+'/submit', {}, headers);

export const deleteFrs = (id) => api.delete(RESOURCE+'/'+id, headers);