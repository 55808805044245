<template>
    <div class="menu-item" :class="pathname == url ? 'here' : ''">
        <a :href="url" class="menu-link">
            <span class="menu-icon">
                <span class="svg-icon svg-icon-2">
                    <slot name="icon"></slot>
                </span>
            </span>
            <span class="menu-title">{{ nama }}</span>
        </a>
    </div>
</template>

<script>
export default {
    props: ['nama', 'url'],
    data() {
        return {
            pathname : ''
        }
    },
    mounted() {
        this.pathname = window.location.pathname;
    }
}
</script>