<template>
    <div>
        <div class="p-5">
            <label-basic name="Pedoman Penetapan Beban Studi (SKS) :" />
        </div>
        <table-basic class="fs-7">
            <template #thead>
                <tr class="fw-bolder bg-light">
                    <th class="rounded-start ps-4 w-auto w-md-50px">No</th>
                    <th>IPK</th>
                    <th class="rounded-end w-auto w-md-100px">SKS</th>
                </tr>
            </template>
            
            <template #tbody>
                <tr v-for="(batas, i) in daftar_batas" :key="i">
                    <td class="text-end">{{ i+1 }}</td>
                    <td>{{ batas.min_ipk }} s.d {{ batas.ipk }}</td>
                    <td>{{ batas.sks }}</td>
                </tr>
            </template>
        </table-basic>
    </div>
</template>

<script>
    import { getBatas } from '@/apis/frs';
    export default {
        data() {
            return {
                daftar_batas : {}
            }
        },
        beforeMount() {
            this.getBatas();
        },
        methods: {
            async getBatas() {
                try {
                    const respone = await getBatas();
                    this.daftar_batas = respone.data.data;
                }
                catch ( err ) {
                    console.error( err );
                }
            }
        }
    }
</script>