<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <TabelNilaiHuruf :data="daftarNilaiHuruf" :getData="fetchNilaiHuruf" />
      <TabelPersentaseNilai :data="daftarInstrumentNilai" :getData="fetchInstrumenNilai" />
    </template>
  </container-app>
</template>

<script>
// Components
import TabelNilaiHuruf from "@/components/_penilaianUjian/monitoring/TabelNilaiHuruf.vue";
import TabelPersentaseNilai from "@/components/_penilaianUjian/monitoring/TabelPersentaseNilai.vue";

// API
import { getNilaiHuruf, getInstrumenNilai } from "@/apis/penilaianUjian";

export default {
  components:{
    TabelNilaiHuruf,
    TabelPersentaseNilai
  },
  setup() {
      return {
      }
  },
  data() {
    return {
      container_loading: false,
      daftarNilaiHuruf: [],
      daftarInstrumentNilai: []
    };
  },

  mounted() {
    this.fetchNilaiHuruf()
    this.fetchInstrumenNilai()
  },

  methods: {
    async fetchNilaiHuruf() {
        try {
          this.container_loading = true;
          const response = await getNilaiHuruf();
          if (response.status === 200) {
            this.daftarNilaiHuruf = response.data.data;
  
            this.container_loading = false;
          } else if (response.status === 404) {
            Swal.fire({
              text: response.data.message,
              icon: "warning",
            });
            this.container_loading = false;
          }
        } catch (error) {
          this.container_loading = false;
          Swal.fire({
            text: error.response.data.message,
            icon: "error",
          });
        }
    },
    async fetchInstrumenNilai() {
        try {
          this.container_loading = true;
          const response = await getInstrumenNilai()
          if (response.status === 200) {
            this.daftarInstrumentNilai = response.data.data;
  
            this.container_loading = false;
          } else if (response.status === 404) {
            Swal.fire({
              text: response.data.message,
              icon: "warning",
            });
            this.container_loading = false;
          }
        } catch (error) {
          this.container_loading = false;
          Swal.fire({
            text: error.response.data.message,
            icon: "error",
          });
        }
    },
  }
};
</script>
