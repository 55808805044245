<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <container-double>
        <template #left>
          <card-basic>
            <template #content>
              <div class="p-5">
                <div class="my-5">
                  <label-header value="Detail Kontrak Perkuliahan" />
                </div>

                <form-icon>
                  <template #icon>
                    <icon-search />
                  </template>

                  <template #content>
                    <input
                      type="text"
                      class="form-control ps-14 fw-normal"
                      placeholder="Pencarian berdasarkan nama atau nim lalu tekan enter"
                      autofocus
                      v-model="keyword"
                      @change="filterStudents"
                    />
                  </template>
                </form-icon>
              </div>
              <table-basic>
                <template #thead>
                  <tr class="fw-bolder bg-light fs-7">
                    <th class="rounded-start ps-4 w-50px">No.</th>
                    <th>NIM & Nama</th>
                    <th class="rounded-end pe-5 text-center">Status</th>
                  </tr>
                </template>

                <template #tbody>
                  <tr v-for="(item, i) in data.student_list" :key="i">
                    <td class="text-center ps-5">{{ i + 1 }}</td>
                    <td>
                      <span class="text-dark fw-bolder d-block mb-1 fs-7">
                        {{ item.NMMHS }}
                      </span>
                      <span class="text-muted text-muted d-block fs-8">
                        {{ item.NIMHS }}
                      </span>
                    </td>
                    <td class="text-center align-middle">
                      <span class="badge" :class="getItemStatus(item.status)">
                        {{ item.status }}
                      </span>
                    </td>
                  </tr>
                </template>
              </table-basic>
              <!-- CONTRACT MODAL -->
              <div class="modal fade" tabindex="-1" id="contract_modal">
                <div class="modal-dialog modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Ganti RPS</h5>

                      <!--begin::Close-->
                      <div
                        class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <icon-close />
                      </div>
                      <!--end::Close-->
                    </div>
                    <div class="modal-body">
                      <h5 class="modal-title mb-5">
                        Upload RPS<span class="text-danger">*</span>
                      </h5>
                      <!--begin::Form-->
                      <form action="#" method="post" id="uploadRps">
                        <!--begin::Input group-->
                        <div class="fv-row">
                          <!--begin::Dropzone-->
                          <div class="dropzone" ref="dropzoneRef">
                            <!--begin::Message-->
                            <div class="dz-message needsclick">
                              <icon-upload />
                              <!--begin::Info-->
                              <div class="ms-4">
                                <h3 class="fs-5 fw-bold text-gray-900 mb-1">
                                  Drag & drop file atau klik untuk mengunggah.
                                </h3>
                                <span class="fs-7 fw-semibold text-gray-500"
                                  >Upload maks. 1 file pdf/doc/docx 10 MB</span
                                >
                              </div>
                              <!--end::Info-->
                            </div>
                          </div>
                          <!--end::Dropzone-->
                        </div>
                        <!--end::Input group-->
                      </form>
                      <!--end::Form-->
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-light"
                        data-bs-dismiss="modal"
                      >
                        Tutup
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        :data-kt-indicator="submit_loading"
                        @click="putLecturerRPS"
                        :disabled="isButtonDisable"
                      >
                        <span class="indicator-label"> Submit </span>
                        <span class="indicator-progress">
                          Mohon tunggu...
                          <span
                            class="spinner-border spinner-border-sm align-middle ms-2"
                          ></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- END CONTRACT MODAL -->
            </template>
          </card-basic>
        </template>
        <template #right>
          <card-basic>
            <template #content>
              <div class="p-5">
                <label-basic name="Informasi Mata Kuliah" />
                <div class="row">
                  <div class="col">Kode Mata Kuliah</div>
                  <div class="col-6">: {{ data.course_id }}</div>
                </div>
                <div class="row">
                  <div class="col">Nama Mata Kuliah</div>
                  <div class="col-6">: {{ data.course_name }}</div>
                </div>
                <div class="row">
                  <div class="col">Program Studi</div>
                  <div class="col-6">: {{ data.major_name }}</div>
                </div>
                <div class="row">
                  <div class="col">Kelas</div>
                  <div class="col-6">: {{ data.class }}</div>
                </div>
                <div class="row">
                  <div class="col">Dosen</div>
                  <div class="col-6">: {{ data.lecturer_title }}</div>
                </div>
                <div class="row">
                  <div class="col">Mahasiswa Pengontrak</div>
                  <div class="col-6">: {{ data.student_total }}</div>
                </div>
                <div class="row">
                  <div class="col">Mahasiswa Menyetujui</div>
                  <div class="col-6">: {{ data.student_completed }}</div>
                </div>
                <div class="row">
                  <div class="col">Status</div>
                  <div class="col-6">
                    :
                    <span class="badge" :class="getItemStatus(data.status)">
                      {{ data.status }}
                    </span>
                  </div>
                </div>
                <div class="separator my-5"></div>
                <div>
                  <a
                    v-if="data.status == 'Dosen Sudah Mengisi'"
                    :href="data.rps"
                    target="_blank"
                    class="me-2 btn btn-sm btn-secondary btn-active-primary"
                  >
                    Lihat RPS
                  </a>
                  <a
                    v-if="data.status == 'Dosen Sudah Mengisi'"
                    class="me-2 btn btn-sm btn-secondary btn-active-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#contract_modal"
                  >
                    Ganti RPS
                  </a>
                  <button
                    v-if="data.status == 'Dosen Sudah Mengisi' && isEligible"
                    class="btn btn-sm btn-secondary btn-active-primary"
                    @click="donwloadReport(data.id)"
                    :data-kt-indicator="download_loading"
                  >
                    <span class="indicator-label"> Unduh Laporan </span>
                    <span class="indicator-progress">
                      Mohon tunggu...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span>
                    </span>
                  </button>
                </div>
              </div>
            </template>
          </card-basic>
        </template>
      </container-double>
    </template>
  </container-app>
</template>

<script>
// STORE
import { getAccessToken, getUserData } from "@/store";

// API
import {
  getContractDetail,
  downloadRpsReport,
} from "@/apis/kontrakPerkuliahan";

export default {
  data() {
    return {
      container_loading: false,
      keyword: "",
      data: [],
      submit_loading: "off",
      download_loading: "off",
      isButtonDisable: true,
      isEligible: false,
    };
  },
  mounted() {
    if (getUserData().akses) {
      if (getUserData().akses.includes("KONTRAK_PERKULIAHAN")) {
        this.isEligible = true;
      }
    }

    this.fetchContractDetail(this.$route.params.id);
    this.myDropzone = new Dropzone(this.$refs.dropzoneRef, {
      url: `${process.env.VUE_APP_BASE_URL}course-contract/update-rps`,
      paramName: "rps",
      maxFiles: 1,
      maxFilesize: 10, // MB
      addRemoveLinks: true,
      autoProcessQueue: false,
      headers: { Authorization: "Bearer " + getAccessToken() },
      acceptedFiles: ".pdf, .docx, .doc",
      params: {
        id: this.$route.params.id,
      },
    });

    this.myDropzone.on("addedfile", (file) => {
      this.isButtonDisable = false;
    });

    this.myDropzone.on("removedfile", (file) => {
      this.isButtonDisable = true;
    });
  },
  methods: {
    async donwloadReport(id) {
      try {
        this.download_loading = "on";
        const response = await downloadRpsReport(id);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Kontrak Perkuliahan-${this.data.lecturer}-${this.data.course_name}-${this.data.class}-${this.data.major_name}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        this.download_loading = "off";
      } catch (error) {
        this.download_loading = "off";
        console.error("Error downloading PDF report:", error);
      }
    },
    async putLecturerRPS() {
      this.submit_loading = "on";
      await this.myDropzone.processQueue();

      await this.myDropzone.on("processing", () => {
        console.log("Queue is being processed");
      });

      await this.myDropzone.on("success", (file) => {
        Swal.fire({
          text: "RPS Berhasil diganti",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        this.fetchContractDetail(this.$route.params.id);
        this.myDropzone.removeFile(file);
        this.submit_loading = "off";
        $("#contract_modal").modal("hide");
      });
    },
    async fetchContractDetail(id) {
      try {
        this.container_loading = true;
        const response = await getContractDetail(id);
        if (
          (response.data.message =
            "Detail Kontrak Perkuliahan berhasil diambil")
        ) {
          this.data = response.data.data;
          this.container_loading = false;
        }
      } catch (error) {
        this.container_loading = false;
        Swal.fire({
          text: error,
          icon: "danger",
          buttonsStyling: false,
        });
      }
    },
    getItemStatus(status) {
      switch (status) {
        case "Dosen Belum Mengisi":
          return "badge badge-light-danger";
        case "Mahasiswa Belum Mengisi":
          return "badge badge-light-warning";
        case "Dosen Sudah Mengisi":
          return "badge badge-light-success";
        case "Mahasiswa Telah Mengisi":
          return "badge badge-light-success";
        default:
          return "";
      }
    },
    filterStudents() {
      if (!this.original_student_list) {
        // Simpan data asli jika belum disimpan sebelumnya
        this.original_student_list = [...this.data.student_list];
      }

      if (this.keyword) {
        const lowerKeyword = this.keyword.toLowerCase();
        this.data.student_list = this.original_student_list.filter(
          (student) => {
            return (
              student.NIMHS.toLowerCase().includes(lowerKeyword) ||
              student.NMMHS.toLowerCase().includes(lowerKeyword)
            );
          }
        );
      } else {
        // Jika tidak ada kata kunci, kembalikan data asli
        this.data.student_list = [...this.original_student_list];
      }
    },
  },
};
</script>
