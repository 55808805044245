import {api, headers} from './api';

const RESOURCE = 'dosen2';

export const indexDosen = () => api.get(RESOURCE, headers);

export const showDosen = (id) => api.get(RESOURCE+'/'+id, headers);

export const updateProdiKK = (data) => api.post(RESOURCE+'/updateProdiKK', data, headers);

export const profilDosen = () => api.get(RESOURCE+'/profil', headers);

export const updateProfil = (data) => api.post(RESOURCE+'/updateProfil', data, headers);

export const updateKontak = (data) => api.post(RESOURCE+'/updateKontak', data, headers);

export const updatePendidikan = (data) => api.post(RESOURCE+'/updatePendidikan', data, headers);

export const updateJabatanAkademik = (data) => api.post(RESOURCE+'/updateJabatanAkademik', data, headers);

export const bukaAksesPenelitian = (id) => api.post(RESOURCE+'/bukaAksesPenelitian/'+id, {}, headers);

export const tutupAksesPenelitian = (id) => api.post(RESOURCE+'/tutupAksesPenelitian/'+id, {}, headers);
