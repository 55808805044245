import {api, headers} from './api';

const RESOURCE = 'admin';

export const indexAdmin = () => api.get(RESOURCE, headers);

export const storeAdmin = (data) => api.post(RESOURCE, data, headers);

export const showAkses = (id) => api.get(RESOURCE+'/akses?id='+id, headers);

export const aksesAdmin = (data) => api.post(RESOURCE+'/akses', data, headers);