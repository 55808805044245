<template>
    <container-app :container_loading="loading_container">
        <template #content>

            <div v-if="active">
                <form-ijazah v-if="status" :informasi_mhs="permintaan"  :logs="logs"/>
                <div v-else class="card shadow-sm bg-white">
                    <div class="card-body p-0">
                        <div class="card" style="background: linear-gradient(112.14deg, #3A7BD5 100%, #00D2FF 0%);">
                            <div class="card-body d-flex justify-content-between text-white p-6">
                                <div class="d-flex flex-column justify-content-between">
                                    <p class="fs-6 m-0  text-capitalize">Anda belum mengajukan pengajuan ijazah, klik
                                        tombol untuk melakukan pengajuan dan lengkapi
                                        dokumen yang diminta, untuk mendapatkan bukti pengambilan ijazah.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="p-1 ">
                            <button @click="ajukan" target="_blank" class="btn btn-sm btn-secondary w-100">
                                <span class="ms-2">Ajukan</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="alert alert-danger">
                Pengajuan ijazah tidak dapat diakses karna ketentuan mahasiswa belum memenuhi
            </div>
        </template>
    </container-app>

</template>

<script>
import TableInformasi from '@/components/_ijazah/TableInformasi.vue';
import FormIjazah from '@/components/_ijazah/FormIjazah.vue';
import { getUserData } from '@/store';
import { getPermintaan, getActiveStatus, ajukan, getLogs } from '@/apis/ijazah'
import { toFormData } from '@/helpers/main';
import { formatDate } from '@/helpers/waktu';
export default {
    components: {
        TableInformasi,
        FormIjazah
    },
    data() {
        return {
            user: {},
            loading_container: true,
            permintaan: {},
            status: false,
            active: false,
            logs: []
        }
    },
    beforeMount() {
            localStorage.setItem('menu', 'Ijazah')
            localStorage.setItem('halaman', 'Pengajuan Ijazah')
        },
    async mounted() {
        this.loading_container = true;
        try {
            this.user = await getUserData();
            await this.getActiveStatus();
            await this.getPermintaan();
            await this.getLogs();
            this.loading_container = false
        } catch (error) {
            console.log(error);
            this.loading_container = false
        }


    },
    methods: {
        async getPermintaan() {
            const nim = this.user.nim;
            let res = await getPermintaan(nim);
            let data = res.data;
            this.status = data.status;
            this.permintaan = data.data;
        },
        async getActiveStatus() {
            const nim = this.user.nim;
            let res = await getActiveStatus(nim);
            let data = res.data;
            console.log(res.data);
            this.active = data.data.status_sidang;
        },
        async getLogs(){
            const nim = this.user.nim;
            let res = await getLogs(nim);
            this.logs = res.data.data.map(e => ({...e, created_at: formatDate(e.created_at)}))
            console.log(res);
        },
        async ajukan() {
            Swal.fire({
                title: 'Yakin melakukan pengajuan?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yakin',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.handlePengajuan();
                }
            })
        },
        async handlePengajuan() {
            try {
                this.loading_container = true;
                const res = await ajukan(toFormData({ nim: this.user.nim }));
                if (res.data.status) {
                    Swal.fire('Berhasil melakukan pengajuan', '', 'success')
                    await this.getPermintaan();
                    await this.getLogs();
                    await this.getActiveStatus();
                } else {
                    Swal.fire('Gagal melakukan pengajuan', '', 'danger')
                }
                this.loading_container = false;
            } catch (error) {
                this.loading_container = false;
            }
        }
    }
}
</script>