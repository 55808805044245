import { defineStore } from "pinia";

export const useMataKuliahStore = defineStore("mata-kuliah", {
  state: () => {
    return {
      tahun_semester: "",
      kode_mata_kuliah: "",
      mata_kuliah: "",
      kelas: "",
      kelas_ujian: "",
      expired: false,
    };
  },
  actions: {
    set(
      tahun_semester,
      kode_mata_kuliah,
      mata_kuliah,
      kelas,
      kelas_ujian,
      expired
    ) {
      this.tahun_semester = tahun_semester;
      this.kode_mata_kuliah = kode_mata_kuliah;
      this.mata_kuliah = mata_kuliah;
      this.kelas = kelas;
      this.kelas_ujian = kelas_ujian;
      this.expired = expired;

      localStorage.setItem(
        "mataKuliahData",
        JSON.stringify({
          tahun_semester,
          kode_mata_kuliah,
          mata_kuliah,
          kelas,
          kelas_ujian,
          expired,
        })
      );
    },
    restoreFromLocalStorage() {
      const storedData = localStorage.getItem("mataKuliahData");
      if (storedData) {
        const {
          tahun_semester,
          kode_mata_kuliah,
          mata_kuliah,
          kelas,
          kelas_ujian,
          expired,
        } = JSON.parse(storedData);
        this.set(
          tahun_semester,
          kode_mata_kuliah,
          mata_kuliah,
          kelas,
          kelas_ujian,
          expired
        );
      }
    },
  },
});
