<template>
    <div class="table-responsive">
        <table class="table  gs-0 gy-4 table-row-dashed fs-7">
            <thead>
                <tr class=" ">
                    <th class="rounded-start fw-bolder ps-4  text-right">Nama</th>
                    <td class="rounded-end pe-4  text-right">{{data.nama}}</td>
                </tr>
                <!-- <tr class=" ">
                    <th class="rounded-start fw-bolder ps-4  text-right">NIK</th>
                    <td class="rounded-end pe-4  text-right">-</td>
                </tr> -->
                <tr class=" ">
                    <th class="rounded-start fw-bolder ps-4  text-right">NIM</th>
                    <td class="rounded-end pe-4  text-right">{{data.nimhs}}</td>
                </tr>
                <tr class=" ">
                    <th class="rounded-start fw-bolder ps-4  text-right">Program Studi</th>
                    <td class="rounded-end pe-4  text-right">{{data.prodi}}</td>
                </tr>
                <tr class=" ">
                    <th class="rounded-start fw-bolder ps-4  text-right">Tanggal Sidang</th>
                    <td class="rounded-end pe-4  text-right">{{data.jadwal_sidang}}</td>
                </tr>
                <tr class=" ">
                    <th class="rounded-start fw-bolder ps-4  text-right">Judul B.Indonesia</th>
                    <td class="rounded-end pe-4  text-right">{{data.judul_ind}}</td>
                </tr>
                <tr class=" ">
                    <th class="rounded-start fw-bolder ps-4  text-right">Judul B.Inggris</th>
                    <td class="rounded-end pe-4  text-right">{{data.judul_eng}}
                    </td>
                </tr>
                <tr class=" ">
                    <th class="rounded-start fw-bolder ps-4  text-right">Pembimbing</th>
                    <td class="rounded-end pe-4  text-right">
                        <span>
                            {{data.pembimbing1}}
                        </span>
                        <br>
                        <span>
                            {{data.pembimbing2}}
                        </span>
                    </td>
                </tr>
            </thead>


        </table>
    </div>
</template>
<script>

    export default {
        props: ['data'],
        mounted(){
            console.log(this.data);
        }
    }
</script>
