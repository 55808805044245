<template>
  <div class="p-3">
    <label-header class="mb-3" :value="title"> </label-header>
    <div
      v-if="!daftar_nilai || daftar_nilai.length < 1"
      class="alert alert-primary d-flex align-items-center p-5"
    >
      <div class="d-flex flex-column">
        <span
          >Anda tidak mempunyai {{ title }}. Punya kendala? Silahkan hubungi
          Lembaga Sistem Informasi dan Pangkalan Data untuk informasi lebih
          lanjut
        </span>
      </div>
    </div>
    <div v-else class="table-responsive">
      <table class="table align-middle gs-0 gy-4 table-row-dashed fs-7">
        <thead>
          <tr class="fw-bolder bg-light">
            <th class="rounded-start ps-4 w-50px text-center">No</th>
            <th class="text-center">Mata Kuliah</th>
            <th class="text-center">Kelas</th>
            <th class="text-center">Kelas Ujian</th>
            <th class="text-center">UTS</th>
            <th class="text-center">Tugas</th>
            <th class="text-center">Absen</th>
            <th class="text-center">UAS</th>
            <th class="text-center">Nilai Akhir</th>
            <th class="text-center">Huruf Mutu</th>
            <th class="rounded-end">Keterangan</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, i) in daftar_nilai" :key="i">
            <td class="rounded-start ps-4 w-50px text-center">
              {{ i + 1 }}
            </td>
            <td>
              <span class="text-dark fw-bolder d-block mb-1 fs-7">{{
                item.nama_mata_kuliah
              }}</span>
              <span class="text-muted fw-bold text-muted d-block fs-8">
                {{ item.kode_mata_kuliah }}
              </span>
            </td>
            <td class="text-center">{{ item.kelas }}</td>
            <td class="text-center">{{ item.kelas_ujian }}</td>
            <td class="text-center">
              {{ item.dikunci ? item.uts : "T" }}
            </td>
            <td class="text-center">
              {{ item.dikunci ? item.tugas : "T" }}
            </td>
            <td class="text-center">
              {{ item.dikunci ? item.absen : "T" }}
            </td>
            <td class="text-center">
              {{ item.dikunci ? item.uas : "T" }}
            </td>
            <td class="text-center">
              {{
                item.dikunci ? (item.absen < 75 ? 0 : item.nilai_akhir) : "T"
              }}
            </td>
            <td class="text-center">
              {{
                item.dikunci ? (item.absen < 75 ? "E" : item.nilai_huruf) : "T"
              }}
            </td>
            <td class="rounded-end pe-4 w-250px">
              <span class="text-dark fw-bold d-block mb-1 fs-7">
                Dosen Mengunci Nilai:
                <span v-if="item.dikunci" class="text-success">
                  <icon-done />
                </span>
                <span v-else class="text-danger"><icon-cross /></span>
              </span>

              <span class="text-dark fw-bold d-block mb-1 fs-7">
                Validasi Program Studi:
                <span v-if="item.divalidasi" class="text-success">
                  <icon-done />
                </span>
                <span v-else class="text-danger"><icon-cross /></span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["daftar_nilai", "title"],
};
</script>
