<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <div class="p-5">
            <label-header label="Penilaian Hasil Belajar" value="UTS">
              <template #control>
                <select
                  class="form-select form-select-solid"
                  data-placeholder="Pilih Tahun Semester"
                  v-model="selected.tahun_semester"
                  @change="onSelectedTahunSemesterChange($event)"
                >
                  <option
                    v-for="(item, i) in tahun_semester"
                    :key="i"
                    :value="item.thsms"
                  >
                    Tahun {{ item.ket }}
                  </option>
                </select>
              </template>
            </label-header>
          </div>
          <div class="m-5">
            <TabelNilaiUTS
              title="Ujian Tengah Semester (UTS) Reguler"
              :daftar_nilai="uts"
            />
            <TabelNilaiUTS
              title="Ujian Tengah Semester (UTS) Susulan"
              :daftar_nilai="uts_susulan"
            />
          </div>
        </template>
      </card-basic>
    </template>
  </container-app>
</template>

<script>
// components
import TabelNilaiUTS from "@/components/_penilaianUjian/nilaiUtsMahasiwa/TabelNilaiUTS.vue";

// apis
import { getTahunSemester, utsNilai } from "@/apis/PenilaianHasilBelajar/uts";
import { utsSusulanNilai } from "@/apis/PenilaianHasilBelajar/utsSusulan";

export default {
  components: {
    TabelNilaiUTS,
  },
  data() {
    return {
      container_loading: false,
      selected: {},
      uts: [],
      uts_susulan: [],
      tahun_semester: [],
    };
  },
  beforeMount() {
    localStorage.setItem("menu", "Hasil Studi");
    localStorage.setItem("halaman", "Nilai Ujian Tengah Semester (UTS)");
  },
  async mounted() {
    await this.tahunSemesterFetch();
    await this.utsFetch(this.selected.tahun_semester);
    await this.utsSusulanFetch(this.selected.tahun_semester);
  },
  methods: {
    async tahunSemesterFetch() {
      try {
        const response = await getTahunSemester();
        this.tahun_semester = response.data.data;
        this.selected.tahun_semester = response.data.data[0].thsms;
      } catch (err) {
        console.error(err);
      }
    },
    async utsFetch(tahun_semester) {
      this.container_loading = true;
      const { data } = await utsNilai(tahun_semester);

      if (data.success) {
        this.uts = data.data;
        this.container_loading = false;
      } else {
        this.uts = null;
        this.container_loading = false;
      }
    },
    async utsSusulanFetch(tahun_semester) {
      this.container_loading = true;
      const { data } = await utsSusulanNilai(tahun_semester);

      if (data.success) {
        this.uts_susulan = data.data;
        this.container_loading = false;
      } else {
        this.uts_susulan = null;
        this.container_loading = false;
      }
    },
    onSelectedTahunSemesterChange(event) {
      this.utsFetch(event.target.value);
      this.utsSusulanFetch(event.target.value);
    },
  },
};
</script>
