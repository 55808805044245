import { reactive, computed } from "vue";

const defaultState = {
  accessToken: null,
  user: {
    nama: null,
    email: null,
    nim: null,
    peran: null,
    foto: null,
    nodos: null,
  },
};

const initState = () =>
  JSON.parse(sessionStorage.getItem("authState")) || defaultState;

const state = reactive(initState());

export const getAccessToken = () => state.accessToken;

export const getUserData = () => state.user;

export const loggedIn = computed(() => !!getAccessToken());

export const setState = (newState) => {
  Object.assign(state, newState);

  sessionStorage.setItem("authState", JSON.stringify(newState));
};

export const clearState = () => {
  Object.assign(state, defaultState);

  sessionStorage.removeItem("authState");
};
