import { api, headers } from './api';

const RESOURCE = 'kewajiban_tahunan';

export const getSemester = () => api.get(`${RESOURCE}/semester`, headers);

export const getStatusTombol = () => api.get(`${RESOURCE}/statusTombol`, headers);

export const getAll = (semester) => api.get(`${RESOURCE}/all?semester=${semester}`, headers);

export const getPencairan = (semester) => api.get(`${RESOURCE}/pencairan?semester=${semester}`, headers);

export const getDosen = () => api.get(`${RESOURCE}/dosen`, headers);

export const getDosenPencairan = (id) => api.get(`${RESOURCE}/dosen_pencairan/${id}`, headers);

export const postKewajibanTahunan = (data) => api.post(`${RESOURCE}`, data, headers);

export const postAjukan = (id) => api.post(`${RESOURCE}/ajukan/${id}`, {}, headers);

export const postValidasi = (data) => api.post(`${RESOURCE}/validasi`, data, headers);

export const postCairkan = (id) => api.post(`${RESOURCE}/cairkan/${id}`, {}, headers);