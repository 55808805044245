<template>
    <container-app :container_loading="container_loading">
        <template #content>
            <div class="row mt-3">
                <card-pengumuman 
                    v-for="(pengumuman, i) in daftar_pengumuman" 
                    :key="i" 
                    :pengumuman="pengumuman"
                    data-bs-toggle="modal" data-bs-target="#modalDetailPengumuman"
                    @click="getPengumuman(pengumuman)"
                />
            </div>
        </template>
    </container-app>

    <!-- begin::modal -->
    <modal-fullscreen-sm id="modalDetailPengumuman" title="Detail Pengumuman">
        <template #content>
            <get-pengumuman :pengumuman="pengumuman" />
        </template>
    </modal-fullscreen-sm>
    <!-- end::modal -->
</template>

<script>
// components
import CardPengumuman from '@/components/_pengumuman/CardPengumuman.vue';
import GetPengumuman from '@/components/_pengumuman/GetPengumuman.vue';

// apis
import { getsAllPengumuman } from '@/apis/pengumuman';

export default {
    components: {
        CardPengumuman,
        GetPengumuman
    },
    data() {
        return {
            daftar_pengumuman : {},
            pengumuman : {},
            container_loading : true,
        }
    },
    beforeMount() {
        localStorage.setItem('menu', 'AISnet');
        localStorage.setItem('halaman', 'Pengumuman');
    },
    mounted() {
        this.getsPengumuman();
    },
    methods: {
        async getsPengumuman() {
            try {
                const response = await getsAllPengumuman();
                this.daftar_pengumuman = response.data.data;

                this.container_loading = false;
            }
            catch ( err ) {
                console.error( err )
            }
        },
        getPengumuman(pengumuman) {
            this.pengumuman = pengumuman;
        },
        
    }
}

</script>