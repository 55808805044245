<template>
    <container-app>
        <template #content>
            <card-basic>
                <template #content>
                    <!-- filter semester -->
                    <div class="d-flex flex-column flex-md-row justify-content-between gap-3 my-5 mx-5">
                        <div class="d-flex align-items-center gap-2">
                            <label class="form-label m-0">Semester</label>
                            <select class="form-select w-auto" v-model="jadwal_id">
                                <option v-for="jadwal in data_jadwal_pengusulan" :key="jadwal.semester" :value="jadwal.semester">
                                    {{ jadwal.semester }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <table-basic>
                        <template #thead>
                            <tr class="fw-bolder bg-light fs-7">
                                <th class="rounded-start ps-5 w-md-50px">No.</th>
                                <th>Dosen</th>
                                <th class="w-md-200px">Jenis</th>
                                <th class="w-md-300px">File</th>
                                <th class="w-md-200px">Status</th>
                                <th class="rounded-end pe-5 w-md-150px">Aksi</th>
                            </tr>
                        </template>

                        <template #tbody>
                            <tr v-for="(usulan, i) in data_usulan" :key="usulan.id">
                                <td class="text-end">{{ i + 1 }}</td>
                                <td>
                                    <span class="fw-bold d-block">{{ usulan.dosen_nama }}</span>{{ usulan.dosen_nidn }}
                                </td>
                                <td>{{ usulan.jenis }}</td>
                                <td>
                                    <div class="d-flex gap-2">
                                        <a :href="usulan.url_jurnal" target="_blank" class="btn btn-sm btn-secondary">URL Jurnal</a>
                                        <a :href="usulan.url_loa" target="_blank" class="btn btn-sm btn-secondary">File LOA</a>
                                    </div>
                                </td>
                                <td>
                                    <span class="badge" :class="usulan.status == 'DRAFT' ? 'badge-danger' : usulan.status == 'TERVALIDASI' ? 'bg-success' : 'bg-warning'">{{ usulan.status }}</span>
                                </td>
                                <td>
                                    <button v-if="lembaga_jabatan == 'LPPM STAF' && usulan.waktu_validasi_staf_lppm == null" class="btn btn-sm btn-primary" @click="handleValidasi(usulan.id)">Validasi</button>
                                    <button v-if="lembaga_jabatan == 'LPPM KETUA' && usulan.waktu_validasi_staf_lppm != null && usulan.waktu_validasi_lppm == null" class="btn btn-sm btn-primary" @click="handleValidasi(usulan.id)">Validasi</button>
                                </td>
                            </tr>
                        </template>
                    </table-basic>
                </template>
            </card-basic>
        </template>
    </container-app>
</template>

<script>
import { lembagaJabatan } from "@/apis/user";
import {
    getSemester,
    getAll,
    postValidasi
} from '@/apis/kewajibanTahunan'

import { getUserData } from "@/store";
export default {
    data() {
        return {
            user: getUserData(),
            lembaga_jabatan: "-",
            full_akses: false,
            data_jadwal_pengusulan: [],
            jadwal_id: null,
            data_usulan: [],
        }
    },

    watch: {
        jadwal_id() {
            this.getAll();
        }
    },

    beforeMount() {
        localStorage.setItem("menu", "Penelitian dan PkM");
        localStorage.setItem("halaman", "Validasi Kewajiban Tahunan");
        this.lembagaJabatan();
        this.getSemester();
    },

    methods: {
        async lembagaJabatan() {
            const res = await lembagaJabatan();

            this.lembaga_jabatan = res.data.lembaga_jabatan;

            if ( this.user.akses.includes('ALL') ) {
                this.full_akses = true;
            } else {
                this.full_akses = this.akses.includes(this.lembaga_jabatan) ? true : false;
            }
        },

        async getSemester() {
            const res = await getSemester();
            this.data_jadwal_pengusulan = res.data.data;
            this.jadwal_id = res.data.data[0].semester;
        },

        async getAll() {
            const res = await getAll(this.jadwal_id);    

            this.data_usulan = res.data.data;
        },

        handleValidasi(id) {
            Swal.fire({
                title: 'Validasi Kewajiban Tahunan',
                text: "Apakah anda yakin ingin memvalidasi kewajiban tahunan ini?",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Validasi'
            }).then( async e => {
                if( e.isConfirmed ) {
                    const res = await postValidasi({
                        id: id,
                        lembaga_jabatan: this.lembaga_jabatan
                    });

                    if( res.status == 200 ) {
                        Swal.fire({
                            icon: 'success',
                            text: "Kewajiban tahunan berhasil divalidasi",
                            showConfirmButton: false,
                            timer: 3000
                        })

                        this.getAll();
                    }
                }
            })
        }
    }
}
</script>