<template>
    <div class="card mb-3 shadow-sm">
        <div class="card-body p-6">
            <div class="text-gray-700 fs-8">
                <div class="d-flex w-100">
                    <icon-chat class="me-2" />
                    <div class="w-100">
                        <div class="fs-7">
                            <div>Jenis : {{ riwayat.jenis }}</div>
                            <div>Tujuan : {{ riwayat.tujuan }}</div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center mt-3 w-100">
                            <span>
                                {{ riwayat.created_at }}
                            </span>
                            <span 
                                data-bs-toggle="collapse" 
                                :href="'#riwayat'+riwayat.id" 
                                role="button" 
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                @click="handleDetail"
                                >
                                Detail
                                <icon-arrow-bottom v-if="!detail" />
                                <icon-arrow-up v-else/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="collapse" :id="'riwayat'+riwayat.id">
                <div class="text-gray-700 mt-4 pt-3 border-top fs-7">
                    {{ riwayat.isi }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['riwayat'],
    data() {
        return {
            detail : false
        }
    },
    methods: {
        handleDetail() {
            this.detail = !this.detail
        }
    }
}
</script>