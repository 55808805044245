<template>
    <container-app :container_loading="container_loading">
        <template #content>
            <container-double class="mb-6">
                <template #left>
                    <div class="card shadow-sm bg-white">
                        <div class="card-body p-0">
                            <div class="card" style="background: linear-gradient(112.14deg, #3A7BD5 100%, #00D2FF 0%);">
                                <div class="card-body d-flex justify-content-between text-white p-6">
                                    <div class="d-flex flex-column justify-content-between">
                                        <div>
                                            <p class="fs-5 m-0">Selamat datang</p>
                                            <p class="fs-2 m-0 fw-bold text-capitalize">{{ user.nama }}</p>
                                        </div>
                                        <p class="fs-6 m-0 mt-4 text-capitalize">{{ user.peran }} Institut Teknologi Garut</p>
                                    </div>
                                    <img src="@/assets/dasbor.png" class="d-none d-md-block" style="height: 20vh"/>
                                </div>
                            </div>
                            
                            <div class="p-1 d-block d-md-none">
                                <a :href="panduan_penggunaan" target="_blank" class="btn btn-sm btn-secondary w-100">
                                    <span class="ms-2">Panduan Penggunaan</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </template>

                <template #right>
                    <div class="card h-100 shadow-sm">
                        <div class="card-body text-center d-flex flex-column justify-content-between p-1">
                            <div class="p-5">
                                <span class="fs-7">Informasi mengenai panduan penggunaan aplikasi AISnet Web ITG bisa diakses melalui tombol berikut.</span>
                            </div>
                            <a :href="panduan_penggunaan" target="_blank" class="btn btn-sm btn-secondary">
                                <span class="ms-2">Panduan Penggunaan</span>
                            </a>
                        </div>
                    </div>
                </template>
            </container-double>

            <cards-pengumuman @stop_loading="stop_loading" />

        </template>
    </container-app>
</template>

<script>
// components
import CardsPengumuman from '@/components/_pengumuman/CardsPengumuman.vue';

// stores
import { getUserData } from '@/store';

// helpers
import { panduanPenggunaan } from '@/helpers/panduan';

export default {
    components: {
        CardsPengumuman
    },
    data() {
        return {
            user : {},
            panduan_penggunaan: panduanPenggunaan(),
            container_loading: true
        }
    },
    emits: ['stop_loading_container'],
    beforeMount() {
        localStorage.setItem('menu', 'AISnet');
        localStorage.setItem('halaman', 'Dasbor');
    },
    mounted() {
        const user = getUserData();
        this.user.nama = user.nama.toLowerCase();
        this.user.peran = user.peran.toLowerCase();
    },
    methods: {
        stop_loading() {
            this.container_loading = false;
        }
    }
}
</script>