<template>
    <table-basic>
        <template #thead>
            <tr class="fw-bolder bg-light fs-7">
                <th class="rounded-start ps-4 w-auto w-md-50px ">No</th>
                <th>Mahasiswa</th>
                <th>Prodi</th>
                <th>Tanggal Arsip</th>
                <th>Status Arsip</th>
                <th class="rounded-end w-auto w-md-100px">Aksi</th>
            </tr>
        </template>

        <template #tbody>
            <!-- <tr v-for="(mahasiswa, index) in daftar_pengajuan" :key="index">
            </tr> -->
        </template>
    </table-basic>
    
</template>

<script>
import { getUserData } from '@/store';
import { verifikasi, getPermintaan, updatePengajuan } from '@/apis/ijazah'
import { toFormData, closeModal } from '@/helpers/main';

export default {
    props: ['daftar_pengajuan'],
    data() {
        return {
            
        }
    },
    mounted() {
       
    },
    methods: {
       
    }
}
</script>