<template>
  <container-app>
    <template #content>
      <container-double>
        <template #left>
          <!-- informasi kontak detila -->
          <div class="alert text-dark d-flex alert-light shadow-sm">
            <span class="svg-icon svg-icon-3 me-3 mb-sm-0 text-dark">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
            <div class="pe-0 pe-sm-10">
              <span class="fs-7"
                >Informasi terkait Kewajiban Tahunan bisa menghubungi
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=6281808608572"
                  ><u>Detila Rostilawati</u></a
                ></span
              >
            </div>
          </div>

          <!-- filter semester -->
          <div class="d-flex justify-content-between mb-5">
            <div></div>
            <button v-if="dosen.penelitian == 1 && status_tombol" class="btn btn-sm btn-primary ms-2" data-bs-toggle="modal" data-bs-target="#modalFormUsulan" @click="form_usulan = {}">
              Upload Kewajiban Tahunan
            </button>
          </div>
          
          <card-basic>
            <template #content>
              <table-basic>
                <template #thead>
                  <tr class="fw-bolder bg-light fs-7">
                    <th class="rounded-start ps-5 w-md-50px">No.</th>
                    <th class="w-md-100px">Semester</th>
                    <th class="w-md-100px">Jenis</th>
                    <th class="w-md-300px">File</th>
                    <th class="w-md-200px">Status</th>
                    <th class="rounded-end pe-5 w-md-150px">Aksi</th>
                  </tr>
                </template>

                <template #tbody>
                  <tr v-for="(usulan, i) in data_usulan" :key="i">
                    <td class="text-end">{{ i + 1 }}</td>
                    <td>{{ usulan.semester }}</td>
                    <td>{{ usulan.jenis }}</td>
                    <td>
                      <div class="d-flex gap-2">
                        <a :href="usulan.url_jurnal" target="_blank" class="btn btn-sm btn-secondary">URL Jurnal</a>
                        <a :href="usulan.url_loa" target="_blank" class="btn btn-sm btn-secondary">File LOA</a>
                      </div>
                    </td>
                    <td>
                      <span class="badge" :class="usulan.status == 'DRAFT' ? 'badge-danger' : usulan.status == 'TERVALIDASI' ? 'bg-success' : 'bg-warning'">{{ usulan.status }}</span>
                    </td>
                    <td>
                      <button v-if="usulan.waktu_pengajuan == null" class="btn btn-sm btn-primary" @click="handleAjukan(usulan.id)">Ajukan</button>
                      <button v-if="usulan.waktu_validasi_lppm != null" class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#modalRiwayatPencairan" @click="getDosenPencairan(usulan.id)">Pencairan</button>
                    </td>
                  </tr>
                </template>
              </table-basic>
            </template>
          </card-basic>
        </template>

        <template #right>
          <card-basic>
            <template #content>
              <div class="p-5">
                <label-basic name="Profil Dosen" />

                <div class="mb-2 mt-3">
                  <span class="fs-8 text-gray-700 d-inline-block w-150px">NIDN</span>
                  <span class="fs-7 fw-bold text-gray-800">{{dosen.nidn}}</span>
                </div>

                <div class="mb-2">
                  <span class="fs-8 text-gray-700 d-inline-block w-150px" >Nama Lengkap</span>
                  <span class="fs-7 fw-bold text-gray-800">{{dosen.nama}}</span>
                </div>

                <div class="mb-2">
                  <span class="fs-8 text-gray-700 d-inline-block w-150px">No. HP</span>
                  <span class="fs-7 fw-bold text-gray-800">{{dosen.no_hp}}</span>
                </div>
              </div>
            </template>
          </card-basic>

          <card-basic>
            <template #content>
              <div class="p-5">
                <label-basic name="Program Studi" />

                <div class="mb-2 mt-3">
                  <span class="fs-8 text-gray-700 d-inline-block w-150px"
                    >Program Studi</span
                  >
                  <span class="fs-7 fw-bold text-gray-800">{{
                    dosen.program_studi
                  }}</span>
                </div>

                <div class="mb-2">
                  <span class="fs-8 text-gray-700 d-inline-block w-150px"
                    >Kelompok Keahlian</span
                  >
                  <span class="fs-7 fw-bold text-gray-800">{{
                    dosen.kelompok_keahlian
                  }}</span>
                </div>
              </div>
            </template>
          </card-basic>

          <card-basic>
            <template #content>
              <div class="p-5">
                <label-basic name="Penelitian" />

                <div class="mt-3">
                  <div
                    class="mb-2"
                    v-for="(penelitian_dosen, i) in data_penelitian_dosen"
                    :key="i"
                  >
                    <span class="fs-8 text-gray-700 d-inline-block w-150px">{{
                      penelitian_dosen.nama
                    }}</span>
                    <span class="fs-7 fw-bold text-gray-800">{{
                      penelitian_dosen.url
                    }}</span>
                  </div>
                </div>
              </div>
            </template>
          </card-basic>
        </template>
      </container-double>
    </template>
  </container-app>

  <modal-fullscreen-sm id="modalFormUsulan" title="Upload Kewajiban Tahunan">
    <template #content>
      <form @submit.prevent="postKewajibanTahunan">
        <div class="p-1 pb-4 pt-0">
          <div class="form-group mb-2">
            <label class="col-form-label pb-2">Jenis Publikasi</label>
            <select v-model="form_usulan.jenis" class="form-select" required>
              <option value="Sinta 1">Sinta 1</option>
              <option value="Sinta 2">Sinta 2</option>
              <option value="Sinta 3">Sinta 3</option>
              <option value="Sinta 4">Sinta 4</option>
              <option value="Q1">Q1</option>
              <option value="Q2">Q2</option>
              <option value="Q3">Q3</option>
              <option value="Q4">Q4</option>
            </select>
          </div>
          <div class="form-group mb-2">
            <label class="col-form-label pb-2">URL Jurnal</label>
            <input
              v-model="form_usulan.url_jurnal"
              type="url"
              class="form-control"
              required
            />
          </div>
          <div class="form-group mb-2">
            <label class="col-form-label pb-2">File LOA</label>
            <input
              id="file_loa"
              type="file"
              class="form-control"
              accept=".pdf"
              required
            />
          </div>
          <div class="mt-7">
            <input
              type="submit"
              value="Simpan"
              class="btn btn-success d-block w-100 w-sm-auto"
            />
          </div>
        </div>
      </form>
    </template>
  </modal-fullscreen-sm>

  <modal-fullscreen-sm id="modalRiwayatPencairan" title="Riwayat Pencairan Kewajiban Tahunan">
    <template #content>
      <div class="card" :class="{'mb-3': i < 12}" v-for="(pencairan, i) in data_pencairan" :key="i">
        <div class="p-3 d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center gap-2">
            <icon-status-0 v-if="pencairan.status == 0" />
            <icon-status-1 v-if="pencairan.status == 1" />
            <icon-status-2 v-if="pencairan.status == 2" />
            <p class="fw-bold m-0">Bulan {{ pencairan.bulan }}</p>
          </div>
          <p class="fs-8 m-0 text-gray-700">
            {{ pencairan.waktu }}
          </p>
        </div>
      </div>
    </template>
  </modal-fullscreen-sm>
</template>

<script>
import { profilDosen } from "@/apis/dosen2";
import { dataPenelitianDosen } from "@/apis/penelitianDosen";
import { 
  getStatusTombol,
  getDosen,
  getDosenPencairan,
  postKewajibanTahunan,
  postAjukan
} from "@/apis/kewajibanTahunan";

export default {
  beforeMount() {
    localStorage.setItem("menu", "Penelitian dan PkM");
    localStorage.setItem("halaman", "Kewajiban Tahunan");
  },

  data() {
    return {
      status_tombol: false,
      dosen: {},
      data_penelitian_dosen: [],
      form_usulan: {},
      data_usulan: [],
      data_pencairan: []
    };
  }, 

  mounted() {
    this.profilDosen();
    this.getStatusTombol();
    this.getDosen();
  },

  methods: {
    async getStatusTombol() {
      const res = await getStatusTombol();

      this.status_tombol = res.data.status;
    },

    async profilDosen() {
      const res = await profilDosen();

      this.dosen = res.data.data;
      this.dataPenelitianDosen(this.dosen.id);
    },

    async dataPenelitianDosen(id) {
      const res = await dataPenelitianDosen(id);

      this.data_penelitian_dosen = res.data.data;
    },

    async getDosen() {
      const res = await getDosen();

      this.data_usulan = res.data.data;

      this.getStatusTombol();
    },

    async getDosenPencairan(id) {
      this.data_pencairan = [];
      const res = await getDosenPencairan(id);

      this.data_pencairan = res.data.data.data_pencairan;
    },

    async postKewajibanTahunan() {
      let form_data = new FormData();

      form_data = this.objectToFormData(this.form_usulan);

      const file_loa = document.querySelector("#file_loa");
      form_data.append("file_loa", file_loa.files[0]);

      const res = await postKewajibanTahunan(form_data);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Kewajiban tahunan berhasil disimpan",
          showConfirmButton: false,
          timer: 3000,
        });

        this.getDosen();
      }
    },

    objectToFormData(obj) {
      const formData = new FormData();
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          formData.append(key, obj[key]);
        }
      }

      return formData;
    },

    handleAjukan(id) {
      Swal.fire({
        icon: 'question',
        title: 'Ajukan Kewajiban Tahunan',
        confirmButtonText: "Ajukan",
        confirmButtonColor: "#3085d6",
        showCancelButton: true,
        cancelButtonText: 'Batal',
        cancelButtonColor: "#d33",
      }).then(async e => {
        if( e.isConfirmed ) {
          const res = await postAjukan(id);

          if (res.status == 200) {
            Swal.fire({
              icon: 'success',
              text: "Kewajiban Tahunan Berhasil Diajukan",
              showConfirmButton: false,
              timer: 3000
            })

            this.getDosen();
          }
        }
      })
    }
  },
};
</script>
