<template>
    <container-app>
        <template #content>

            <div class="row">
                <div class="col-12 col-md-5">
                    <card-basic>
                        <template #content>

                            <div class="p-5 w-100" v-if="profil.nama.length > 0">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <div class="symbol symbol-100px symbol-lg-160px mb-3">
                                        <img :src="profil.foto" />
                                    </div>

                                    <button class="btn btn-white text-primary" data-bs-toggle="modal"
                                        data-bs-target="#modalEditFoto">Ganti Foto</button>
                                </div>

                                <div class="row mb-5 mt-7">
                                    <label class="col-lg-4 text-muted">Nama</label>
                                    <div class="col-lg-8">
                                        <span class="fs-6 text-gray-800">{{ profil.nama }}</span>
                                    </div>
                                </div>

                                <div class="row mb-5">
                                    <label class="col-lg-4 text-muted">NIM</label>
                                    <div class="col-lg-8">
                                        <span class="fs-6 text-gray-800">{{ profil.nim }}</span>
                                    </div>
                                </div>

                                <div class="row mb-5">
                                    <label class="col-lg-4 text-muted">Email</label>
                                    <div class="col-lg-8">
                                        <span class="fs-6 text-gray-800">{{ profil.email }}@itg.ac.id</span>
                                    </div>
                                </div>

                                <div class="row mb-5">
                                    <label class="col-lg-4 text-muted">Program Studi</label>
                                    <div class="col-lg-8">
                                        <span class="fs-6 text-gray-800">{{ profil.program_studi }}</span>
                                    </div>
                                </div>
                            </div>

                        </template>
                    </card-basic>
                    <card-basic>
                        <template #content>

                            <div class="p-5 w-100" v-if="profil.nama.length > 0">
                                <h4>Whatsapp</h4>
                                <button data-bs-toggle="modal" class="btn btn-info w-100" data-bs-target="#modalEdit">
                                    <span class="ms-2">Aktifasi Whatsapp</span>
                                </button>
                            </div>

                        </template>
                    </card-basic>
                </div>
            </div>
        </template>
    </container-app>

    <modal-basic id="modalEdit" title="Aktifasi Whatsapp">
        <template #content>
            <div v-if="step == 0">
                <p class="mb-4">untuk aktifasi Whatsapp, anda wajib untuk memverifikasi nomor WhatsApp pribadi terlebih
                    dahulu!</p>

                <div class="col-12 mb-5">

                    <div class="input-group">
                        <span class="input-group-text" id="inputGroup-sizing-default">ID (+62)</span>
                        <input type="text" placeholder="08xxxxxxxxxx" class="form-control" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-default" v-model="nowa" >
                    </div>
                    <div class="form-text" id="basic-addon4">Contoh : 08123456789.</div>
                </div>
                <button class="btn btn-primary d-grid w-100" type="submit" name="wa-send" value="wa-send"
                    @click="step = 1">Verifikasi
                    nomor</button>

            </div>
            <div v-if="step == 1">
                <p class="text-start mb-4">
                    Kami Telah mengirimkan 6 digit kode ke nomor WhatsApp anda, Silahkan inputkan 6 digit kode ke form input
                    di
                    bawah:

                    <span class="fw-bold d-block mt-2">{{ nowa }}</span>
                </p>
                <p class="mb-0 fw-semibold">6 digit kode OTP</p>
                <form id="twoStepsForm" class="mb-3" method="POST">
                    <div class="mb-3">
                        <div
                            class="auth-input-wrapper d-flex align-items-center            justify-content-sm-between numeral-mask-wrapper">
                            <input type="text"
                                class="form-control auth-input w-px-50 text-center numeral-mask text-center h-px-50 mx-1 my-2"
                                maxlength="1" autofocus />
                            <input type="text"
                                class="form-control auth-input w-px-50 text-center numeral-mask text-center h-px-50 mx-1 my-2"
                                maxlength="1" />
                            <input type="text"
                                class="form-control auth-input w-px-50 text-center numeral-mask text-center h-px-50 mx-1 my-2"
                                maxlength="1" />
                            <input type="text"
                                class="form-control auth-input w-px-50 text-center numeral-mask text-center h-px-50 mx-1 my-2"
                                maxlength="1" />
                            <input type="text"
                                class="form-control auth-input w-px-50 text-center numeral-mask text-center h-px-50 mx-1 my-2"
                                maxlength="1" />
                            <input type="text"
                                class="form-control auth-input w-px-50 text-center numeral-mask text-center h-px-50 mx-1 my-2"
                                maxlength="1" />
                        </div>
                        <!-- Create a hidden field which is combined by 3 fields above -->
                        <input type="hidden" name="otp" id="otp" />
                    </div>
                    <button class="btn btn-primary d-grid w-100 mb-3" type="submit" name="wa-otp" value="wa-otp">Verifikasi
                        akun
                        saya</button>
                    <div class="text-center">Jika anda tidak menerima OTP atau salah mengisi nomor WhatsApp dapat
                        mengubahnya sampai waktu dibawah selesai: </div>
                    <div class="text-center"> {{ countdown.menit }} menit {{ countdown.detik }} detik </div>
                </form>
            </div>


        </template>
    </modal-basic>
</template>

<script>
// apis
import { getUser, verifWhatsapp } from '@/apis/user';

import { getUserData } from '@/store';

export default {
    data() {
        return {
            profil: {
                nama: ''
            },
            step: 0,
            countdown: {
                menit: 0,
                detik: 0
            },
            nowa: null,
            user: getUserData()
        }
    },
    mounted() {
        this.getUser();
        console.log(this.user);
        
        let maskWrapper = document.querySelector('.numeral-mask-wrapper');
        if (maskWrapper) {
            for (let pin of maskWrapper.children) {
                pin.onkeyup = function (e) {
                    if (pin.nextElementSibling) {
                        if (this.value.length === parseInt(this.attributes['maxlength'].value)) {
                            pin.nextElementSibling.focus();
                        }
                    }

                    if (pin.previousElementSibling) {
                        if (e.keyCode === 8 || e.keyCode === 46) {
                            pin.previousElementSibling.focus();
                        }
                    }
                };
            }
        }
    },
    methods: {
        async getUser() {
            try {
                const response = await getUser();
                this.profil = response.data.data;

                this.profil.foto = this.profil.foto == 'http://api-aisnet.itg.ac.id//-' ? '/img/blank.aad363d0.png' : this.profil.foto;
                // this.profil.foto = this.profil.foto == 'http://127.0.0.1:8000/-' ? '/img/blank.aad363d0.png' : this.profil.foto;

            } catch (err) {
                console.error(err);
            }
        },
        async verifWhatsapp(){
            try {

            } catch (err){

            }
        }
    }
}
</script>