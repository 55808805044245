<template>
    <container-app :container_loading="container_loading">
        <template #content>
            <card-basic>
                <template #content>
                    <div class="p-5">
                        <label-header label="Semester/TA Sekarang" :value="ta.ket">
                            <template #control>
    
                                <button-icon name="Filter" class="btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#modalFilter">
                                    <template #icon>
                                        <icon-filter />
                                    </template>
                                </button-icon>

                                <a 
                                    href="https://drive.google.com/drive/folders/1m2yZ_WmDFmNpDweZNX4PS1gkB4jTiNED?usp=sharing"
                                    class="btn btn-sm btn-secondary ms-3 d-flex align-items-center px-3 px-md-4"
                                    target="_blank"
                                    >
                                    <icon-calender /><span class="d-none d-md-inline ms-2">Jadwal Kuliah</span>
                                </a>
                                
                            </template>
                        </label-header>
                    </div>

                    <tables-jadwal-kuliah :daftar_jadwal="daftar_jadwal" class="d-none d-md-block" />
                </template>
            </card-basic>

            <div class="d-block d-md-none">
                <cards-jadwal-kuliah :daftar_jadwal="daftar_jadwal" />
            </div>
        </template>
    </container-app>

    <!-- begin::modal -->
    <modal-basic id="modalFilter" title="Filter">
        <template #content>
            <label for="basic-url" class="form-label">Tahun Akademik</label>
            <div class="input-group mb-5">
                <select class="form-select form-select-solid" v-model="filter.thsms">
                    <option v-for="(ta, index) in daftar_ta" :key="index" :value="ta.thsms">Tahun {{ ta.ket }}</option>
                </select>
            </div>
        </template>

        <template #button>
            <button-basic class="bg-primary text-light" name="Simpan" @click="processFilter" />
        </template>
    </modal-basic>
    <!-- end::modal -->
</template>

<script>
    // components
    import CardsJadwalKuliah from '@/components/_JadwalKuliah/CardsJadwalKuliah.vue'
    import TablesJadwalKuliah from '@/components/_JadwalKuliah/TablesJadwalKuliah.vue'

    // apis
    import { getsTahunAkademik } from '@/apis/tahun';
    import { getsJadwalKuliah } from '@/apis/jadwal';
    
    export default {
        components: {
            CardsJadwalKuliah,
            TablesJadwalKuliah
        },
        data() {
            return {
                daftar_ta : [],
                ta : {},
                daftar_jadwal : [],
                filter : {},
                container_loading : true
            }
        },
        beforeMount() {
            localStorage.setItem('menu', 'Kuliah')
            localStorage.setItem('halaman', 'Jadwal Kuliah')
        },
        mounted() {
            this.getData();
        },
        methods: {
            async getData() {
                await this.getsTahunAkademik();
                this.getsJadwalKuliah();
            },
            async getsTahunAkademik() {
                try {
                    const response = await getsTahunAkademik();
                    this.daftar_ta = response.data.data;
                    this.ta = this.daftar_ta[0];
                    this.filter.thsms = this.daftar_ta[0].thsms;
                    
                } catch ( err ) {
                    console.error( err );
                }
            },
            async getsJadwalKuliah() {
                try {
                    const response = await getsJadwalKuliah( this.ta.thsms );
                    this.daftar_jadwal = response.data.data;
                    this.container_loading = false;
                } catch ( err ) {
                    console.error( err );
                }
            },
            processFilter() {
                const filter = this.daftar_ta.filter( e => e.thsms == this.filter.thsms );
                this.ta = filter[0];
                this.getsJadwalKuliah();
            }
        }
    }
</script>