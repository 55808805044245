<template>
  <container-app>
    <template #content>
      <card-basic>
        <template #content>
          <!-- filter semester -->
          <div class="d-flex flex-column flex-md-row justify-content-between gap-3 my-5 mx-5">
            <div class="d-flex align-items-center gap-2">
              <label class="form-label m-0">Semester</label>
              <select class="form-select w-auto" v-model="jadwal_id">
                <option v-for="jadwal in data_jadwal_pengusulan" :key="jadwal.id" :value="jadwal.id">
                  {{ jadwal.semester }}
                </option>
              </select>
            </div>
          </div>

          <!-- table -->
          <table-basic>
            <template #thead>
              <tr class="fw-bolder bg-light fs-7">
                <th class="rounded-start ps-5 w-md-50px">No.</th>
                <th class="w-md-200px">Dosen</th>
                <th>Luaran Tambaha</th>
                <th class="rounded-end pe-5 w-md-150px">Aksi</th>
              </tr>
            </template>

            <template #tbody>
              <tr class="fs-7" v-for="(pengusul, i) in data_pengusul" :key="i">
                <td class="text-end ps-5">{{ i + 1 }}</td>
                <td>
                  <span class="fw-bold d-block">{{ pengusul.nama }}</span>
                  NIDN. {{ pengusul.nidn }}
                </td>
                <td>
                  <div v-for="(data, i) in pengusul.data_usulan" :key="i" :class="i > 0 ? 'mt-6' : ''">
                    <div class="d-flex justify-content-between">
                      <div>
                        <span>Usulan {{ i + 1 }}</span>
                      </div>
                      <span class="badge" :class="['DISETUJUI', 'PERUBAHAN DISETUJUI'].includes(data.status) ? 'badge-success' : 'badge-secondary'">
                        {{ data.status }}
                      </span>
                    </div>
                    <p class="fw-bold mb-1">{{ data.judul }}</p>
                  </div>
                </td>
                <td class="pe-5">
                  <button v-if="pengusul.jumlah_usulan > 0" class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#detailUsulanPPM" @click="detailUsulan(i)">
                    Detail
                  </button>
                </td>
              </tr>
            </template>
          </table-basic>
        </template>
      </card-basic>
    </template>
  </container-app>

  <!-- begin::detailUsulanPPM -->
  <modal-medium id="detailUsulanPPM" title="Detail Usulan Penelitian dan PkM">
    <template #content>
      <div class="card border border-secondary">
        <div class="card-basic p-4">
          <label-basic name="Profil Dosen" />

          <div class="row mb-2 mt-3">
            <div class="col-sm-3">Nama Dosen</div>
            <div class="col-sm-9 fw-bold d-block">: {{ pengusul.dosen_nama }}</div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-3">NIDN</div>
            <div class="col-sm-9 fw-bold d-block">: {{ pengusul.dosen_nidn }}</div>
          </div>
          <div class="row">
            <div class="col-sm-3">Program Studi</div>
            <div class="col-sm-9 fw-bold d-block">: {{ pengusul.program_studi }}</div>
          </div>
        </div>
      </div>

      <nav class="mt-4">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button class="nav-link" :class="i == 1 ? 'active' : ''" id="nav-detail_usulan_ppm_usulan_1-tab" data-bs-toggle="tab" type="button" role="tab" aria-selected="true" v-for="i in jumlah_usulan" :key="i" @click="detailUsulan(index_usulan, i - 1)">
            Usulan {{ i }}
          </button>
        </div>
      </nav>

      <div class="card border border-secondary">
        <div class="card-basic p-4">
          <div class="d-flex justify-content-between mb-4">
            <label-basic name="Usulan PPM" />
            <span class="btn btn-sm btn-link p-0" v-if="!detail[0]" @click="detail[0] = !detail[0]">Detail <icon-arrow-bottom/></span>
            <span class="btn btn-sm btn-link text-danger p-0" v-else @click="detail[0] = !detail[0]">Tutup Detail <icon-arrow-up/></span>
          </div>

          <div v-if="!detail[0]">
            <p>{{ pengusul.judul }}</p>
            <div class="d-flex justify-content-between">
              <div>
                <span class="badge ms-2" :class=" ['DISETUJUI', 'PERUBAHAN DISETUJUI'].includes(pengusul.status) ? 'badge-success' : 'badge-secondary'">{{ pengusul.status }}</span>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="row mb-2">
              <div class="col-sm-3">Jenis</div>
              <div class="col-sm-9 fw-bold d-block">: Luaran Tambahan</div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Luaran</div>
              <div class="col-sm-9 fw-bold d-block">
                : {{ pengusul.luaran_tambahan }}
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Kategori</div>
              <div class="col-sm-9 fw-bold d-block">
                : {{ pengusul.luaran_tambahan_kategori }}
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Tema</div>
              <div class="col-sm-9 fw-bold d-block">: {{ pengusul.tema }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Judul</div>
              <div class="col-sm-9 fw-bold d-block">: {{ pengusul.judul }}</div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-3">Status</div>
              <div class="col-sm-9 fw-bold d-block">
                :
                <span class="badge" :class=" ['DISETUJUI', 'PERUBAHAN DISETUJUI'].includes( pengusul.status ) ? 'badge-success' : 'badge-secondary'">{{ pengusul.status }}</span>
              </div>
            </div>

            <!-- start: tombol validasi -->
            <div class="row mt-7">
              <div class="col-sm-3"></div>
              <div class="col-sm-9">
                <div v-if=" (lembaga_jabatan == 'LPPM STAF') & (pengusul.waktu_validasi_staf_lppm == null)">
                  <button class="btn btn-sm btn-success" @click="validasiStafLPPM(pengusul.id)">
                    Validasi
                  </button>
                  <button class="btn btn-sm btn-danger ms-2" @click="tolakPengusulan(pengusul.id)">
                    Tolak
                  </button>
                </div>
                <div v-if="(lembaga_jabatan == 'LPPM KETUA') & (pengusul.waktu_validasi_lppm == null) & (pengusul.waktu_validasi_staf_lppm != null)">
                  <button class="btn btn-sm btn-success" @click="validasiLPPM(pengusul.id)">
                    Validasi
                  </button>
                  <button class="btn btn-sm btn-danger ms-2" @click="tolakPengusulan(pengusul.id)">
                    Tolak
                  </button>
                </div>
                <div v-if="(lembaga_jabatan == 'ITG REKTOR') & (pengusul.waktu_validasi_rektor == null) & (pengusul.waktu_validasi_lppm != null)">
                  <button class="btn btn-sm btn-success" @click="validasiRektor(pengusul.id)">
                    Validasi
                  </button>
                  <button class="btn btn-sm btn-danger ms-2" @click="tolakPengusulan(pengusul.id)">
                    Tolak
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-medium>
  <!-- end::detailUsulanPPM -->
  <!-- end::modal -->
</template>

<script>
import { lembagaJabatan } from "@/apis/user";
import { indexJadwalPengusulan } from "@/apis/jadwalPengusulan";
import { indexPengusulan, showPengusulan, validasiPengusulan } from "@/apis/pengusulanLuaranTambahan";

import { getUserData } from "@/store";
export default {
  beforeMount() {
    localStorage.setItem("menu", "Penelitian dan PkM");
    localStorage.setItem("halaman", "Validasi Luaran Tambahan");
    this.lembagaJabatan();
  },

  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
      user: getUserData(),
      lembaga_jabatan: "-",
      full_akses: false,
      data_jadwal_pengusulan: [],
      jadwal_id: 0,
      data_pengusul: [],
      pengusul: {},
      jumlah_usulan: 0,
      detail: [],
    };
  },

  mounted() {
    this.indexJadwalPengusulan();
  },

  watch: {
    jadwal_id() {
      this.indexPengusulan();
    }
  },

  methods: {
    async lembagaJabatan() {
      const res = await lembagaJabatan();

      this.lembaga_jabatan = res.data.lembaga_jabatan;

      if ( this.user.akses.includes('ALL') ) {
        this.full_akses = true;
      } else {
        this.full_akses = this.akses.includes(this.lembaga_jabatan) ? true : false;
      }
    },

    async indexJadwalPengusulan() {
      const res = await indexJadwalPengusulan();

      this.data_jadwal_pengusulan = res.data.data;
      this.jadwal_id = res.data.data[0].id;
    },

    async indexPengusulan() {
      const res = await indexPengusulan(this.jadwal_id);

      this.data_pengusul = res.data.data.map((e) => {
        e.status_kode = e.ajukan == 0 ? 0 : 1;
        e.edit = e.ajukan == 0 ? true : false;
        e.hapus = e.ajukan == 0 ? true : false;

        return e;
      });
    },

    async showPengusulan(id) {
      this.pengusul = {};
      const res = await showPengusulan(id);

      this.pengusul = res.data.data;
    },

    detailUsulan(index, usulan = 0) {
      this.resetDetail();
      this.usulan_ppm = this.data_pengusul[index];
      this.index_usulan = index;
      this.jumlah_usulan = this.data_pengusul[index].jumlah_usulan;
      this.showPengusulan(this.usulan_ppm.data_usulan[usulan].id);
    },

    resetDetail() {
      this.detail.forEach((e, i) => {
        this.detail[i] = false;
      });
    },

    async validasiPengusulan(data) {
      const res = await validasiPengusulan(data);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Usulan berhasil divalidasi",
          showConfirmButton: false,
          timer: 3000,
        });

        this.indexPengusulan();
        this.showPengusulan(data.id);
      }
    },

    async validasiPengusulan(data) {
      const res = await validasiPengusulan(data);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Usulan berhasil divalidasi",
          showConfirmButton: false,
          timer: 3000,
        });

        this.indexPengusulan();
        this.showPengusulan(data.id);
      }
    },

    async validasiStafLPPM(id) {
      this.validasiPengusulan({ id: id, validasi: "STAF_LPPM" });
    },

    async validasiLPPM(id) {
      this.validasiPengusulan({ id: id, validasi: "LPPM" });
    },

    async validasiRektor(id) {
      this.validasiPengusulan({ id: id, validasi: "REKTOR" });
    },
  },
};
</script>
