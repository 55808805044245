<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs></defs>
    <g id="Abstract_Elements" data-name="Abstract Elements" fill="currentColor">
      <g id="abs001-049">
        <g id="abs031-040">
          <g id="abs036">
            <path
              d="M22,12c0,.24,0,.48,0,.71L19.5,10.23,16.93,12.8A4.25,4.25,0,0,0,17,12a5,5,0,0,0-4.2-4.93L10.23,4.5,12.71,2A10,10,0,0,1,22,12ZM11.2,16.93A5,5,0,0,1,7,12a4.25,4.25,0,0,1,.07-.8L4.5,13.77,2,11.29c0,.23,0,.47,0,.71a10,10,0,0,0,9.29,10l2.48-2.47Z"
            />
            <path
              class="cls-1"
              d="M22,12.71A10,10,0,0,1,12,22c-.24,0-.48,0-.71,0l2.48-2.47L11.2,16.93A4.25,4.25,0,0,0,12,17a5,5,0,0,0,4.93-4.2l2.57-2.57ZM10.23,4.5,12.71,2c-.23,0-.47,0-.71,0A10,10,0,0,0,2,11.29L4.5,13.77,7.07,11.2A5,5,0,0,1,12,7a4.25,4.25,0,0,1,.8.07Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<style>
.cls-1 {
  opacity: 0.3;
}
</style>
