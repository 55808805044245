<template>
    <div data-kt-menu-trigger="click" class="menu-item menu-accordion" :class="pathname == label ? 'here' : ''">
        <span class="menu-link">
            <span class="menu-icon">
                <span class="svg-icon svg-icon-2">
                    <slot name="icon"></slot>
                </span>
            </span>
            <span class="menu-title">{{ nama }}</span>
            <span class="menu-arrow"></span>
        </span>
        <div class="menu-sub menu-sub-accordion">
            <slot class="menu-item" name="items">
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['nama', 'label'],
        data() {
            return {
                pathname : ''
            }
        },
        mounted() {
            this.pathname = window.location.pathname.split("-")[0];
        }
    }
</script>