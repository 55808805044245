<template>
    
    <container-app>
        <template #content>
            
            <container-double>
                <template #left>

                    <card-basic>
                        <template #content>
                            
                            <div class="p-5">
                                <form-icon>
                                    <template #icon>
                                        <icon-search />
                                    </template>

                                    <template #content>
                                        <input 
                                            type="text" 
                                            class="form-control ps-14 fw-normal"
                                            placeholder="Pencarian berdasarkan id atau nama..."
                                            autofocus
                                            v-model="keyword"
                                            >
                                    </template>
                                </form-icon>

                                <span class="d-block mt-1 text-gray-500 ms-14" v-if="keyword.length > 0">Tekan tombol enter untuk melakukan pencarian</span>

                                <div class="d-flex justify-content-end mt-3">
                                    <button class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modalPusatKajian">Tambah</button>
                                </div>
                            </div>     
                            
                            <table-basic>
                                <template #thead>
                                    <tr class="fw-bolder bg-light fs-7">
                                        <th class="rounded-start ps-5 w-md-50px">No.</th>
                                        <th>Pusat Kajian</th>
                                        <th class="rounded-end pe-5 w-md-200px">Aksi</th>
                                    </tr>
                                </template>

                                <template #tbody>
                                    <tr v-for="(pusat_kajian, i) in data_pusat_kajian" :key="i">
                                        <td class="ps-5 text-end">{{ i+1 }}</td>
                                        <td>
                                            <span class="d-block fw-bold text-dark mb-1">{{ pusat_kajian.nama }}</span>
                                            <span class="d-block fs-8 text-muted mb-3">{{ pusat_kajian.fokus_utama }}</span>
                                            <span class="d-block fs-8 text-muted">Jurusan : {{ pusat_kajian.jurusan_nama }}</span>
                                        </td>
                                        <td class="pe-4">
                                            <button class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#modalKelompokKeahlian" @click="indexKelompokKeahlian(pusat_kajian.id)">Kelompok Keahlian</button>
                                        </td>
                                    </tr>
                                </template>
                            </table-basic>

                        </template>
                    </card-basic>

                </template>
            </container-double>

        </template>
    </container-app>

    <!-- begin::modalPusatKajian -->
    <modal-fullscreen-sm id="modalPusatKajian" title="Pusat Kajian">
        <template #content>
            <form @submit.prevent="storePusatKajian">
                <div class="form-group mb-3">
                    <label class="col-form-label">Jurusan (opsional)</label>
                    <select class="form-select" v-model="form_pusat_kajian.dm_jurusan_id">
                        <option v-for="(jurusan, i) in data_jurusan" :key="i" :value="jurusan.id">{{ jurusan.nama }}</option>
                    </select>
                </div>

                <div class="form-group mb-3">
                    <label class="col-form-label">Nama Pusat Kajian</label>
                    <textarea rows="3" class="form-control" v-model="form_pusat_kajian.nama" required></textarea>
                </div>

                <div class="form-group mb-3">
                    <label class="col-form-label">Fokus Utama</label>
                    <textarea rows="5" class="form-control" v-model="form_pusat_kajian.fokus_utama" required></textarea>
                </div>

                <input type="submit" value="Simpan" class="mt-5 btn btn-success">
            </form>
        </template>
    </modal-fullscreen-sm>
    <!-- end::modalPusatKajian -->

    <!-- begin::modalKelompokKeahlian -->
    <modal-fullscreen-sm id="modalKelompokKeahlian" title="Kelompok Keahlian">
        <template #content>
            <form @submit.prevent="storeKelompokKeahlian">

                <div class="form-group mb-3">
                    <label class="col-form-label">Nama Kelompok Keahlian</label>
                    <textarea rows="2" class="form-control" v-model="form_kelompok_keahlian.nama" required></textarea>
                </div>

                <input type="submit" value="Simpan" class="mt-5 btn btn-success">
            </form>

            <ol class="mt-7" v-if="data_kelompok_keahlian.length > 0">
                <li v-for="(kelompok_keahlian, i) in data_kelompok_keahlian" :key="i">{{ kelompok_keahlian.nama }}</li>
            </ol>
        </template>
    </modal-fullscreen-sm>
    <!-- end::modalKelompokKeahlian -->
</template>

<script>
// apis
import {indexPusatKajian, storePusatKajian} from '@/apis/pusatKajian';
import {indexJurusan} from '@/apis/jurusan';
import {indexKelompokKeahlian, storeKelompokKeahlian} from '@/apis/kelompokKeahlian';

export default {
    beforeMount() {
        localStorage.setItem('menu', 'AISnet');
        localStorage.setItem('halaman', 'Pusat Kajian');
    },
    data() {
        return {
            keyword: '',
            data_pusat_kajian: {},
            form_pusat_kajian: {},
            pusat_kajian: {},
            data_jurusan: {},
            data_kelompok_keahlian: {},
            form_kelompok_keahlian: {},
        }
    },
    mounted() {
        this.indexPusatKajian();
        this.indexJurusan();
    },
    methods: {
        async indexPusatKajian() {
            const res = await indexPusatKajian();

            this.data_pusat_kajian = res.data.data;
        },
        async indexJurusan() {
            const res = await indexJurusan();

            this.data_jurusan = res.data.data;
        },
        async storePusatKajian() {
            const res = await storePusatKajian(this.form_pusat_kajian);

            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Pusat Kajian berhasil disimpan',
                    showConfirmButton: false,
                    timer: 3000
                });

                this.form_pusat_kajian = {};
                this.indexPusatKajian();
            }
        },
        async indexKelompokKeahlian(pusat_kajian_id) {
            this.data_kelompok_keahlian = {};
            const res = await indexKelompokKeahlian(pusat_kajian_id);

            this.pusat_kajian.id = pusat_kajian_id;
            this.data_kelompok_keahlian = res.data.data;
        },
        async storeKelompokKeahlian() {
            this.form_kelompok_keahlian.dm_pusat_kajian_id = this.pusat_kajian.id;

            const res = await storeKelompokKeahlian(this.form_kelompok_keahlian);

            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Kelompok keahlian berhasil disimpan',
                    showConfirmButton: false,
                    timer: 3000
                });

                this.form_kelompok_keahlian = {};
                this.indexKelompokKeahlian(this.pusat_kajian.id);
            }
        }
    }
}
</script>