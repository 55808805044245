<template>
    <a class="menu-link" :href="url" :class="pathname == url ? 'active' : ''">
        <span class="menu-bullet">
            <span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">{{ nama }}</span>
    </a>
</template>

<script>
    export default {
        props: ['nama', 'url'],
        data() {
            return {
                pathname : ''
            }
        },
        mounted() {
            this.pathname = window.location.pathname;
        }
    }
</script>