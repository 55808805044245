<template>
    <container-app>
        <template #content>
            
            <container-double>
                <template #left>
                    
                    <card-basic>
                        <template #content>
                            <div class="p-5">
                                <form-icon>
                                    <template #icon>
                                        <icon-search />
                                    </template>

                                    <template #content>
                                        <input 
                                            type="text" 
                                            class="form-control ps-14 fw-normal"
                                            placeholder="Pencarian berdasarkan nama..."
                                            autofocus
                                            autocomplete="off"
                                            v-model="keyword"
                                            >
                                    </template>
                                </form-icon>

                                <span class="d-block mt-1 text-gray-500 ms-14" v-if="keyword.length > 0">Tekan tombol enter untuk melakukan pencarian</span>
                                
                                <div class="d-flex justify-content-end mt-3">
                                    <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalFormUser">Tambah</button>
                                    <button class="btn btn-primary ms-2" data-bs-toggle="modal" data-bs-target="#modalDosen">Dosen</button>
                                </div>
                            </div>

                            <table-basic>
                                <template #thead>
                                    <tr class="fw-bolder bg-light fs-7">
                                        <th class="rounded-start ps-5 w-md-50px">No.</th>
                                        <th>Nama Lengkap</th>
                                        <th class="rounded-end pe-5">Aksi</th>
                                    </tr>
                                </template>

                                <template #tbody>
                                    <tr class="fs-7" v-for="(admin, i) in data.admin" :key="i">
                                        <td class="text-end ps-5">{{ i + 1 }}</td>
                                        <td>
                                            <p class="m-0 mb-1">{{ admin.name }}</p>
                                            <span class="badge badge-info">{{ admin.lembaga }}</span>
                                            <span class="badge badge-secondary ms-2">{{ admin.jabatan }}</span>
                                        </td>
                                        <td class="pe-5">
                                            <div class="d-flex">
                                                <button-icon class="btn-sm btn-secondary" name="Reset Password" @click="resetPassword(admin.email)">
                                                    <template #icon>
                                                        <icon-reset />
                                                    </template>
                                                </button-icon>
                                                <button-icon class="btn-sm ms-2 btn-secondary" name="Lembaga" data-bs-toggle="modal" data-bs-target="#modalLembaga" @click="userLembagaJabatan(admin.id)">
                                                    <template #icon>
                                                        <icon-institution />
                                                    </template>
                                                </button-icon>
                                                <button-icon class="btn-sm ms-2 btn-secondary" name="Akses" data-bs-toggle="modal" data-bs-target="#modalAkses" @click="modalAkses(admin.id)">
                                                    <template #icon>
                                                        <icon-bill />
                                                    </template>
                                                </button-icon>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </table-basic>
                        </template>
                    </card-basic>

                </template>

            </container-double>

        </template>
    </container-app>

    <!-- begin::modalFormUser -->
    <modal-fullscreen-sm id="modalFormUser" title="Form User">
        <template #content>
            <form class="p-1" @submit.prevent="storeAdmin">
                <div class="form-group mb-3">
                    <label for="name">Nama Lengkap</label>
                    <input type="text" name="name" id="name" class="form-control" v-model="form.name" required>
                </div>
                <div class="form-group mb-3">
                    <label for="email">Email</label>
                    <input type="email" name="email" id="email" class="form-control" v-model="form.email" required>
                </div>
                <div class="form-group mb-3">
                    <label for="password">Password</label>
                    <input type="password" name="password" id="password" class="form-control" v-model="form.password" required>
                </div>

                <input type="submit" value="Simpan" class="btn btn-success mt-7">
            </form>
        </template>
    </modal-fullscreen-sm>
    <!-- end::modalFormUser -->

    <!-- begin::modalLembaga -->
    <modal-fullscreen-sm id="modalLembaga" title="Lembaga">
        <template #content>
            <form class="p-1" @submit.prevent="storeLembagaJabatan">
                <div class="form-group mb-3">
                    <label for="dm_lembaga_id">Nama Lembaga</label>
                    <select name="dm_lembaga_id" id="dm_lembaga_id" class="form-select" v-model="form_lembaga.dm_lembaga_id">
                        <option v-for="(lembaga, i) in data_lembaga" :key="i" :value="lembaga.id">
                            {{ lembaga.singkatan }} - {{ lembaga.nama }}
                        </option>
                    </select>
                </div>

                <div class="form-group mb-3">
                    <label for="jabatan">Jabatan</label>
                    <input type="text" name="jabatan" id="jabatan" class="form-control" v-model="form_lembaga.jabatan">
                </div>

                <input type="submit" value="Simpan" class="btn btn-success mt-5" />
            </form>
        </template>
    </modal-fullscreen-sm>
    <!-- end::modalLembaga -->

    <!-- begin::modalAkses -->
    <modal-fullscreen-sm id="modalAkses" title="Akses Admin">
        <template #content>
            <form class="p-1" @submit.prevent="aksesAdmin">
                <div class="form-group mb-3">
                    <label for="akses">Akses</label>
                    <input type="text" class="form-control" v-model="form_akses.akses" required>
                </div>

                <input type="submit" value="Simpan" class="btn btn-success mt-5" />
            </form>

            <div class="p-1 mt-5">
                <p class="mb-0">Akses :</p>
                <ul class="fs-7">
                    <li v-for="(akses, i) in data_akses" :key="i">{{ akses }}</li>
                </ul>
            </div>
        </template>
    </modal-fullscreen-sm>
    <!-- end::modalAkses -->

    <!-- begin::modalDosen -->
    <modal-fullscreen-sm id="modalDosen" title="Tambah Akses Admin">
        <template #content>
            <form class="p-1" @submit.prevent="aksesAdmin">
                <div class="mb-3">
                    <label for="user_id">Dosen</label>
                    <select name="user_id" id="user_Id" class="form-select" v-model="form_akses.id">
                        <option v-for="(dosen, i) in data_dosen" :key="i" :value="dosen.user_id">{{ dosen.nama }}</option>
                    </select>
                </div>

                <div class="form-group mb-3">
                    <label for="akses">Akses</label>
                    <input type="text" class="form-control" v-model="form_akses.akses" required>
                </div>

                <input type="submit" value="Tambah" class="mt-5 btn btn-success">
            </form>
        </template>
    </modal-fullscreen-sm>
    <!-- end::modalDosen -->
</template>

<script>
// apis
import { indexAdmin, storeAdmin, aksesAdmin, showAkses} from '@/apis/admin';
import { indexLembaga } from '@/apis/lembaga';
import { userLembagaJabatan, storeLembagaJabatan } from '@/apis/lembagaJabatan';
import { resetPassword } from '@/apis/user';
import { getsDosen, searchDosen } from '@/apis/dosen';

export default {
    beforeMount() {
        localStorage.setItem('menu', 'Manajemen User')
        localStorage.setItem('halaman', 'Admin')
    },
    data() {
        return {
            keyword: '',
            data: {},
            form: {},
            data_lembaga: {},
            form_lembaga: {},
            form_akses: {},
            data_akses: {},
            form_dosen: {},
            data_dosen: {}
        }
    },
    mounted() {
        this.indexAdmin();
        this.indexLembaga();
        this.getsDosen();
    },
    methods: {
        async indexAdmin() {
            try {
                const res = await indexAdmin();
                this.data.admin = res.data.data;

            } catch (err) {
                console.error( err );
            }
        },
        async getsDosen() {
            const res = await getsDosen();

            this.data_dosen = res.data.data.filter(e => e.user_id != '-');
        },
        storeAdmin() {
            Swal.fire({
                icon: 'question',
                text: 'Tambah admin ? ',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                confirmButtonText: 'Ya',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-secondary'
                }
            }).then( async result => {
                if( result.isConfirmed ) {
                    try {
                        const res = await storeAdmin(this.form);

                        if( res.status === 200 ) {
                            Swal.fire({
                                icon: 'success',
                                text: 'Admin berhasil disimpan',
                                showConfirmButton: false,
                                timer: 5000
                            })
                        }

                        this.indexAdmin();
                    } catch ( err ) {
                        console.error( err );
                    }
                }
            })
        },  
        resetPassword(email) {
            Swal.fire({
                icon: 'question',
                text: 'Reset password atas email ' + email,
                showCancelButton: true,
                cancelButtonText: 'Batal',
                confirmButtonText: 'Ya',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-secondary'
                }
            }).then( async result => {
                if( result.isConfirmed ) {
                    try {
                        const res = await resetPassword({email: email});

                        if( res.status === 200 ) {
                            Swal.fire({
                                icon: 'success',
                                text: 'Password berhasil di reset',
                                showConfirmButton: false,
                                timer: 5000
                            })
                        }
                    } catch ( err ) {
                        console.error( err );
                    }
                }
            })
        },
        async indexLembaga() {
            try {
                const res = await indexLembaga();

                this.data_lembaga = res.data.data;
            } catch (err) {
                console.error( err );
            }
        },
        async userLembagaJabatan(user_id) {
            this.form_lembaga = {}
            try {
                const res = await userLembagaJabatan(user_id);

                if( res.data.data.length != 0 ) {
                    this.form_lembaga = res.data.data;
                }
            } catch (err) {
                console.error( err );
            }

            this.form_lembaga.users_id = user_id;
        },
        storeLembagaJabatan() {
            Swal.fire({
                icon: 'question',
                text: 'Simpan jabatan ? ',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                confirmButtonText: 'Ya',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-secondary'
                }
            }).then( async result => {
                if( result.isConfirmed ) {
                    try {
                        const res = await storeLembagaJabatan(this.form_lembaga);

                        if( res.status === 200 ) {
                            Swal.fire({
                                icon: 'success',
                                text: 'Jabatan berhasil disimpan',
                                showConfirmButton: false,
                                timer: 5000
                            })

                            this.indexAdmin();
                        }
                    } catch ( err ) {
                        console.error( err );
                    }
                }
            })
        },
        async modalAkses(id) {
            this.form_akses.id = id;
            this.data_akses = {};

            const res = await showAkses(id);

            this.data_akses = res.data.data;
        },
        aksesAdmin() {
            Swal.fire({
                icon: 'question',
                text: 'Simpan akses ? ',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                confirmButtonText: 'Ya',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-secondary'
                }
            }).then( async result => {
                if( result.isConfirmed ) {
                    try {
                        const res = await aksesAdmin(this.form_akses);

                        if( res.status === 200 ) {
                            Swal.fire({
                                icon: 'success',
                                text: 'Akses berhasil disimpan',
                                showConfirmButton: false,
                                timer: 5000
                            })
                        }

                        this.indexAdmin();
                        this.modalAkses(this.form_akses.id);
                    } catch ( err ) {
                        console.error( err );
                    }
                }
            })
        }
    }
}

</script>