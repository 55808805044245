<template>
    <table-basic>
        <template #thead>
            <tr class="fw-bolder bg-light fs-7">
                <th class="rounded-start ps-4 w-auto w-md-50px ">No</th>
                <th>Mahasiswa</th>
                <th class="w-auto w-md-100px">Status</th>
                <th class="rounded-end w-auto w-md-100px">Aksi</th>
            </tr>
        </template>

        <template #tbody>
            <tr v-for="(mahasiswa, index) in daftar_mahasiswa" :key="index">
                <td class="text-end fs-7">{{ index + 1 }}</td>
                <td>
                    <span class="text-dark fw-bolder d-block mb-1 fs-7">{{ mahasiswa.nmmhs }}</span>
                    <span class="text-muted fw-bold text-muted d-block fs-8">({{ mahasiswa.nimhs }})</span>
                </td>
                <td>
                    <icon-status-2 v-if="mahasiswa.status_kode == 2" />
                    <icon-status-1 v-else-if="mahasiswa.status_kode == 1" />
                    <icon-status-0 v-else />
                </td>
                <td class="pe-4">
                    <div class="d-flex">
                        <button-icon name="Validasi" class="bg-secondary me-2 btn-sm" data-bs-toggle="modal" data-bs-target="#modalValidasi" @click="getFrs(mahasiswa.nimhs)">
                            <template #icon>
                                <icon-check />
                            </template>
                        </button-icon>
                        <button-icon name="Transkrip" class="bg-secondary me-2 btn-sm" data-bs-toggle="modal" data-bs-target="#modalTranskrip" @click="getTranskrip(mahasiswa)">
                            <template #icon>
                                <icon-chart />
                            </template>
                        </button-icon>
                        <button class="btn btn-secondary btn-sm me-2 px-3 px-md-4 position-relative d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#modalKonsultasi" @click="getsKonsultasi(mahasiswa.nimhs)">
                            <icon-chat-2 />
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {{ mahasiswa.belum_dibaca }}
                            </span>
                            <span class="d-none d-md-inline ms-2">Konsultasi</span>
                        </button>
                    </div>
                </td>
            </tr>
        </template>
    </table-basic>

    <!-- begin::modal -->
    <!-- begin::validasi -->
    <modal-fullscreen-sm id="modalValidasi" title="Validasi FRS">
        <template #content>

            <card-basic>
                <template #content>
                    <div class="p-5">
                        <info-user 
                            :name="informasi.mahasiswa_nama" 
                            :id="informasi.mahasiswa_nim" 
                            :foto='informasi.mahasiswa_foto' 
                        />
                    </div>
                </template>
            </card-basic>

            <div class="d-block d-md-none">
                <card-basic>
                    <template #content>
                        <div class="px-5 py-3">
                            <div class="d-flex justify-content-between align-items-center w-100">
                                <span>
                                    Warna Mata Kuliah & Batas SKS
                                </span>
                                <span 
                                    data-bs-toggle="collapse" 
                                    href="#batasSKS" 
                                    role="button" 
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                    @click="handleDetail"
                                    >
                                    Detail
                                    <icon-arrow-bottom v-if="!detail" />
                                    <icon-arrow-up v-else/>
                                </span>
                            </div>
                        </div>
                        <div class="collapse" id="batasSKS">
                            <div class="mt-2 pt-2 px-5 fs-7">
                                <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-bs-toggle="tab" href="#warnaMK">Warna Mata Kuliah</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#batas2">Batas SKS</a>
                                    </li>
                                </ul>
                            </div>

                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active p-5" id="warnaMK" role="tabpanel">
                                    <div class="text-gray-700 mt-2 pt-4 fs-7 p-5">
                                        <ul class="ps-0 m-0">
                                            <li class="py-3 ms-5 ps-4 bg-light-success">Mata Kuliah Wajib</li>
                                            <li class="py-3 ms-5 ps-4 bg-light-warning">Mata Kuliah Pilihan</li>
                                            <li class="py-3 ms-5 ps-4 bg-light-danger">Mata Kuliah Merdeka Belajar</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="batas2" role="tabpanel">
                                    <info-batas />
                                </div>
                            </div>
                        </div>
    
                    </template>
                </card-basic>
            </div>
            
            <card-basic>
                <template #content>
                    <div class="row p-5">
                        <div class="col-6">
                            <div class="d-flex align-items-center">
                                <div class="bg-light-primary border border-1 border-dashed border-primary p-2 symbol symbol-40px">
                                    <icon-status-1 />
                                </div>
                                <div class="d-flex flex-column ms-4">
                                    <span class="fw-bold text-muted fs-8">SKS Diajukan</span>
                                    <span class="fw-bolder fs-7">{{ informasi.jumlah_sks }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="d-flex align-items-center">
                                <div class="bg-light-primary border border-1 border-dashed border-primary p-2 symbol symbol-40px">
                                    <icon-status-2 />
                                </div>
                                <div class="d-flex flex-column ms-4">
                                    <span class="fw-bold text-muted fs-8">SKS Disetujui</span>
                                    <span class="fw-bolder fs-7">{{ informasi.jumlah_disetujui }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table align-middle gs-0 gy-4 table-row-dashed fs-7">
                            <thead>
                                <tr class="fw-bolder bg-light fs-7">
                                    <th class="rounded-start ps-4 w-50px">No</th>
                                    <th>Mata Kuliah</th>
                                    <th class="w-auto w-md-100px">SKS</th>
                                    <th class="rounded-end w-auto pe-4">Check</th>
                                </tr>
                            </thead>

                            <tbody v-for="(frs, i) in daftar_frs" :key="i">
                                <tr class="fs-7">
                                    <td colspan="4" class="text-center bg-dark text-light">Semester {{ frs.semester }}</td>
                                </tr>
                                <tr v-for="(t, l) in frs.transkrip" :key="l" :class="t.jenis_matakuliah == 0 ? 'bg-light-warning' : t.jenis_matakuliah == 1 ? 'bg-light-success' : 'bg-light-danger'">
                                    <td class="text-end fs-7">
                                        {{ l+1 }}
                                    </td>
                                    <td>
                                        <span class="text-dark d-block fs-7">{{ t.nakmk }}</span>
                                        <span class="d-block fs-8" :class="t.status_kode == 2 ? 'text-success' : t.status_kode == 3 ? 'text-danger' : 'text-muted'">({{ t.status }})</span>
                                    </td>
                                    <td class="fs-7">{{ t.sks }}</td>
                                    <td class="fs-7">
                                        <input type="checkbox" :value="t.id" :key="l" v-model="frs_id" class="form-check-input" v-if="validasi" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="d-flex mt-5" v-if="frs_id.length != 0 & validasi">
                        <button class="d-block w-100 btn btn-primary me-2" @click="confirmFrs">Setuju</button>
                        <button class="d-block w-100 btn btn-danger ms-2" @click="rejectFrs">Tolak</button>
                    </div>
                </template>
            </card-basic>

        </template>
    </modal-fullscreen-sm>
    <!-- end::validasi -->

    <!-- begin::transkrip -->
    <modal-fullscreen-sm id="modalTranskrip" title="Transkrip Nilai">
        <template #content>
            
            <card-basic>
                <template #content>
                    <div class="p-5">
                        <info-user 
                            :name="informasi.mahasiswa_nama" 
                            :id="informasi.mahasiswa_nim" 
                            :foto='informasi.mahasiswa_foto' 
                        />
                    </div>
                </template>
            </card-basic>

            <card-basic>
                <template #content>
                    <div class="p-5 pb-0">
                        <label-basic name="Info Grafis IPS Mahasiswa" class="text-center" />
                    </div>
                    <div class="p-2 pt-0">
                        <canvas id="renderChart" height="150"></canvas>
                    </div>
                </template>
            </card-basic>

            <card-basic>
                <template #content>
                    <div class="p-5">
                        <div class="row">
                            <div class="col-6">
                                <div class="d-flex align-items-center">
                                    <div class="bg-light-primary border border-1 border-dashed border-primary p-2 symbol symbol-40px">
                                        <icon-list />
                                    </div>
                                    <div class="d-flex flex-column ms-4">
                                        <span class="fw-bold text-muted fs-8">Total SKS</span>
                                        <span class="fw-bolder fs-7">{{ informasi.sks }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="d-flex align-items-center">
                                    <div class="bg-light-primary border border-1 border-dashed border-primary p-2 symbol symbol-40px">
                                        <icon-chart />
                                    </div>
                                    <div class="d-flex flex-column ms-4">
                                        <span class="fw-bold text-muted fs-8">IPK</span>
                                        <span class="fw-bolder fs-7">{{ informasi.ipk }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <tables-transkrip :daftar_transkrip="daftar_transkrip" />
                </template>
            </card-basic>
        </template>
    </modal-fullscreen-sm>
    <!-- end::transkrip -->

    <!-- begin::modalKonsultasi -->
    <modal-scroll id="modalKonsultasi" title="Konsultasi" body_id="containerKonsultasi">
        <template #body>
            <div class="d-flex flex-column">

                <div class="flex-fill mb-3 pt-5" style="min-height: 50vh">
                    <div class="text-center" v-if="loading_chat">
                        <icon-loading />
                    </div>

                    <div v-for="(chat, i) in daftar_chat" :key="i">
                        <div class="d-flex mx-5 mb-3" :class="chat.sender == 'Saya' ? 'justify-content-end' : 'justify-content-start'">
                            <div class="d-flex flex-column" :class="chat.sender == 'Saya' ? 'align-items-end' : 'align-items-start'">
                                <span class="fs-7 fw-bold text-gray-900 text-hover-primary mb-1">{{ chat.sender }}</span>
                                <div class="p-3 rounded border border-secondary bg-white text-dark mw-lg-400px fs-7 mb-1 text-start" v-html="chat.chat"></div>
                                <span class="text-muted fs-8">{{ chat.time }}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </template>

        <template #footer>
            <div class="d-flex w-100">
                <textarea 
                    class="form-control border border-secondary fw-normal bg-light form-control-flush rounded" 
                    id="formChat" 
                    v-model="chat" 
                    style="overflow-y: hidden;" 
                    rows="1" @keyup.enter="sendKonsultasi"></textarea>
                <button class="btn btn-primary flex-grow ms-3 align-self-end" @click="sendKonsultasi">
                    Kirim
                </button>
            </div>
        </template>
    </modal-scroll>
    <!-- end::modalKonsultasi -->
    <!-- end::modal -->
</template>

<script>
// components
import TablesTranskrip from '@/components/_Transkrip/TablesTranskrip.vue';

// apis
import { getFrs, confirmFrs, rejectFrs } from '@/apis/frs';
import { getTranskrip } from '@/apis/hasilStudi';
import { getsKonsultasi, sendKonsultasi } from '@/apis/chatKonsultasi';

// helpers
import { daftar_transkrip } from '@/helpers/transkrip';
import { formatDate, formatSemester } from "@/helpers/waktu";

export default {
    props: ['daftar_mahasiswa', 'validasi'],
    components: {
        TablesTranskrip,
    },
    data() {
        return {
            informasi : {},
            daftar_frs : {},
            daftar_transkrip : {},
            daftar_ips : [],
            frs_id : [],
            daftar_semester : ['SM1', 'SM2', 'SM3', 'SM4', 'SM5', 'SM6', 'SM7', 'SM8'],
            grafik : null,
            detail: false,
            daftar_chat: {},
            chat: '',
            receiver_id: '',
            loading_chat: false,
        }
    },  
    emits: ["update-data"],
    mounted() {
        this.renderChart();
    },
    methods: {
        async getFrs(nim) {
            try {
                const data = await getFrs(nim);
                const informasi = data.data.informasi;
                
                this.clearFrsId();
                this.informasi = await informasi;
                this.daftar_frs = daftar_transkrip(data.data.data);
            }
            catch ( err ) {
                console.error( err )
            }
        },
        confirmFrs() {
            Swal.fire({
                icon: 'question',
                text: `Setuju mahasiswa untuk mengambil ${this.frs_id.length} mata kuliah tersebut ?`,
                showCancelButton: true,
                cancelButtonText: 'Batal',
                confirmButtonText: 'Ya',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-secondary'
                }
            }).then( async result => {
                if( result.isConfirmed ) {
                   try {
                        let formData = new FormData();
                        formData.append('nim', this.informasi.mahasiswa_nim)

                        this.frs_id.forEach( e => {
                            formData.append('frs_id[]', e)
                        });

                        const data = await confirmFrs(formData);
                        this.getFrs(this.informasi.mahasiswa_nim);
                        this.clearFrsId();
                        this.updateData();

                        if( data.status == 200 ) {
                            Swal.fire({
                                icon: 'success',
                                text: 'Mata kuliah berhasil disetujui',
                                timer: 5000,
                                showConfirmButton: false
                            })
                        }
                    }
                    catch ( err ) {
                        console.error( err )
                    }
                }
            })
        },
        rejectFrs() {
            Swal.fire({
                icon: 'question',
                text: `Tolak mahasiswa untuk mengambil ${this.frs_id.length} mata kuliah tersebut ?`,
                showCancelButton: true,
                cancelButtonText: 'Batal',
                confirmButtonText: 'Ya',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-secondary'
                }
            }).then( async result => {
                if( result.isConfirmed ) {
                    try {
                        let formData = new FormData();
                        formData.append('nim', this.informasi.mahasiswa_nim)

                        this.frs_id.forEach( e => {
                            formData.append('frs_id[]', e)
                        });

                        const data = await rejectFrs(formData);
                        this.getFrs(this.informasi.mahasiswa_nim);
                        this.clearFrsId();
                        this.updateData();

                        if( data.status == 200 ) {
                            Swal.fire({
                                icon: 'success',
                                text: 'Mata kuliah berhasil ditolak',
                                timer: 5000,
                                showConfirmButton: false
                            })
                        }
                    }
                    catch ( err ) {
                        console.error( err )
                    }
                }
            })
        },
        clearFrsId() {
            this.frs_id = []
        },
        updateData() {
            this.$emit('update-data')
        },
        async getTranskrip(data) {
            this.grafik.destroy();
            
            try {
                const res = await getTranskrip(data.nimhs);
                this.informasi = {
                    ipk : res.data.ipk,
                    sks : res.data.sks,
                    mahasiswa_nim : res.data.mahasiswa_nim,
                    mahasiswa_foto : res.data.mahasiswa_foto,
                    mahasiswa_nama : res.data.mahasiswa_nama,
                }
                this.daftar_transkrip = daftar_transkrip(res.data.data);

                this.daftar_ips = await res.data.data_ips.map(e => {
                    return e.ips
                });

                this.renderChart();
            }
            catch ( err ) {
                console.error( err );
            }
        },
        renderChart() {
            this.grafik = new Chart("renderChart", {
                type: "line",
                data: {
                    labels: this.daftar_semester,
                    datasets: [{
                        backgroundColor: "rgba(0, 158, 247, 0.1)",
                        borderColor: "rgba(25, 29, 52, 0.5)",
                        fill: true,
                        data: this.daftar_ips
                    }]
                },
                options: {
                    plugins: {
                        legend: {
                            display: false,
                        }
                    },
                    scales: {
                        y: {
                            min: 0,
                            max: 4.5,
                        }
                    }
                }
            });

            this.grafik.render();
        },
        handleDetail() {
            this.detail = !this.detail;
        },
        async getsKonsultasi(receiver_id) {
                this.daftar_chat = {};
                this.receiver_id = receiver_id;
                this.loading_chat = true;
                try {
                    const response = await getsKonsultasi(this.receiver_id);
                    this.daftar_chat = response.data.data.map(e=> {
                        return {
                            sender : e.sender,
                            chat : e.chat,
                            time : formatDate(e.time),
                        }
                    });

                    let e = document.getElementById('containerKonsultasi');
                    setTimeout(() => {
                        e.scrollTop = e.scrollHeight;
                    }, 100);

                    document.getElementById("formChat").autofocus;
                } catch (err) {
                    console.error( err );
                }
                this.loading_chat = false;
        },
        async sendKonsultasi() {
            try {
                let formData = new FormData();
                formData.append('receiver_id', this.receiver_id);
                formData.append('chat', this.chat);

                const response = await sendKonsultasi(formData);

                if( response.status == 200 ) {
                    await this.daftar_chat.push({   
                        sender: 'Saya',
                        chat: this.chat,
                        time: formatDate(new Date())
                    })

                    let e = document.getElementById('containerKonsultasi');
                    e.scrollTop = e.scrollHeight;
                    document.getElementById("formChat").autofocus;

                    this.chat = '';
                    this.updateData();
                }
            } catch ( err ) {
                console.error( err );
            }
        },
        addEnter() {
            this.chat = this.chat + '<br>';
        }
    },
}
</script>