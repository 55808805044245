<template>
    <div class="alert text-dark d-flex">
        <span class="svg-icon svg-icon-3 me-3 mb-sm-0 text-dark">
            <icon-alert />
        </span>

        <div class="pe-0 pe-sm-10">
            <span class="fs-7" v-html="message"></span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['message']
}
</script>