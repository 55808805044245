<template>
  <major-table-kontrak-perkuliahan v-if="isMajor" />
  <all-table-kontrak-perkuliahan v-else />
</template>

<script>
// components
import MajorTableKontrakPerkuliahan from "@/components/_kontrakPerkuliahan/MajorTableKontrakPerkuliahan.vue";
import AllTableKontrakPerkuliahan from "@/components/_kontrakPerkuliahan/AllTableKontrakPerkuliahan.vue";

// state
import { getUserData } from "@/store";

export default {
  components: {
    MajorTableKontrakPerkuliahan,
    AllTableKontrakPerkuliahan,
  },
  data() {
    return {
      user: {
        peran: "-",
      },
      isMajor: false,
    };
  },
  beforeMount() {
    localStorage.setItem("halaman", "Monitoring Kontrak Perkuliahan");
  },
  mounted() {
    this.isMajor = this.getMajorStatus(getUserData().nama);
    if (getUserData().peran == "ADMIN") {
      this.user.peran = getUserData().nama.toUpperCase();
    } else {
      this.user.peran = getUserData().peran;
    }
    localStorage.setItem(
      "menu",
      `${this.user.peran == "MAHASISWA" ? "Kuliah" : "AISnet"}`
    );
  },
  methods: {
    getMajorStatus(status) {
      switch (status) {
        case "Teknik Industri":
          return true;
        case "Teknik Sipil":
          return true;
        case "Teknik Informatika":
          return true;
        case "Sistem Informasi":
          return true;
        case "Arsitektur":
          return true;
        default:
          return false;
      }
    },
  },
};
</script>
