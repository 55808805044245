<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <div class="p-5">
            <!-- <div
              v-if="!isStarted"
              class="alert alert-primary d-flex align-items-center p-5"
            >
              <div class="d-flex flex-column">
                <span>Penilaian Ujian Akhir Semester (UAS) belum dibuka</span>
              </div>
            </div> -->
            <alert-icon
              v-if="isStarted"
              :message="
                expire.expired
                  ? 'Penilaian Ujian Akhir Semester (UAS) telah ditutup pada tanggal '
                  : 'Penilaian Ujian Akhir Semester (UAS) dibuka pada '
              "
              :tgl_buka="
                $date(expire.tgl_buka)
                  .locale('id')
                  .format('dddd, D MMMM YYYY [pukul] HH:mm [WIB]')
              "
              :tgl_tutup="
                $date(expire.tgl_tutup)
                  .locale('id')
                  .format('dddd, D MMMM YYYY [pukul] HH:mm [WIB]')
              "
              :type="expire.expired ? 'danger' : 'warning'"
            />
            <div
              v-if="daftarMataKuliahDosen.length === 0"
              class="alert alert-primary d-flex align-items-center p-5"
            >
              <div class="d-flex flex-column">
                <span>Penilaian Ujian Akhir Semester (UAS) belum tersedia</span>
              </div>
            </div>
            <div>
              <label-header value="Penilaian Ujian Akhir Semester (UAS)">
                <template #control>
                  <select
                    class="form-select form-select-solid"
                    data-control="selected_tahun_semester"
                    data-placeholder="Select an option"
                    v-model="selected_tahun_semester"
                    @change="onSelectedAcademicChange($event)"
                  >
                    <option
                      v-for="(item, i) in tahunAkademik"
                      :key="i"
                      :value="item.thsms"
                    >
                      Tahun {{ item.ket }}
                    </option>
                  </select>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#bobot_nilai"
                    type="button"
                    class="btn btn-primary btn-sm"
                  >
                    Bobot Nilai
                  </button>
                </template>
              </label-header>
            </div>
          </div>
          <table-basic>
            <template #thead>
              <tr class="fw-bolder bg-light fs-7">
                <th class="ps-4 w-50px rounded-start">No</th>
                <th class="min-w-100px">Mata Kuliah</th>
                <th class="min-w-100px">Program Studi</th>
                <th class="min-w-100px">Kelas</th>
                <th class="min-w-100px">Kelas Ujian</th>
                <th class="rounded-end"></th>
              </tr>
            </template>

            <template #tbody>
              <tr v-for="(item, i) in daftarMataKuliahDosen" :key="i">
                <td class="text-center align-middle">{{ i + 1 }}</td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.mata_kuliah }}
                  </span>
                  <span class="text-muted text-muted d-block fs-8">
                    {{ item.kode_mata_kuliah }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.program_studi }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.kelas }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.kelas_ujian }}
                  </span>
                </td>
                <td class="align-middle">
                  <a
                    @click="
                      getDetailUas(
                        item.tahun_semester,
                        item.kode_mata_kuliah,
                        item.mata_kuliah,
                        item.kelas,
                        item.kelas_ujian
                      )
                    "
                    class="mt-5 mt-lg-0 btn btn-sm btn-secondary btn-active-primary disabled"
                  >
                    Detail
                  </a>
                </td>
              </tr>
            </template>
          </table-basic>
        </template>
      </card-basic>
      <div class="modal fade" tabindex="-1" id="bobot_nilai">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Bobot & Instrumen Penilaian</h5>
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <icon-close />
              </div>
            </div>
            <div class="modal-body">
              <div class="mb-6 p-3 border">
                <label-header class="mb-5" value="Bobot Nilai" />

                <table-basic>
                  <template #thead>
                    <tr class="fw-bolder bg-light fs-7">
                      <th class="ps-4 w-50px rounded-start">No</th>
                      <th class="min-w-100px">Nilai Huruf</th>
                      <th class="rounded-end min-w-100px">Rentang Nilai</th>
                    </tr>
                  </template>

                  <template #tbody>
                    <tr v-for="(item, i) in daftarNilaiHuruf" :key="i">
                      <td class="text-center align-middle">{{ i + 1 }}</td>
                      <td class="align-middle">
                        {{ item.nilai_huruf }}
                      </td>
                      <td class="align-middle">
                        {{ item.batas_bawah }} {{ item.batas_bawah_simbol }}
                        {{ item.nilai_huruf }} {{ item.batas_atas_simbol }}
                        {{ item.batas_atas }}
                      </td>
                    </tr>
                  </template>
                </table-basic>
              </div>
              <div class="p-3 border">
                <label-header class="mb-5" value="Instrumen Penilaian" />
                <table-basic>
                  <template #thead>
                    <tr class="fw-bolder bg-light fs-7">
                      <th class="ps-4 w-50px rounded-start">No</th>
                      <th class="min-w-100px">Nama Instrumen Nilai</th>
                      <th class="rounded-end min-w-100px text-center">
                        Persentase
                      </th>
                    </tr>
                  </template>

                  <template #tbody>
                    <tr v-for="(item, i) in daftarInstrumentNilai" :key="i">
                      <td class="text-center align-middle">{{ i + 1 }}</td>
                      <td class="align-middle">
                        {{ item.instrumen_nilai }}
                      </td>
                      <td class="align-middle text-center">
                        {{ item.persentase }}%
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td class="fw-bolder">Total</td>
                      <td class="fw-bolder align-middle text-center">
                        {{ totalPersentase }}%
                      </td>
                      <td></td>
                    </tr>
                  </template>
                </table-basic>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </container-app>
</template>

<script>
import { getUserData } from "@/store";
import { useMataKuliahStore } from "@/stores/mataKuliah";

// API
import { getNidn, getAcademicYearsLecturer } from "@/apis/kontrakPerkuliahan";
import { getNilaiHuruf, getInstrumenNilai } from "@/apis/penilaianUjian";

import {
  getJadwalUASDosen,
  getJadwalPengisianNilai,
} from "@/apis/penilaianUjian";

export default {
  setup() {
    const mataKuliah = useMataKuliahStore();

    return {
      mataKuliah,
    };
  },
  data() {
    return {
      container_loading: false,
      daftarMataKuliahDosen: [],
      tahunAkademik: [],
      selected_tahun_semester: "",
      selected_nidn: "",
      selected_id: null,
      expire: {},
      isStarted: false,
      daftarNilaiHuruf: [],
      daftarInstrumentNilai: [],
    };
  },

  mounted() {
    this.fetchInstrumenNilai();
    this.fetchNilaiHuruf();
    this.loadData();
  },

  methods: {
    async fetchNilaiHuruf() {
      try {
        this.container_loading = true;
        const response = await getNilaiHuruf();
        if (response.status === 200) {
          this.daftarNilaiHuruf = response.data.data;

          this.container_loading = false;
        } else if (response.status === 404) {
          Swal.fire({
            text: response.data.message,
            icon: "warning",
          });
          this.container_loading = false;
        }
      } catch (error) {
        this.container_loading = false;
        Swal.fire({
          text: error.response.data.message,
          icon: "error",
        });
      }
    },

    async fetchInstrumenNilai() {
      try {
        this.container_loading = true;
        const response = await getInstrumenNilai();
        if (response.status === 200) {
          this.daftarInstrumentNilai = response.data.data;

          this.container_loading = false;
        } else if (response.status === 404) {
          Swal.fire({
            text: response.data.message,
            icon: "warning",
          });
          this.container_loading = false;
        }
      } catch (error) {
        this.container_loading = false;
        Swal.fire({
          text: error.response.data.message,
          icon: "error",
        });
      }
    },

    async onSelectedAcademicChange(event) {
      await this.lecturerContractsFetch(this.selected_nidn, event.target.value);
      await this.fetchJadwalPengisianNilai(this.selected_tahun_semester, "UAS");
    },
    async loadData() {
      await this.getsTahunAkademik();
      await this.nidnFetch(getUserData().nodos);
      await this.lecturerContractsFetch(
        this.selected_nidn,
        this.selected_tahun_semester
      );
      await this.fetchJadwalPengisianNilai(this.selected_tahun_semester, "UAS");
    },
    async getsTahunAkademik() {
      try {
        const response = await getAcademicYearsLecturer();
        this.tahunAkademik = response.data.data;
        this.selected_tahun_semester = response.data.data[0].thsms;
      } catch (err) {
        console.error(err);
      }
    },

    async nidnFetch(nodos) {
      try {
        const response = await getNidn(nodos);
        this.selected_nidn = response.data.data.nidn;
      } catch (err) {
        console.error(err);
      }
    },

    async lecturerContractsFetch(nidn, academic_year) {
      try {
        this.container_loading = true;
        const response = await getJadwalUASDosen(nidn, academic_year);
        this.daftarMataKuliahDosen = response.data.data;
        this.container_loading = false;
      } catch (err) {
        console.error(err);
        this.container_loading = false;
      }
    },

    getDetailUas(
      tahun_semester,
      kode_mata_kuliah,
      mata_kuliah,
      kelas,
      kelas_ujian
    ) {
      this.mataKuliah.set(
        tahun_semester,
        kode_mata_kuliah,
        mata_kuliah,
        kelas,
        kelas_ujian,
        this.expire.expired
      );
      this.$router.push({
        path: `/penilaian-uas/detail`,
      });
    },

    async fetchJadwalPengisianNilai(tahun_semester, jenis_ujian) {
      try {
        this.container_loading = true;
        const response = await getJadwalPengisianNilai();
        if (response.status === 200) {
          this.daftarJadwalPengisianNilai = response.data.data;
          response.data.data.forEach((element) => {
            if (
              element.tahun_semester == tahun_semester &&
              element.jenis_ujian == jenis_ujian
            ) {
              this.expire = element;
              this.isStarted = true;
            }
          });
          this.container_loading = false;
        } else if (response.status === 404) {
          Swal.fire({
            text: response.data.message,
            icon: "warning",
          });
          this.container_loading = false;
        }
      } catch (error) {
        this.container_loading = false;
        Swal.fire({
          text: error.response.data.message,
          icon: "error",
        });
      }
    },
  },

  computed: {
    totalPersentase() {
      let total = 0;
      for (let item of this.daftarInstrumentNilai) {
        total += parseInt(item.persentase);
      }
      return total;
    },
  },
};
</script>
