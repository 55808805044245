<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic class="d-md-none">
        <template #content>
          <div
            id="kartu-header"
            class="p-5 d-flex align-items-center justify-content-between"
          >
            <div id="judul">
              <span class="fw-bolder fs-5">Informasi Mata Kuliah</span>
            </div>
          </div>
          <div class="px-5 pb-5" id="tabel">
            <tr>
              <td>Kode Mata Kuliah</td>
              <td>:</td>
              <td>{{ matkul.kode }}</td>
            </tr>
            <tr>
              <td>Nama Mata Kuliah</td>
              <td>:</td>
              <td>{{ matkul.nama }}</td>
            </tr>
            <tr>
              <td>Kelas</td>
              <td>:</td>
              <td>{{ matkul.kelas }}</td>
            </tr>
            <tr>
              <td>Kelas Ujian</td>
              <td>:</td>
              <td>{{ matkul.kelas_ujian }}</td>
            </tr>
            <tr>
              <td>Jumlah Simpan</td>
              <td>:</td>
              <td>
                {{ nilai.jml_simpan }}
              </td>
            </tr>
            <tr>
              <td>Jumlah Buka</td>
              <td>:</td>
              <td>
                {{ nilai.jml_buka }}
              </td>
            </tr>
            <tr>
              <td>Program Studi membuka Nilai</td>
              <td>:</td>
              <td>
                <span v-if="nilai.dibuka" class="text-success">
                  <icon-done />
                </span>
                <span v-else class="text-danger"><icon-cross /></span>
              </td>
            </tr>
            <tr>
              <td>Dosen Mengunci Nilai</td>
              <td>:</td>
              <td>
                <span v-if="nilai.dikunci" class="text-success">
                  <icon-done />
                </span>
                <span v-else class="text-danger"><icon-cross /></span>
              </td>
            </tr>
            <tr>
              <td>Validasi Program Studi</td>
              <td>:</td>
              <td>
                <span v-if="nilai.divalidasi" class="text-success">
                  <icon-done />
                </span>
                <span v-else class="text-danger"><icon-cross /></span>
              </td>
            </tr>
            <tr>
              <td>DPUTS</td>
              <td></td>
              <td>
                <a
                  class="mt-5 mt-lg-0 btn btn-sm btn-secondary"
                  @click="utsDownloadAdd"
                >
                  Download DPUTS
                </a>
              </td>
            </tr>
          </div>
        </template>
      </card-basic>
      <card-basic class="d-md-none">
        <template #content>
          <div id="kartu-header" class="p-5">
            <div id="judul">
              <span class="fw-bolder fs-5">Aksi</span>
            </div>
            <small class="form-text text-danger">
              *Simpan minimal 1x untuk dapat mengunci
            </small>
          </div>
          <div class="px-5 pb-5 d-grid gap-2" id="tombol-aksi">
            <a class="btn btn-sm btn-success" @click="utsSimpanAdd"> Simpan </a>
            <a
              class="btn btn-sm btn-danger"
              data-bs-toggle="modal"
              data-bs-target="#kunci_penilaian_uts"
            >
              Kunci
            </a>
            <a
              class="btn btn-sm btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#buka_penilaian_uts"
            >
              Buka
            </a>
            <a
              class="btn btn-sm btn-warning"
              data-bs-toggle="modal"
              data-bs-target="#validasi_penilaian_uts"
            >
              Validasi
            </a>
          </div>
        </template>
      </card-basic>
      <container-double>
        <template #left>
          <card-basic>
            <template #content>
              <!-- START CARD INFORMATION -->
              <alert-icon v-if="status.empty" type="primary">
                <template #body>
                  <span>
                    <span>
                      Jadwal Penilaian Ujian Tengah Semester (UTS) belum dibuat.
                    </span>
                  </span>
                </template>
              </alert-icon>

              <alert-icon v-if="status.buka" type="primary">
                <template #body>
                  <span>
                    <span
                      >Penilaian Ujian Tengah Semester (UTS) dibuka pada
                    </span>
                    <span class="text-success fw-bold">
                      {{
                        $date(status.tgl_buka)
                          .locale("id")
                          .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                      }}</span
                    >
                    <span> s.d. </span>
                    <span class="text-danger fw-bold">
                      {{
                        $date(status.tgl_tutup)
                          .locale("id")
                          .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                      }}
                    </span>
                  </span>
                </template>
              </alert-icon>

              <alert-icon v-if="status.berlangsung" type="warning">
                <template #body>
                  <span>
                    <span>
                      Penilaian Ujian Tengah Semester (UTS) sedang berlangsung
                      dari
                    </span>
                    <span class="text-success fw-bold">
                      {{
                        $date(status.tgl_buka)
                          .locale("id")
                          .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                      }}
                    </span>
                    <span> s.d. </span>
                    <span class="text-danger fw-bold">
                      {{
                        $date(status.tgl_tutup)
                          .locale("id")
                          .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                      }}
                    </span>
                  </span>
                </template>
              </alert-icon>

              <alert-icon v-if="status.tutup" type="danger">
                <template #body>
                  <span>
                    <span>
                      Penilaian Ujian Tengah Semester (UTS) telah ditutup pada
                    </span>
                    <span class="text-danger fw-bold">
                      {{
                        $date(status.tgl_tutup)
                          .locale("id")
                          .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                      }}
                    </span>
                  </span>
                </template>
              </alert-icon>
              <!-- END CARD INFORMATION -->
              <div id="kartu-header" class="p-5 d-flex align-items-center">
                <div id="tombol-kembali" class="me-3">
                  <button-icon
                    @click="$router.go(-1)"
                    name="Kembali"
                    class="btn-sm btn-secondary"
                  >
                    <template #icon>
                      <icon-arrow-left />
                    </template>
                  </button-icon>
                </div>
                <div id="judul">
                  <span class="text-gray-700 mb-2 fw-normal fs-8 d-block">
                    Monitoring Penilaian Hasil Belajar
                  </span>
                  <span class="fw-bolder fs-5">
                    Ujian Tengah Semester (UTS)
                  </span>
                </div>
              </div>
              <div id="pencarian" class="mx-5 mb-5">
                <form-icon>
                  <template #icon>
                    <icon-search />
                  </template>

                  <template #content>
                    <input
                      type="text"
                      class="form-control ps-14 fw-normal"
                      placeholder="Pencarian berdasarkan NIM atau Nama Mahasiswa"
                      v-model="filters.mahasiswa"
                      @keydown.enter="onSearchMahasiswa"
                    />
                  </template>
                </form-icon>
                <small class="form-text text-muted">
                  Tekan Enter untuk memulai pencarian
                </small>
              </div>
              <div id="tabel">
                <table-basic v-if="data.result > 0">
                  <template #thead>
                    <tr class="fw-bolder bg-light fs-7">
                      <th class="rounded-start w-50px ps-3">No</th>
                      <th class="min-w-100px">NIM & Nama</th>
                      <th class="min-w-80px">Nilai</th>
                      <th class="min-w-80px">Kehadiran</th>
                      <th class="rounded-end"></th>
                    </tr>
                  </template>
                  <template #tbody>
                    <tr v-for="(item, i) in data.data" :key="i">
                      <td class="text-center align-middle">{{ i + 1 }}</td>
                      <td class="align-middle">
                        <span class="text-dark fw-bolder d-block mb-1 fs-7">
                          {{ item.nama }}
                        </span>
                        <span class="text-muted text-muted d-block fs-8">
                          {{ item.nim }}
                        </span>
                      </td>
                      <td class="text-center align-middle">
                        <div class="form">
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Masukkan Nilai"
                            v-model="item.nilai"
                            @input="checkValue($event, i)"
                            :disabled="checkStatusEdit()"
                          />
                        </div>
                      </td>
                      <td class="text-center align-middle">
                        <div class="form">
                          <select
                            class="form-select"
                            data-placeholder="Pilih Kehadiran"
                            data-control="select2"
                            v-model="item.kehadiran"
                            :disabled="checkStatusEdit()"
                          >
                            <option value="Hadir">Hadir</option>
                            <option value="Sakit">Sakit</option>
                            <option value="Izin">Izin</option>
                            <option value="Kendala Administrasi">
                              Kendala Administrasi
                            </option>
                            <option value="Tanpa Keterangan">
                              Tanpa Keterangan
                            </option>
                          </select>
                        </div>
                      </td>
                      <td class="text-center align-middle">
                        <a
                          class="mt-5 mt-lg-0 btn btn-sm btn-secondary btn-active-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#logUTS"
                          @click="utsLogFetch(item.t_nilai_id)"
                        >
                          Log
                        </a>
                      </td>
                    </tr>
                  </template>
                </table-basic>
                <!-- START CARD EMPTY STATE -->
                <div
                  v-else
                  class="alert alert-primary d-flex align-items-center mx-5 p-5"
                >
                  <div class="d-flex flex-column">
                    <span> Data Peserta UTS Tidak Tersedia! </span>
                  </div>
                </div>
                <!-- END CARD EMPTY STATE -->
              </div>
            </template>
          </card-basic>
        </template>
        <template #right>
          <card-basic>
            <template #content>
              <div
                id="kartu-header"
                class="p-5 d-flex align-items-center justify-content-between"
              >
                <div id="judul">
                  <span class="fw-bolder fs-5">Informasi Mata Kuliah</span>
                </div>
              </div>
              <div class="px-5 pb-5" id="tabel">
                <tr>
                  <td>Kode Mata Kuliah</td>
                  <td>:</td>
                  <td>{{ matkul.kode }}</td>
                </tr>
                <tr>
                  <td>Nama Mata Kuliah</td>
                  <td>:</td>
                  <td>{{ matkul.nama }}</td>
                </tr>
                <tr>
                  <td>Kelas</td>
                  <td>:</td>
                  <td>{{ matkul.kelas }}</td>
                </tr>
                <tr>
                  <td>Kelas Ujian</td>
                  <td>:</td>
                  <td>{{ matkul.kelas_ujian }}</td>
                </tr>
                <tr>
                  <td>Jumlah Simpan</td>
                  <td>:</td>
                  <td>
                    {{ nilai.jml_simpan ? nilai.jml_simpan : 0 }}
                  </td>
                </tr>
                <tr>
                  <td>Jumlah Buka</td>
                  <td>:</td>
                  <td>
                    {{ nilai.jml_buka }}
                  </td>
                </tr>
                <tr>
                  <td>Program Studi membuka Nilai</td>
                  <td>:</td>
                  <td>
                    <span v-if="nilai.dibuka" class="text-success">
                      <icon-done />
                    </span>
                    <span v-else class="text-danger"><icon-cross /></span>
                  </td>
                </tr>
                <tr>
                  <td>Dosen Mengunci Nilai</td>
                  <td>:</td>
                  <td>
                    <span v-if="nilai.dikunci" class="text-success">
                      <icon-done />
                    </span>
                    <span v-else class="text-danger"><icon-cross /></span>
                  </td>
                </tr>
                <tr>
                  <td>Validasi Program Studi</td>
                  <td>:</td>
                  <td>
                    <span v-if="nilai.divalidasi" class="text-success">
                      <icon-done />
                    </span>
                    <span v-else class="text-danger"><icon-cross /></span>
                  </td>
                </tr>
                <tr>
                  <td>DPUTS</td>
                  <td></td>
                  <td>
                    <a
                      class="mt-5 mt-lg-0 btn btn-sm btn-secondary"
                      @click="utsDownloadAdd"
                    >
                      Download DPUTS
                    </a>
                  </td>
                </tr>
              </div>
            </template>
          </card-basic>
          <card-basic>
            <template #content>
              <div id="kartu-header" class="p-5">
                <div id="judul">
                  <span class="fw-bolder fs-5">Aksi</span>
                </div>
                <small class="form-text text-danger">
                  *Simpan minimal 1x untuk dapat mengunci
                </small>
              </div>

              <div class="px-5 pb-5" id="tombol-aksi">
                <a class="btn btn-sm btn-success me-2" @click="utsSimpanAdd">
                  Simpan
                </a>
                <a
                  class="btn btn-sm btn-danger me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#kunci_penilaian_uts"
                >
                  Kunci
                </a>
                <a
                  class="btn btn-sm btn-primary me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#buka_penilaian_uts"
                >
                  Buka
                </a>
                <a
                  class="btn btn-sm btn-warning"
                  data-bs-toggle="modal"
                  data-bs-target="#validasi_penilaian_uts"
                >
                  Validasi
                </a>
              </div>
            </template>
          </card-basic>
        </template>
      </container-double>
    </template>
  </container-app>

  <!-- begin::Log Pengisian UTS -->
  <modal-medium id="logUTS" title="Log Pengisian Nilai UTS">
    <template #content>
      <div class="card table-responsive">
        <table class="table table-bordered" v-if="logs.length > 0">
          <tr class="fw-bolder bg-light fs-7">
            <th class="rounded-start text-center w-50px ps-3">No</th>
            <th>Nilai Lama</th>
            <th>Nilai Baru</th>
            <th>Kehadiran Lama</th>
            <th>Kehadiran Baru</th>
            <th>Author</th>
            <th>Tanggal Perubahan</th>
          </tr>

          <tr v-for="(item, i) in logs" :key="i">
            <td class="text-center align-middle">{{ i + 1 }}</td>
            <td class="align-middle">
              {{ item.nilai_lama }}
            </td>
            <td class="align-middle">
              {{ item.nilai_baru }}
            </td>
            <td class="align-middle">
              {{ item.kehadiran_lama }}
            </td>
            <td class="align-middle">
              {{ item.kehadiran_baru }}
            </td>
            <td class="align-middle">
              {{ item.author }}
            </td>
            <td class="rounded-end align-middle">
              {{ item.timestamp }}
            </td>
          </tr>
        </table>
      </div>
    </template>
  </modal-medium>
  <!-- end::Log Pengisian UTS  -->

  <!-- begin::Kunci Penilaian UTS -->
  <div class="modal fade" tabindex="-1" id="kunci_penilaian_uts">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Kunci Penilaian</h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <icon-close />
          </div>
        </div>
        <div class="modal-body">
          <div>
            <h4 class="text-center">
              Apakah anda yakin untuk melakukan kunci penilaian? Nilai tidak
              dapat diubah apabila sudah dikunci.
            </h4>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Batal
          </button>
          <button type="button" class="btn btn-success" @click="utsKunciAdd">
            Kunci
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end::Kunci Penilaian UTS  -->

  <!-- begin::Buka Penilaian UTS -->
  <div class="modal fade" tabindex="-1" id="buka_penilaian_uts">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Buka Penilaian</h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <icon-close />
          </div>
        </div>
        <div class="modal-body">
          <div>
            <h4 class="text-center">
              Apakah anda yakin untuk membuka kunci penilaian? Dosen dapat
              mengisi kembali setelah dibuka.
            </h4>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Batal
          </button>
          <button type="button" class="btn btn-success" @click="utsBukaAdd">
            Buka
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end::Buka Penilaian UTS  -->

  <!-- begin::Validasi Penilaian UTS -->
  <div class="modal fade" tabindex="-1" id="validasi_penilaian_uts">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Validasi Penilaian</h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <icon-close />
          </div>
        </div>
        <div class="modal-body">
          <div>
            <h4 class="text-center">
              Apakah anda yakin untuk melakukan validasi penilaian? Aksi ini
              tidak dapat dikembalikan.
            </h4>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Batal
          </button>
          <button type="button" class="btn btn-success" @click="utsValidasiAdd">
            Validasi
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end::Validasi Penilaian UTS  -->
</template>

<script>
// API
import {
  utsCekJadwalStatus,
  utsDetail,
  utsSimpan,
  utsLog,
  utsKunci,
  utsDownload,
  utsMonitoringBuka,
  utsMonitoringValidasi,
} from "../../../apis/PenilaianHasilBelajar/uts";

// Store
import { useMatkulStore } from "@/stores/matkul";

export default {
  async mounted() {
    await this.statusFetch();
    await this.utsDetailFetch(this.matkul);
  },
  setup() {
    const matkul = useMatkulStore();
    matkul.restoreFromLocalStorage();
    return {
      matkul,
    };
  },
  data() {
    return {
      container_loading: false,
      selected: {},
      status: {},
      filters: {},
      nilai: {
        jml_simpan: 0,
        jml_kunci: 0,
        jml_buka: 0,
        dikunci: false,
        divalidasi: false,
        dibuka: false,
      },
      data: [],
      logs: [],
    };
  },

  methods: {
    async statusFetch() {
      this.container_loading = true;
      const { data } = await utsCekJadwalStatus(this.matkul.tahun_semester);

      if (data.success) {
        this.status = data.data;
        this.container_loading = false;
      } else {
        this.status = data.data;
        this.container_loading = false;
      }
    },
    async utsDetailFetch(params, filters = {}) {
      const { tahun_semester, kode, kelas, kelas_ujian } = params;

      const { mahasiswa } = filters;

      const queryParams = {
        tahun_semester,
        kode_mata_kuliah: kode,
        kelas,
        kelas_ujian,
        mahasiswa,
      };

      this.container_loading = true;
      const { data } = await utsDetail(queryParams);

      if (data.success) {
        data.data.forEach((element) => {
          if (!element.kehadiran) {
            element.kehadiran = "Hadir";
          }
        });

        this.data = data;
        const { jml_simpan, jml_kunci, jml_buka, dikunci, divalidasi, dibuka } =
          data.data[0];
        this.nilai = {
          jml_simpan: jml_simpan,
          jml_kunci: jml_kunci,
          jml_buka: jml_buka,
          dikunci: dikunci,
          divalidasi: divalidasi,
          dibuka: dibuka,
        };

        this.container_loading = false;
      } else {
        this.data = null;
        this.container_loading = false;
      }
    },
    async utsSimpanAdd() {
      try {
        const swalLoading = Swal.fire({
          title: "Menyimpan nilai UTS",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const nilai_peserta = this.data.data
          .map((item) => ({
            nim: item.nim,
            nilai: item.nilai,
            kehadiran: item.kehadiran,
          }))
          .filter((item) => item.nilai !== null);

        const response = await utsSimpan({
          tahun_semester: this.matkul.tahun_semester,
          kode_mata_kuliah: this.matkul.kode,
          kelas: this.matkul.kelas,
          kelas_ujian: this.matkul.kelas_ujian,
          jml_simpan: this.nilai.jml_simpan,
          mahasiswa: nilai_peserta,
        });

        swalLoading.close();

        if (response.status === 201) {
          await this.utsDetailFetch(this.matkul);
          Swal.fire({
            title: "Berhasil!",
            text: "Data penilaian UTS berhasil disimpan!",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal menyimpan nilai UTS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat menyimpan nilai UTS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async utsKunciAdd() {
      try {
        const swalLoading = Swal.fire({
          title: "Mengunci nilai UTS",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const nilai_peserta = this.data.data.map((item) => ({
          nim: item.nim,
          nilai: item.nilai,
          kehadiran: item.kehadiran,
        }));

        const response = await utsKunci({
          tahun_semester: this.matkul.tahun_semester,
          kode_mata_kuliah: this.matkul.kode,
          kelas: this.matkul.kelas,
          kelas_ujian: this.matkul.kelas_ujian,
          jml_kunci: this.nilai.jml_simpan,
          mahasiswa: nilai_peserta,
        });

        swalLoading.close();

        if (response.status === 201) {
          await this.utsDetailFetch(this.matkul);
          $("#kunci_penilaian_uts").modal("hide");
          Swal.fire({
            title: "Berhasil!",
            text: "Data penilaian UTS berhasil dikunci!",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal mengunci nilai UTS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat mengunci nilai UTS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async utsBukaAdd() {
      try {
        const swalLoading = Swal.fire({
          title: "Membuka nilai UTS",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const nilai_peserta = this.data.data.map((item) => ({
          nim: item.nim,
          nilai: item.nilai,
          kehadiran: item.kehadiran,
        }));

        const response = await utsMonitoringBuka({
          tahun_semester: this.matkul.tahun_semester,
          kode_mata_kuliah: this.matkul.kode,
          kelas: this.matkul.kelas,
          kelas_ujian: this.matkul.kelas_ujian,
          jml_kunci: this.nilai.jml_simpan,
          mahasiswa: nilai_peserta,
        });

        swalLoading.close();

        if (response.status === 201) {
          await this.utsDetailFetch(this.matkul);
          $("#buka_penilaian_uts").modal("hide");
          Swal.fire({
            title: "Berhasil!",
            text: "Data penilaian UTS berhasil dibuka!",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal mengunci nilai UTS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat membuka nilai UTS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async utsValidasiAdd() {
      try {
        const swalLoading = Swal.fire({
          title: "Memvalidasi nilai UTS",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const nilai_peserta = this.data.data.map((item) => ({
          nim: item.nim,
          nilai: item.nilai,
          kehadiran: item.kehadiran,
        }));

        const response = await utsMonitoringValidasi({
          tahun_semester: this.matkul.tahun_semester,
          kode_mata_kuliah: this.matkul.kode,
          kelas: this.matkul.kelas,
          kelas_ujian: this.matkul.kelas_ujian,
          jml_kunci: this.nilai.jml_simpan,
          mahasiswa: nilai_peserta,
        });

        swalLoading.close();

        if (response.status === 201) {
          await this.utsDetailFetch(this.matkul);
          $("#validasi_penilaian_uts").modal("hide");
          Swal.fire({
            title: "Berhasil!",
            text: "Data penilaian UTS berhasil divalidasi!",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal memvalidasi nilai UTS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat memvalidasi nilai UTS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async utsLogFetch(t_nilai_id) {
      const { data } = await utsLog(t_nilai_id);

      this.logs = data.data;
    },

    async utsDownloadAdd() {
      try {
        const swalLoading = Swal.fire({
          title: "Mengunduh Nilai UTS",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await utsDownload({
          tahun_semester: this.matkul.tahun_semester,
          kode_mata_kuliah: this.matkul.kode,
          kelas: this.matkul.kelas,
          kelas_ujian: this.matkul.kelas_ujian,
        });

        swalLoading.close();

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `dputs-${this.matkul.kode}-${this.matkul.nama}-${this.matkul.kelas}-${this.matkul.kelas_ujian}.pdf`
          );
          document.body.appendChild(link);
          link.click();

          Swal.fire({
            title: "Berhasil!",
            text: "Penilaian UTS berhasil diunduh",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal mengunduh nilai UTS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat mengunduh nilai UTS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

    checkStatusEdit() {
      let result = true;

      return result;
    },

    onSearchMahasiswa() {
      this.utsDetailFetch(this.matkul, { mahasiswa: this.filters.mahasiswa });
    },
    checkValue(event, index) {
      const newValue = parseFloat(event.target.value);
      if (newValue < 0 || newValue > 100) {
        this.data.data[index].nilai = 0;
      }
    },
  },
};
</script>

<style></style>
