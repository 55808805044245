import {api, headers} from './api';

const RESOURCE = 'jadwal_pengusulan';

export const indexJadwalPengusulan = () => api.get(RESOURCE, headers);

export const jadwalAktif = () => api.get(RESOURCE+'/jadwalAktif', headers);

export const storeJadwalPengusulan = (data) => api.post(RESOURCE, data, headers);

export const ubahJadwalPengusulan = (id) => api.post(RESOURCE+'/ubah/'+id, {}, headers);