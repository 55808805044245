<template>
    <container-app :container_loading="container_loading">
        <template #content>

            <div class="d-block d-md-none" v-if="message != ''">
                <alert-basic
                    :class="informasi.status_perwalian == 'YA' || informasi.status_perwalian == 'MENUNGGU' ? 'alert-primary' : 'alert-danger'"
                    :message="message" />
            </div>

            <card-basic class="d-block d-md-none" v-if="validasi && search_mahasiswa.length == 0">
                <template #content>
                    <div class="p-5">
                        <header-perwalian :tahun="tahun" :kelas="kelas">
                            <template #control>
                                <button-icon name="Filter" class="bg-secondary btn-sm" data-bs-toggle="modal" data-bs-target="#modalFilter" @click="showModalFilter">
                                    <template #icon>
                                        <icon-filter />
                                    </template>
                                </button-icon>
                            </template>
                        </header-perwalian>
                    </div>
                </template>
            </card-basic>

            <container-double>
                <template #left>
                    <div v-if="message != ''">
                        <div class="d-none d-md-block">
                            <alert-basic
                                :class="informasi.status_perwalian == 'YA' || informasi.status_perwalian == 'MENUNGGU' ? 'alert-primary' : 'alert-danger'"
                                :message="message" />
                        </div>

                        <!-- <div v-if="validasi"> -->
                        <div>
                            <div class="d-block d-md-none">
                                <card-basic>
                                    <template #content>

                                        <div class="px-5 py-3">
                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                <span>
                                                    Keterangan & Batas SKS
                                                </span>
                                                <span 
                                                    data-bs-toggle="collapse" 
                                                    href="#keteranganbatas" 
                                                    role="button" 
                                                    aria-expanded="false"
                                                    aria-controls="collapseExample"
                                                    @click="handleDetail"
                                                    >
                                                    Detail
                                                    <icon-arrow-bottom v-if="!detail" />
                                                    <icon-arrow-up v-else/>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="collapse" id="keteranganbatas">
                                            <div class="mt-2 pt-2 px-5 fs-7">
                                                <ul class="nav nav-tabs">
                                                    <li class="nav-item">
                                                        <a class="nav-link active" data-bs-toggle="tab" href="#keterangan">Keterangan</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" data-bs-toggle="tab" href="#batas">Batas SKS</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="tab-content" id="myTabContent">
                                                <div class="tab-pane fade show active p-5" id="keterangan" role="tabpanel">
                                                    <info-status />
                                                </div>
                                                <div class="tab-pane fade" id="batas" role="tabpanel">
                                                    <info-batas />
                                                </div>
                                            </div>
                                        </div>
                    
                                    </template>
                                </card-basic>
                            </div>
                            
                            <card-basic>
                                <template #content>
                                    <div class="p-5">
                                        <div class="d-none d-md-block mb-5" v-if="search_mahasiswa.length == 0">
                                            <header-perwalian :tahun="tahun" :kelas="kelas">
                                                <template #control>
                                                    <button-icon name="Filter" class="bg-secondary btn-sm" data-bs-toggle="modal" data-bs-target="#modalFilter" @click="showModalFilter">
                                                        <template #icon>
                                                            <icon-filter />
                                                        </template>
                                                    </button-icon>
                                                </template>
                                            </header-perwalian>
                                        </div>

                                        <form-icon>
                                            <template #icon>
                                                <icon-search />
                                            </template>

                                            <template #content>
                                                <input 
                                                    type="text" 
                                                    class="form-control ps-14 fw-normal"
                                                    placeholder="Pencarian berdasarkan NIM / Nama..."
                                                    v-model="search_mahasiswa"
                                                    >
                                            </template>
                                        </form-icon>
                                    </div>
                                    
                
                                    <tables-perwalian :daftar_mahasiswa="daftar_mahasiswa" :validasi="validasi" @update-data="getMahasiswa" />
                        
                                </template>
                            </card-basic>
                        </div>
                    </div>

                </template>
        
                <template #right>
                    <card-basic>
                        <template #content>
                            <div class="p-5">
                                <info-status />
                            </div>
                        </template>
                    </card-basic>
                    <card-basic>
                        <template #content>
                            <info-batas />
                        </template>
                    </card-basic>
                </template>
            </container-double>

        </template>
    </container-app>

    <!-- begin::modal -->
    <!-- begin::filter -->
    <modal-basic id="modalFilter" title="Filter">
        <template #content>
            <div class="row">
                <div class="col-6">
                    <label for="filterTahun" class="form-label">Tahun Masuk</label>
                    <div class="input-group mb-5">
                        <select class="form-select form-select-solid" id="filterTahun" v-model="filter.tahun" @change="changeTahun" >
                            <option v-for="(tahun, index) in daftar_tahun" :key="index">{{ tahun.tahun }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-6">
                    <label for="filterKelas" class="form-label">Kelas</label>
                    <div class="input-group mb-5">
                        <select class="form-select form-select-solid" id="filterKelas" v-model="filter.kelas" >
                            <option v-for="(kelas, index) in daftar_kelas" :key="index">{{ kelas.kelas }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </template>

        <template #button>
            <button-basic class="bg-primary text-light" name="Simpan" @click="processFilter" />
        </template>
    </modal-basic>
    <!-- end::filter -->
    <!-- end::modal -->
</template>

<script>
    // components
    import HeaderPerwalian from '@/components/_Perwalian/HeaderPerwalian.vue'
    import TablesPerwalian from '@/components/_Perwalian/TablesPerwalian.vue'
    
    // apis
    import { getUser } from '@/apis/user';
    import { getActiveDosen, getMahasiswa, getMahasiswaTahun, getMahasiswaKelas, searchMahasiswa } from '@/apis/perwalian';

    // helpers
    import { formatDate, formatSemester } from "@/helpers/waktu";

    export default {
        components: {
            HeaderPerwalian,
            TablesPerwalian
        },
        data() {
            return {
                daftar_tahun : [],
                tahun : '-',
                daftar_kelas : [],
                kelas : '-',
                daftar_mahasiswa : [],
                nodos : '',
                filter : {
                    tahun : '',
                    kelas : ''
                },
                container_loading : true,
                informasi: {},
                message: '',
                validasi: false,
                detail: false,
                search_mahasiswa: ''
            }
        },
        async beforeMount() {
            await this.getActiveDosen();
            this.getData();
            localStorage.setItem('menu', 'AISnet');
            localStorage.setItem('halaman', 'Perwalian');
        },
        watch: {
            async search_mahasiswa(newValue, oldvalue) {
                if( newValue.length > 0 ) {
                    const response = await searchMahasiswa(this.nodos, this.search_mahasiswa);
                    this.daftar_mahasiswa =  response.data.data;

                } else {
                    this.getMahasiswa();
                }
            }
        },
        methods: {
            async getActiveDosen() {
                try {
                    const response = await getActiveDosen();
                    this.informasi = response.data;

                    const jenis = this.informasi.jenis == 'PERWALIAN' ? 'Validasi' : 'Validasi Perubahan Perwalian/';
                    const angkatan = this.informasi.angkatan == '-' ? '' : ` untuk angkatan ${this.informasi.angkatan}`

                    if (this.informasi.status_perwalian == 'MENUNGGU') {
                        this.message = jenis+" Formulir Rencana Studi (FRS) Semester "+ formatSemester(this.informasi
                                .thsms) + angkatan + "</span> dibuka pada hari <span style='color: #e67e22'>" + formatDate(this.informasi.waktu_mulai) +
                            "</span> dan ditutup pada hari <span class='text-danger'>" + formatDate(this.informasi.waktu_tutup)+"</span>";
                    } else if (this.informasi.status_perwalian == 'YA') {
                        this.message = jenis+' Formulir Rencana Studi (FRS) Semester ' + formatSemester(this.informasi
                            .thsms) + angkatan + " ditutup pada hari <span class='text-danger'>" + formatDate(this.informasi.waktu_tutup)+"</span>";
                    } else if (this.informasi.status_perwalian == 'TIDAK') {
                        this.message = jenis+' Formulir Rencana Studi (FRS) Semester ' + formatSemester(this.informasi
                            .thsms) + angkatan + " telah ditutup pada hari <span class='text-danger'>" + formatDate(this.informasi.waktu_tutup)+"</span>";
                    }

                    if (this.informasi.status_perwalian == 'YA') {
                        this.validasi = true;
                    }

                } catch ( err ) {
                    console.error( err );
                }
            },
            async getData() {
                await this.getTahun();
                await this.getKelas();
                await this.getMahasiswa();
            },
            async getTahun() {
                try {
                    const data = await getUser();
                    this.nodos = data.data.data.nodos;

                    const data_tahun = await getMahasiswaTahun(this.nodos);
                    this.daftar_tahun = data_tahun.data.data;
                    this.filter.tahun = this.tahun = this.daftar_tahun[0].tahun;
                }
                catch (err) {
                    console.error( err )
                }
            },
            async getKelas() {
                try {
                    const data_kelas = await getMahasiswaKelas(this.nodos, this.tahun);
                    this.daftar_kelas = data_kelas.data.data;
                    this.filter.kelas = this.kelas = this.daftar_kelas[0].kelas;
                }
                catch (err) {
                    console.error( err )
                }
            },
            async getMahasiswa() {
                try {
                    const data_mahasiswa = await getMahasiswa(this.nodos, this.tahun, this.kelas);
                    this.daftar_mahasiswa = data_mahasiswa.data.data;
                    this.container_loading = false;
                }
                catch (err) {
                    console.error( err )
                }
            },
            showModalFilter() {
                this.filter.tahun = this.tahun;
                this.filter.kelas = this.kelas;
            },
            async changeTahun() {
                try {
                    const data_kelas = await getMahasiswaKelas(this.nodos, this.filter.tahun);
                    this.daftar_kelas = data_kelas.data.data;
                    this.filter.kelas = this.daftar_kelas[0].kelas;
                }
                catch (err) {
                    console.error( err )
                }
            },
            async processFilter(e) {
                this.tahun = this.filter.tahun;
                this.kelas = this.filter.kelas;
                this.getMahasiswa();
            },
            handleDetail() {
                this.detail = !this.detail;
            },
        },
    }
</script>