<template>
    <p class="fs-6 mb-0">Berikut layanan akademik yang tersedia :</p>
    <div class="fs-6 d-flex align-items-sm-start align-items-md-center">
        <span class="me-2 text-dark">
            <icon-status-1 />
        </span>
        Informasi Status Pembayaran Biaya Kuliah Mahasiswa.
    </div>
    <div class="fs-6 d-flex align-items-sm-start align-items-md-center">
        <span class="me-2 text-dark">
            <icon-status-1 />
        </span>
        Informasi Kelas & Dosen.
    </div>
    <div class="fs-6 d-flex align-items-sm-start align-items-md-center">
        <span class="me-2 text-dark">
            <icon-status-1 />
        </span>
        Informasi Jadwal Kuliah, Praktek, UTS & UAS.
    </div>
    <div class="fs-6 d-flex align-items-sm-start align-items-md-center">
        <span class="me-2 text-dark">
            <icon-status-1 />
        </span>
        Informasi Kartu Hasil Studi/Nilai Semester.
    </div>
    <div class="fs-6 d-flex align-items-sm-start align-items-md-center">
        <span class="me-2 text-dark">
            <icon-status-1 />
        </span>
        Informasi Transkrip Nilai Sementara.
    </div>
    <div class="fs-6 d-flex align-items-sm-start align-items-md-center">
        <span class="me-2 text-dark">
            <icon-status-1 />
        </span>
        Informasi Rekapitulasi Kehadiran Kuliah Mahasiswa.
    </div>
    <div class="fs-6 d-flex align-items-sm-start align-items-md-center">
        <span class="me-2 text-dark">
            <icon-status-1 />
        </span>
        Pengisian Form Rencana Studi (FRS) Semester Ganjil, Genap & Antara.
    </div>
    <div class="fs-6 d-flex align-items-sm-start align-items-md-center">
        <span class="me-2 text-dark">
            <icon-status-1 />
        </span>
        Konsultasi Form Rencana Studi
    </div>
</template>