<template>
    <container-app :container_loading="container_loading">
        <template #content>
            <card-basic>
                <template #content>
                    <div class="p-5 d-flex">
                        <div class="w-100">
                            <form-icon>
                                <template #icon>
                                    <icon-search />
                                </template>

                                <template #content>
                                    <input type="text" class="form-control ps-14 fw-normal"
                                        placeholder="Pencarian berdasarkan nim..." autofocus v-model="keyword"
                                        @keyup.enter="getPengajuanAll">
                                </template>

                            </form-icon>
                            <span class="d-block mt-1 text-gray-500 ms-14" v-if="keyword.length > 0">Tekan tombol enter
                                untuk melakukan pencarian</span>
                        </div>

                    </div>

                    <!-- <tables-perwalian :daftar_mahasiswa="daftar_mahasiswa" @update-data="getMahasiswa" /> -->
                    <TableVerifikasiIjazahVue :daftar_pengajuan="daftar_pengajuan" />
                </template>
            </card-basic>
        </template>
    </container-app>

    <!-- begin::modal -->
    <!-- begin::filter -->
    <modal-basic id="modalFilter" title="Filter">
        <template #content>
            <div class="row">
                <!-- <div class="col-6">
                    <label for="filterTahun" class="form-label">Tahun Masuk</label>
                    <div class="input-group mb-5">
                        <select class="form-select form-select-solid" id="filterTahun" v-model="filter.tahun"
                            @change="changeTahun">
                            <option v-for="(tahun, index) in daftar_tahun" :key="index">{{ tahun.tahun }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-6">
                    <label for="filterKelas" class="form-label">Kelas</label>
                    <div class="input-group mb-5">
                        <select class="form-select form-select-solid" id="filterKelas" v-model="filter.kelas">
                            <option v-for="(kelas, index) in daftar_kelas" :key="index">{{ kelas.kelas }}</option>
                        </select>
                    </div>
                </div> -->
            </div>
        </template>

        <!-- <template #button>
            <button-basic class="bg-primary text-light" name="Simpan" @click="processFilter" />
        </template> -->
    </modal-basic>
    <!-- end::filter -->
    <!-- end::modal -->
</template>

<script>
// components
import TableVerifikasiIjazahVue from '@/components/_ijazah/TableVerifikasiIjazah.vue';

// apis
// import { getActiveDosen, getMahasiswa, getMahasiswaTahun, getMahasiswaKelas, searchMahasiswa } from '@/apis/perwalian';

// helpers
import { getPengajuanAll } from '@/apis/ijazah';

export default {
    components: {
        TableVerifikasiIjazahVue
    },
    data() {
        return {
            daftar_pengajuan: [],
            keyword: "",
            container_loading: true
        }
    },
    beforeMount() {
        localStorage.setItem('menu', 'Ijazah')
        localStorage.setItem('halaman', 'Pengajuan Ijazah')
    },
    methods: {
        async getPengajuanAll() {
            this.container_loading = true
            let res = await getPengajuanAll(this.keyword);
            this.daftar_pengajuan = res.data.data
            this.container_loading = false
        }
    },
    mounted() {
        this.getPengajuanAll();
    }

}


</script>