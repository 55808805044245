<template>
    <label-header label="Tahun Masuk/ Kelas" :value="tahun+'/ '+kelas">
        <template #control>

            <slot name="control"></slot>
            
        </template>
    </label-header>
</template>

<script>
    export default {
        props: [
            'tahun', 
            'kelas', 
        ],
    }
</script>