<template>
    <div class="d-block d-md-none">
        <card-basic>
            <template #content>
                <div class="rounded accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="informasi-mhs-head">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse-informasi" aria-expanded="false"
                                aria-controls="collapse-informasi">
                                <div>
                                    <h4 class=" ">Informasi Mahasiswa</h4>
                                    <br>
                                </div>
                            </button>
                        </h2>
                        <div id="collapse-informasi" class="accordion-collapse collapse"
                            aria-labelledby="informasi-mhs-head" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <table-informasi :data="informasi_mhs" />
                               
                            </div>
                        </div>
                    </div>
                </div>

            </template>
        </card-basic>
    </div>

    <container-double>
        <template #left>
            <div class="card shadow-sm">
                <div class="card-header d-flex flex-row align-items-center">
                    <h4 class="col-10 my-3">Formulir Pengajuan Ijazah, Transkrip Nilai dan SKPI</h4>
                    <button-icon name="Logs" class="bg-secondary  btn-sm col-2" data-bs-toggle="modal" @click="getLogs()"
                        data-bs-target="#modalLogs">
                        <template #icon>
                            <icon-history />
                        </template>
                    </button-icon>
                </div>
                <div class="card-body">
                    <div class="rounded accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseZero" aria-expanded="false"
                                    aria-controls="flush-collapseZero">
                                    <div>
                                        <span class="fw-bolder">LP3B</span>
                                        <br>
                                        <span class="fs-9 " :class="getStatus(form?.LP3B?.head?.status)">({{
                                            form?.LP3B?.head?.status_label
                                        }})</span>
                                    </div>

                                </button>
                            </h2>
                            <div id="flush-collapseZero" class="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <div class="table-responsive">
                                        <table class="table   table-row-dashed fs-7">
                                            <thead>
                                                <tr class="fw-bolder bg-light">
                                                    <th class="rounded-start  ps-4 w-auto  text-right">Uraian</th>
                                                    <th>Status</th>
                                                    <th class="rounded-end pe-4  text-right w-30">Catatan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="ps-4">
                                                        Judul B. Inggris
                                                    </td>

                                                    <td :class="getStatus(form?.LP3B?.item?.judul_inggris?.status)">
                                                        {{ form?.LP3B?.item?.judul_inggris?.status_label }}</td>
                                                    <td class="w-30">{{ form?.LP3B?.item?.judul_inggris?.catatan }}
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne" aria-expanded="false"
                                    aria-controls="flush-collapseOne">
                                    <div>
                                        <span class="fw-bolder">Kelengkapan Dokumen</span>
                                        <br>
                                        <span class="fs-9 " :class="getStatus(form?.BAAK?.head?.status)">({{
                                            form?.BAAK?.head?.status_label
                                        }})</span>
                                    </div>

                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <div class="table-responsive">
                                        <table class="table   table-row-dashed fs-7">
                                            <thead>
                                                <tr class="fw-bolder bg-light">
                                                    <th class="rounded-start  ps-4 w-auto  text-right">Uraian</th>
                                                    <th>Dokumen</th>
                                                    <th>Status</th>
                                                    <th class="rounded-end pe-4  text-right w-30">Catatan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="ps-4">
                                                        Ijazah Terakhir (SMU/SMK/Sederajat)
                                                    </td>
                                                    <td class="w-auto">
                                                        <div v-if="!form?.BAAK?.item?.ijazah?.dokumen || (form?.BAAK?.item?.ijazah?.status === 2)"
                                                            class="input-group mb-3">
                                                            <input
                                                                v-on:change="(evt) => handleChange(evt, 'BAAK', 'ijazah')"
                                                                type="file" class="form-control">
                                                            <div v-if="data_loading.ijazah"
                                                                class="d-flex justify-content-center align-items-center ms-2">
                                                                <div class="spinner-border" role="status">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <a target="_blank" :href="form?.BAAK?.item?.ijazah?.dokumen"
                                                                class="btn btn-info btn-sm p-3">Lihat Dokumen</a>
                                                        </div>
                                                    </td>
                                                    <td :class="getStatus(form?.BAAK?.item?.ijazah?.status)">
                                                        {{ form?.BAAK?.item?.ijazah?.status_label }}</td>
                                                    <td class="w-30">{{ form?.BAAK?.item?.ijazah?.catatan }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="ps-4">
                                                        Fotokopi KTP 1 lembar
                                                    </td>
                                                    <td class="w-40">
                                                        <div v-if="!form?.BAAK?.item?.ktp?.dokumen || (form?.BAAK?.item?.ktp?.status === 2)"
                                                            class="input-group mb-3">
                                                            <input v-on:change="(evt) => handleChange(evt, 'BAAK', 'ktp')"
                                                                type="file" class="form-control">
                                                            <div v-if="data_loading.ktp"
                                                                class="d-flex justify-content-center align-items-center ms-2">
                                                                <div class="spinner-border" role="status">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <a target="_blank" :href="form?.BAAK?.item?.ktp?.dokumen"
                                                                class="btn btn-info btn-sm p-3">Lihat Dokumen</a>
                                                        </div>
                                                    </td>
                                                    <td :class="getStatus(form?.BAAK?.item?.ktp?.status)">
                                                        {{ form?.BAAK?.item?.ktp?.status_label }}</td>
                                                    <td class="w-30">{{ form?.BAAK?.item?.ktp?.catatan }}</td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                    aria-controls="flush-collapseTwo">
                                    <div>
                                        <span class="fw-bolder">Keuangan</span>
                                        <br>
                                        <span class="fs-9 " :class="getStatus(form?.KEUANGAN?.head?.status)">({{
                                            form?.KEUANGAN?.head?.status_label
                                        }})</span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <div class="table-responsive">
                                        <table class="table   table-row-dashed fs-7">
                                            <thead>
                                                <tr class="fw-bolder bg-light">
                                                    <th class="rounded-start  ps-4  text-right">Uraian</th>
                                                    <th>Status</th>
                                                    <th class="rounded-end pe-4  text-right w-30">Catatan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="ps-4">
                                                        Administrasi Keuangan
                                                    </td>
                                                    <td :class="getStatus(form?.KEUANGAN?.item?.keuangan?.status)">
                                                        {{ form?.KEUANGAN?.item?.keuangan?.status_label }}</td>
                                                    <td class="w-30">{{ form?.KEUANGAN?.item?.keuangan?.catatan }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ps-4">
                                                        Biaya Wisuda
                                                    </td>

                                                    <td :class="getStatus(form?.KEUANGAN?.item?.wisuda?.status)">
                                                        {{ form?.KEUANGAN?.item?.wisuda?.status_label }}</td>
                                                    <td class="w-30">
                                                        {{ form?.KEUANGAN?.item?.wisuda?.catatan }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ps-4">
                                                        Pencetakan Ijazah
                                                    </td>

                                                    <td :class="getStatus(form?.KEUANGAN?.item?.ijazah?.status)">
                                                        {{ form?.KEUANGAN?.item?.ijazah?.status_label }}</td>
                                                    <td class="w-30">
                                                        {{ form?.KEUANGAN?.item?.ijazah?.catatan }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ps-4">
                                                        Biaya Penerbitan Jurnal
                                                    </td>

                                                    <td :class="getStatus(form?.KEUANGAN?.item?.jurnal?.status)">
                                                        {{ form?.KEUANGAN?.item?.jurnal?.status_label }}</td>
                                                    <td class="w-30">
                                                        {{ form?.KEUANGAN?.item?.jurnal?.catatan }}
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree" aria-expanded="false"
                                    aria-controls="flush-collapseThree">

                                    <div>
                                        <span class="fw-bolder">Program Studi</span>
                                        <br>
                                        <span class="fs-9 " :class="getStatus(form?.PROGRAM_STUDI?.head?.status)">({{
                                            form?.PROGRAM_STUDI?.head?.status_label
                                        }})</span>
                                    </div>
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse"
                                aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <div class="table-responsive">
                                        <table class="table   table-row-dashed fs-7">
                                            <thead>
                                                <tr class="fw-bolder bg-light">
                                                    <th class="rounded-start  ps-4  text-right">Uraian</th>
                                                    <th>Dokumen</th>
                                                    <th>Status</th>
                                                    <th class="rounded-end pe-4  text-right w-30">Catatan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="ps-4">
                                                        Laporan Tugas Akhir
                                                    </td>
                                                    <td class="w-40">
                                                        <div v-if="!form?.PROGRAM_STUDI?.item?.laporan_ta?.dokumen || form?.PROGRAM_STUDI?.item?.laporan_ta?.status === 2"
                                                            class="input-group mb-3">
                                                            <input
                                                                v-on:change="(evt) => handleChange(evt, 'PROGRAM_STUDI', 'laporan_ta')"
                                                                type="file" class="form-control">
                                                            <div v-if="data_loading.laporan_ta"
                                                                class="d-flex justify-content-center align-items-center ms-2">
                                                                <div class="spinner-border" role="status">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <a target="_blank"
                                                                :href="form?.PROGRAM_STUDI?.item?.laporan_ta?.dokumen"
                                                                class="btn btn-info btn-sm p-3">Lihat Dokumen</a>
                                                        </div>
                                                    </td>
                                                    <td :class="getStatus(form?.PROGRAM_STUDI?.item?.laporan_ta?.status)">
                                                        {{ form?.PROGRAM_STUDI?.item?.laporan_ta?.status_label }}
                                                    </td>
                                                    <td class="w-30">
                                                        {{ form?.PROGRAM_STUDI?.item?.laporan_ta?.catatan }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ps-4">
                                                        Jurnal Penelitian
                                                    </td>
                                                    <td class="w-40">
                                                        <div v-if="!form?.PROGRAM_STUDI?.item?.jurnal?.dokumen || form?.PROGRAM_STUDI?.item?.jurnal?.status === 2"
                                                            class="input-group mb-3">
                                                            <input
                                                                v-on:change="(evt) => handleChange(evt, 'PROGRAM_STUDI', 'jurnal')"
                                                                type="file" class="form-control">
                                                            <div v-if="data_loading.jurnal"
                                                                class="d-flex justify-content-center align-items-center ms-2">
                                                                <div class="spinner-border" role="status">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <a target="_blank"
                                                                :href="form?.PROGRAM_STUDI?.item?.jurnal?.dokumen"
                                                                class="btn btn-info btn-sm p-3">Lihat Dokumen</a>
                                                        </div>
                                                    </td>
                                                    <td :class="getStatus(form?.PROGRAM_STUDI?.item?.jurnal?.status)">
                                                        {{ form?.PROGRAM_STUDI?.item?.jurnal?.status_label }}</td>
                                                    <td class="w-30">
                                                        {{ form?.PROGRAM_STUDI?.item?.jurnal?.catatan }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ps-4">
                                                        Link Jurnal Publikasi
                                                    </td>
                                                    <td class="w-40">
                                                        <div v-if="!form?.PROGRAM_STUDI?.item?.link_jurnal?.dokumen || form?.PROGRAM_STUDI?.item?.link_jurnal?.status === 2"
                                                            class="input-group mb-3">
                                                            <form v-if="!data_loading.link_jurnal" class="input-group "
                                                                @submit.prevent="e => handleChange(null, 'PROGRAM_STUDI', 'link_jurnal')">
                                                                <input type="text" class="form-control"
                                                                    v-model="data.link_jurnal">
                                                                <button class="btn btn-sm btn-info">
                                                                    Kirim
                                                                </button>
                                                            </form>

                                                            <div v-else
                                                                class="d-flex justify-content-center align-items-center ms-2">
                                                                <div class="spinner-border" role="status">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <a target="_blank"
                                                                :href="form?.PROGRAM_STUDI?.item?.link_jurnal?.dokumen"
                                                                class=" p-3">{{
                                                                    form?.PROGRAM_STUDI?.item?.link_jurnal?.dokumen
                                                                }}</a>
                                                        </div>
                                                    </td>
                                                    <td :class="getStatus(form?.PROGRAM_STUDI?.item?.link_jurnal?.status)">
                                                        {{ form?.PROGRAM_STUDI?.item?.link_jurnal?.status_label }}
                                                    </td>
                                                    <td class="w-30">
                                                        {{ form?.PROGRAM_STUDI?.item?.link_jurnal?.catatan }}
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="hed3">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#colapse3" aria-expanded="false" aria-controls="colapse3">
                                    <div>
                                        <span class="fw-bolder">Perpustakaan</span>
                                        <br>
                                        <span class="fs-9 " :class="getStatus(form?.PERPUSTAKAAN?.head?.status)">({{
                                            form?.PERPUSTAKAAN?.head?.status_label
                                        }})</span>
                                    </div>
                                </button>
                            </h2>
                            <div id="colapse3" class="accordion-collapse collapse" aria-labelledby="hed3"
                                data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <div class="table-responsive">
                                        <table class="table   table-row-dashed fs-7">
                                            <thead>
                                                <tr class="fw-bolder bg-light">
                                                    <th class="rounded-start  ps-4  text-right">Uraian</th>
                                                    <th>Status</th>
                                                    <th class="rounded-end pe-4  text-right w-30">Catatan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="ps-4">
                                                        Keterangan Bebas Peminjaman Buku
                                                    </td>
                                                    <td
                                                        :class="getStatus(form?.PERPUSTAKAAN?.item?.bebas_peminjaman?.status)">
                                                        {{ form?.PERPUSTAKAAN?.item?.bebas_peminjaman?.status_label
                                                        }}
                                                    </td>
                                                    <td class="w-30">
                                                        {{ form?.PERPUSTAKAAN?.item?.bebas_peminjaman?.catatan }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ps-4">
                                                        Sumbangan Buku
                                                    </td>
                                                    <td
                                                        :class="getStatus(form?.PERPUSTAKAAN?.item?.sumbangan_buku?.status)">
                                                        {{ form?.PERPUSTAKAAN?.item?.sumbangan_buku?.status_label }}
                                                    </td>
                                                    <td class="w-30">
                                                        {{ form?.PERPUSTAKAAN?.item?.sumbangan_buku?.catatan }}
                                                    </td>
                                                </tr>

                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="hed4">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#colapse4" aria-expanded="false" aria-controls="colapse4">

                                    <div>
                                        <span class="fw-bolder">CDC</span>
                                        <br>
                                        <span class="fs-9 " :class="getStatus(form?.CDC?.head?.status)">({{
                                            form?.CDC?.head?.status_label
                                        }})</span>
                                    </div>
                                </button>
                            </h2>
                            <div id="colapse4" class="accordion-collapse collapse" aria-labelledby="hed4"
                                data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <table class="table   table-row-dashed fs-7">
                                        <thead>
                                            <tr class="fw-bolder bg-light">
                                                <th class="rounded-start  ps-4  text-right">Uraian</th>
                                                <th>Form</th>
                                                <th>Upload Bukti Pengisian</th>
                                                <th>Status</th>
                                                <th class="rounded-end pe-4  text-right w-30">Catatan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="ps-4">
                                                    Pengisian Form untuk Tracer Studi
                                                </td>
                                                <td class="">
                                                    <div class="input-group mb-3">
                                                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfMC_Dg-lLE9nahmGFhaTIU2-PWcGH4B6OOTD3LtenAIg52iQ/viewform"
                                                            target="_blank" class="btn btn-primary btn-sm">Isi
                                                            Form</a>
                                                    </div>
                                                </td>
                                                <td class="w-40">
                                                    <div v-if="!form?.CDC?.item?.CDC?.dokumen || !form?.CDC?.item?.CDC?.status == 2"
                                                        class="input-group mb-3">
                                                        <input v-on:change="(evt) => handleChange(evt, 'CDC', 'CDC')"
                                                            type="file" class="form-control">
                                                        <div v-if="data_loading.CDC"
                                                            class="d-flex justify-content-center align-items-center ms-2">
                                                            <div class="spinner-border" role="status">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <a target="_blank" :href="form?.CDC?.item?.CDC?.dokumen"
                                                            class="btn btn-info btn-sm p-3">Lihat Dokumen</a>
                                                    </div>
                                                </td>
                                                <td :class="getStatus(form?.CDC?.item?.CDC?.status)">
                                                    {{ form?.CDC?.item?.CDC?.status_label }}</td>
                                                <td class="w-30">
                                                    {{ form?.CDC?.item?.CDC?.catatan }}
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="hed5">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#colapse5" aria-expanded="false" aria-controls="colapse5">
                                    <div>
                                        <span class="fw-bolder">SKPI</span>
                                        <br>
                                        <span class="fs-9 " :class="getStatus(form?.LSIPD?.head?.status)">({{
                                            form?.LSIPD?.head?.status_label
                                        }})</span>
                                    </div>
                                </button>
                            </h2>
                            <div id="colapse5" class="accordion-collapse collapse" aria-labelledby="hed5"
                                data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <div class="table-responsive">
                                        <table class="table   table-row-dashed fs-7">
                                            <thead>
                                                <tr class="fw-bolder bg-light">
                                                    <th class="rounded-start  ps-4  text-right">Uraian</th>
                                                    <th>Template</th>
                                                    <th>Dokumen</th>
                                                    <th>Status</th>
                                                    <th class="rounded-end pe-4  text-right w-30">Catatan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="ps-4">
                                                        Form SKPI
                                                    </td>
                                                    <td>
                                                        <a target="_blank" href="/assets/template/template_skpi.xlsx"
                                                                class="btn btn-info btn-sm p-3">Download</a>
                                                    </td>
                                                    <td class="w-40">
                                                        <div v-if="!form?.LSIPD?.item?.LSIPD?.dokumen || !form?.LSIPD?.item?.LSIPD?.status == 2"
                                                            class="input-group mb-3">
                                                            <input
                                                                v-on:change="(evt) => handleChange(evt, 'LSIPD', 'LSIPD')"
                                                                type="file" class="form-control">
                                                            <div v-if="data_loading.LSIPD"
                                                                class="d-flex justify-content-center align-items-center ms-2">
                                                                <div class="spinner-border" role="status">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <a target="_blank" :href="form?.LSIPD?.item?.LSIPD?.dokumen"
                                                                class="btn btn-info btn-sm p-3">Lihat Dokumen</a>
                                                        </div>
                                                    </td>
                                                    <td :class="getStatus(form?.LSIPD?.item?.LSIPD?.status)">
                                                        {{ form?.LSIPD?.item?.LSIPD?.status_label }}</td>
                                                    <td class="w-30">{{ form?.LSIPD?.item?.LSIPD?.catatan }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="ps-4">
                                                        Link Drive Sertifikat
                                                    </td>
                                                    <td>
                                                        <!-- <a target="_blank" :href="form?.LSIPD?.item?.link_sertifikat?.dokumen"
                                                                class="btn btn-info btn-sm p-3">Download</a> -->
                                                        -
                                                    </td>
                                                    <td class="w-40">
                                                        <div v-if="!form?.LSIPD?.item?.link_sertifikat?.dokumen || form?.LSIPD?.item?.link_sertifikat?.status === 2"
                                                            class="input-group mb-3">
                                                            <form v-if="!data_loading.link_sertifikat" class="input-group "
                                                                @submit.prevent="e => handleChange(null, 'LSIPD', 'link_sertifikat')">
                                                                <input type="text" class="form-control"
                                                                    v-model="data.link_sertifikat">
                                                                <button class="btn btn-sm btn-info">
                                                                    Kirim
                                                                </button>
                                                            </form>

                                                            <div v-else
                                                                class="d-flex justify-content-center align-items-center ms-2">
                                                                <div class="spinner-border" role="status">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <a target="_blank"
                                                                :href="form?.LSIPD?.item?.link_sertifikat?.dokumen"
                                                                class=" p-3">{{
                                                                    form?.LSIPD?.item?.link_sertifikat?.dokumen
                                                                }}</a>
                                                        </div>
                                                    </td>
                                                    <td :class="getStatus(form?.LSIPD?.item?.link_sertifikat?.status)">
                                                        {{ form?.LSIPD?.item?.link_sertifikat?.status_label }}
                                                    </td>
                                                    <td class="w-30">
                                                        {{ form?.LSIPD?.item?.link_sertifikat?.catatan }}
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



        </template>
        <template #right>
            <card-basic>
                <template #content>

                    <table-informasi :data="informasi_mhs" />
                    <button data-bs-toggle="modal" class="btn btn-sm btn-info w-100" data-bs-target="#modalEdit">
                        <span class="ms-2">Edit Informasi</span>
                    </button>
                </template>
            </card-basic>

        </template>
    </container-double>

    <modal-fullsceen-lg id="modalEdit" title="Edit Informasi">
        <template #content>
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Informasi Mahasiswa</h5>
                    <div class="table-responsive">
                        <table class="table  gs-0 gy-4 table-row-dashed fs-7">
                            <thead>
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">Nama</th>

                                    <td class="rounded-end pe-4  text-right">
                                        <input type="text" v-bind:value="informasi_mhs?.nama"
                                            @input="e => form_informasi.nama = e.target.value"
                                            class="form-control form-control-sm">
                                    </td>
                                </tr>
                                <!-- <tr class=" ">
                    <th class="rounded-start fw-bolder ps-4  text-right">NIK</th>
                    <td class="rounded-end pe-4  text-right">-</td>
                </tr> -->
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">NIM</th>
                                    <td class="rounded-end pe-4  text-right">{{ informasi_mhs?.nimhs }}</td>

                                </tr>
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">Program Studi</th>
                                    <td class="rounded-end pe-4  text-right">{{ informasi_mhs?.prodi }}</td>

                                </tr>
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">Tanggal Sidang</th>

                                    <td class="rounded-end pe-4  text-right">
                                        <input type="date" v-bind:value="informasi_mhs?.jadwal_sidang"
                                            @input="e => form_informasi.jadwal_sidang = e.target.value"
                                            class="form-control form-control-sm">
                                    </td>
                                </tr>
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">Judul B.Indonesia</th>

                                    <td class="rounded-end pe-4  text-right">
                                        <input type="text" v-bind:value="informasi_mhs?.judul_ind"
                                            @input="e => form_informasi.judul_ind = e.target.value"
                                            class="form-control form-control-sm">
                                    </td>
                                </tr>
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">Judul B.Inggris</th>

                                    <td class="rounded-end pe-4  text-right">
                                        <input type="text" v-bind:value="informasi_mhs?.judul_eng"
                                            @input="e => form_informasi.judul_eng = e.target.value"
                                            class="form-control form-control-sm">
                                    </td>
                                </tr>
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">Pembimbing</th>
                                    <td class="rounded-end pe-4  text-right">
                                        <span>
                                            {{ informasi_mhs?.pembimbing1 }}
                                        </span>
                                        <br>
                                        <span>
                                            {{ informasi_mhs?.pembimbing2 }}
                                        </span>
                                    </td>

                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="card-footer bg-secondary text-end">
                        <div class="d-flex flex-row justify-content-end align-items-center">
                            <button class="btn btn-success  " @click="handleUpdate">Simpan</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal-fullsceen-lg>

    <modal-fullsceen-lg id="modalLogs" title="Logs">
        <template #content v-if="!isLoadingLogs">
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Logs Pengajuan</h5>
                    <div class="table-responsive" style="max-height: 60vh; overflow-y: scroll;">
                        <table class="table  gs-0 gy-4 table-row-dashed fs-7">
                            <thead>
                                <tr class=" ">
                                    <th class="rounded-start  fw-bolder ps-4  text-right">Petugas/Pemohon</th>
                                    <th class="fw-bolder ps-4  text-right">Tanggal</th>
                                    <th class="fw-bolder ps-4  text-right">Aksi</th>
                                    <th class="fw-bolder ps-4  text-right">Status</th>
                                    <th class=" fw-bolder ps-4  text-right">Catatan</th>

                                </tr>
                                <tr class=" " v-for="(log, index) in logs" :key="index">
                                    <th class="rounded-start rounded-end  ps-4  text-right">{{ log?.user?.name }}</th>
                                    <th class=" rounded-end  ps-4  text-right">{{ log.created_at }}</th>
                                    <th class="  ps-4  text-right"
                                        :class="log.status == 1 ? 'text-success' : 'text-danger'">{{ log.keterangan }}</th>
                                    <th class="  ps-4  text-right"
                                        :class="log.status == 1 ? 'text-success' : 'text-danger'">
                                        <icon-status-2 v-if="log.status == 1" />
                                        <icon-status-3 v-else-if="log.status == 0" />
                                        <icon-status-0 v-else />
                                    </th>
                                    <th class="rounded-end  ps-4  text-right">{{ log?.catatan }}</th>

                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </template>
        <template v-else #content>
            <div class="d-flex justify-content-center align-items-center text-gray-700 mb-7">
                <div class="spinner-border me-2" role="status" style="width: 1.3rem; height: 1.3rem">
                    <span class="visually-hidden"></span>
                </div>
                <span class="fs-4 fw-bold">
                    Tunggu Sebentar
                </span>
            </div>

        </template>
    </modal-fullsceen-lg>
</template>

<script>
import TableInformasi from '@/components/_ijazah/TableInformasi.vue';
import { getUserData } from '@/store';
import { getStatusForm, storeItemForm, updatePengajuan, getLogs } from '@/apis/ijazah'
import { toFormData } from '@/helpers/main';
import { formatDate } from '@/helpers/waktu';
export default {
    props: ['informasi_mhs'],
    components: {
        TableInformasi
    },
    data() {
        return {
            user: {},
            form: {},
            data_loading: {},
            form_informasi: {},
            data: {},
            isLoadingLogs: false,
            logs: [],
        }
    },
    async mounted() {
        this.user = await getUserData();
        await this.getStatusForm()
    },
    methods: {
        async handleUpdate() {
            let fd = new FormData()
            Object.keys(this.form_informasi).map((e) => {
                if (this.form_informasi[e]) {
                    fd.append(e, this.form_informasi[e]);
                }
            })

            let res = await updatePengajuan(this.informasi_mhs.id, fd);
            console.log();
            if (res?.data?.status) {
                Swal.fire('Berhasil mengupload dokumen', '', 'success')
            }
        },

        async handleChange(evt, head, type) {
            let temp = {}
            try {
                if (evt) {
                    temp = {
                        nim: this.user.nim,
                        type: type,
                        head: head,
                        file: evt.target.files[0]
                    }
                } else {
                    temp = {
                        nim: this.user.nim,
                        type: type,
                        head: head,
                        link: this.data[type]
                    }
                }
                Swal.fire({
                    title: 'Yakin mengupload dokumen?',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Yakin',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        if (temp.file || temp.link) {

                            this.data_loading[type] = true;
                            const res = await storeItemForm(toFormData(temp));
                            await this.getStatusForm();
                            this.data_loading[type] = false;
                            Swal.fire('Berhasil mengupload dokumen', '', 'success')
                        } else {
                            Swal.fire('Dokumen Kosong', '', 'error')
                        }
                    }
                })
            } catch (error) {
                Swal.fire('Dokumen Kosong', '', 'error')
            }

            // console.log(evt.target.files, head, type);
        },
        async getStatusForm() {
            const nim = this.user.nim;
            let res = await getStatusForm(nim);
            this.form = res.data;
        },
        getStatus(status) {
            switch (status) {
                case 0: return "text-gray-500";
                case 1: return "text-success";
                case 2: return "text-danger";
                case 3: return "text-warning";
                default: return "text-gray-500";
            }
        },
        async getLogs() {
            const nim = this.user.nim;
            this.isLoadingLogs = true;
            let res = await getLogs(nim);
            this.logs = res.data.data.map(e => ({ ...e, created_at: formatDate(e.created_at) }))

            this.isLoadingLogs = false;
        },
    }
}
</script>

