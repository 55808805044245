<template>
  <container-app>
    <template #content>
      <div class="row">
        <div class="col-12 col-sm-3">
          <card-basic>
            <template #content>
              <div
                class="d-flex flex-column justify-content-center align-items-center p-5"
              >
                <div class="symbol symbol-100px symbol-lg-160px mb-3">
                  <img :src="profil.foto" />
                </div>

                <button
                  class="btn btn-white text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#modalEditFoto"
                >
                  Ganti Foto
                </button>
              </div>
            </template>
          </card-basic>
        </div>

        <div class="col-12 col-sm-9">
          <card-basic>
            <template #content>
              <div class="p-5">
                <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      class="nav-link active"
                      id="nav-profil-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profil"
                      type="button"
                      role="tab"
                      aria-controls="nav-profil"
                      aria-selected="true"
                    >
                      Profil
                    </button>
                    <button
                      class="nav-link"
                      id="nav-kontak-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-kontak"
                      type="button"
                      role="tab"
                      aria-controls="nav-kontak"
                      aria-selected="false"
                    >
                      Kontak
                    </button>
                    <button
                      class="nav-link"
                      id="nav-pendidikan-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-pendidikan"
                      type="button"
                      role="tab"
                      aria-controls="nav-pendidikan"
                      aria-selected="false"
                    >
                      Pendidikan
                    </button>
                    <button
                      class="nav-link"
                      id="nav-program_studi-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-program_studi"
                      type="button"
                      role="tab"
                      aria-controls="nav-program_studi"
                      aria-selected="false"
                    >
                      Kualifikasi
                    </button>
                  </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="nav-profil"
                    role="tabpanel"
                    aria-labelledby="nav-profil-tab"
                  >
                    <div class="d-flex justify-content-end">
                      <button
                        class="btn btn-link"
                        data-bs-toggle="modal"
                        data-bs-target="#modalEditProfil"
                      >
                        Edit
                      </button>
                    </div>

                    <div class="row mb-5">
                      <label class="col-sm-3 text-muted">NIDN</label>
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800">{{ dosen.nidn }}</span>
                      </div>
                    </div>

                    <div class="row mb-5">
                      <label class="col-sm-3 text-muted">Nama Lengkap</label>
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800">{{ dosen.nama }}</span>
                      </div>
                    </div>

                    <div class="row mb-5">
                      <label class="col-sm-3 text-muted">Jenis Kelamin</label>
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800">{{
                          dosen.jenis_kelamin
                        }}</span>
                      </div>
                    </div>

                    <div class="row mb-5">
                      <label class="col-sm-3 text-muted"
                        >Tempat, Tanggal Lahir</label
                      >
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800"
                          >{{ dosen.tempat_lahir }},
                          {{ dosen.tanggal_lahir }}</span
                        >
                      </div>
                    </div>

                    <div class="row mb-5">
                      <label class="col-sm-3 text-muted">Program Studi</label>
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800">{{
                          dosen.program_studi
                        }}</span>
                      </div>
                    </div>

                    <div
                      class="d-flex justify-content-between mt-7 align-items-center"
                    >
                      <label-basic name="Publikasi" />
                      <button
                        class="btn btn-link"
                        data-bs-toggle="modal"
                        data-bs-target="#modalEditPenelitian"
                      >
                        Edit
                      </button>
                    </div>

                    <div
                      class="row mb-5"
                      v-for="(penelitian_dosen, i) in data_penelitian_dosen"
                      :key="i"
                    >
                      <label class="col-sm-3 text-muted">{{
                        penelitian_dosen.nama
                      }}</label>
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800">{{
                          penelitian_dosen.url
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="nav-kontak"
                    role="tabpanel"
                    aria-labelledby="nav-kontak-tab"
                  >
                    <div class="d-flex justify-content-end">
                      <button
                        class="btn btn-link"
                        data-bs-toggle="modal"
                        data-bs-target="#modalEditKontak"
                      >
                        Edit
                      </button>
                    </div>

                    <div class="row mb-5">
                      <label class="col-sm-3 text-muted">No. HP</label>
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800">{{
                          dosen.no_hp
                        }}</span>
                      </div>
                    </div>

                    <div class="row mb-5">
                      <label class="col-sm-3 text-muted">Email</label>
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800">{{
                          profil.email
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="nav-pendidikan"
                    role="tabpanel"
                    aria-labelledby="nav-pendidikan-tab"
                  >
                    <div class="d-flex justify-content-end">
                      <button
                        class="btn btn-link"
                        data-bs-toggle="modal"
                        data-bs-target="#modalEditPendidikan"
                      >
                        Edit
                      </button>
                    </div>
                    <div class="row mb-5">
                      <label class="col-sm-3 text-muted"
                        >Pendidikan Terakhir</label
                      >
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800">{{
                          dosen.pendidikan_terakhir
                        }}</span>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <label class="col-sm-3 text-muted">Gelar Depan</label>
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800">{{
                          dosen.gelar_depan
                        }}</span>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <label class="col-sm-3 text-muted">Gelar Belakang</label>
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800">{{
                          dosen.gelar_belakang
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="nav-program_studi"
                    role="tabpanel"
                    aria-labelledby="nav-program_studi-tab"
                  >
                    <div class="d-flex justify-content-end">
                      <button
                        class="btn btn-link"
                        data-bs-toggle="modal"
                        data-bs-target="#modalEditProgramStudi"
                      >
                        Edit
                      </button>
                    </div>
                    <div class="row mb-5">
                      <label class="col-sm-3 text-muted"
                        >Kelompok Keahlian</label
                      >
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800">{{
                          dosen.kelompok_keahlian
                        }}</span>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <label class="col-sm-3 text-muted"
                        >Jabatan Akademik</label
                      >
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800">{{
                          dosen.jabatan_akademik
                        }}</span>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <label class="col-sm-3 text-muted"
                        >Pangkat Golongan</label
                      >
                      <div class="col-lg-9">
                        <span class="fs-6 text-gray-800">{{
                          dosen.pangkat_golongan
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="nav-penelitian"
                    role="tabpanel"
                    aria-labelledby="nav-penelitian-tab"
                  ></div>
                </div>
              </div>
            </template>
          </card-basic>
        </div>
      </div>
    </template>
  </container-app>

  <!-- begin::modal -->
  <!-- begin::modalEditProfil -->
  <modal-fullscreen-sm id="modalEditProfil" title="Edit Profil">
    <template #content>
      <form class="p-1 pb-4 pt-0" @submit.prevent="updateProfil">
        <div class="form-group mb-2">
          <label for="nidn" class="col-form-label pb-2">NIDN</label>
          <input
            type="text"
            name="nidn"
            id="nidn"
            class="form-control"
            v-model="form.nidn"
            disabled
          />
        </div>

        <div class="form-group mb-2">
          <label for="nama" class="col-form-label pb-2">Nama Lengkap</label>
          <input
            type="text"
            name="nama"
            id="nama"
            class="form-control"
            v-model="form.nama"
          />
        </div>

        <div class="form-grou mb-2">
          <label for="jenis_kelamin" class="col-form-label pb-2"
            >Jenis Kelamin</label
          >
          <select
            name="jenis_kelamin"
            id="jenis_kelamin"
            class="form-select"
            v-model="form.jenis_kelamin"
          >
            <option
              :value="kelamin.kode"
              v-for="kelamin in jenis_kelamin"
              :key="kelamin.kode"
            >
              {{ kelamin.jenis }}
            </option>
          </select>
        </div>

        <div class="form-group mb-2">
          <label for="tempat_lahir" class="col-form-label pb-2"
            >Tempat Lahir</label
          >
          <input
            type="text"
            name="tempat_lahir"
            id="tempat_lahir"
            class="form-control"
            v-model="form.tempat_lahir"
          />
        </div>

        <div class="form-group mb-2">
          <label for="tanggal_lahir" class="col-form-label pb-2"
            >Tanggal Lahir</label
          >
          <input
            type="date"
            name="tanggal_lahir"
            id="tanggal_lahir"
            class="form-control"
            v-model="form.tanggal_lahir"
          />
        </div>

        <div class="mt-7">
          <input
            type="submit"
            value="Simpan"
            class="btn btn-success d-block w-100 w-sm-auto"
          />
        </div>
      </form>
    </template>
  </modal-fullscreen-sm>
  <!-- end::modalEditProfil -->

  <!-- begin::modalEditKontak -->
  <modal-fullscreen-sm id="modalEditKontak" title="Edit Kontak">
    <template #content>
      <form class="p-1 pb-4 pt-0" @submit.prevent="updateKontak">
        <div class="form-group mb-2">
          <label for="no_hp" class="col-form-label pb-2">No. HP</label>
          <input
            type="text"
            name="no_hp"
            id="no_hp"
            class="form-control"
            v-model="form.no_hp"
          />
        </div>

        <div class="mt-7">
          <input
            type="submit"
            value="Simpan"
            class="btn btn-success d-block w-100 w-sm-auto"
          />
        </div>
      </form>
    </template>
  </modal-fullscreen-sm>
  <!-- end::modalEditKontak -->

  <!-- begin::modalEditPendidikan -->
  <modal-fullscreen-sm id="modalEditPendidikan" title="Edit Pendidikan">
    <template #content>
      <form class="p-1 pb-4 pt-0" @submit.prevent="updatePendidikan">
        <div class="form-group mb-2">
          <label for="dm_pendidikan_terakhir_id" class="col-form-label pb-2"
            >Pendidikan Terakhir</label
          >
          <select
            name="dm_pendidikan_terakhir_id"
            id="dm_pendidikan_terakhir_id"
            class="form-select"
            v-model="form.dm_pendidikan_terakhir_id"
          >
            <option
              v-for="(pendidikan, i) in pendidikan_terakhir"
              :key="i"
              :value="pendidikan.id"
            >
              {{ pendidikan.nama }}
            </option>
          </select>
        </div>

        <div class="form-group mb-2">
          <label for="gelar_depan" class="col-form-label pb-2"
            >Gelar Depan</label
          >
          <input
            disabled
            type="text"
            name="gelar_depan"
            id="gelar_depan"
            class="form-control"
            v-model="form.gelar_depan"
          />
        </div>

        <div class="form-group mb-2">
          <label for="gelar_belakang" class="col-form-label pb-2"
            >Gelar Belakang</label
          >
          <input
            disabled
            type="text"
            name="gelar_belakang"
            id="gelar_belakang"
            class="form-control"
            v-model="form.gelar_belakang"
          />
        </div>

        <div class="mt-7">
          <input
            type="submit"
            value="Simpan"
            class="btn btn-success d-block w-100 w-sm-auto"
          />
        </div>
      </form>
    </template>
  </modal-fullscreen-sm>
  <!-- end::modalEditPendidikan -->

  <!-- begin::modalEditProgramStudi -->
  <modal-fullscreen-sm id="modalEditProgramStudi" title="Edit Program Studi">
    <template #content>
      <form class="p-1 pb-4 pt-0" @submit.prevent="updateJabatanAkademik">
        <div class="form-group mb-2">
          <label for="dm_jabatan_akademik_id" class="col-form-label pb-2"
            >Jabatan Akademik</label
          >
          <select
            name="dm_jabatan_akademik_id"
            id="dm_jabatan_akademik_id"
            class="form-select"
            v-model="form.dm_jabatan_akademik_id"
          >
            <option
              v-for="(jabatan_akademik, i) in data_jabatan_akademik"
              :key="i"
              :value="jabatan_akademik.id"
            >
              {{ jabatan_akademik.nama }}
            </option>
          </select>
        </div>

        <div class="form-group mb-2">
          <label for="dm_pangkat_golongan_id" class="col-form-label pb-2"
            >Pangkat Golongan</label
          >
          <select
            name="dm_pangkat_golongan_id"
            id="dm_pangkat_golongan_id"
            class="form-select"
            v-model="form.dm_pangkat_golongan_id"
          >
            <option
              v-for="(pangkat_golongan, i) in data_pangkat_golongan"
              :key="i"
              :value="pangkat_golongan.id"
            >
              {{ pangkat_golongan.pangkat }} - {{ pangkat_golongan.golongan }}
            </option>
          </select>
        </div>

        <div class="mt-7">
          <input
            type="submit"
            value="Simpan"
            class="btn btn-success d-block w-100 w-sm-auto"
          />
        </div>
      </form>
    </template>
  </modal-fullscreen-sm>
  <!-- end::modalEditProgramStudi -->

  <!-- begin::modalEditPenelitian -->
  <modal-fullscreen-sm id="modalEditPenelitian" title="Edit Publikasi">
    <template #content>
      <form class="p-1 pb-4 pt-0" @submit.prevent="storePenelitianDosen">
        <div
          class="form-group mb-2"
          v-for="(penelitian_dosen, i) in form_penelitian_dosen"
          :key="i"
        >
          <label class="col-form-label pb-2">{{ penelitian_dosen.nama }}</label>
          <input
            type="text"
            class="form-control"
            v-model="penelitian_dosen.url"
            required
          />
        </div>

        <div class="mt-7">
          <input
            type="submit"
            value="Simpan"
            class="btn btn-success d-block w-100 w-sm-auto"
          />
        </div>
      </form>
    </template>
  </modal-fullscreen-sm>
  <!-- end::modalEditPenelitian -->
  <!-- end::modal -->
</template>

<script>
// apis
import {
  profilDosen,
  updateProfil,
  updateKontak,
  updatePendidikan,
  updateJabatanAkademik,
} from "@/apis/dosen2";
import { getUser } from "@/apis/user";
import { indexPendidikanTerakhir } from "@/apis/pendidikanTerakhir";
import { indexPangkatGolongan } from "@/apis/pangkatGolongan";
import { indexJabatanAkademik } from "@/apis/jabatanAkademik";
import {
  dataPenelitianDosen,
  storePenelitianDosen,
} from "@/apis/penelitianDosen";

export default {
  data() {
    return {
      profil: {
        nama: "",
      },
      jenis_kelamin: [
        { kode: "L", jenis: "Laki-laki" },
        { kode: "P", jenis: "Perempuan" },
      ],
      pendidikan_terakhir: {},
      dosen: {},
      form: {},
      data_pangkat_golongan: {},
      data_jabatan_akademik: {},
      data_penelitian_dosen: {},
      form_penelitian_dosen: {},
    };
  },
  mounted() {
    this.getUser();
    this.indexPendidikanTerakhir();
    this.profilDosen();
    this.indexPangkatGolongan();
    this.indexJabatanAkademik();
  },
  methods: {
    async getUser() {
      try {
        const response = await getUser();
        this.profil = response.data.data;

        this.profil.foto =
          this.profil.foto == "http://api-aisnet.itg.ac.id//-"
            ? "/img/blank.aad363d0.png"
            : this.profil.foto;
      } catch (err) {
        console.error(err);
      }
    },
    async indexPendidikanTerakhir() {
      const res = await indexPendidikanTerakhir();

      this.pendidikan_terakhir = res.data.data;
    },
    async profilDosen() {
      const res = await profilDosen();

      this.dosen = res.data.data;
      this.form = this.dosen;
      this.form.id_baru = this.dosen.id;
      this.dataPenelitianDosen(this.dosen.id);
    },
    async indexPangkatGolongan() {
      const res = await indexPangkatGolongan();

      this.data_pangkat_golongan = res.data.data;
    },
    async indexJabatanAkademik() {
      const res = await indexJabatanAkademik();

      this.data_jabatan_akademik = res.data.data;
    },
    async updateProfil() {
      const res = await updateProfil(this.form);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Data berhasil diperbarui",
          showConfirmButton: false,
          timer: 5000,
        });
      }

      this.profilDosen();
    },
    async updateKontak() {
      const res = await updateKontak(this.form);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Data berhasil diperbarui",
          showConfirmButton: false,
          timer: 5000,
        });

        this.profilDosen();
      }
    },
    async updatePendidikan() {
      const res = await updatePendidikan(this.form);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Data berhasil diperbarui",
          showConfirmButton: false,
          timer: 5000,
        });

        this.profilDosen();
      }
    },
    async updateJabatanAkademik() {
      const res = await updateJabatanAkademik(this.form);

      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Data berhasil diperbarui",
          showConfirmButton: false,
          timer: 5000,
        });

        this.profilDosen();
      }
    },
    async dataPenelitianDosen(id) {
      const res = await dataPenelitianDosen(id);

      this.data_penelitian_dosen = res.data.data;
      this.form_penelitian_dosen = res.data.data;
    },
    async storePenelitianDosen() {
      this.form_penelitian_dosen.forEach(async (e) => {
        const data = {
          dm_dosen_id: this.dosen.id,
          dm_penelitian_id: e.id,
          url: e.url,
        };

        const res = await storePenelitianDosen(data);
      });

      Swal.fire({
        icon: "success",
        text: "Data berhasil diperbarui",
        showConfirmButton: false,
        timer: 5000,
      });

      this.profilDosen();
    },
  },
};
</script>
