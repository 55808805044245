<template>
    <div class="card mb-3 shadow-sm" v-for="(absen, i) in rekap_absen" :key="i">
        <div class="card-body px-5 py-3 d-flex justify-content-between align-items-center">
            <span class="fs-7 fw-bold">Pertemuan {{ i+1 }}</span>
            <div class="d-flex align-items-center">
                <span class="fs-8 me-1">{{ absen.pesan }}</span>
                <icon-status-2 v-if="absen.status == 2" />
                <icon-status-0 v-else-if="absen.status == 0" />
                <icon-status-3 v-else-if="absen.status == 3" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['absen'],
    data() {
        return {
            rekap_absen : [],
        }
    },
    watch: {
        absen() {
            this.rekap_absen = [];
            for(let i = 1; i <= 14; i++) {
                Object.keys(this.absen).map(e=> {
                    if( e == 'pertemuan_'+i ) {
                        let status = 3;
                        let pesan = 'Belum terindeks';
                        if( i <= this.absen.pertemuan ) {
                            status = this.absen[e] == 1 ? 2 : 0;
                            pesan = this.absen[e] == 1 ? 'Hadir' : 'Tidak hadir';                     
                        }

                        this.rekap_absen.push( {
                            status,
                            pesan
                        } )
                    }
                });
            }
        }
    }
}
</script>