<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <div class="p-5">
            <div class="alert alert-warning d-flex align-items-center p-5">
              <icon-alert />
              <div class="d-flex flex-column">
                <span
                  >Pengisian Kontrak Perkuliahan diisi paling lambat hari
                  <span class="fw-bold"
                    >Sabtu, 12 Oktober 2024 pukul 23:59 WIB</span
                  ></span
                >
              </div>
            </div>
            <div
              v-if="daftarKontrak.length === 0"
              class="alert alert-primary d-flex align-items-center p-5"
            >
              <div class="d-flex flex-column">
                <span>Kontrak Perkuliahan belum tersedia</span>
              </div>
            </div>
            <label-header value="Kontrak Perkuliahan">
              <template #control>
                <select
                  class="form-select form-select-solid"
                  data-control="selected_tahun_semester"
                  data-placeholder="Select an option"
                  v-model="selected_tahun_semester"
                  @change="onSelectedAcademicChange($event)"
                >
                  <option
                    v-for="(item, i) in tahunAkademik"
                    :key="i"
                    :value="item.thsms"
                  >
                    Tahun {{ item.ket }}
                  </option>
                </select>
              </template>
            </label-header>
          </div>
          <table-basic>
            <template #thead>
              <tr class="fw-bolder bg-light fs-7">
                <th class="ps-4 w-50px rounded-start">No</th>
                <th class="min-w-100px">Mata Kuliah</th>
                <th class="min-w-100px">Kelas</th>
                <th class="min-w-100px">Mahasiswa Pengontrak</th>
                <th class="min-w-100px">Mahasiswa Menyetujui</th>
                <th class="min-w-100px">Status</th>
                <th class="rounded-end"></th>
              </tr>
            </template>

            <template #tbody>
              <tr v-for="(item, i) in daftarKontrak" :key="i">
                <td class="text-center align-middle">{{ i + 1 }}</td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.course_name }}
                  </span>
                  <span class="text-muted text-muted d-block fs-8">
                    {{ item.course_id }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.class }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.student_total }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.student_completed }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="badge" :class="getItemStatus(item.status)">
                    {{ item.status }}
                  </span>
                </td>
                <td class="align-middle">
                  <a
                    class="me-md-3 btn btn-sm btn-secondary btn-active-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#contract_modal"
                    @click="selected_id = item.id"
                  >
                    Isi Kontrak Perkuliahan
                  </a>
                  <a
                    @click="getDetailCourseContract(item.id)"
                    class="mt-5 mt-lg-0 btn btn-sm btn-secondary btn-active-primary"
                  >
                    Detail
                  </a>
                </td>
              </tr>
            </template>
          </table-basic>
        </template>
      </card-basic>
      <!-- CONTRACT MODAL -->
      <div class="modal fade" tabindex="-1" id="contract_modal">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Kontrak Perkuliahan</h5>

              <!--begin::Close-->
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <icon-close />
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body">
              <p>
                Saya sudah menyampaikan isi kontrak perkuliahan yang terdiri
                dari:
              </p>

              <div class="d-flex flex-column">
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  Capaian Pembelajaran
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  Rencana Pembelajaran Semester (RPS)
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  Bahan Ajar
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  Kisi-kisi Penilaian
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  Pedoman Praktikum/Praktik*
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  Pedoman Penugasan
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  Peraturan Perkuliahan
                </li>
              </div>
              <div class="separator my-5"></div>
              <h5 class="modal-title mb-5">
                Upload RPS<span class="text-danger">*</span>
              </h5>
              <!--begin::Form-->
              <form action="#" method="post" id="uploadRps">
                <!--begin::Input group-->
                <div class="fv-row">
                  <!--begin::Dropzone-->
                  <div class="dropzone" ref="dropzoneRef">
                    <!--begin::Message-->
                    <div class="dz-message needsclick">
                      <icon-upload />
                      <!--begin::Info-->
                      <div class="ms-4">
                        <h3 class="fs-5 fw-bold text-gray-900 mb-1">
                          Drag & drop file atau klik untuk mengunggah.
                        </h3>
                        <span class="fs-7 fw-semibold text-gray-500"
                          >Upload maks. 1 file pdf/doc/docx 10 MB</span
                        >
                      </div>
                      <!--end::Info-->
                    </div>
                  </div>
                  <!--end::Dropzone-->
                </div>
                <!--end::Input group-->
              </form>
              <!--end::Form-->
              <div class="separator my-5"></div>
              <p class="fw-bolder text-wrap">
                Saat submit kontrak perkuliahan ini, maka segala tugas dan
                kewajiban perkuliahan yang tertera di dalam isi kontrak
                perkuliahan ini akan menjadi tanggung jawab bersama dosen dan
                seluruh mahasiswa.
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Tutup
              </button>
              <button
                type="button"
                class="btn btn-success"
                :data-kt-indicator="submit_loading"
                @click="submitLecturerContract"
                :disabled="isButtonDisable"
              >
                <span class="indicator-label"> Submit </span>
                <span class="indicator-progress">
                  Mohon tunggu...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- END CONTRACT MODAL -->
    </template>
  </container-app>
</template>

<script>
import { getAccessToken, getUserData } from "@/store";

// API
import {
  getNidn,
  getLecturerContracts,
  postLecturerContract,
  getAcademicYearsLecturer,
} from "@/apis/kontrakPerkuliahan";

export default {
  data() {
    return {
      myDropzone: null,
      container_loading: false,
      isButtonDisable: true,
      submit_loading: "off",
      daftarKontrak: [],
      tahunAkademik: [],
      selected_tahun_semester: "",
      selected_nidn: "",
      selected_id: null,
    };
  },

  mounted() {
    this.loadData();
    this.myDropzone = new Dropzone(this.$refs.dropzoneRef, {
      url: `${process.env.VUE_APP_BASE_URL}course-contract/upload-rps`,
      paramName: "rps",
      maxFiles: 1,
      maxFilesize: 10, // MB
      addRemoveLinks: true,
      autoProcessQueue: false,
      headers: { Authorization: "Bearer " + getAccessToken() },
      acceptedFiles: ".pdf, .docx, .doc",
    });

    this.myDropzone.on("addedfile", (file) => {
      this.isButtonDisable = false;
    });

    this.myDropzone.on("removedfile", (file) => {
      this.isButtonDisable = true;
    });
  },

  methods: {
    async onSelectedAcademicChange(event) {
      await this.lecturerContractsFetch(
        this.selected_nidn,
        event.target.value,
        100
      );
    },
    async submitLecturerContract() {
      this.submit_loading = "on";
      await this.myDropzone.processQueue();

      await this.myDropzone.on("processing", () => {
        console.log("Queue is being processed");
      });

      await this.myDropzone.on("success", (file, response) => {
        this.lecturerContractSubmit(this.selected_id, response.path);
        this.myDropzone.removeFile(file);
      });
    },
    async loadData() {
      await this.getsTahunAkademik();
      await this.nidnFetch(getUserData().nodos);
      await this.lecturerContractsFetch(
        this.selected_nidn,
        this.selected_tahun_semester,
        100
      );
    },
    async getsTahunAkademik() {
      try {
        const response = await getAcademicYearsLecturer();
        this.tahunAkademik = response.data.data;
        this.selected_tahun_semester = response.data.data[0].thsms;
      } catch (err) {
        console.error(err);
      }
    },

    async nidnFetch(nodos) {
      try {
        const response = await getNidn(nodos);
        this.selected_nidn = response.data.data.nidn;
      } catch (err) {
        console.error(err);
      }
    },

    async lecturerContractsFetch(nidn, academic_year, item_per_page) {
      try {
        this.container_loading = true;
        const response = await getLecturerContracts(
          nidn,
          academic_year,
          item_per_page
        );
        this.daftarKontrak = response.data.data.data;
        this.container_loading = false;
      } catch (err) {
        console.error(err);
        this.container_loading = false;
      }
    },
    async lecturerContractSubmit(id, path) {
      try {
        this.submit_loading = "on";
        const response = await postLecturerContract(id, path);
        if (response.data.message == "Kontrak Perkuliahan berhasil diisi") {
          Swal.fire({
            text: "Upload RPS dan Isi Kontrak Perkuliahan Selesai",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          this.lecturerContractsFetch(
            this.selected_nidn,
            this.selected_tahun_semester,
            100
          );
          this.submit_loading = "off";
          $("#contract_modal").modal("hide");
        }
      } catch (err) {
        console.error(err);
      }
    },
    getItemStatus(status) {
      switch (status) {
        case "Dosen Belum Mengisi":
          return "badge badge-light-danger";
        case "Mahasiswa Belum Mengisi":
          return "badge badge-light-warning";
        case "Dosen Sudah Mengisi":
          return "badge badge-light-success";
        default:
          return "";
      }
    },
    getDetailCourseContract(id) {
      this.$router.push({
        path: `/kontrak-perkuliahan/detail/${id}`,
      });
    },
    isPrincipal(item) {
      if (
        item.course_id == "TIUWP4303" &&
        item.course_name == "Perancangan & Man. Organisasi Industri" &&
        item.class == "A"
      ) {
        return true;
      } else if (
        item.course_id == "TIUWP4303" &&
        item.course_name == "Perancangan & Man. Organisasi Industri" &&
        item.class == "B"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
