import {api, headers} from './api';

const RESOURCE = 'kelompok_keahlian';

export const indexKelompokKeahlian = (pusat_kajian_id) => api.get(RESOURCE+'/'+pusat_kajian_id, headers);

export const semuaKelompokKeahlian = () => api.get(RESOURCE+'/semua', headers);

export const dataKelompokKeahlian = () => api.get(RESOURCE+'/data', headers);

export const storeKelompokKeahlian = (data) => api.post(RESOURCE, data, headers);