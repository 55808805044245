<template>
    <div class="row">
        <div class="col-md-4">
            <label-icon-warna warna="bg-light-dark border-dark" label="Kewajiban Pembayaran" :value="'Rp. '+informasi.total">
                <template #icon>
                    <icon-wallet />
                </template>
            </label-icon-warna>
        </div>
        <div class="col-md-4">
            <label-icon-warna warna="bg-light-success border-success" label="Telah Dibayar" :value="'Rp. '+informasi.dibayar">
                <template #icon>
                    <icon-status-2 />
                </template>
            </label-icon-warna>
        </div>
        <div class="col-md-4">
            <label-icon-warna warna="bg-light-danger border-danger" label="Tunggakan" :value="'Rp. '+informasi.tunggakan">
                <template #icon>
                    <icon-status-0 />
                </template>
            </label-icon-warna>
        </div>
    </div>
</template>

<script>
export default {
    props: ['informasi']
}
</script>