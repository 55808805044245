<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <div class="p-5">
            <div class="alert alert-warning d-flex align-items-center p-5">
              <icon-alert />
              <div class="d-flex flex-column">
                <span
                  >Pengisian Kontrak Perkuliahan diisi paling lambat hari
                  <span class="fw-bold"
                    >Sabtu, 2 Maret 2024 pukul 23:59 WIB</span
                  ></span
                >
              </div>
            </div>
            <div
              v-if="daftarKontrak.length === 0"
              class="alert alert-primary d-flex align-items-center p-5"
            >
              <div class="d-flex flex-column">
                <span>Kontrak Perkuliahan belum tersedia</span>
              </div>
            </div>
            <label-header value="All Monitoring Kontrak Perkuliahan">
              <template #control>
                <select
                  class="me-3 form-select form-select-solid"
                  data-control="selected_tahun_semester"
                  data-placeholder="Select an option"
                  v-model="selected_major"
                  @change="onSelectedMajor($event)"
                >
                  <option v-for="(item, i) in major_list" :key="i">
                    {{ item }}
                  </option>
                </select>
                <select
                  class="form-select form-select-solid"
                  data-control="selected_tahun_semester"
                  data-placeholder="Select an option"
                  v-model="selected_tahun_semester"
                  @change="onSelectedAcademicYear($event)"
                >
                  <option
                    v-for="(item, i) in tahunAkademik"
                    :key="i"
                    :value="item.thsms"
                  >
                    Tahun {{ item.ket }}
                  </option>
                </select>
                <!--begin::Input-->
                <input
                  type="text"
                  class="ms-2 form-control form-control-lg form-control-solid"
                  name="search"
                  v-model="keyword"
                  placeholder="Cari mata kuliah"
                  @change="onCourseNameFilter"
                />
                <!--end::Input-->
              </template>
            </label-header>
          </div>
          <table-basic>
            <template #thead>
              <tr class="fw-bolder bg-light fs-7">
                <th class="ps-4 w-50px rounded-start">No</th>
                <th class="min-w-100px">Mata Kuliah</th>
                <th class="min-w-50px">Kelas</th>
                <th class="min-w-100px">Dosen</th>
                <th class="min-w-50px">Mahasiswa Pengontrak</th>
                <th class="min-w-50px">Mahasiswa Menyetujui</th>
                <th class="min-w-100px">Status</th>
                <th class="rounded-end"></th>
              </tr>
            </template>

            <template #tbody>
              <tr v-for="(item, i) in daftarKontrak" :key="i">
                <td class="text-center align-middle">{{ i + 1 }}</td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.course_name }}
                  </span>
                  <span class="text-muted text-muted d-block fs-8">
                    {{ item.course_id }}
                  </span>
                </td>
                <td class="text-center align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.class }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.lecturer }}
                  </span>
                </td>
                <td class="text-center align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.student_total }}
                  </span>
                </td>
                <td class="text-center align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.student_completed }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="badge" :class="getItemStatus(item.status)">
                    {{ item.status }}
                  </span>
                </td>
                <td class="align-middle">
                  <a
                    @click="getDetailCourseContract(item.id)"
                    class="mt-5 ms-md-3 mt-md-0 btn btn-sm btn-secondary btn-active-primary"
                  >
                    Detail
                  </a>
                </td>
              </tr>
            </template>
          </table-basic>
          <div class="container py-2 my-5">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <div>
                  <select
                    class="form-select form-select-solid"
                    data-control="selected_tahun_semester"
                    data-placeholder="Select an option"
                    v-model="selected_item_per_page"
                    @change="onSelectedItemPerPage($event)"
                  >
                    <option v-for="(item, i) in item_per_page" :key="i">
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="ms-5">
                  Menampilkan
                  <span class="fw-bolder">{{ pagination.from }}</span> sampai
                  <span class="fw-bolder">{{ pagination.to }}</span> dari
                  <span class="fw-bolder">{{ pagination.total }}</span> data
                </div>
              </div>
              <div>
                <ul class="pagination">
                  <li class="page-item previous">
                    <button
                      @click="prevPage"
                      class="btn btn-icon btn-primary"
                      :class="
                        pagination.prev_page_url == null ? 'disabled' : ''
                      "
                    >
                      &larr;
                    </button>
                  </li>
                  <div class="align-self-center mx-3">
                    <div v-if="container_loading">
                      <span
                        class="spinner-border text-primary"
                        role="status"
                      ></span>
                    </div>
                    <span v-else
                      >{{ pagination.current_page }}/{{
                        pagination.last_page
                      }}</span
                    >
                  </div>
                  <li class="page-item next">
                    <button
                      @click="nextPage"
                      class="btn btn-icon btn-primary"
                      :class="
                        pagination.next_page_url == null ? 'disabled' : ''
                      "
                    >
                      &rarr;
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </card-basic>
    </template>
  </container-app>
</template>

<script>
// API
import {
  getContractByMajor,
  getAcademicYearsLecturer,
} from "@/apis/kontrakPerkuliahan";

export default {
  data() {
    return {
      container_loading: false,
      total: 8,
      current: 1,
      myDropzone: null,
      container_loading: false,
      isButtonDisable: true,
      submit_loading: "off",
      daftarKontrak: [],
      tahunAkademik: [],
      selected_tahun_semester: "",
      selected_nidn: "",
      selected_id: null,
      selected_item_per_page: 10,
      item_per_page: [10, 20, 50, 100],
      selected_page: 1,
      pagination: {},
      major_list: [
        "All",
        "Arsitektur",
        "Sistem Informasi",
        "Teknik Industri",
        "Teknik Informatika",
        "Teknik Sipil",
      ],
      selected_major: "All",
      keyword: "",
    };
  },

  mounted() {
    this.loadData();
  },

  methods: {
    async onSelectedMajor(event) {
      this.selected_major = event.target.value;
      await this.fetchContractByMajor(
        event.target.value,
        this.selected_tahun_semester,
        this.selected_item_per_page,
        this.selected_page,
        `&course_name=${this.keyword}`
      );
    },
    async onCourseNameFilter() {
      if (this.keyword) {
        await this.fetchContractByMajor(
          this.selected_major,
          this.selected_tahun_semester,
          this.selected_item_per_page,
          this.selected_page,
          `&course_name=${this.keyword}`
        );
      } else {
        await this.fetchContractByMajor(
          this.selected_major,
          this.selected_tahun_semester,
          this.selected_item_per_page,
          this.selected_page
        );
      }
    },
    async onSelectedItemPerPage(event) {
      this.selected_item_per_page = event.target.value;
      await this.fetchContractByMajor(
        this.selected_major,
        this.selected_tahun_semester,
        event.target.value,
        this.selected_page,
        `&course_name=${this.keyword}`
      );
    },
    async onSelectedAcademicYear(event) {
      this.selected_tahun_semester = event.target.value;
      await this.fetchContractByMajor(
        this.selected_major,
        event.target.value,
        this.selected_item_per_page,
        this.selected_page,
        `&course_name=${this.keyword}`
      );
    },
    async loadData() {
      await this.getsTahunAkademik();
      await this.fetchContractByMajor(
        this.selected_major,
        this.selected_tahun_semester,
        this.selected_item_per_page,
        this.selected_page
      );
    },
    async getsTahunAkademik() {
      try {
        const response = await getAcademicYearsLecturer();
        this.tahunAkademik = response.data.data;
        this.selected_tahun_semester = response.data.data[0].thsms;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchContractByMajor(
      major,
      academic_year,
      item_per_page,
      page,
      course_name
    ) {
      try {
        this.container_loading = true;
        const response = await getContractByMajor(
          major,
          academic_year,
          item_per_page,
          page,
          course_name
        );
        this.daftarKontrak = await response.data.data.data;
        const {
          current_page,
          first_page_url,
          from,
          last_page,
          last_page_url,
          links,
          next_page_url,
          path,
          per_page,
          prev_page_url,
          to,
          total,
        } = response.data.data;
        this.pagination.current_page = current_page;
        this.pagination.first_page_url = first_page_url;
        this.pagination.from = from;
        this.pagination.last_page = last_page;
        this.pagination.last_page_url = last_page_url;
        this.pagination.links = links;
        this.pagination.next_page_url = next_page_url;
        this.pagination.path = path;
        this.pagination.per_page = per_page;
        this.pagination.prev_page_url = prev_page_url;
        this.pagination.to = to;
        this.pagination.total = total;
        this.container_loading = false;
      } catch (err) {
        console.error(err);
        this.container_loading = false;
      }
    },
    getItemStatus(status) {
      switch (status) {
        case "Dosen Belum Mengisi":
          return "badge badge-light-danger";
        case "Mahasiswa Belum Mengisi":
          return "badge badge-light-warning";
        case "Dosen Sudah Mengisi":
          return "badge badge-light-success";
        default:
          return "";
      }
    },
    async prevPage() {
      if (this.pagination.prev_page_url) {
        this.selected_page--;
        await this.fetchContractByMajor(
          this.selected_major,
          this.selected_tahun_semester,
          this.selected_item_per_page,
          this.selected_page,
          `&course_name=${this.keyword}`
        );
      }
    },
    async nextPage() {
      if (this.pagination.next_page_url) {
        this.selected_page++;
        await this.fetchContractByMajor(
          this.selected_major,
          this.selected_tahun_semester,
          this.selected_item_per_page,
          this.selected_page,
          `&course_name=${this.keyword}`
        );
      }
    },
    getDetailCourseContract(id) {
      this.$router.push({
        path: `/kontrak-perkuliahan/detail/${id}`,
      });
    },
  },
};
</script>
