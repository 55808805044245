export const formatDate = wkt => {
    var date = new Date(wkt);
    var tahun = date.getFullYear();
    var bulan = date.getMonth();
    var tanggal = date.getDate();
    var hari = date.getDay();
    var jam = String(date.getHours()).padStart(2, '0') + ":" + String(date.getMinutes()).padStart(2, '0')+' WIB';
    switch (hari) {
        case 0:
            hari = "Minggu";
            break;
        case 1:
            hari = "Senin";
            break;
        case 2:
            hari = "Selasa";
            break;
        case 3:
            hari = "Rabu";
            break;
        case 4:
            hari = "Kamis";
            break;
        case 5:
            hari = "Jum'at";
            break;
        case 6:
            hari = "Sabtu";
            break;
    }
    switch (bulan) {
        case 0:
            bulan = "Januari";
            break;
        case 1:
            bulan = "Februari";
            break;
        case 2:
            bulan = "Maret";
            break;
        case 3:
            bulan = "April";
            break;
        case 4:
            bulan = "Mei";
            break;
        case 5:
            bulan = "Juni";
            break;
        case 6:
            bulan = "Juli";
            break;
        case 7:
            bulan = "Agustus";
            break;
        case 8:
            bulan = "September";
            break;
        case 9:
            bulan = "Oktober";
            break;
        case 10:
            bulan = "November";
            break;
        case 11:
            bulan = "Desember";
            break;
    }
    var tampilTanggal = "" + hari + ", " + tanggal + " " + bulan + " " + tahun+" pukul "+jam;

    return tampilTanggal
}

export const formatSemester = (tahun) => {
    switch (tahun.split('')[tahun.length-1]) {
        case '1':
            return 'Ganjil';
            break;
        case '2':
            return 'Genap';
            break;
        case '3':
            return 'Antara';
            break;
        default:
            return '';
            break;
    }
}
export const daftar_semester = (tahun) => {
    switch (tahun.split('')[tahun.length-1]) {
        case '1':
            return [1, 3, 5, 7];
            break;
        case '2':
            return [2, 4, 6, 8];
            break;
        case '3':
            return [1, 2, 3, 4, 5, 6, 7, 8];
            break;
        default:
            return [1, 2, 3, 4, 5, 6, 7, 8];
            break;
    }
}