<template>
    <container-app :container_loading="container_loading">
        <template #content>

            <container-double>
                <template #left>

                    <card-basic>
                        <template #content>
                            <div class="p-5">
                                <form-icon>
                                    <template #icon>
                                        <icon-search />
                                    </template>

                                    <template #content>
                                        <input 
                                            type="text" 
                                            class="form-control ps-14 fw-normal"
                                            placeholder="Pencarian berdasarkan nama..."
                                            autofocus
                                            v-model="keyword"
                                            @keyup.enter="searchDosen"
                                            >
                                    </template>
                                </form-icon>

                                <span class="d-block mt-1 text-gray-500 ms-14" v-if="keyword.length > 0">Tekan tombol enter untuk melakukan pencarian</span>
                            </div>        

                            <table-basic>
                                <template #thead>
                                    <tr class="fw-bolder bg-light fs-7">
                                        <th class="rounded-start ps-5 w-md-50px">No.</th>
                                        <th>Nama Lengkap</th>
                                        <th class="rounded-end pe-5 w-md-200px">Aksi</th>
                                    </tr>
                                </template>

                                <template #tbody>
                                    <tr class="fs-7" v-for="(dosen, i) in daftar_dosen" :key="i">
                                        <td class="text-end ps-5">{{ i+1 }}</td>
                                        <td>
                                            <div class="d-flex symbol symbol-30px symbol-md-40px">
                                                <img :src="dosen.foto == '-' ? '/img/blank.aad363d0.png' : dosen.foto " />
                                                <div class="ms-3">
                                                    <span class="d-block fw-bold text-dark mb-1">{{ dosen.nama }}</span>
                                                    <span class="d-block fs-8 text-muted">{{ dosen.username }}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="pe-5">
                                            <button-icon class="btn-sm btn-secondary" name="Reset Password" @click="resetPassword(dosen.username)">
                                                <template #icon>
                                                    <icon-reset />
                                                </template>
                                            </button-icon>
                                        </td>
                                    </tr>
                                </template>
                            </table-basic>
                        </template>
                    </card-basic>

                </template>
            </container-double>

        </template>
    </container-app>
</template>

<script>
    // apis
    import { getsDosen, searchDosen } from '@/apis/dosen';
    import { resetPassword } from '@/apis/user';

    export default {
        beforeMount() {
            localStorage.setItem('menu', 'Manajemen User')
            localStorage.setItem('halaman', 'Dosen')
        },
        data() {
            return {
                container_loading: true,
                daftar_dosen: {},
                keyword: ''
            }
        },
        mounted() {
            this.getsDosen();
        },
        watch: {
            keyword(newValue) {
                if( newValue.length > 0 ) {
                    this.daftar_dosen = {};
                } else {
                    this.getsDosen();
                }
            }
        },
        methods: {
            async getsDosen() {
                this.container_loading = true;

                try {
                    const res = await getsDosen();
                    this.daftar_dosen = res.data.data;

                    this.container_loading = false;
                } catch (err) {
                    console.error( err );
                }

                this.container_loading = false;
            },
            async searchDosen() {
                try {
                    const res = await searchDosen(this.keyword);
                    this.daftar_dosen = res.data.data;
                } catch ( err ) {
                    console.error( err );
                }
            },
            resetPassword(email) {
                Swal.fire({
                    icon: 'question',
                    text: 'Reset password atas email ' + email,
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                    confirmButtonText: 'Ya',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-secondary'
                    }
                }).then( async result => {
                    if( result.isConfirmed ) {
                        try {
                            const res = await resetPassword({email: email});

                            if( res.status === 200 ) {
                                Swal.fire({
                                    icon: 'success',
                                    text: 'Password berhasil di reset',
                                    showConfirmButton: false,
                                    timer: 5000
                                })
                            }
                        } catch ( err ) {
                            console.error( err );
                        }
                    }
                })
            }
        }
    }
</script>