<template>
	<container-app>
		<template #content>

			<div class="d-block d-md-none" v-if="message != ''">
				<alert-basic
					:class="informasi.status_perwalian == 'YA' || informasi.status_perwalian == 'MENUNGGU' ? 'alert-primary' : 'alert-danger'"
					:message="message" />
			</div>

			<container-double>
				<template #left>
					<div v-if="message != ''">
						<div class="d-none d-md-block">
							<alert-basic
								:class="informasi.status_perwalian == 'YA' || informasi.status_perwalian == 'MENUNGGU' ? 'alert-primary' : 'alert-danger'"
								:message="message" />
						</div>

						<!-- <div v-if="validasi"> -->
						<div>
							<div class="d-block d-md-none">
								<card-basic>
									<template #content>

										<div class="px-5 py-3">
											<div class="d-flex justify-content-between align-items-center w-100">
												<span>
													Keterangan & Batas SKS
												</span>
												<span data-bs-toggle="collapse" href="#keteranganbatas" role="button" aria-expanded="false"
													aria-controls="collapseExample" @click="handleDetail">
													Detail
													<icon-arrow-bottom v-if="!detail" />
													<icon-arrow-up v-else />
												</span>
											</div>
										</div>

										<div class="collapse" id="keteranganbatas">
											<div class="mt-2 pt-2 px-5 fs-7">
												<ul class="nav nav-tabs">
													<li class="nav-item">
														<a class="nav-link active" data-bs-toggle="tab" href="#keterangan">Keterangan</a>
													</li>
													<li class="nav-item">
														<a class="nav-link" data-bs-toggle="tab" href="#batas">Batas
															SKS</a>
													</li>
												</ul>
											</div>

											<div class="tab-content" id="myTabContent">
												<div class="tab-pane fade show active p-5" id="keterangan" role="tabpanel">
													<info-status />
												</div>
												<div class="tab-pane fade" id="batas" role="tabpanel">
													<info-batas />
												</div>
											</div>
										</div>

									</template>
								</card-basic>
							</div>

							<card-basic>
								<template #content>
									<div class="p-5">
										<form-icon>
											<template #icon>
												<icon-search />
											</template>

											<template #content>
												<input type="text" class="form-control ps-14 fw-normal"
													placeholder="Pencarian berdasarkan NIM / Nama..." v-model="search_mahasiswa">
											</template>
										</form-icon>
									</div>


									<tables-perwalian :daftar_mahasiswa="daftar_mahasiswa" :validasi="validasi"  @update-data="getMahasiswaProdi" />

								</template>
							</card-basic>
						</div>
					</div>

				</template>

				<template #right>
					<card-basic>
						<template #content>
							<div class="p-5">
								<info-status />
							</div>
						</template>
					</card-basic>
					<card-basic>
						<template #content>
							<info-batas />
						</template>
					</card-basic>
				</template>
			</container-double>

		</template>
	</container-app>
</template>

<script>
	// components
	import TablesPerwalian from '@/components/_Perwalian/TablesPerwalian.vue'

	// apis
	import {
		getUser
	} from '@/apis/user';
	import {
		getActiveDosen,
		getMahasiswaProdi
	} from '@/apis/perwalian';

	// helpers
	import {
		formatDate,
		formatSemester
	} from "@/helpers/waktu";

	export default {
		components: {
			TablesPerwalian,
		},
		data() {
			return {
				daftar_tahun: [],
				tahun: '-',
				daftar_kelas: [],
				kelas: '-',
				daftar_mahasiswa: [],
				nodos: '',
				filter: {
					tahun: '',
					kelas: ''
				},
				container_loading: true,
				informasi: {},
				message: '',
				validasi: false,
				detail: false,
				search_mahasiswa: ''
			}
		},
		async beforeMount() {
			this.getActiveDosen();
			this.getMahasiswaProdi();
			localStorage.setItem('menu', 'AISnet');
			localStorage.setItem('halaman', 'Perwalian');
		},
		methods: {
			handleDetail() {
				this.detail = !this.detail;
			},
			async getActiveDosen() {
				try {
					const response = await getActiveDosen();
					this.informasi = response.data;

					const jenis = this.informasi.jenis == 'PERWALIAN' ? 'Validasi' :
						'Validasi Perubahan Perwalian/';
					const angkatan = this.informasi.angkatan == '-' ? '' :
						` untuk angkatan ${this.informasi.angkatan}`

					if (this.informasi.status_perwalian == 'MENUNGGU') {
						this.message = jenis + " Formulir Rencana Studi (FRS) Semester " + formatSemester(this
								.informasi
								.thsms) + angkatan + "</span> dibuka pada hari <span style='color: #e67e22'>" +
							formatDate(this.informasi.waktu_mulai) +
							"</span> dan ditutup pada hari <span class='text-danger'>" + formatDate(this.informasi
								.waktu_tutup) + "</span>";
					} else if (this.informasi.status_perwalian == 'YA') {
						this.message = jenis + ' Formulir Rencana Studi (FRS) Semester ' + formatSemester(this
							.informasi
							.thsms) + angkatan + " ditutup pada hari <span class='text-danger'>" + formatDate(
							this.informasi.waktu_tutup) + "</span>";
					} else if (this.informasi.status_perwalian == 'TIDAK') {
						this.message = jenis + ' Formulir Rencana Studi (FRS) Semester ' + formatSemester(this
								.informasi
								.thsms) + angkatan + " telah ditutup pada hari <span class='text-danger'>" +
							formatDate(this.informasi.waktu_tutup) + "</span>";
					}

					if (this.informasi.status_perwalian == 'YA') {
						this.validasi = true;
					}

				} catch (err) {
					console.error(err);
				}
			},
			async getMahasiswaProdi() {
				const { data } = await getMahasiswaProdi();
				this.daftar_mahasiswa = data
			}
		},
	}
</script>