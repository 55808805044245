<template>
  <card-basic>
    <template #content>
      <div class="p-5">
        <label-header value="Konfigurasi Jadwal">
          <template #control>
            <button
              data-bs-toggle="modal"
              data-bs-target="#create_jadwal_modal"
              type="button"
              class="btn btn-primary ms-auto"
            >
              Tambah
            </button>
          </template>
        </label-header>
      </div>
      <div
        v-if="data.length === 0"
        class="alert alert-primary d-flex align-items-center p-5"
      >
        <div class="d-flex flex-column">
          <span>Jadwal Pengisian Nilai Belum Tersedia</span>
        </div>
      </div>
      <table-basic v-else>
        <template #thead>
          <tr class="fw-bolder bg-light fs-7">
            <th class="ps-4 w-50px rounded-start">No</th>
            <th class="min-w-100px">Tanggal Buka</th>
            <th class="min-w-100px">Tanggal Tutup</th>
            <th class="min-w-100px">Tahun Akademik</th>
            <th class="min-w-100px">Jenis Ujian</th>
            <th class="rounded-end"></th>
          </tr>
        </template>

        <template #tbody>
          <tr v-for="(item, i) in data" :key="i">
            <td class="text-center align-middle">{{ i + 1 }}</td>
            <td class="align-middle">
              {{ item.tgl_buka }}
            </td>
            <td class="align-middle">
              {{ item.tgl_tutup }}
            </td>
            <td class="align-middle">
              {{ item.tahun_semester }}
            </td>
            <td class="align-middle">
              <span
                class="badge"
                :class="
                  item.jenis_ujian == 'UAS'
                    ? 'badge-light-success'
                    : 'badge-light-warning'
                "
                >{{ item.jenis_ujian }}</span
              >
            </td>
            <td class="align-middle">
              <button
                data-bs-toggle="modal"
                data-bs-target="#update_jadwal_pengisian_modal"
                type="button"
                class="btn btn-sm btn-secondary ms-auto"
                @click="
                  () => {
                    selected_jadwal = item;
                  }
                "
              >
                Ubah
              </button>
            </td>
          </tr>
        </template>
      </table-basic>
    </template>
  </card-basic>

  <!-- TAMBAH PENJADWALAN NILAI MODAL -->
  <div class="modal fade" tabindex="-1" id="create_jadwal_modal">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Tambah Jadwal Pengisian Nilai</h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <icon-close />
          </div>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <label class="form-label fs-7 fw-normal col-form-label col-md-5"
              >Tanggal Buka</label
            >
            <div class="col-md-7">
              <input
                class="form-control fw-normal"
                type="datetime-local"
                v-model="selected_jadwal.tgl_buka"
                required
              />
            </div>
          </div>

          <div class="row mb-3">
            <label class="form-label fs-7 fw-normal col-form-label col-md-5"
              >Tanggal Tutup</label
            >
            <div class="col-md-7">
              <input
                class="form-control fw-normal"
                type="datetime-local"
                v-model="selected_jadwal.tgl_tutup"
                required
              />
            </div>
          </div>

          <div class="row mb-3">
            <label class="form-label fs-7 fw-normal col-form-label col-md-5"
              >Tahun Akademik</label
            >
            <div class="col-md-7">
              <select
                class="form-select form-select-solid"
                data-placeholder="Select an option"
                v-model="selected_jadwal.tahun_semester"
              >
                <option
                  v-for="(item, i) in tahunAkademik"
                  :key="i"
                  :value="item.thsms"
                >
                  Tahun {{ item.ket }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mb-3">
            <label class="form-label fs-7 fw-normal col-form-label col-md-5"
              >Jenis Ujian</label
            >
            <div class="col-md-7">
              <select
                class="form-select form-select-solid"
                data-placeholder="Select an option"
                v-model="selected_jadwal.jenis_ujian"
              >
                <option value="UTS">UTS</option>
                <option value="UAS">UAS</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Tutup
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="submitJadwalPengisianNilai"
          >
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END TAMBAH PENJADWALAN NILAI MODAL -->

  <!-- UBAH PENJADWALAN NILAI MODAL -->
  <div class="modal fade" tabindex="-1" id="update_jadwal_pengisian_modal">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Ubah Jadwal Pengisian Nilai</h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <icon-close />
          </div>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <label class="form-label fs-7 fw-normal col-form-label col-md-5"
              >Tanggal Buka</label
            >
            <div class="col-md-7">
              <input
                class="form-control fw-normal"
                type="datetime-local"
                v-model="selected_jadwal.tgl_buka"
                required
              />
            </div>
          </div>

          <div class="row mb-3">
            <label class="form-label fs-7 fw-normal col-form-label col-md-5"
              >Tanggal Tutup</label
            >
            <div class="col-md-7">
              <input
                class="form-control fw-normal"
                type="datetime-local"
                v-model="selected_jadwal.tgl_tutup"
                required
              />
            </div>
          </div>

          <div class="row mb-3">
            <label class="form-label fs-7 fw-normal col-form-label col-md-5"
              >Tahun Akademik</label
            >
            <div class="col-md-7">
              <select
                class="form-select form-select-solid"
                data-placeholder="Select an option"
                v-model="selected_jadwal.tahun_semester"
              >
                <option
                  v-for="(item, i) in tahunAkademik"
                  :key="i"
                  :value="item.thsms"
                >
                  Tahun {{ item.ket }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mb-3">
            <label class="form-label fs-7 fw-normal col-form-label col-md-5"
              >Jenis Ujian</label
            >
            <div class="col-md-7">
              <select
                class="form-select form-select-solid"
                data-placeholder="Select an option"
                v-model="selected_jadwal.jenis_ujian"
              >
                <option value="UTS">UTS</option>
                <option value="UAS">UAS</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Tutup
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="updateJadwalPengisianNilai"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END UBAH PENJADWALAN NILAI MODAL -->
</template>

<script>
// API
import { getAcademicYearsLecturer } from "@/apis/kontrakPerkuliahan";
import { postJadwalPenilaian, putJadwalPenilaian } from "@/apis/penilaianUjian";

export default {
  props: ["data", "getData"],
  setup() {
    return {};
  },
  data() {
    return {
      selected_jadwal: {},
      tahunAkademik: [],
    };
  },

  mounted() {
    this.fetchTahunAkademik();
  },

  methods: {
    async submitJadwalPengisianNilai() {
      try {
        const swalLoading = Swal.fire({
          title: "Menyimpan jadwal...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await postJadwalPenilaian({
          tgl_buka: this.selected_jadwal.tgl_buka,
          tgl_tutup: this.selected_jadwal.tgl_tutup,
          jenis_ujian: this.selected_jadwal.jenis_ujian,
          tahun_semester: this.selected_jadwal.tahun_semester,
        });

        swalLoading.close();

        if (response.status === 201) {
          this.getData();
          this.selected_jadwal = {};
          $("#create_jadwal_modal").modal("hide");
          Swal.fire({
            title: "Berhasil!",
            text: response.data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal menyimpan jadwal pengisian nilai!");
        }
      } catch (error) {
        Swal.fire({
          title: "Gagal!",
          text: error,
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async updateJadwalPengisianNilai() {
      try {
        const swalLoading = Swal.fire({
          title: "Mengupdate jadwal pengisian nilai!",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await putJadwalPenilaian({
          id: this.selected_jadwal.id,
          tgl_buka: this.selected_jadwal.tgl_buka,
          tgl_tutup: this.selected_jadwal.tgl_tutup,
          jenis_ujian: this.selected_jadwal.jenis_ujian,
          tahun_semester: this.selected_jadwal.tahun_semester,
        });

        swalLoading.close();

        if (response.status === 201) {
          this.getData();
          this.selected_jadwal = {};
          $("#update_jadwal_pengisian_modal").modal("hide");
          Swal.fire({
            title: "Berhasil!",
            text: response.data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal mengupdate jadwal pengisian nilai");
        }
      } catch (error) {
        Swal.fire({
          title: "Gagal!",
          text: error,
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async fetchTahunAkademik() {
      try {
        const response = await getAcademicYearsLecturer();
        this.tahunAkademik = response.data.data;
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
