import { api, headers } from './api';

const RESOURCE = 'jadwal';

export const getsJadwalKuliah = (tahun)=> api.get(RESOURCE+'/kuliah/'+tahun, headers);

export const getsAbsen = (tahun)=> api.get(RESOURCE+'/kuliah/'+tahun+'/absen', headers);

export const getAbsen = (tahun, kode_matkul)=> api.get(RESOURCE+'/kuliah/'+tahun+'/absen/'+kode_matkul, headers);

export const getsJadwalUts = (tahun)=> api.get(RESOURCE+'/uts/'+tahun, headers);

export const getsJadwalUas = (tahun)=> api.get(RESOURCE+'/uas/'+tahun, headers);