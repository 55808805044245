<template>
    <div class="table-responsive">
        <table class="table align-middle gs-0 gy-4 table-row-dashed fs-7">
            <thead>
                <tr class="fw-bolder bg-light">
                    <th class="rounded-start ps-4 w-50px">No</th>
                    <th>Mata Kuliah</th>
                    <th class="rounded-end pe-4 w-50px">SKS</th>
                    <th class="rounded-end pe-4 w-50px">Nilai</th>
                </tr>
            </thead>

            <tbody v-for="(transkrip, i) in daftar_transkrip" :key="i">
                <tr class="fs-7">
                    <td colspan="4" class="text-center bg-dark text-light">Semester {{ transkrip.semester }}</td>
                </tr>
                <tr v-for="(t, l) in transkrip.transkrip" :key="l">
                    <td class="rounded-start ps-4 w-50px text-end">{{ l+1 }}</td>
                    <td>
                        <span class="text-dark fw-bolder d-block mb-1 fs-7">{{ t.nama_matkul }}</span>
                        <span class="text-muted fw-bold text-muted d-block fs-8">({{ t.kode_matkul }})</span>
                    </td>
                    <td class="rounded-end pe-4 w-100px fs-7">{{ t.sks }}</td>
                    <td class="rounded-end pe-4 w-100px fs-7">{{ t.nilai }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: ['daftar_transkrip'],
}
</script>