<template>
    <div class="symbol symbol-40px d-flex align-items-center">
        <img src="@/assets/blank.png" v-if="foto == '-'" />
        <img :src="foto" v-else/>
        <div class="d-flex flex-column ms-4">
            <span class="fw-bolder d-flex align-items-center fs-7">{{ name }}</span>
            <span class="fw-bold text-muted fs-8">{{ id }}</span>
        </div>
        
    </div>
</template>

<script>
export default {
    props: ['name', 'id', 'foto']
}
</script>