<template>
    <card-basic>
          <template #content>
            <div class="p-5">
              <label-header value="Konfigurasi Grade Nilai">
                <template #control>
                  <button 
                  data-bs-toggle="modal"
                  data-bs-target="#create_nilai_huruf_modal"
                  type="button" class="btn btn-primary ms-auto">
                    Tambah
                  </button>
                </template>
              </label-header>
            </div>
            <div
                v-if="data.length === 0"
                class="alert alert-primary d-flex align-items-center p-5"
              >
                <div class="d-flex flex-column">
                  <span>Nilai Huruf Belum Tersedia</span>
                </div>
              </div>
            <table-basic v-else>
              <template #thead>
                <tr class="fw-bolder bg-light fs-7">
                  <th class="ps-4 w-50px rounded-start">No</th>
                  <th class="min-w-100px">Nilai Huruf</th>
                  <th class="min-w-100px">Rentang Nilai</th>
                  <th class="min-w-100px">Status</th>
                  <th class="rounded-end"></th>
                </tr>
              </template>
  
              <template #tbody>
                <tr v-for="(item, i) in data" :key="i">
                  <td class="text-center align-middle">{{ i + 1 }}</td>
                  <td class="align-middle">
                    {{ item.nilai_huruf }}
                  </td>
                  <td class="align-middle">
                    {{ item.batas_bawah }} {{ item.batas_bawah_simbol }} {{ item.nilai_huruf }} {{ item.batas_atas_simbol }} {{ item.batas_atas }} 
                  </td>
                  <td class="align-middle">
                    <span class="badge" :class="item.status == 'Aktif' ? 'badge-light-success': 'badge-light-danger' ">{{ item.status }}</span>
                  </td>
                  <td class="align-middle">
                    <button data-bs-toggle="modal" data-bs-target="#update_nilai_huruf_modal" type="button" class="btn btn-sm btn-secondary ms-auto" @click="() => {
                      selected_nilai_huruf = item
                    }">
                    Ubah
                  </button>
                  </td>
                </tr>
              </template>
            </table-basic>
          </template>      
    </card-basic>
    <!-- TAMBAH NILAI HURUF MODAL -->
    <div class="modal fade" tabindex="-1" id="create_nilai_huruf_modal">
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Tambah Nilai Huruf</h5>
                <div
                  class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <icon-close />
                </div>
              </div>
              <div class="modal-body">
                <div>
                  <h4 class="text-center">Rentang Nilai:</h4>
                  <div class="card shadow-sm p-3">
                    <p class="m-0 text-center text-gray-700 fw-bold">
                      {{ selected_nilai_huruf.batas_bawah }} {{ selected_nilai_huruf.batas_bawah_simbol }} {{ selected_nilai_huruf.nilai_huruf }} {{ selected_nilai_huruf.batas_atas_simbol }} {{ selected_nilai_huruf.batas_atas }} 
                    </p>
                  </div>
                </div>
                <form>
                  <div class="form-floating mt-4">
                    <input type="text" class="form-control" placeholder="Masukkan Nilai" v-model="selected_nilai_huruf.nilai_huruf"/>
                    <label for="nilai">Nilai Huruf</label>
                  </div>
                  <div class="d-flex flex-row align-items-center mt-4">
                    <div class="form-floating me-2">
                    <input type="number" class="form-control" placeholder="Masukkan Nilai" v-model="selected_nilai_huruf.batas_bawah"/>
                    <label for="nilai">Nilai Batas Bawah</label>
                    </div>
  
                    <div>
                    <select class="form-select" data-placeholder="Pilih Simbol" v-model="selected_nilai_huruf.batas_bawah_simbol">
                      <option value='>'>Lebih dari</option>
                      <option value='≥'>Lebih dari sama dengan</option>
                    </select>
                    </div>
                  </div>
                  <div class="d-flex flex-row align-items-center mt-4">
                    <div class="form-floating me-2">
                    <input type="number" class="form-control" placeholder="Masukkan Nilai" v-model="selected_nilai_huruf.batas_atas"/>
                    <label for="nilai">Nilai Batas Atas</label>
                    </div>
  
                    <div>
                    <select class="form-select" data-placeholder="Pilih Simbol" v-model="selected_nilai_huruf.batas_atas_simbol" >
                      <option value='<'>Kurang dari</option>
                      <option value='≤'>Kurang dari sama dengan</option>
                    </select>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  Tutup
                </button>
                <button type="button" class="btn btn-success" @click="submitNilaiHuruf"> Simpan </button>
              </div>
            </div>
          </div>
    </div>
    <!-- END TAMBAH NILAI HURUF MODAL -->
  
    <!-- TAMBAH NILAI HURUF MODAL -->
    <div class="modal fade" tabindex="-1" id="update_nilai_huruf_modal">
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Ubah Nilai Huruf</h5>
                <div
                  class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <icon-close />
                </div>
              </div>
              <div class="modal-body">
                <div>
                  <h4 class="text-center">Rentang Nilai:</h4>
                  <div class="card shadow-sm p-3">
                    <p class="m-0 text-center text-gray-700 fw-bold">
                      {{ selected_nilai_huruf.batas_bawah }} {{ selected_nilai_huruf.batas_bawah_simbol }} {{ selected_nilai_huruf.nilai_huruf }} {{ selected_nilai_huruf.batas_atas_simbol }} {{ selected_nilai_huruf.batas_atas }} 
                    </p>
                  </div>
                </div>
                <form>
                  <div class="form-floating mt-4">
                    <input type="text" class="form-control" placeholder="Masukkan Nilai" v-model="selected_nilai_huruf.nilai_huruf"/>
                    <label for="nilai">Nilai Huruf</label>
                  </div>
                  <div class="d-flex flex-row align-items-center mt-4">
                    <div class="form-floating me-2">
                    <input type="number" class="form-control" placeholder="Masukkan Nilai" v-model="selected_nilai_huruf.batas_bawah"/>
                    <label for="nilai">Nilai Batas Bawah</label>
                    </div>
  
                    <div>
                    <select class="form-select" data-placeholder="Pilih Simbol" v-model="selected_nilai_huruf.batas_bawah_simbol">
                      <option value='>'>Lebih dari</option>
                      <option value='≥'>Lebih dari sama dengan</option>
                    </select>
                    </div>
                  </div>
                  <div class="d-flex flex-row align-items-center mt-4">
                    <div class="form-floating me-2">
                    <input type="number" class="form-control" placeholder="Masukkan Nilai" v-model="selected_nilai_huruf.batas_atas"/>
                    <label for="nilai">Nilai Batas Atas</label>
                    </div>
  
                    <div>
                    <select class="form-select" data-placeholder="Pilih Simbol" v-model="selected_nilai_huruf.batas_atas_simbol" >
                      <option value='<'>Kurang dari</option>
                      <option value='≤'>Kurang dari sama dengan</option>
                    </select>
                    </div>
                  </div>
  
                  <div class="mt-4">
                    <select class="form-select" data-placeholder="Pilih Simbol" v-model="selected_nilai_huruf.status" >
                      <option value='Aktif'>Aktif</option>
                      <option value='Tidak Aktif'>Tidak Aktif</option>
                    </select>
                    </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  Tutup
                </button>
                <button type="button" class="btn btn-success" @click="updateNilaiHuruf"> Update </button>
              </div>
            </div>
          </div>
    </div>
    <!-- END TAMBAH NILAI HURUF MODAL -->
  </template>
  
  <script>
  
  
  // API
  
  import { postNilaiHuruf, putNilaiHuruf } from "@/apis/penilaianUjian";
  
  export default {
    props:['data', 'getData'],
    setup() {
        return {
        }
    },
    data() {
      return {
        selected_nilai_huruf:{}
      };
    },
  
    methods: {
      async submitNilaiHuruf() {
        try {
          const swalLoading = Swal.fire({
            title: "Menyimpan nilai huruf",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
  
          const response = await postNilaiHuruf({
            nilai_huruf: this.selected_nilai_huruf.nilai_huruf,
            batas_bawah: this.selected_nilai_huruf.batas_bawah,
            batas_bawah_simbol: this.selected_nilai_huruf.batas_bawah_simbol,
            batas_atas: this.selected_nilai_huruf.batas_atas,
            batas_atas_simbol: this.selected_nilai_huruf.batas_atas_simbol,
            status: 'Aktif'
          });
  
          swalLoading.close();
  
          if (response.status === 201) {
            this.getData()
            this.selected_nilai_huruf = {}
            $("#create_nilai_huruf_modal").modal("hide");
            Swal.fire({
              title: "Berhasil!",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 3000
            });
          } else {
            throw new Error("Gagal menyimpan nilai Huruf");
          }
        } catch (error) {
          Swal.fire({
            title: "Gagal!",
            text: error,
            icon: "error",
            showConfirmButton: false,
            timer: 3000
          });
        }
      },
      async updateNilaiHuruf() {
        try {
          const swalLoading = Swal.fire({
            title: "Mengupdate nilai huruf",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
  
          const response = await putNilaiHuruf({
            id: this.selected_nilai_huruf.id,
            nilai_huruf: this.selected_nilai_huruf.nilai_huruf,
            batas_bawah: this.selected_nilai_huruf.batas_bawah,
            batas_bawah_simbol: this.selected_nilai_huruf.batas_bawah_simbol,
            batas_atas: this.selected_nilai_huruf.batas_atas,
            batas_atas_simbol: this.selected_nilai_huruf.batas_atas_simbol,
            status: this.selected_nilai_huruf.status
          });
  
          swalLoading.close();
  
          if (response.status === 201) {
            this.getData()
            this.selected_nilai_huruf = {}
            $("#update_nilai_huruf_modal").modal("hide");
            Swal.fire({
              title: "Berhasil!",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 3000
            });
          } else {
            throw new Error("Gagal mengupdate nilai Huruf");
          }
        } catch (error) {
          Swal.fire({
            title: "Gagal!",
            text: error,
            icon: "error",
            showConfirmButton: false,
            timer: 3000
          });
        }
      },
    },
  };
  </script>
  