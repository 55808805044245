export const daftar_hari = ["Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];

export const daftar_semester = [1, 2, 3, 4, 5, 6, 7, 8];

export function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toUpperCase() : word.toLowerCase();
  }).replace(/\s+/g, '');
}

export function toFormData(data) {
  let fd = new FormData();
  Object.keys(data).map((e) => {
    fd.append(e, data[e]);
  })
  return fd;
}

export function closeModal(id) {
  const containerClaimReqModal = document.getElementById(id);
  const claimReqModal = new bootstrap.Modal(containerClaimReqModal, {
    keyboard: false
  });
  claimReqModal.hide()
}