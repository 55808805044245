<template>
    <div>
        <label-basic name="Keterangan :" />
        <ul class="ps-0 m-0">
            <li class="d-flex align-items-center fs-7"><icon-status-2 /><span class="ms-2">Selesai/ Tervalidasi</span></li>
            <li class="d-flex align-items-center fs-7"><icon-status-0 /><span class="ms-2">Ditolak</span></li>
            <li class="d-flex align-items-center fs-7"><icon-status-1 /><span class="ms-2">Menunggu Validasi</span></li>
            <li class="d-flex align-items-center fs-7"><icon-status-3 /><span class="ms-2">Belum Diajukan</span></li>
        </ul>
    </div>
</template>