<template>
    <div v-for="(daftar, i) in jadwal" :key="i">
        <label-basic :name="daftar.hari" />
        <card-jadwal-kuliah :jadwal="j" v-for="( j, i) in daftar.jadwal" :key="i" />
    </div>
</template>

<script>
// helpers
import { jadwal_kuliah } from '@/helpers/jadwal'

// components
import CardJadwalKuliah from '@/components/_JadwalKuliah/CardJadwalKuliah.vue'

export default {
    props: ['daftar_jadwal'],
    components: {
        CardJadwalKuliah
    },
    data() {
        return {
            jadwal : {},
        }
    },
    watch: {
        daftar_jadwal() {
            this.jadwal = jadwal_kuliah( this.daftar_jadwal )
        }
    }
}
</script>