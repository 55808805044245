<template>
    <div v-for="(daftar, i) in jadwal" :key="i">
        <card-jadwal-uts :jadwal="daftar" />
    </div>
</template>

<script>
// helpers
import { jadwal_kuliah } from '@/helpers/jadwal';

// components
import CardJadwalUts from './CardJadwalUts.vue';

export default {
    props: ['daftar_jadwal'],
    components: {
        CardJadwalUts
    },
    data() {
        return {
            jadwal : {},
        }
    },
    watch: {
        daftar_jadwal() {
            this.jadwal = this.daftar_jadwal
        }
    }
}
</script>