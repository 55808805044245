import { defineStore } from "pinia";

export const useMatkulStore = defineStore("matkul", {
  state: () => {
    return {
      tahun_semester: "",
      nama: "",
      kode: "",
      kelas: "",
      kelas_ujian: "",
    };
  },
  actions: {
    set(tahun_semester, nama, kode, kelas, kelas_ujian) {
      this.tahun_semester = tahun_semester;
      this.nama = nama;
      this.kode = kode;
      this.kelas = kelas;
      this.kelas_ujian = kelas_ujian;

      localStorage.setItem(
        "dataMatkul",
        JSON.stringify({
          tahun_semester,
          nama,
          kode,
          kelas,
          kelas_ujian,
        })
      );
    },
    restoreFromLocalStorage() {
      const storedData = localStorage.getItem("dataMatkul");
      if (storedData) {
        const { tahun_semester, nama, kode, kelas, kelas_ujian } =
          JSON.parse(storedData);
        this.set(tahun_semester, nama, kode, kelas, kelas_ujian);
      }
    },
  },
});
