<template>
    <container-app>
        <template #content>

            <div class="row">
                <div class="col-12 col-md-5">
                    <card-basic>
                        <template #content>

                            <div class="p-5">
                                <button-icon name="Riwayat" class="btn-sm btn-secondary ms-auto mb-5" data-bs-toggle="modal" data-bs-target="#modalRiwayat">
                                    <template #icon>
                                        <icon-history />
                                    </template>
                                </button-icon>

                                <form @submit.prevent="storeHotline">
                                    <div class="mb-5">
                                        <label for="jenis" class="form-label">Jenis</label>
                                        <div class="input-group">
                                            <select id="jenis" class="form-select form-select-solid" v-model="form.jenis" required>
                                                <option v-for="(jenis, i) in daftar_jenis" :key="i" :value="jenis">{{ jenis }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="mb-5">
                                        <label for="tujuan" class="form-label">Diajukan Kepada</label>
                                        <div class="input-group">
                                            <select id="tujuan" class="form-select form-select-solid" v-model="form.tujuan" required>
                                                <option v-for="(tujuan, i) in daftar_tujuan" :key="i" :value="tujuan.nama">{{ tujuan.nama }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="mb-5">
                                        <label for="tujuan" class="form-label">Isi</label>
                                        <div class="input-group">
                                            <textarea class="form-control form-control-solid" rows="5" v-model="form.isi" required></textarea>
                                        </div>
                                        <span class="text-end text-muted d-block mt-1">{{form.isi.length}}/500</span>
                                    </div>

                                    <button class="btn btn-primary w-100" type="submit" :disabled="loading">
                                        <div v-if="loading">
                                            <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                            Loading
                                        </div>
                                        <div v-else>
                                            Kirim
                                        </div>
                                    </button>
                                </form>

                            </div>

                        </template>
                    </card-basic>
                </div>
            </div>

        </template>
    </container-app>

    <!-- begin::modal -->
    <!-- begin::riwayat -->
    <modal-fullscreen-sm id="modalRiwayat" title="Riwayat Hotline">
        <template #content>
            <card-riwayat 
                v-for="(riwayat, i) in daftar_riwayat" :key="i"
                :riwayat="riwayat" />
        </template>
    </modal-fullscreen-sm>
    <!-- end::riwayat -->
    <!-- end::modal -->
</template>

<script>
// apis
import { getsJenis, getsTujuan, storeHotline, getsHotline } from '@/apis/hotline';

// components
import CardRiwayat from '@/components/_hotline/CardRiwayat.vue';

// store
import { getUserData } from '@/store';

export default {
    components: {
        CardRiwayat
    },
    data() {
        return {
            user: {},
            form: {
                nim: '',
                nama: '',
                jenis: '',
                tujuan: '',
                isi: ''
            },
            daftar_jenis: {},
            daftar_tujuan: {},
            daftar_riwayat: {},
            loading: false
        }
    },
    beforeMount() {
        this.user = getUserData();
        localStorage.setItem('menu', 'AISnet');
        localStorage.setItem('halaman', 'Hotline');
    },
    mounted() {
        this.getsJenis();
        this.getsTujuan();
        this.getsHotline();
    },
    methods: {
        async getsJenis() {
            try {
                const response = await getsJenis();
                this.daftar_jenis = response.data.data;
            } catch (err) {
                console.error(err);
            }
        },
        async getsTujuan() {
            try {
                const response = await getsTujuan();
                this.daftar_tujuan = response.data.data;
            } catch (err) {
                console.error(err);
            }
        },
        storeHotline() {
            Swal.fire({
                icon: 'question',
                text: 'Kirim hotline ?',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                confirmButtonText: 'Ya',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-secondary'
                }
            }).then( async res => {
                if( res.isConfirmed ) {
                    this.loading = true;
                    try {
                        this.form.nim = this.user.nim;
                        this.form.nama = this.user.nama; 

                        const response = await storeHotline(this.user.nim, this.form);
                        
                        if( response.status == 200 ) {
                            Swal.fire({
                                icon: 'success',
                                text: 'Hotline berhasil dikirim',
                                showConfirmButton: false,
                                timer: 5000
                            })

                            this.getsHotline();

                            this.form = {
                                nim: '',
                                nama: '',
                                jenis: '',
                                tujuan: '',
                                isi: ''
                            }
                        }
                    } catch (err) {
                        Swal.fire({
                            icon: 'error',
                            text: 'Hotline gagal dikirim',
                            showConfirmButton: false,
                            timer: 5000
                        })
                    }
                    this.loading = false;
                }
            })
        },
        async getsHotline() {
            try {
                const response = await getsHotline(this.user.nim);

                this.daftar_riwayat = response.data.data;
            } catch (err) {
                console.error( err );
            }
        }
    }
}
</script>