<template>
    <container-app :container_loading="container_loading">

        <template #content>
            <container-double>
                <template #left>
                    <card-basic>
                        <template #content>
                            <div class="p-5 d-flex">
                                <div class="w-100">
                                    <form-icon>
                                        <template #icon>
                                            <icon-search />
                                        </template>

                                        <template #content>
                                            <input type="text" class="form-control ps-14 fw-normal"
                                                placeholder="Pencarian berdasarkan nim..." autofocus v-model="keyword">
                                        </template>

                                    </form-icon>
                                    <span class="d-block mt-1 text-gray-500 ms-14" v-if="keyword.length > 0">Tekan tombol
                                        enter
                                        untuk melakukan pencarian</span>
                                </div>

                            </div>

                            <!-- <tables-perwalian :daftar_mahasiswa="daftar_mahasiswa" @update-data="getMahasiswa" /> -->
                            <TableArsipIjazah :daftar_pengajuan="daftar_pengajuan" />
                        </template>
                    </card-basic>
                </template>
                <template #right>
                    <card-basic>
                        <template #content>

                            <button data-bs-toggle="modal" class="btn btn-sm btn-info w-100" data-bs-target="#modalEdit">
                                <span class="ms-2">Upload Arsip</span>
                            </button>
                        </template>
                    </card-basic>

                </template>
            </container-double>

        </template>
    </container-app>

    <modal-fullsceen-lg id="modalEdit" title="Upload Arsip">
        <template #content>
            <div class="card mb-2">
                <div class="card-body"  >
                    <h5 class="card-title">Daftar Upload Arsip</h5>
                    <div class="table-responsive" style="max-height: 60vh; overflow-y: scroll;">
                        <table class="table  gs-0 gy-4 table-row-dashed fs-7">
                            <thead>
                                <tr>
                                    <th>NIM</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(at, index) in arsipTemps" :key="index">
                                    <td :class="at.status == 1 ? 'text-success':'text-danger'" >{{ at.label }}</td>
                                    <td><button class="btn btn-sm btn-danger   me-3 "
                                            @click="handleDeleteFile(at.id)">Hapus</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer bg-secondary text-end">
                        <div class="d-flex justify-content-center align-items-center text-gray-700 mb-7" v-if="loading_files" >
                            <div class="spinner-border me-2" role="status" style="width: 1.3rem; height: 1.3rem">
                                <span class="visually-hidden"></span>
                            </div>
                            <span class="fs-4 fw-bold">
                                Tunggu Sebentar
                            </span>
                        </div>
                        <div class="d-flex flex-row justify-content-end align-items-center">
                            <div class="me-3">
                                <input class="form-control" @change="handleFileSelect" multiple
                                    placeholder="Pilih File Ijazah" type="file" id="formFile">
                            </div>
                            <button class="btn btn-danger   me-3 " @click="arsipTemps = null">Hapus</button>
                            <button class="btn btn-success  " @click="handleUploadFile">Simpan</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal-fullsceen-lg>

    <!-- begin::modal -->
    <!-- begin::filter -->
    <modal-basic id="modalFilter" title="Filter">
        <template #content>
            <div class="row">
                <!-- <div class="col-6">
                    <label for="filterTahun" class="form-label">Tahun Masuk</label>
                    <div class="input-group mb-5">
                        <select class="form-select form-select-solid" id="filterTahun" v-model="filter.tahun"
                            @change="changeTahun">
                            <option v-for="(tahun, index) in daftar_tahun" :key="index">{{ tahun.tahun }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-6">
                    <label for="filterKelas" class="form-label">Kelas</label>
                    <div class="input-group mb-5">
                        <select class="form-select form-select-solid" id="filterKelas" v-model="filter.kelas">
                            <option v-for="(kelas, index) in daftar_kelas" :key="index">{{ kelas.kelas }}</option>
                        </select>
                    </div>
                </div> -->
            </div>
        </template>

        <!-- <template #button>
            <button-basic class="bg-primary text-light" name="Simpan" @click="processFilter" />
        </template> -->
    </modal-basic>
    <!-- end::filter -->
    <!-- end::modal -->
</template>

<script>
// components
import TableArsipIjazah from '@/components/_ijazah/TableArsipIjazah.vue';

// apis
// import { getActiveDosen, getMahasiswa, getMahasiswaTahun, getMahasiswaKelas, searchMahasiswa } from '@/apis/perwalian';
import { getArsip, checkArsip, storeArsip } from '@/apis/ijazah'
import { toFormData } from '@/helpers/main';
import { toRaw } from 'vue';
import { headers } from '@/apis/api';
// helpers

export default {
    components: {
        TableArsipIjazah
    },
    data() {
        return {
            daftar_arsip: [],
            keyword: "",
            container_loading: true,
            arsipTemps: [],
            loading_files: false
        }
    },
    beforeMount() {
        localStorage.setItem('menu', 'Ijazah')
        localStorage.setItem('halaman', 'Arsip Ijazah')
    },
    methods: {
        async getArsip() {
            let res = await getArsip();
            this.daftar_arsip = res?.data?.data;
        },

        async handleFileSelect(e) {
            let temp = Array.from(e.target.files)
            this.loading_files = true;
            try {
                let files = temp.map((e, i) => {
                    let res = (e.name).split("_")
                    try {
                        if (res[0] !== 'IJAZAH') throw new Error('tot')

                        let nim = (res[1]).split('.')[0]
                        return { label: nim, id: i, file: e, status: 1 };
                    } catch (error) {
                        return { label: e.name + ' (format file tidak sesuai)', id: i, file: e, status: 2 }
                    }
                });
                let res = await checkArsip(toFormData({ arsips: JSON.stringify(toRaw(files)) }));
                this.arsipTemps = res?.data?.data.map((e,i) => ({...e, file: files[i].file}))
                this.loading_files = false;
            } catch (error) {
                this.loading_files = false;
            }

        },
        handleDeleteFile(id) {
            this.arsipTemps = this.arsipTemps.filter(e => e.id !== id);
        },
        async handleUploadFile(){
            let temp = this.arsipTemps;
            let fd = new FormData();
            temp.map(e => {
                console.log(e.file.name);
                fd.append(e.nim, e.file, e.file.name);
            })
            let res = await storeArsip(fd);
            console.log(res);
        }
    },
    mounted() {
        try {
            this.container_loading = true;
            this.getArsip();
            this.container_loading = false;
        } catch (error) {
            this.container_loading = false;

        }

    },
    watch: {
        arsipTemps(curr, prev) {

            console.log('from watch', curr);
        }
    }

}


</script>