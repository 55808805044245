import { api, headers } from "./api";

const RESOURCE = "penilaian-ujian";

export const getPesertaUts = (
  tahun_semester,
  kode_mata_kuliah,
  mata_kuliah,
  kelas,
  kelas_ujian
) =>
  api.get(
    RESOURCE +
      `/uts?tahun_semester=${tahun_semester}&kode_mata_kuliah=${kode_mata_kuliah}&mata_kuliah=${mata_kuliah}&kelas=${kelas}&kelas_ujian=${kelas_ujian}`,
    headers
  );

export const getPesertaUas = (
  tahun_semester,
  kode_mata_kuliah,
  mata_kuliah,
  kelas,
  kelas_ujian
) =>
  api.get(
    RESOURCE +
      `/akhir?tahun_semester=${tahun_semester}&kode_mata_kuliah=${kode_mata_kuliah}&mata_kuliah=${mata_kuliah}&kelas=${kelas}&kelas_ujian=${kelas_ujian}`,
    headers
  );

export const getNilaiHuruf = () => api.get(RESOURCE + `/nilai-huruf`, headers);

export const getInstrumenNilai = () =>
  api.get(RESOURCE + `/instrumen`, headers);

export const getJadwalPengisianNilai = () =>
  api.get(RESOURCE + `/jadwal`, headers);

export const getJadwalMataKuliahDosen = (nidn, tahun_semester) =>
  api.get(
    RESOURCE +
      `/list-mata-kuliah?nidn=${nidn}&tahun_semester=${tahun_semester}`,
    headers
  );

export const getJadwalUASDosen = (nidn, tahun_semester) =>
  api.get(
    RESOURCE +
      `/list-mata-kuliah-uas?nidn=${nidn}&tahun_semester=${tahun_semester}`,
    headers
  );

export const getMonitoring = (tahun_semester, program_studi) =>
  api.get(
    RESOURCE +
      `/monitoring?tahun_semester=${tahun_semester}&program_studi=${program_studi}`,
    headers
  );

export const getMonitoringPencairanUTS = (tahun_semester) =>
  api.get(
    RESOURCE + `/pencairan-uts?tahun_semester=${tahun_semester}`,
    headers
  );

export const getMonitoringPencairanUTSSusulan = (tahun_semester) =>
  api.get(
    RESOURCE + `/pencairan-uts-susulan?tahun_semester=${tahun_semester}`,
    headers
  );

export const getMonitoringAkhir = (tahun_semester) =>
  api.get(
    RESOURCE + `/monitoring-akhir?tahun_semester=${tahun_semester}`,
    headers
  );

export const postNilaiPesertaUts = (data) =>
  api.post(RESOURCE + `/uts`, data, headers);

export const postNilaiPesertaUas = (data) =>
  api.post(RESOURCE + `/akhir`, data, headers);

export const postNilaiHuruf = (data) =>
  api.post(RESOURCE + `/nilai-huruf`, data, headers);

export const postInstrumenNilai = (data) =>
  api.post(RESOURCE + `/instrumen`, data, headers);

export const postKunciPenilaianUTS = (data) =>
  api.post(RESOURCE + `/kunci-penilaian-uts`, data, headers);

export const postBukaPenilaianUTS = (data) =>
  api.post(RESOURCE + `/buka-penilaian-uts`, data, headers);

export const postBukaPenilaianUTSSusulan = (data) =>
  api.post(RESOURCE + `/uts-susulan/buka`, data, headers);

export const postKunciPenilaianUAS = (data) =>
  api.post(RESOURCE + `/kunci-penilaian-uas`, data, headers);

export const postBukaPenilaianUAS = (data) =>
  api.post(RESOURCE + `/buka-penilaian-uas`, data, headers);

export const postJadwalPenilaian = (data) =>
  api.post(RESOURCE + `/jadwal`, data, headers);

export const downloadDPUTS = (data) =>
  api.post(RESOURCE + `/download`, data, {
    ...headers,
    responseType: "arraybuffer",
  });

export const downloadDPNA = (data) =>
  api.post(RESOURCE + `/download-dpna`, data, {
    ...headers,
    responseType: "arraybuffer",
  });

export const putNilaiHuruf = (data) =>
  api.put(RESOURCE + `/nilai-huruf`, data, headers);

export const putInstrumenNilai = (data) =>
  api.put(RESOURCE + `/instrumen`, data, headers);

export const putJadwalPenilaian = (data) =>
  api.put(RESOURCE + `/jadwal`, data, headers);

export const postValidasiPenilaianUTS = (data) =>
  api.post(RESOURCE + `/validasi-uts`, data, headers);

export const getNilaiUts = (tahun_semester, nim) =>
  api.get(
    RESOURCE + `/nilai-uts?tahun_semester=${tahun_semester}&nim=${nim}`,
    headers
  );

// UTS SUSULAN
export const getUTSSusulan = (nidn, tahun_semester) =>
  api.get(
    RESOURCE + `/uts-susulan?nidn=${nidn}&tahun_semester=${tahun_semester}`,
    headers
  );

export const getDetailUTSSusulan = (
  tahun_semester,
  kode_mata_kuliah,
  mata_kuliah,
  kelas,
  kelas_ujian
) =>
  api.get(
    RESOURCE +
      `/uts-susulan/detail?tahun_semester=${tahun_semester}&kode_mata_kuliah=${kode_mata_kuliah}&mata_kuliah=${mata_kuliah}&kelas=${kelas}&kelas_ujian=${kelas_ujian}`,
    headers
  );

export const getMonitoringUTSSusulan = (tahun_semester, program_studi) =>
  api.get(
    RESOURCE +
      `/uts-susulan/monitoring?tahun_semester=${tahun_semester}&program_studi=${program_studi}`,
    headers
  );

export const postSimpanUTSSusulan = (data) =>
  api.post(RESOURCE + `/uts-susulan/simpan`, data, headers);

export const postKunciUTSSusulan = (data) =>
  api.post(RESOURCE + `/uts-susulan/kunci`, data, headers);

export const postValidasiUTSSusulan = (data) =>
  api.post(RESOURCE + `/uts-susulan/validasi-uts`, data, headers);

export const downloadDPUTSSusulan = (data) =>
  api.post(RESOURCE + `/uts-susulan/unduh`, data, {
    ...headers,
    responseType: "arraybuffer",
  });

export const getNilaiUtsSusulan = (tahun_semester, nim) =>
  api.get(
    RESOURCE + `/nilai-uts-susulan?tahun_semester=${tahun_semester}&nim=${nim}`,
    headers
  );
