import {api, headers} from './api';

const SOURCE = 'dm_luaran_tambahan';

export const indexLuaranTambahan = ()=> api.get(SOURCE, headers);

export const dataLuaranTambahan = ()=> api.get(SOURCE+'/data', headers);

export const showLuaranTambahan = (id)=> api.get(SOURCE+'/'+id, headers);

export const storeLuaranTambahan = (data)=> api.post(SOURCE, data, headers);

export const deleteLuaranTambahan = (id)=> api.delete(SOURCE+'/'+id, headers);