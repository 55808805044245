<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <div class="row rounded">
        <div class="col-12 col-md-8">
          <card-basic>
            <template #content>
              <div
                id="kartu-header"
                class="p-5 d-flex align-items-center justify-content-between"
              >
                <div id="judul">
                  <span class="fw-bolder fs-5">Informasi Mata Kuliah</span>
                </div>
              </div>
              <div class="px-5 pb-5" id="tabel">
                <tr>
                  <td>Kode Mata Kuliah</td>
                  <td>:</td>
                  <td>{{ matkul.kode }}</td>
                </tr>
                <tr>
                  <td>Nama Mata Kuliah</td>
                  <td>:</td>
                  <td>{{ matkul.nama }}</td>
                </tr>
                <tr>
                  <td>Kelas</td>
                  <td>:</td>
                  <td>{{ matkul.kelas }}</td>
                </tr>
                <tr>
                  <td>Kelas Ujian</td>
                  <td>:</td>
                  <td>{{ matkul.kelas_ujian }}</td>
                </tr>
                <tr>
                  <td>Jumlah Simpan</td>
                  <td>:</td>
                  <td>
                    {{ nilai.jml_simpan }}
                  </td>
                </tr>
                <tr>
                  <td>Dosen Mengunci Nilai</td>
                  <td>:</td>
                  <td>
                    <span v-if="nilai.dikunci" class="text-success">
                      <icon-done />
                    </span>
                    <span v-else class="text-danger"><icon-cross /></span>
                  </td>
                </tr>
                <tr>
                  <td>Validasi Program Studi</td>
                  <td>:</td>
                  <td>
                    <span v-if="nilai.divalidasi" class="text-success">
                      <icon-done />
                    </span>
                    <span v-else class="text-danger"><icon-cross /></span>
                  </td>
                </tr>
                <tr>
                  <td>DPUAS</td>
                  <td></td>
                  <td>
                    <a
                      class="mt-5 mt-lg-0 btn btn-sm btn-secondary"
                      @click="uasDownloadAdd"
                    >
                      Download DPNA
                    </a>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#bobot_nilai"
                      type="button"
                      class="btn btn-primary btn-sm"
                    >
                      Bobot Nilai
                    </button>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <a
                      class="mt-5 mt-lg-0 btn btn-sm btn-info"
                      href="https://s.id/PanduanDosenPenilaianHasilBelajar"
                      target="_blank"
                    >
                      Panduan Pengisian
                    </a>
                  </td>
                </tr>
              </div>
            </template>
          </card-basic>
        </div>
        <div class="col">
          <card-basic>
            <template #content>
              <div id="kartu-header" class="p-5">
                <div id="judul">
                  <span class="fw-bolder fs-5">Aksi</span>
                </div>
                <small class="form-text text-danger">
                  *Simpan minimal 1x untuk dapat mengunci
                </small>
              </div>
              <div class="px-5 pb-5 d-grid gap-2" id="tombol-aksi">
                <a class="btn btn-sm btn-success" @click="uasSimpanAdd">
                  Simpan
                </a>
                <a
                  class="btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kunci_penilaian_uas"
                >
                  Kunci
                </a>
              </div>
            </template>
          </card-basic>
        </div>
      </div>

      <card-basic>
        <template #content>
          <!-- START CARD INFORMATION -->
          <alert-icon v-if="status.empty" type="primary">
            <template #body>
              <span>
                <span>
                  Jadwal Penilaian Ujian Akhir Semester (UAS) belum dibuat.
                </span>
              </span>
            </template>
          </alert-icon>

          <alert-icon v-if="status.buka" type="primary">
            <template #body>
              <span>
                <span>Penilaian Ujian Akhir Semester (UAS) dibuka pada </span>
                <span class="text-success fw-bold">
                  {{
                    $date(status.tgl_buka)
                      .locale("id")
                      .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                  }}</span
                >
                <span> s.d. </span>
                <span class="text-danger fw-bold">
                  {{
                    $date(status.tgl_tutup)
                      .locale("id")
                      .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                  }}
                </span>
              </span>
            </template>
          </alert-icon>

          <alert-icon v-if="status.berlangsung" type="warning">
            <template #body>
              <span>
                <span>
                  Penilaian Ujian Akhir Semester (UAS) sedang berlangsung dari
                </span>
                <span class="text-success fw-bold">
                  {{
                    $date(status.tgl_buka)
                      .locale("id")
                      .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                  }}
                </span>
                <span> s.d. </span>
                <span class="text-danger fw-bold">
                  {{
                    $date(status.tgl_tutup)
                      .locale("id")
                      .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                  }}
                </span>
              </span>
            </template>
          </alert-icon>

          <alert-icon v-if="status.tutup" type="danger">
            <template #body>
              <span>
                <span>
                  Penilaian Ujian Akhir Semester (UAS) telah ditutup pada
                </span>
                <span class="text-danger fw-bold">
                  {{
                    $date(status.tgl_tutup)
                      .locale("id")
                      .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                  }}
                </span>
              </span>
            </template>
          </alert-icon>
          <!-- END CARD INFORMATION -->
          <div id="kartu-header" class="p-5 d-flex align-items-center">
            <div id="tombol-kembali" class="me-3">
              <button-icon
                @click="$router.go(-1)"
                name="Kembali"
                class="btn-sm btn-secondary"
              >
                <template #icon>
                  <icon-arrow-left />
                </template>
              </button-icon>
            </div>
            <div id="judul">
              <span class="text-gray-700 mb-2 fw-normal fs-8 d-block">
                Penilaian Hasil Belajar
              </span>
              <span class="fw-bolder fs-5"> Ujian Akhir Semester (UAS) </span>
            </div>
          </div>
          <div id="pencarian" class="mx-5 mb-5">
            <form-icon>
              <template #icon>
                <icon-search />
              </template>

              <template #content>
                <input
                  type="text"
                  class="form-control ps-14 fw-normal"
                  placeholder="Pencarian berdasarkan NIM atau Nama Mahasiswa"
                  v-model="filters.mahasiswa"
                  @keydown.enter="onSearchMahasiswa"
                />
              </template>
            </form-icon>
            <small class="form-text text-muted">
              Tekan Enter untuk memulai pencarian
            </small>
          </div>
          <div id="tabel">
            <table-basic v-if="data.result > 0">
              <template #thead>
                <tr class="fw-bolder bg-light fs-7">
                  <th class="rounded-start w-50px ps-3">No</th>
                  <th class="min-w-100px">NIM & Nama</th>
                  <th class="min-w-80px">UTS</th>
                  <th class="min-w-80px">Tugas</th>
                  <th class="min-w-80px">Absen</th>
                  <th class="min-w-80px">UAS</th>
                  <th class="min-w-80px">Nilai Akhir</th>
                  <th class="min-w-80px">Angka Mutu</th>
                  <th class="min-w-80px">Kehadiran</th>
                  <th class="rounded-end"></th>
                </tr>
              </template>
              <template #tbody>
                <tr v-for="(item, i) in data.data" :key="i">
                  <td class="text-center align-middle">{{ i + 1 }}</td>
                  <td class="align-middle">
                    <span class="text-dark fw-bolder d-block mb-1 fs-7">
                      {{ item.nama }}
                    </span>
                    <span class="text-muted text-muted d-block fs-8">
                      {{ item.nim }}
                    </span>
                  </td>
                  <td class="text-center align-middle">
                    <div class="form">
                      <input
                        type="number"
                        class="form-control"
                        placeholder="UTS"
                        v-model="item.uts"
                        @input="checkValue($event, i)"
                        disabled
                      />
                    </div>
                  </td>
                  <td class="text-center align-middle">
                    <div class="form">
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Tugas"
                        v-model="item.tugas"
                        @input="checkValue($event, i)"
                        @change="hitungNilaiAkhir(item)"
                        :disabled="checkStatusPengisian()"
                      />
                    </div>
                  </td>
                  <td class="text-center align-middle">
                    <div class="form">
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Absen"
                        v-model="item.absen"
                        @input="checkValue($event, i)"
                        disabled
                      />
                    </div>
                  </td>
                  <td class="text-center align-middle">
                    <div class="form">
                      <input
                        type="number"
                        class="form-control"
                        placeholder="UAS"
                        v-model="item.uas"
                        @input="checkValue($event, i)"
                        @change="hitungNilaiAkhir(item)"
                        :disabled="checkStatusPengisian()"
                      />
                    </div>
                  </td>
                  <td class="text-center align-middle">
                    <div class="form">
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Nilai Akhir"
                        v-model="item.nilai_akhir"
                        @input="checkValue($event, i)"
                        disabled
                      />
                    </div>
                  </td>
                  <td class="text-center align-middle">
                    <div class="form">
                      <input
                        class="form-control"
                        placeholder="Mutu"
                        v-model="item.nilai_huruf"
                        @input="checkValue($event, i)"
                        disabled
                      />
                    </div>
                  </td>
                  <td class="text-center align-middle">
                    <div class="form">
                      <select
                        class="form-select"
                        data-placeholder="Pilih Kehadiran"
                        data-control="select2"
                        v-model="item.kehadiran"
                        :disabled="checkStatusPengisian()"
                      >
                        <option value="Hadir">Hadir</option>
                        <option value="Sakit">Sakit</option>
                        <option value="Izin">Izin</option>
                        <option value="Kendala Administrasi">
                          Kendala Administrasi
                        </option>
                        <option value="Tanpa Keterangan">
                          Tanpa Keterangan
                        </option>
                      </select>
                    </div>
                  </td>
                  <td class="text-center align-middle">
                    <a
                      class="mt-5 mt-lg-0 btn btn-sm btn-secondary btn-active-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#logUAS"
                      @click="uasLogFetch(item.t_nilai_id)"
                    >
                      Log
                    </a>
                  </td>
                </tr>
              </template>
            </table-basic>
            <!-- START CARD EMPTY STATE -->
            <div
              v-else
              class="alert alert-primary d-flex align-items-center mx-5 p-5"
            >
              <div class="d-flex flex-column">
                <span> Data Peserta UAS Tidak Tersedia! </span>
              </div>
            </div>
            <!-- END CARD EMPTY STATE -->
          </div>
        </template>
      </card-basic>
    </template>
  </container-app>

  <!-- begin::Log Pengisian UAS -->
  <modal-medium id="logUAS" title="Log Pengisian Nilai UAS">
    <template #content>
      <div class="card table-responsive">
        <table class="table table-bordered" v-if="logs.length > 0">
          <tr class="fw-bolder bg-light fs-7">
            <th class="rounded-start text-center w-50px ps-3">No</th>
            <th>UTS</th>
            <th>Tugas</th>
            <th>Absen</th>
            <th>UAS</th>
            <th>Nilai Akhir</th>
            <th>Nilai Huruf</th>
            <th>Kehadiran</th>
            <th>Author</th>
            <th>Tanggal Perubahan</th>
          </tr>

          <tr v-for="(item, i) in logs" :key="i">
            <td class="text-center align-middle">{{ i + 1 }}</td>
            <td class="align-middle">
              <p class="text-dark d-block m-0 p-0">{{ item.uts_baru }}</p>
              <p class="text-muted d-block m-0 p-0">
                {{ item.uts_lama }}
              </p>
            </td>
            <td class="align-middle">
              <p class="text-dark d-block m-0 p-0">
                {{ item.tugas_baru }}
              </p>
              <p class="text-muted d-block m-0 p-0">
                {{ item.tugas_lama }}
              </p>
            </td>
            <td class="align-middle">
              <p class="text-dark d-block m-0 p-0">
                {{ item.absen_baru }}
              </p>
              <p class="text-muted d-block m-0 p-0">
                {{ item.absen_lama }}
              </p>
            </td>
            <td class="align-middle">
              <p class="text-dark d-block m-0 p-0">
                {{ item.uas_baru }}
              </p>
              <p class="text-muted d-block m-0 p-0">
                {{ item.uas_lama }}
              </p>
            </td>
            <td class="align-middle">
              <p class="text-dark d-block m-0 p-0">
                {{ item.akhir_baru }}
              </p>
              <p class="text-muted d-block m-0 p-0">
                {{ item.akhir_lama }}
              </p>
            </td>
            <td class="align-middle">
              <p class="text-dark d-block m-0 p-0">
                {{ item.huruf_baru }}
              </p>
              <p class="text-muted d-block m-0 p-0">
                {{ item.huruf_lama }}
              </p>
            </td>
            <td class="align-middle">
              <p class="text-dark d-block m-0 p-0">
                {{ item.kehadiran_baru }}
              </p>
              <p class="text-muted d-block m-0 p-0">
                {{ item.kehadiran_lama }}
              </p>
            </td>
            <td class="align-middle">
              {{ item.author }}
            </td>
            <td class="rounded-end align-middle">
              {{ item.timestamp }}
            </td>
          </tr>
        </table>
      </div>
    </template>
  </modal-medium>
  <!-- end::Log Pengisian UAS  -->

  <!-- begin::Kunci Penilaian UAS -->
  <div class="modal fade" tabindex="-1" id="kunci_penilaian_uas">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Kunci Penilaian</h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <icon-close />
          </div>
        </div>
        <div class="modal-body">
          <div>
            <h4 class="text-center">
              Apakah anda yakin untuk melakukan kunci penilaian? Nilai tidak
              dapat diubah apabila sudah dikunci.
            </h4>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Batal
          </button>
          <button type="button" class="btn btn-success" @click="uasKunciAdd">
            Kunci
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end::Kunci Penilaian UAS  -->

  <!-- START Modal Bobot Nilai -->
  <div class="modal fade" tabindex="-1" id="bobot_nilai">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Bobot & Instrumen Penilaian</h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <icon-close />
          </div>
        </div>
        <div class="modal-body">
          <div class="mb-6 p-3 border">
            <label-header class="mb-5" value="Bobot Nilai" />

            <table-basic>
              <template #thead>
                <tr class="fw-bolder bg-light fs-7">
                  <th class="ps-4 w-50px rounded-start">No</th>
                  <th class="min-w-100px">Nilai Huruf</th>
                  <th class="rounded-end min-w-100px">Rentang Nilai</th>
                </tr>
              </template>

              <template #tbody>
                <tr v-for="(item, i) in daftarNilaiHuruf" :key="i">
                  <td class="text-center align-middle">{{ i + 1 }}</td>
                  <td class="align-middle">
                    {{ item.nilai_huruf }}
                  </td>
                  <td class="align-middle">
                    {{ item.batas_bawah }} {{ item.batas_bawah_simbol }}
                    {{ item.nilai_huruf }} {{ item.batas_atas_simbol }}
                    {{ item.batas_atas }}
                  </td>
                </tr>
              </template>
            </table-basic>
          </div>
          <div class="p-3 border">
            <label-header class="mb-5" value="Instrumen Penilaian" />
            <table-basic>
              <template #thead>
                <tr class="fw-bolder bg-light fs-7">
                  <th class="ps-4 w-50px rounded-start">No</th>
                  <th class="min-w-100px">Nama Instrumen Nilai</th>
                  <th class="rounded-end min-w-100px text-center">
                    Persentase
                  </th>
                </tr>
              </template>

              <template #tbody>
                <tr v-for="(item, i) in daftarInstrumentNilai" :key="i">
                  <td class="text-center align-middle">{{ i + 1 }}</td>
                  <td class="align-middle">
                    {{ item.instrumen_nilai }}
                  </td>
                  <td class="align-middle text-center">
                    {{ item.persentase }}%
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td class="fw-bolder">Total</td>
                  <td class="fw-bolder align-middle text-center">
                    {{ totalPersentase }}%
                  </td>
                  <td></td>
                </tr>
              </template>
            </table-basic>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END Modal Bobot Nilai -->
</template>

<script>
// API
import {
  uasCekJadwalStatus,
  uasDetail,
  uasSimpan,
  uasLog,
  uasKunci,
  uasDownload,
} from "../../apis/PenilaianHasilBelajar/uasSusulan";

import { getNilaiHuruf, getInstrumenNilai } from "@/apis/penilaianUjian";

// Store
import { useMatkulStore } from "@/stores/matkul";

export default {
  async mounted() {
    await this.fetchInstrumenNilai();
    await this.fetchNilaiHuruf();
    await this.statusFetch();
    await this.uasDetailFetch(this.matkul);
  },
  setup() {
    const matkul = useMatkulStore();
    matkul.restoreFromLocalStorage();
    return {
      matkul,
    };
  },
  data() {
    return {
      container_loading: false,
      selected: {},
      status: {},
      filters: {},
      nilai: {
        jml_simpan: 0,
        jml_kunci: 0,
        dikunci: false,
        divalidasi: false,
        dibuka: false,
      },
      data: [],
      logs: [],
      daftarNilaiHuruf: [],
      daftarInstrumentNilai: [],
    };
  },

  methods: {
    async statusFetch() {
      this.container_loading = true;
      const { data } = await uasCekJadwalStatus(this.matkul.tahun_semester);

      if (data.success) {
        this.status = data.data;
        this.container_loading = false;
      } else {
        this.status = data.data;
        this.container_loading = false;
      }
    },
    async uasDetailFetch(params, filters = {}) {
      const { tahun_semester, kode, kelas, kelas_ujian } = params;

      const { mahasiswa } = filters;

      const queryParams = {
        tahun_semester,
        kode_mata_kuliah: kode,
        kelas,
        kelas_ujian,
        mahasiswa,
      };

      this.container_loading = true;
      const { data } = await uasDetail(queryParams);

      if (data.success) {
        data.data.forEach((element) => {
          if (!element.kehadiran) {
            element.kehadiran = "Hadir";
          }
          // if (!element.tugas) {
          //   element.tugas = 0;
          // }
          // if (!element.absen) {
          //   element.absen = 0;
          // }
          // if (!element.uas) {
          //   element.uas = 0;
          // }

          this.hitungNilaiAkhir(element);
        });

        this.data = data;
        const { jml_simpan, jml_kunci, dikunci, divalidasi, dibuka } =
          data.data[0];
        this.nilai = {
          jml_simpan: jml_simpan,
          jml_kunci: jml_kunci,
          dikunci: dikunci,
          divalidasi: divalidasi,
          dibuka: dibuka,
        };

        this.container_loading = false;
      } else {
        this.data = null;
        this.container_loading = false;
      }
    },
    async uasSimpanAdd() {
      try {
        const swalLoading = Swal.fire({
          title: "Menyimpan nilai UAS",
          allowOuasideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const nilai_peserta = this.data.data
          .map((item) => ({
            nim: item.nim,
            uts: item.uts,
            tugas: item.tugas,
            absen: item.absen,
            uas: item.uas,
            nilai_akhir: item.nilai_akhir,
            nilai_huruf: item.nilai_huruf,
            kehadiran: item.kehadiran,
          }))
          .filter(
            (item) =>
              item.tugas !== null || item.absen !== null || item.uas !== null
          );

        const response = await uasSimpan({
          tahun_semester: this.matkul.tahun_semester,
          kode_mata_kuliah: this.matkul.kode,
          kelas: this.matkul.kelas,
          kelas_ujian: this.matkul.kelas_ujian,
          jml_simpan: this.nilai.jml_simpan,
          mahasiswa: nilai_peserta,
        });

        swalLoading.close();

        if (response.status === 201) {
          await this.uasDetailFetch(this.matkul);
          Swal.fire({
            title: "Berhasil!",
            text: "Data penilaian UAS berhasil disimpan!",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal menyimpan nilai UAS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat menyimpan nilai UAS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async uasKunciAdd() {
      try {
        const swalLoading = Swal.fire({
          title: "Mengunci nilai UAS",
          allowOuasideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const nilai_peserta = this.data.data.map((item) => ({
          nim: item.nim,
          uts: item.uts,
          tugas: item.tugas,
          absen: item.absen,
          uas: item.uas,
          nilai_akhir: item.nilai_akhir,
          nilai_huruf: item.nilai_huruf,
          kehadiran: item.kehadiran,
        }));

        const response = await uasKunci({
          tahun_semester: this.matkul.tahun_semester,
          kode_mata_kuliah: this.matkul.kode,
          kelas: this.matkul.kelas,
          kelas_ujian: this.matkul.kelas_ujian,
          jml_kunci: this.nilai.jml_simpan,
          mahasiswa: nilai_peserta,
        });

        swalLoading.close();

        if (response.status === 201) {
          await this.uasDetailFetch(this.matkul);
          $("#kunci_penilaian_uas").modal("hide");
          Swal.fire({
            title: "Berhasil!",
            text: "Data penilaian UAS berhasil dikunci!",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal mengunci nilai UAS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat mengunci nilai UAS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async uasLogFetch(t_nilai_id) {
      const { data } = await uasLog(t_nilai_id);

      this.logs = data.data;
    },

    async uasDownloadAdd() {
      try {
        const swalLoading = Swal.fire({
          title: "Mengunduh Nilai UAS",
          allowOuasideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await uasDownload({
          tahun_semester: this.matkul.tahun_semester,
          kode_mata_kuliah: this.matkul.kode,
          kelas: this.matkul.kelas,
          kelas_ujian: this.matkul.kelas_ujian,
        });

        swalLoading.close();

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `dpna-sul-${this.matkul.kode}-${this.matkul.nama}-${this.matkul.kelas}-${this.matkul.kelas_ujian}.pdf`
          );
          document.body.appendChild(link);
          link.click();

          Swal.fire({
            title: "Berhasil!",
            text: "Penilaian UAS berhasil diunduh",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal mengunduh nilai UAS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat mengunduh nilai UAS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

    async fetchNilaiHuruf() {
      try {
        this.container_loading = true;
        const response = await getNilaiHuruf();
        if (response.status === 200) {
          this.daftarNilaiHuruf = response.data.data;

          this.container_loading = false;
        } else if (response.status === 404) {
          Swal.fire({
            text: response.data.message,
            icon: "warning",
          });
          this.container_loading = false;
        }
      } catch (error) {
        this.container_loading = false;
        Swal.fire({
          text: error.response.data.message,
          icon: "error",
        });
      }
    },

    async fetchInstrumenNilai() {
      try {
        this.container_loading = true;
        const response = await getInstrumenNilai();
        if (response.status === 200) {
          this.daftarInstrumentNilai = response.data.data;

          this.container_loading = false;
        } else if (response.status === 404) {
          Swal.fire({
            text: response.data.message,
            icon: "warning",
          });
          this.container_loading = false;
        }
      } catch (error) {
        this.container_loading = false;
        Swal.fire({
          text: error.response.data.message,
          icon: "error",
        });
      }
    },

    checkStatusPengisian() {
      let result = false;
      // default case
      if (this.status.berlangsung) {
        result = false;
      }

      if (this.status.berlangsung && this.nilai.dikunci) {
        result = true;
      }

      // if (this.status.berlangsung && this.nilai.dikunci && this.nilai.dibuka) {
      //   result = false;
      // }

      if (this.status.tutup) {
        result = true;
      }

      if (this.status.tutup && this.nilai.dibuka) {
        result = false;
      }

      return result;
    },

    onSearchMahasiswa() {
      this.uasDetailFetch(this.matkul, { mahasiswa: this.filters.mahasiswa });
    },
    checkValue(event, index) {
      const newValue = parseFloat(event.target.value);
      if (newValue < 0 || newValue > 100) {
        this.data.data[index].nilai = 0;
      }
    },
    hitungNilaiAkhir(item) {
      let total = 0;
      let grade = "E";

      this.daftarInstrumentNilai.forEach((instrumen) => {
        switch (instrumen.instrumen_nilai) {
          case "Absensi":
            total += item.absen * (instrumen.persentase / 100);
            break;
          case "Tugas":
            total += item.tugas * (instrumen.persentase / 100);
            break;
          case "UTS":
            total += item.uts * (instrumen.persentase / 100);
            break;
          case "UAS":
            total += item.uas * (instrumen.persentase / 100);
            break;
          default:
            break;
        }
      });

      item.nilai_akhir = total.toFixed(2);

      this.daftarNilaiHuruf.forEach((konfigurasi) => {
        const batas_bawah = parseFloat(konfigurasi.batas_bawah);
        const batas_atas = parseFloat(konfigurasi.batas_atas);
        if (
          item.nilai_akhir >= batas_bawah &&
          (item.nilai_akhir < batas_atas || item.nilai_akhir <= batas_atas)
        ) {
          grade = konfigurasi.nilai_huruf;
        }
      });

      if (item.absen < 75) {
        grade = "E";
        item.nilai_akhir = 0;
      }

      item.nilai_huruf = grade;
    },
  },
  computed: {
    totalPersentase() {
      let total = 0;
      for (let item of this.daftarInstrumentNilai) {
        total += parseInt(item.persentase);
      }
      return total;
    },
  },
};
</script>

<style></style>
