<template>
    <div>
        <card-basic>
            <template #content>
                <div class="p-5">
                    <label-icon :value="pengumuman.judul">
                        <template #icon>
                            <icon-alert />
                        </template>
                    </label-icon>
                </div>
            </template>
        </card-basic>

        <div 
            class="rounded p-1 bg-white shadow-sm base64" 
            v-html="pengumuman.deskripsi"
            >
        </div>
    </div>
</template>

<script>
export default {
    props: ['pengumuman']
}
</script>

<style>
.base64 > p > img {
    width: 100% !important;
}
</style>