<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <!-- Title Header & Filter -->
          <div class="p-5">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex">
                <div class="me-3">
                  <button-icon
                    @click="$router.go(-1)"
                    name="Kembali"
                    class="btn-sm btn-secondary"
                  >
                    <template #icon>
                      <icon-arrow-left />
                    </template>
                  </button-icon>
                </div>
                <div>
                  <h5 class="m-0">
                    <span class="text-gray-700 mb-2 fw-normal fs-8 d-block">
                      {{ title }}</span
                    >
                    <span class="fw-bolder fs-5"
                      >Detail Penilaian UTS Susulan</span
                    >
                  </h5>
                </div>
              </div>

              <div class="my-5">
                <div class="mb-0">
                  <button
                    v-if="isLocked || mataKuliah.expired"
                    @click="downloadDaftarNilaiUTS"
                    type="button"
                    class="btn btn-secondary btn-sm"
                  >
                    Unduh Laporan DPUTS
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Title Header & Filter -->
          <table-basic>
            <template #thead>
              <tr class="fw-bolder bg-light fs-7">
                <th class="rounded-start ps-4 w-50px">No.</th>
                <th>NIM & Nama</th>
                <th class="text-center">Kelas</th>
                <th class="text-center">Kelas Ujian</th>
                <th class="text-center">Ruangan</th>
                <th class="text-center min-w-150px w-150px">Nilai UTS</th>
                <th class="text-center min-w-250px w-250px">Kehadiran</th>
                <th class="rounded-end"></th>
              </tr>
            </template>

            <template #tbody>
              <tr v-for="(item, i) in data" :key="i">
                <td class="text-center ps-5">{{ i + 1 }}</td>
                <td>
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.nama }}
                  </span>
                  <span class="text-muted text-muted d-block fs-8">
                    {{ item.nim }}
                  </span>
                </td>
                <td class="text-center align-middle">
                  {{ item.kelas }}
                </td>
                <td class="text-center align-middle">
                  {{ item.kelas_ujian }}
                </td>
                <td class="text-center align-middle">
                  {{ item.ruang }}
                </td>
                <td class="text-center align-middle">
                  <div class="form-floating">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Masukkan Nilai"
                      v-model="item.nilai"
                      :disabled="
                        (item.isLocked || mataKuliah.expired) && !isOpen
                      "
                      @input="checkValue($event, i)"
                    />
                    <label for="nilai">Masukkan Nilai</label>
                  </div>
                </td>
                <td class="text-center align-middle">
                  <div class="form">
                    <select
                      class="form-select"
                      data-placeholder="Pilih Kehadiran"
                      data-control="select2"
                      v-model="item.kehadiran"
                      :disabled="
                        (item.isLocked || mataKuliah.expired) && !isOpen
                      "
                    >
                      <option value="Hadir">Hadir</option>
                      <option value="Sakit">Sakit</option>
                      <option value="Izin">Izin</option>
                      <option value="Kendala Administrasi">
                        Kendala Administrasi
                      </option>
                      <option value="Tanpa Keterangan">Tanpa Keterangan</option>
                    </select>
                  </div>
                </td>
                <td class="text-center align-middle">
                  <a
                    class="mt-5 mt-lg-0 btn btn-sm btn-secondary btn-active-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#logUTS"
                    @click="showLog(i)"
                  >
                    Log
                  </a>
                </td>
              </tr>
            </template>
          </table-basic>
          <div class="text-end p-2">
            <button
              v-if="!isLocked && isOpen"
              :disabled="mataKuliah.expired && !isOpen"
              type="button"
              class="btn btn-success ms-auto"
              @click="submitNilaiUTS"
            >
              Simpan
            </button>
            <button
              v-if="!isLocked"
              type="button"
              class="btn btn-primary ms-3"
              data-bs-toggle="modal"
              data-bs-target="#kunci_penilaian_uts"
            >
              Kunci Penilaian
            </button>
          </div>
        </template>
      </card-basic>

      <!-- begin::Kunci Penilaian UTS -->
      <div class="modal fade" tabindex="-1" id="kunci_penilaian_uts">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Kunci Penilaian</h5>
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <icon-close />
              </div>
            </div>
            <div class="modal-body">
              <div>
                <h4 class="text-center">
                  Apakah anda yakin untuk melakukan kunci penilaian? Nilai tidak
                  dapat diubah apabila sudah dikunci.
                </h4>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Batal
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="kunciPenilaianUTS()"
              >
                Kunci
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end::Kunci Penilaian UTS  -->

      <!-- begin::Log Pengisian UTS -->
      <modal-fullscreen-sm id="logUTS" title="Log Pengisian Nilai UTS">
        <template #content>
          <div class="card border mb-2" v-for="(item, i) in log" :key="i">
            <div class="card-body p-2 px-3">
              <span class="mb-1 d-block">
                Mengubah nilai UTS dari
                <span class="fw-bold">{{
                  item.old_uts ? item.old_uts : "-"
                }}</span>
                menjadi
                <span class="fw-bold">{{
                  item.new_uts ? item.new_uts : "-"
                }}</span>
              </span>
              <small class="text-muted"
                >Author: {{ item.author }} [{{ item.timestamp }}]</small
              >
            </div>
          </div>
        </template>
      </modal-fullscreen-sm>
      <!-- end::Log Pengisian UTS  -->
    </template>
  </container-app>
</template>

<script>
// STORE
import { useMataKuliahStore } from "@/stores/mataKuliah";
import { getUserData } from "@/store";

// API
import {
  getDetailUTSSusulan,
  postSimpanUTSSusulan,
  postKunciUTSSusulan,
  downloadDPUTSSusulan,
} from "@/apis/penilaianUjian";

export default {
  setup() {
    const mataKuliah = useMataKuliahStore();
    mataKuliah.restoreFromLocalStorage();
    return {
      mataKuliah,
    };
  },

  data() {
    return {
      container_loading: false,
      title: "",
      data: [],
      log: [],
      user: getUserData(),
      isButtonDisable: true,
      isLocked: false,
      isOpen: false,
      selectedRowIndex: null,
    };
  },
  mounted() {
    this.fetchPesertaUTS(
      this.mataKuliah.tahun_semester,
      this.mataKuliah.kode_mata_kuliah,
      encodeURIComponent(this.mataKuliah.mata_kuliah),
      this.mataKuliah.kelas,
      this.mataKuliah.kelas_ujian
    );
  },
  methods: {
    async fetchPesertaUTS(
      tahun_semester,
      kode_mata_kuliah,
      mata_kuliah,
      kelas,
      kelas_ujian
    ) {
      try {
        this.container_loading = true;
        const response = await getDetailUTSSusulan(
          tahun_semester,
          kode_mata_kuliah,
          mata_kuliah,
          kelas,
          kelas_ujian
        );
        if (response.status === 200) {
          this.data = response.data.data;

          this.title = response.data.data[0].mata_kuliah;
          this.isLocked = response.data.data[0].isLocked;
          this.isOpen = response.data.data[0].isOpen;

          this.container_loading = false;
        } else if (response.status === 404) {
          Swal.fire({
            text: response.data.message,
            icon: "warning",
          });
          this.container_loading = false;
        }
      } catch (error) {
        this.container_loading = false;
        Swal.fire({
          text: error.response.data.message,
          icon: "error",
        });
      }
    },

    async submitNilaiUTS() {
      try {
        const swalLoading = Swal.fire({
          title: "Menyimpan nilai UTS",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const nilaiPesertaUts = this.data
          .map((item) => ({
            nim: item.nim,
            nilai: item.nilai,
            kehadiran: item.kehadiran,
          }))
          .filter((item) => item.nilai !== null);

        const response = await postSimpanUTSSusulan({
          tahun_semester: this.mataKuliah.tahun_semester,
          kode_mata_kuliah: this.mataKuliah.kode_mata_kuliah,
          mata_kuliah: this.mataKuliah.mata_kuliah,
          kelas: this.mataKuliah.kelas,
          kelas_ujian: this.mataKuliah.kelas_ujian,
          nilai_peserta_uts: nilaiPesertaUts,
        });

        swalLoading.close();

        if (response.status === 201) {
          this.fetchPesertaUTS(
            this.mataKuliah.tahun_semester,
            this.mataKuliah.kode_mata_kuliah,
            encodeURIComponent(this.mataKuliah.mata_kuliah),
            this.mataKuliah.kelas,
            this.mataKuliah.kelas_ujian
          );
          Swal.fire({
            title: "Berhasil!",
            text: "Data penilaian UTS berhasil disimpan",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal menyimpan nilai UTS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat menyimpan nilai UTS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

    async kunciPenilaianUTS() {
      try {
        const swalLoading = Swal.fire({
          title: "Mengunci nilai UTS",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const nilaiPesertaUts = this.data.map((item) => ({
          nim: item.nim,
        }));

        const response = await postKunciUTSSusulan({
          tahun_semester: this.mataKuliah.tahun_semester,
          kode_mata_kuliah: this.mataKuliah.kode_mata_kuliah,
          mata_kuliah: this.mataKuliah.mata_kuliah,
          kelas: this.mataKuliah.kelas,
          kelas_ujian: this.mataKuliah.kelas_ujian,
          nilai_peserta_uts: nilaiPesertaUts,
        });

        swalLoading.close();

        if (response.status === 201) {
          this.fetchPesertaUTS(
            this.mataKuliah.tahun_semester,
            this.mataKuliah.kode_mata_kuliah,
            encodeURIComponent(this.mataKuliah.mata_kuliah),
            this.mataKuliah.kelas,
            this.mataKuliah.kelas_ujian
          );
          $("#kunci_penilaian_uts").modal("hide");
          Swal.fire({
            title: "Berhasil!",
            text: "Data penilaian UTS berhasil disimpan",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal menyimpan nilai UTS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat menyimpan nilai UTS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

    async downloadDaftarNilaiUTS() {
      try {
        const swalLoading = Swal.fire({
          title: "Mengunduh Nilai UTS",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await downloadDPUTSSusulan({
          tahun_semester: this.mataKuliah.tahun_semester,
          kode_mata_kuliah: this.mataKuliah.kode_mata_kuliah,
          mata_kuliah: this.mataKuliah.mata_kuliah,
          kelas: this.mataKuliah.kelas,
          kelas_ujian: this.mataKuliah.kelas_ujian,
        });

        swalLoading.close();

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `dputsul-${this.mataKuliah.kode_mata_kuliah}-${this.mataKuliah.mata_kuliah}-${this.mataKuliah.kelas}-${this.mataKuliah.kelas_ujian}.pdf`
          );
          document.body.appendChild(link);
          link.click();

          Swal.fire({
            title: "Berhasil!",
            text: "Penilaian UTS berhasil diunduh",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal mengunduh nilai UTS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat mengunduh nilai UTS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

    checkValue(event, index) {
      const newValue = parseFloat(event.target.value);
      if (newValue < 0 || newValue > 100) {
        this.data[index].nilai = 0;
      }
    },

    showLog(index) {
      this.selectedRowIndex = index;
      this.log = JSON.parse(this.data[this.selectedRowIndex].log);
    },
  },
};
</script>
