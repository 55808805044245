<template>
    <div class="col-12 col-md-3 mb-4 mb-md-6">
        <div class="rounded p-1 bg-white shadow-sm h-100 bg-hover-light-primary">
            <img 
                :src="pengumuman.file" 
                style="width: 100%;"
                class="rounded"
                />
            <div class="p-2">
                <div class="d-flex">
                    <div class="symbol symbol-40px">
                        <icon-alert />
                    </div>
                    <div class="d-flex flex-column justify-content-between">
                        <span class="ms-2 fs-7 d-block mb-2">{{ pengumuman.judul }}</span>
                        <span class="ms-2 fs-8 d-block text-muted">{{ pengumuman.tanggal }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['pengumuman'],
}
</script>