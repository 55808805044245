import {api, headers} from "./api";

const RESOURCE = 'user';

export const getUser = () => api.get(RESOURCE, headers);

export const lembagaJabatan = () => api.get(RESOURCE+'/lembagaJabatan', headers);

export const updateFoto = (data) => api.post(RESOURCE+'/updateFoto', data, headers);

export const updatePassword = (data) => api.post(RESOURCE+'/updatePassword', data, headers);

export const resetPassword = (data) => api.post(RESOURCE+'/resetPassword', data, headers);

export const verifWhatsapp = (data) => api.post(RESOURCE+'/verif-wa', data, headers);