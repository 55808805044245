<template>
    <div class="row">
        <div class="col-6">
            <div class="d-flex align-items-center">
                <div class="bg-light-primary border border-1 border-dashed border-primary p-2 symbol symbol-40px">
                    <icon-list />
                </div>
                <div class="d-flex flex-column ms-4">
                    <span class="fw-bold text-muted fs-8">Beban SKS</span>
                    <span class="fw-bolder fs-7">{{ informasi.sks }}</span>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="d-flex align-items-center">
                <div class="bg-light-primary border border-1 border-dashed border-primary p-2 symbol symbol-40px">
                    <icon-chart />
                </div>
                <div class="d-flex flex-column ms-4">
                    <span class="fw-bold text-muted fs-8">IPS</span>
                    <span class="fw-bolder fs-7">{{ informasi.ips }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['informasi']
}
</script>