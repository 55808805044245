<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <div class="p-5">
            <div
              v-if="daftarDosen.length === 0"
              class="alert alert-primary d-flex align-items-center p-5"
            >
              <div class="d-flex flex-column">
                <span>Daftar Mata Kuliah belum tersedia</span>
              </div>
            </div>
            <label-header
              value="Monitoring Pengisian Nilai Ujian Tengah Semester (UTS)"
            >
              <template #control>
                <button-icon
                  name="Filter"
                  class="btn-sm btn-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#model_filter_daftar_monitoring_mata_kuliah"
                >
                  <template #icon>
                    <icon-filter />
                  </template>
                </button-icon>
              </template>
            </label-header>
          </div>
          <table-basic>
            <template #thead>
              <tr class="fw-bolder bg-light fs-7">
                <th class="ps-4 w-50px rounded-start text-center">No</th>
                <th class="min-w-50px text-center">Dosen</th>
                <th class="min-w-50px text-center"></th>
              </tr>
            </template>

            <template #tbody>
              <tr v-for="(item, i) in Object.keys(daftarDosen)" :key="i">
                <td class="text-center">{{ i + 1 }}</td>
                <td class="">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item }}
                  </span>
                </td>
                <td class="align-middle">
                  <table-basic
                    class="table table-rounded table-row-bordered border"
                  >
                    <template #thead>
                      <tr class="fw-bolder bg-light fs-7">
                        <th class="ps-4 w-50px rounded-start text-center">
                          No
                        </th>
                        <th class="min-w-50px text-center">Mata Kuliah</th>
                        <th class="min-w-50px text-center">Kelas</th>
                        <th class="min-w-50px text-center">Kelas Ujian</th>
                      </tr>
                    </template>
                    <template #tbody>
                      <tr v-for="(item2, i) in daftarDosen[item]">
                        <td class="text-center">{{ i + 1 }}</td>
                        <td>
                          <span class="text-dark fw-bolder d-block mb-1 fs-7">
                            {{ item2.NAKMK }}
                          </span>
                          <span class="text-muted text-muted d-block fs-8">
                            {{ item2.NMPST }}
                          </span>
                        </td>
                        <td class="text-center align-middle">
                          {{ item2.KELAS }}
                        </td>
                        <td class="align-middle">
                          <div class="d-flex flex-column">
                            <li
                              class="d-flex align-items-center"
                              v-for="(item3, index) in item2.kelas_ujian"
                              :key="index"
                            >
                              Kelas Ujian:
                              {{ item3 }}
                              Nilai diisi:
                              {{ item2.kelas_ujian_info[item3].nilai_diisi }}
                              Dosen Mengunci Nilai:
                              <span
                                v-if="item2.kelas_ujian_info[item3].isLocked"
                                class="text-success"
                              >
                                <icon-done />
                              </span>
                              <span v-else class="text-danger"
                                ><icon-cross /></span
                              >Validasi Program Studi:
                              <span
                                v-if="item2.kelas_ujian_info[item3].isValidated"
                                class="text-success"
                              >
                                <icon-done />
                              </span>
                              <span v-else class="text-danger">
                                <icon-cross />
                              </span>
                            </li>
                          </div>
                        </td>
                        <td class="text-center align-middle"></td>
                      </tr>
                    </template>
                  </table-basic>
                </td>
              </tr>
            </template>
          </table-basic>
        </template>
      </card-basic>
      <div
        class="modal fade"
        tabindex="-1"
        id="model_filter_daftar_monitoring_mata_kuliah"
      >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                Filter Monitoring Pengisian Nilai Mata Kuliah
              </h5>
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <icon-close />
              </div>
            </div>
            <div class="modal-body">
              <div class="row mb-3">
                <label class="form-label fs-7 fw-normal col-form-label col-md-5"
                  >Semester</label
                >
                <div class="col-md-7">
                  <select
                    class="form-select form-select-solid"
                    data-placeholder="Select an option"
                  >
                    <option value="All">Semua</option>
                    <option value="Semester 1">Semester 1</option>
                    <option value="Semester 2">Semester 2</option>
                    <option value="Semester 3">Semester 3</option>
                    <option value="Semester 4">Semester 4</option>
                    <option value="Semester 5">Semester 5</option>
                    <option value="Semester 6">Semester 6</option>
                    <option value="Semester 7">Semester 7</option>
                    <option value="Semester 8">Semester 8</option>
                  </select>
                </div>
              </div>

              <div class="row mb-3">
                <label class="form-label fs-7 fw-normal col-form-label col-md-5"
                  >Program Studi</label
                >
                <div class="col-md-7">
                  <select
                    class="form-select form-select-solid"
                    data-placeholder="Select an option"
                  >
                    <option value="All">Semua</option>
                    <option value="Teknik Industri">Teknik Industri</option>
                    <option value="Teknik Sipil">Teknik Sipil</option>
                    <option value="Teknik Informatika">
                      Teknik Informatika
                    </option>
                    <option value="Sistem Informasi">Sistem Informasi</option>
                  </select>
                </div>
              </div>

              <div class="row mb-3">
                <label class="form-label fs-7 fw-normal col-form-label col-md-5"
                  >Jenis Ujian</label
                >
                <div class="col-md-7">
                  <select
                    class="form-select form-select-solid"
                    data-placeholder="Select an option"
                  >
                    <option value="All">Semua</option>
                    <option value="UTS">UTS</option>
                    <option value="UAS">UAS</option>
                  </select>
                </div>
              </div>

              <div class="row mb-3">
                <label class="form-label fs-7 fw-normal col-form-label col-md-5"
                  >Tahun Akademik</label
                >
                <div class="col-md-7">
                  <select
                    class="form-select form-select-solid"
                    data-placeholder="Select an option"
                  >
                    <option
                      v-for="(item, i) in tahunAkademik"
                      :key="i"
                      :value="item.thsms"
                    >
                      Tahun {{ item.ket }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mb-3">
                <label class="form-label fs-7 fw-normal col-form-label col-md-5"
                  >Cari Mata Kuliah berdasarkan kata kunci</label
                >
                <div class="col-md-7">
                  <input class="form-control fw-normal" type="text" required />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Tutup
              </button>
              <button type="button" class="btn btn-success">Simpan</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </container-app>
</template>

<script>
// Component
import Pagination from "../../../components/_pagination/Pagination.vue";

// API
import { getMonitoringPencairanUTS } from "@/apis/penilaianUjian";
import { getAcademicYearsLecturer } from "@/apis/kontrakPerkuliahan";

// State Management
import { useMataKuliahStore } from "@/stores/mataKuliah";

export default {
  components: {
    Pagination,
  },

  setup() {
    const mataKuliah = useMataKuliahStore();

    return {
      mataKuliah,
    };
  },
  data() {
    return {
      container_loading: false,
      daftarDosen: [],
      daftarMataKuliah: [],
      daftarKelasUjian: [],
      tahunAkademik: [],
      filter: {},
      currentPage: 1,
    };
  },

  async mounted() {
    await this.fetchTahunAkademik();
    await this.fetchMonitoringMataKuliah(this.filter.tahunAkademik);
  },

  methods: {
    async onFilterChange(event) {},
    async fetchMonitoringMataKuliah(tahun_semester) {
      try {
        this.container_loading = true;
        const response = await getMonitoringPencairanUTS(tahun_semester);
        if (response.status === 200) {
          this.daftarDosen = response.data.data;

          this.container_loading = false;
        } else if (response.status === 404) {
          Swal.fire({
            text: response.data.message,
            icon: "warning",
          });
          this.container_loading = false;
        }
      } catch (error) {
        this.container_loading = false;
        Swal.fire({
          text: error,
          icon: "error",
        });
      }
    },

    async fetchTahunAkademik() {
      try {
        const response = await getAcademicYearsLecturer();
        this.tahunAkademik = response.data.data;
        this.filter.tahunAkademik = response.data.data[0].thsms;
      } catch (err) {
        console.error(err);
      }
    },

    getDetailUTS(
      tahun_semester,
      kode_mata_kuliah,
      mata_kuliah,
      kelas,
      kelas_ujian
    ) {
      this.mataKuliah.set(
        tahun_semester,
        kode_mata_kuliah,
        mata_kuliah,
        kelas,
        kelas_ujian
      );
      this.$router.push({
        path: `/penilaian-uts/monitoring/detail`,
      });
    },

    onPageChange(page) {
      this.currentPage = page;
    },
  },
};
</script>
