<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <div class="p-5">
            <alert-icon
              v-if="jadwal_kontrak && !jadwal_kontrak.is_expired"
              type="warning"
            >
              <template #body>
                <span>
                  Pengisian Kontrak Perkuliahan berlangsung dari
                  <span class="fw-bold">
                    {{
                      $date(jadwal_kontrak.upload_start)
                        .locale("id")
                        .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                    }}
                  </span>
                  hingga
                  <span class="fw-bold">
                    {{
                      $date(jadwal_kontrak.upload_deadline)
                        .locale("id")
                        .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                    }}
                  </span>
                </span>
              </template>
            </alert-icon>
            <alert-icon
              v-else-if="jadwal_kontrak && jadwal_kontrak.is_expired"
              type="danger"
            >
              <template #body>
                <span>
                  Pengisian Kontrak Perkuliahan sudah ditutup pada
                  <span class="fw-bold">
                    {{
                      $date(jadwal_kontrak.upload_deadline)
                        .locale("id")
                        .format("dddd, D MMMM YYYY [pukul] HH:mm [WIB]")
                    }}
                  </span>
                </span>
              </template>
            </alert-icon>
            <alert-icon v-else type="primary">
              <template #body>
                <span>
                  Jadwal Pengisian Kontrak Perkuliahan belum dibuat.
                </span>
              </template>
            </alert-icon>
            <label-header value="Kontrak Perkuliahan">
              <template #control>
                <select
                  class="form-select form-select-solid"
                  data-control="selected_tahun_semester"
                  data-placeholder="Select an option"
                  v-model="selected_tahun_semester"
                  @change="onTahunSemesterChange($event)"
                >
                  <option
                    v-for="(item, i) in tahun_semester"
                    :key="i"
                    :value="item.thsms"
                  >
                    Tahun {{ item.ket }}
                  </option>
                </select>
              </template>
            </label-header>
          </div>
          <table-basic>
            <template #thead>
              <tr class="fw-bolder bg-light fs-7">
                <th class="ps-4 w-50px rounded-start">No</th>
                <th class="min-w-100px">Mata Kuliah</th>
                <th class="min-w-100px">Kelas</th>
                <th class="min-w-100px">Dosen</th>
                <th class="min-w-100px">Status</th>
                <th class="rounded-end"></th>
              </tr>
            </template>

            <template #tbody>
              <tr v-for="(item, i) in daftar_mata_kuliah" :key="i">
                <td class="text-center align-middle">{{ i + 1 }}</td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.nama_mata_kuliah }}
                  </span>
                  <span class="text-muted text-muted d-block fs-8">
                    {{ item.kode_mata_kuliah }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.kelas }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.nama_dosen }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bold d-block mb-1 fs-7">
                    Dosen Unggah RPS:
                    <span v-if="item.is_upload" class="text-success">
                      <icon-done /> |
                      {{
                        $date(item.upload_at)
                          .locale("id")
                          .format("D-MM-YYYY HH:mm")
                      }}
                    </span>
                    <span v-else class="text-danger"><icon-cross /></span>
                  </span>
                  <span class="text-dark fw-bold d-block mb-1 fs-7">
                    Mahasiswa Mengisi Kontrak:
                    <span v-if="item.is_approved" class="text-success">
                      <icon-done /> |
                      {{
                        $date(item.approved_at)
                          .locale("id")
                          .format("D-MM-YYYY HH:mm")
                      }}
                    </span>
                    <span v-else class="text-danger"><icon-cross /></span>
                  </span>
                </td>
                <td class="align-middle">
                  <a
                    v-if="
                      item.doc_rps &&
                      !item.is_approved &&
                      !jadwal_kontrak.is_expired
                    "
                    class="btn btn-sm btn-secondary btn-active-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#contract_modal"
                    @click="selected_id = item.id"
                  >
                    Isi Kontrak Perkuliahan
                  </a>
                  <a
                    v-if="item.doc_rps"
                    :href="`${baseUrl}public/${item.doc_rps}`"
                    target="_blank"
                    class="mt-5 ms-md-3 mt-md-0 btn btn-sm btn-secondary btn-active-primary"
                  >
                    Lihat RPS
                  </a>
                </td>
              </tr>
            </template>
          </table-basic>
        </template>
      </card-basic>
      <!-- CONTRACT MODAL -->
      <div class="modal fade" tabindex="-1" id="contract_modal">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Kontrak Perkuliahan</h5>

              <!--begin::Close-->
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <icon-close />
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body">
              <p>
                Dengan ini Saya menyatakan menyepakati isi kontrak perkuliahan
                yang terdiri dari:
              </p>

              <div class="d-flex flex-column">
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.capaianPembelajaran"
                      true-value="yes"
                      false-value="no"
                      id="capaianPembelajaran"
                    />
                    <label class="form-check-label" for="capaianPembelajaran">
                      Capaian Pembelajaran</label
                    >
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.rps"
                      true-value="yes"
                      false-value="no"
                      id="rps"
                    />
                    <label class="form-check-label" for="rps">
                      Rencana Pembelajaran Semester (RPS)
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.bahanAjar"
                      true-value="yes"
                      false-value="no"
                      id="bahanAjar"
                    />
                    <label class="form-check-label" for="bahanAjar">
                      Bahan Ajar
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.kisiPenilaian"
                      true-value="yes"
                      false-value="no"
                      id="kisiPenilaian"
                    />
                    <label class="form-check-label" for="kisiPenilaian">
                      Kisi-kisi Penilaian
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.pedomanPraktik"
                      true-value="yes"
                      false-value="no"
                      id="pedomanPraktik"
                    />
                    <label class="form-check-label" for="pedomanPraktik">
                      Pedoman Praktikum/Praktik*
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.pedomanPenugasan"
                      id="pedomanPenugasan"
                      true-value="yes"
                      false-value="no"
                    />
                    <label class="form-check-label" for="pedomanPenugasan">
                      Pedoman Penugasan
                    </label>
                  </div>
                </li>
                <li class="d-flex align-items-center py-2">
                  <span class="bullet me-5"></span>
                  <div
                    class="form-check form-check-custom form-check-success form-check-solid"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="checkbox.peraturanPerkuliahan"
                      true-value="yes"
                      false-value="no"
                      id="peraturanPerkuliahan"
                    />
                    <label class="form-check-label" for="peraturanPerkuliahan">
                      Peraturan Perkuliahan
                    </label>
                  </div>
                </li>
              </div>
              <div class="separator my-5"></div>
              <p class="fw-bolder text-wrap">
                Sejak saat submit kontrak perkuliahan ini, maka segala tugas dan
                kewajiban perkuliahan yang tertera di dalam isi kontrak
                perkuliahan ini akan menjadi tanggung jawab bersama dosen dan
                seluruh mahasiswa.
              </p>
              <div
                class="form-check form-check-custom form-check-success form-check-solid form-check-sm"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="checkbox.confirmCheck"
                  id="confirmCheck"
                  true-value="yes"
                  false-value="no"
                />
                <label class="form-check-label fw-bolder" for="confirmCheck">
                  <span class="text-danger">*</span>Saya menyetujui kontrak
                  perkuliahan
                </label>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Tutup
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="postValidasiMahasiswa(selected_id)"
                :disabled="isButtonDisable"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- END CONTRACT MODAL -->
    </template>
  </container-app>
</template>

<script>
// API
import { getsTahunAkademik } from "@/apis/tahun";

import {
  getJadwalKontrak,
  getMahasiswaMataKuliah,
  postValidasiMahasiswaKontrak,
} from "@/apis/kontrakPerkuliahanBaru";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      container_loading: false,
      jadwal_kontrak: null,
      tahun_semester: [],
      daftar_mata_kuliah: [],
      selected_tahun_semester: "",
      selected_id: null,
      isButtonDisable: true,
      checkbox: {
        capaianPembelajaran: "no",
        rps: "no",
        bahanAjar: "no",
        kisiPenilaian: "no",
        pedomanPraktik: "no",
        pedomanPenugasan: "no",
        peraturanPerkuliahan: "no",
        confirmCheck: "no",
      },
    };
  },
  async mounted() {
    await this.fetchTahunSemester();
    await this.fetchJadwalKontrak(this.selected_tahun_semester);
    await this.fetchMahasiswaMataKuliah(this.selected_tahun_semester);
  },
  methods: {
    async fetchJadwalKontrak(tahun_semester) {
      try {
        this.container_loading = true;
        const { data: response } = await getJadwalKontrak(tahun_semester);
        if (response.success) {
          this.jadwal_kontrak = response.data;
        } else if (!response.success) {
          this.jadwal_kontrak = null;
        }
        this.container_loading = false;
      } catch (err) {
        this.container_loading = false;
      }
    },

    async fetchTahunSemester() {
      try {
        const response = await getsTahunAkademik();
        this.tahun_semester = response.data.data;
        this.selected_tahun_semester = response.data.data[0].thsms;
      } catch (err) {
        console.error(err);
      }
    },

    async onTahunSemesterChange(event) {
      await this.fetchJadwalKontrak(event.target.value);
      await this.fetchMahasiswaMataKuliah(event.target.value);
    },

    async fetchMahasiswaMataKuliah(tahun_semester) {
      try {
        this.container_loading = true;
        const { data: response } = await getMahasiswaMataKuliah(tahun_semester);
        if (response.success) {
          this.daftar_mata_kuliah = response.data;
        } else if (!response.success) {
          this.daftar_mata_kuliah = [];
        }
        this.container_loading = false;
      } catch (err) {
        this.container_loading = false;
      }
    },

    async postValidasiMahasiswa(id) {
      try {
        const { data: response } = await postValidasiMahasiswaKontrak({ id });
        if (response.success) {
          Swal.fire({
            text: response.message,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          $("#contract_modal").modal("hide");
          Object.keys(this.checkbox).forEach((key) => {
            this.checkbox[key] = "no";
          });
          await this.fetchMahasiswaMataKuliah(this.selected_tahun_semester);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  watch: {
    checkbox: {
      handler: function (newVal, oldVal) {
        this.isButtonDisable = !Object.values(newVal).every(
          (value) => value === "yes"
        );
      },
      deep: true,
    },
  },
};
</script>
