<template>
    <div class="d-flex justify-content-between align-items-center mb-0">
        <h5 class="m-0">
            <span class="text-gray-700 mb-2 fw-normal fs-8 d-block">{{ label }}</span>
            <span class="fw-bolder fs-5">{{ value }}</span>
        </h5>

        <div class="d-flex">
            <slot name="control"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['label', 'value']
}
</script>