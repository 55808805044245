<template>
    
    <container-app>
        <template #content>
            
            <card-basic>
                <template #content>

                    <table-basic>
                        <template #thead>
                            <tr class="fw-bolder bg-light fs-7">
                                <th class="rounded-start ps-5 w-md-50px">No.</th>
                                <th class="w-md-150px">Dosen</th>
                                <th>Penelitian</th>
                                <th class="rounded-end pe-5 w-md-150px">Aksi</th>
                            </tr>
                        </template>
        
                        <template #tbody>
                            <tr class="fs-7" v-for="(pengusul, i) in data_pengusul" :key="i">
                                <td class="text-end ps-5">{{ i+1 }}</td>
                                <td>
                                    <span class="fw-bold d-block">{{ pengusul.dosen_nama }}</span>
                                    {{ pengusul.dm_dosen_id }}
                                </td>
                                <td>
                                    <span class="fw-bold d-block mb-4">{{ pengusul.judul }}</span>
                                    <span class="badge badge-info me-2">{{ pengusul.jenis }}</span>
                                    <span class="badge badge-secondary">{{ pengusul.status }}</span>
                                </td>
                                <td class="pe-5">
                                    <button class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#detailPenelitian" @click="showPengusulan(pengusul.id)">Detail</button>
                                </td>
                            </tr>
                        </template>
                    </table-basic>

                </template>
            </card-basic>

        </template>
    </container-app>

    <!-- begin::detailPenelitian -->
    <modal-medium id="detailPenelitian" title="Detail Penelitian">
        <template #content>

            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link active" id="nav-detail_penelitian-tab" data-bs-toggle="tab" data-bs-target="#nav-detail_penelitian" type="button" role="tab" aria-controls="nav-detail_penelitian" aria-selected="true">Penelitian</button>
                    <button class="nav-link" id="nav-detail_anggota-tab" data-bs-toggle="tab" data-bs-target="#nav-detail_anggota" type="button" role="tab" aria-controls="nav-detail_anggota" aria-selected="true">Anggota</button>
                    <button class="nav-link" v-if="lembaga_jabatan == 'ITG REKTOR'" id="nav-detail_penilaian-tab" data-bs-toggle="tab" data-bs-target="#nav-detail_penilaian" type="button" role="tab" aria-controls="nav-detail_penilaian" aria-selected="true">Penilaian</button>
                </div>
            </nav>

            <div class="tab-content p-1 bg-white border border-secondary border-top-0" id="nav-tabContent">
                <div class="tab-pane fade show active p-4" id="nav-detail_penelitian" role="tabpanel" aria-labelledby="nav-penelitian-tab">
                    <div class="row mb-3">
                        <div class="col-sm-2">Jenis</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.jenis }}</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">Tema</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.tema }}</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">Judul</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.judul }}</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">Latar Belakang</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.latar_belakang }}</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">Tujuan</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.tujuan }}</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">Metode</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.metode }}</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">Kebaruan</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.kebaruan }}</span>
                        </div>
                    </div>
                    <div class="row mt-7">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-10">
                            <button class="btn btn-sm btn-success" v-if="pengusul.validasi_program_studi == 0" @click="validasiProgramStudi(pengusul.id)">Validasi</button>
                            <button class="btn btn-sm btn-danger ms-3" v-if="pengusul.validasi_program_studi == 0" @click="tolakPengusulan(pengusul.id)">Tolak</button>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="nav-detail_anggota" role="tabpanel" aria-labelledby="nav-anggota-tab">

                    <table-basic>
                        <template #thead>
                            <tr class="fw-bolder bg-light fs-7">
                                <th class="rounded-start ps-5 w-md-50px">No.</th>
                                <th>Anggota</th>
                            </tr>
                        </template>

                        <template #tbody>
                            <tr v-for="(anggota, i) in data_anggota" :key="i">
                                <th class="ps-5 text-end">{{ i+1 }}</th>
                                <th>{{ anggota.anggota_id }} - {{ anggota.anggota_nama }}</th>
                            </tr>
                        </template>
                    </table-basic>
                </div>

                <div class="tab-pane fade" id="nav-detail_penilaian" role="tabpanel" aria-labelledby="nav-reviewer-tab">
                    <div v-for="(nilai, i) in data_nilai" :key="i" class="border rounded mb-3 p-2">
                        <div class="d-flex justify-content-between fs-7 mb-1">
                            <span class="fw-bold text-capitalize">{{ nilai.nama_dosen }}</span>
                            <span>{{ nilai.skor }} ({{ nilai.keterangan }})</span>
                        </div>
                        <span class="badge badge-danger" v-if="nilai.kunci == 0">Penilaian belum dikunci</span>
                        <span class="badge badge-success" v-else>Penilaian sudah dikunci</span>
                    </div>

                    <div class="d-flex justify-content-end">
                        <button class="btn btn-sm btn-success mb-3" v-if="lembaga_jabatan == 'ITG REKTOR' & belum_dikunci == 0 & pengusul.validasi_rektor == 0" @click="validasiRektor(pengusul.id)">Validasi</button>
                    </div>
                </div>
            </div>

        </template>
    </modal-medium>
    <!-- end::detailPenelitian -->

</template>

<script>
import {indexPengusulan, showPengusulan, validasiPengusulan, tolakPengusulan, penilaianPengusulan} from '@/apis/pengusulan';
import {dataPengusulanAnggota} from '@/apis/pengusulanAnggota';

export default {
    beforeMount() {
        localStorage.setItem('menu', 'Penelitian dan PkM');
        localStorage.setItem('halaman', 'Validasi PPM');
    },
    data() {
        return {
            data_pengusul: {},
            pengusul: {},
            data_anggota: {},
            lembaga_jabatan: '',
            data_nilai: {},
            belum_dikunci: 1
        }
    },
    mounted() {
        this.indexPengusulan();
    },
    methods: {
        async indexPengusulan() {
            const res = await indexPengusulan();

            this.data_pengusul = res.data.data.map(e => {
                e.edit = e.ajukan == 0 ? true : false;
                e.hapus = e.ajukan == 0 ? true : false;

                return e;
            });

            if( res.data.lembaga != '-') {
                this.lembaga_jabatan = res.data.lembaga+' '+res.data.jabatan;
            }
        },  
        async showPengusulan(id) {
            this.pengusul = {};
            const res = await showPengusulan(id);

            this.pengusul = res.data.data;
            this.dataPengusulanAnggota(id);
            this.penilaianPengusulan(id);
        },
        async dataPengusulanAnggota(tr_pengusulan_id) {
            const res = await dataPengusulanAnggota(tr_pengusulan_id);

            this.data_anggota = res.data.data;
        },
        async tolakPengusulan(id) {
            const res = await tolakPengusulan(id);

            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Usulan berhasil ditolak',
                    showConfirmButton: false,
                    timer: 3000
                });

                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
        },
        async validasiPengusulan(data) {
            const res = await validasiPengusulan(data);

            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Usulan berhasil divalidasi',
                    showConfirmButton: false,
                    timer: 3000
                })
            }
        },
        async validasiProgramStudi(id) {
            this.validasiPengusulan({'id' : id, 'validasi' : 'PROGRAM_STUDI'});
        },
        async validasiRektor(id) {
            this.validasiPengusulan({'id' : id, 'validasi' : 'REKTOR'});
        },
        async penilaianPengusulan(id) {
            const res = await penilaianPengusulan(id);

            this.data_nilai = res.data.data;
            this.belum_dikunci = res.data.belum_dikunci;
        }
    }
}

</script>