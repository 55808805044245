import {api, headers} from './api';

const RESOURCE = 'pengusulan_dana';

export const storePengusulanDana = (data) => api.post(RESOURCE, data, headers);

export const dataPengusulanDana = (tr_pengusulan_id) => api.get(RESOURCE+'/data/'+tr_pengusulan_id, headers);

export const deletePengusulanDana = (id) => api.delete(RESOURCE+'/'+id, headers);

export const ajukanPengusulanDana = (id) => api.post(RESOURCE+'/ajukan/'+id, {}, headers);

export const indexPengusulanDana = (jadwal_id) => api.get(RESOURCE+'?jadwal_id='+jadwal_id, headers);

export const validasiPengusulanDana = (data) => api.post(RESOURCE+'/validasi', data, headers);

export const showPengusulanDana = (id) => api.get(RESOURCE+'/'+id, headers);

export const updateTanggalPengusulanDana = (data) => api.put(RESOURCE+'/updateTanggal', data, headers);