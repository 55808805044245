<template>
    <table-basic>
        <template #thead>
            <tr class="fw-bolder bg-light fs-7">
                <th class="ps-4 w-50px rounded-start">No</th>
                <th>Jenis Pembayaran</th>
                <th class="min-w-100px">Jumlah</th>
            </tr>
        </template>

        <template #tbody>
            <tr class="fs-7" v-for="(rincian, i) in daftar_rincian" :key="i">
                <th class="ps-4 text-end">{{ i+1 }}</th>
                <th>{{ rincian.pembayaran }}</th>
                <th class="min-w-100px">Rp. {{ rincian.jumlah }}</th>
            </tr>
        </template>
    </table-basic>
</template>

<script>
export default {
    props: ['daftar_rincian']
}
</script>