<template>
    <container-app>
        <template #content>
            <card-basic>
                <template #content>
                    <!-- filter semester -->
                    <div class="d-flex flex-column flex-md-row justify-content-between gap-3 my-5 mx-5">
                        <div class="d-flex align-items-center gap-2">
                            <label class="form-label m-0">Semester</label>
                            <select class="form-select w-auto" v-model="jadwal_id">
                            <option v-for="jadwal in data_jadwal_pengusulan" :key="jadwal.id" :value="jadwal.id">
                                {{ jadwal.semester }}
                            </option>
                            </select>
                        </div>

                        <a :href="base_url+'download/pencairan_tambahan/'+jadwal_id" target="_blank" class="btn btn-danger" v-if="lembaga_jabatan == 'LPPM STAF' || lembaga_jabatan == 'LPPM KETUA' || lembaga_jabatan == 'BAK KEPALA'">Download</a>
                    </div>

                    <!-- table -->
                    <table-basic>
                        <template #thead>
                            <tr class="fw-bolder bg-light fs-7">
                                <th class="rounded-start ps-5 w-md-50px">No.</th>
                                <th class="w-md-200px">Dosen</th>
                                <th>Judul</th>
                                <th class="w-md-200px">Nominal</th>
                                <th class="w-md-200px">Status</th>
                                <th class="rounded-end pe-5 w-md-150px">Aksi</th>
                            </tr>
                        </template>

                        <template #tbody>
                            <tr v-for="(usulan, i) in data_usulan" :key="i">
                                <td class="text-end">{{ i + 1 }}</td>
                                <td>
                                    <span class="fw-bold d-block">{{ usulan.dosen_nama }}</span>{{ usulan.dosen_nidn }}
                                </td>
                                <td>{{ usulan.judul }}</td>
                                <td>Rp. {{ usulan.nominal_pencairan.toLocaleString("id-ID") }}</td>
                                <td>
                                    <div v-if="usulan.waktu_pencairan != null" class="text-center">
                                        <span class="badge badge-success d-block mb-1">Dicairkan</span>
                                        {{ usulan.waktu_pencairan }}
                                    </div>
                                    <span v-else class="badge badge-danger d-block">Menuggu Pencairan</span>
                                </td>
                                <td>
                                    <button v-if="lembaga_jabatan == 'BAK KEPALA' && usulan.waktu_pencairan == null" class="btn btn-sm btn-primary" @click="handleCairkan(i)">
                                        Cairkan
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </table-basic>
                </template>
            </card-basic>
        </template>
    </container-app>
</template>

<script>
import { lembagaJabatan } from "@/apis/user";
import { indexJadwalPengusulan } from "@/apis/jadwalPengusulan";
import { 
    indexUsulanTambahanPencairan,
    postCairkanUsulanLuaranTambahan
} from "@/apis/usulanTambahanPencairan";

import { getUserData } from "@/store";
export default {
    data() {
        return {
            user: getUserData(),
            lembaga_jabatan: "-",
            full_akses: false,
            data_jadwal_pengusulan: [],
            jadwal_id: {},
            data_usulan: [],
            base_url: process.env.VUE_APP_BASE_URL
        }
    },

    watch: {
        jadwal_id() {
            this.indexUsulanTambahanPencairan();
        }
    },

    beforeMount() {
        localStorage.setItem("menu", "Penelitian dan PkM");
        localStorage.setItem("halaman", "Pencairan Luaran Tambahan");
        this.lembagaJabatan();
    },

    mounted() {
        this.indexJadwalPengusulan()
    },

    methods: {
        async lembagaJabatan() {
            const res = await lembagaJabatan();

            this.lembaga_jabatan = res.data.lembaga_jabatan;

            if ( this.user.akses.includes('ALL') ) {
                this.full_akses = true;
            } else {
                this.full_akses = this.akses.includes(this.lembaga_jabatan) ? true : false;
            }
        },

        async indexJadwalPengusulan() {
            const res = await indexJadwalPengusulan();

            this.data_jadwal_pengusulan = res.data.data;
            this.jadwal_id = res.data.data[0].id;
        },

        async indexUsulanTambahanPencairan() {
            const res = await indexUsulanTambahanPencairan(this.jadwal_id);

            this.data_usulan = res.data.data;
        },

        handleCairkan(index) {
            const data = this.data_usulan[index];

            Swal.fire({
                title: "Cairkan Usulan?",
                text: "Usulan Luaran Tambahan "+ data.dosen_nama +" akan dicairkan.",
                icon: "warning",
                confirmButtonText: "Cairkan",
                confirmButtonColor: "#3085d6",
                showCancelButton: true,
                cancelButtonText: 'Batal',
                cancelButtonColor: "#d33",
            }).then( async e => {
                if ( e.isConfirmed ) {
                    const res = await postCairkanUsulanLuaranTambahan(data.id);

                    if ( res.status == 200 ) {
                        Swal.fire({
                            icon: "success",
                            text: "Usulan Luaran Tambahan "+ data.dosen_nama +" berhasil dicairkan",
                            showConfirmButton: false,
                            timer: 3000,
                        });

                        this.indexUsulanTambahanPencairan();
                    }
                }
            });
        }
    }
}
</script>