<template>
  <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/keenthemes/metronic/docs/core/html/src/media/icons/duotune/general/gen034.svg-->
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.3"
      x="2"
      y="2"
      width="20"
      height="20"
      rx="5"
      fill="currentColor"
    />
    <rect
      x="7"
      y="15.3137"
      width="12"
      height="2"
      rx="1"
      transform="rotate(-45 7 15.3137)"
      fill="currentColor"
    />
    <rect
      x="8.41422"
      y="7"
      width="12"
      height="2"
      rx="1"
      transform="rotate(45 8.41422 7)"
      fill="currentColor"
    />
  </svg>
  <!--end::Svg Icon-->
</template>
