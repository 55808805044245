import axios from "axios";

const RESOURCE = 'http://hotline.itg.ac.id/api/';

export const getsJenis = ()=> axios.get(RESOURCE+'jenis');

export const getsTujuan = ()=> axios.get(RESOURCE+'tujuan');

export const storeHotline = (nim, data)=> axios.post(RESOURCE+'hotline/'+nim, data);

export const getsHotline = (nim) => axios.get(RESOURCE+'hotline/'+nim);