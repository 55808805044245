<template>
    <container-app>
        <template #content>
            <card-basic>
                <template #content>
                    <!-- filter semester -->
                    <div class="d-flex flex-column flex-md-row justify-content-between gap-3 my-5 mx-5">
                        <div class="d-flex align-items-center gap-2">
                            <label class="form-label m-0">Semester</label>
                            <select class="form-select w-auto" v-model="jadwal_id">
                                <option v-for="jadwal in data_jadwal_pengusulan" :key="jadwal.semester" :value="jadwal.semester">
                                    {{ jadwal.semester }}
                                </option>
                            </select>
                        </div>

                        <a :href="base_url+'download/pencairan_tahunan/'+tahun" target="_blank" class="btn btn-danger" v-if="lembaga_jabatan == 'LPPM STAF' || lembaga_jabatan == 'LPPM KETUA' || lembaga_jabatan == 'BAK KEPALA'">Download</a>
                    </div>

                    <table-basic>
                        <template #thead>
                            <tr class="fw-bolder bg-light fs-7 text-center">
                                <th rowspan="2" class="rounded-start ps-5 w-md-50px">No.</th>
                                <th rowspan="2" class="w-md-200px">Dosen</th>
                                <th colspan="12">Pencairan Bulan</th>
                                <th rowspan="2" class="rounded-end pe-5 w-md-150px">Aksi</th>
                            </tr>

                            <tr class="bg-light text-center">
                                <th>Sep</th>
                                <th>Okt</th>
                                <th>Nov</th>
                                <th>Des</th>
                                <th>Jan</th>
                                <th>Feb</th>
                                <th>Mar</th>
                                <th>Apr</th>
                                <th>Mei</th>
                                <th>Jun</th>
                                <th>Jul</th>
                                <th>Ags</th>
                            </tr>
                        </template>

                        <template #tbody>
                            <tr v-for="(usulan, i) in data_usulan" :key="usulan.id">
                                <td class="text-end">{{ i + 1 }}</td>
                                <td>
                                    <span class="fw-bold d-block">{{ usulan.dosen_nama }}</span>{{ usulan.dosen_nidn }}
                                </td>
                                <td class="text-center">
                                    <icon-status-0 v-if="usulan.pencairan[1] == 0" />
                                    <icon-status-1 v-if="usulan.pencairan[1] == 1" />
                                    <icon-status-2 v-if="usulan.pencairan[1] == 2" />
                                </td>
                                <td class="text-center">
                                    <icon-status-0 v-if="usulan.pencairan[2] == 0" />
                                    <icon-status-1 v-if="usulan.pencairan[2] == 1" />
                                    <icon-status-2 v-if="usulan.pencairan[2] == 2" />
                                </td>
                                <td class="text-center">
                                    <icon-status-0 v-if="usulan.pencairan[3] == 0" />
                                    <icon-status-1 v-if="usulan.pencairan[3] == 1" />
                                    <icon-status-2 v-if="usulan.pencairan[3] == 2" />
                                </td>
                                <td class="text-center">
                                    <icon-status-0 v-if="usulan.pencairan[4] == 0" />
                                    <icon-status-1 v-if="usulan.pencairan[4] == 1" />
                                    <icon-status-2 v-if="usulan.pencairan[4] == 2" />
                                </td>
                                <td class="text-center">
                                    <icon-status-0 v-if="usulan.pencairan[5] == 0" />
                                    <icon-status-1 v-if="usulan.pencairan[5] == 1" />
                                    <icon-status-2 v-if="usulan.pencairan[5] == 2" />
                                </td>
                                <td class="text-center">
                                    <icon-status-0 v-if="usulan.pencairan[6] == 0" />
                                    <icon-status-1 v-if="usulan.pencairan[6] == 1" />
                                    <icon-status-2 v-if="usulan.pencairan[6] == 2" />
                                </td>
                                <td class="text-center">
                                    <icon-status-0 v-if="usulan.pencairan[7] == 0" />
                                    <icon-status-1 v-if="usulan.pencairan[7] == 1" />
                                    <icon-status-2 v-if="usulan.pencairan[7] == 2" />
                                </td>
                                <td class="text-center">
                                    <icon-status-0 v-if="usulan.pencairan[8] == 0" />
                                    <icon-status-1 v-if="usulan.pencairan[8] == 1" />
                                    <icon-status-2 v-if="usulan.pencairan[8] == 2" />
                                </td>
                                <td class="text-center">
                                    <icon-status-0 v-if="usulan.pencairan[9] == 0" />
                                    <icon-status-1 v-if="usulan.pencairan[9] == 1" />
                                    <icon-status-2 v-if="usulan.pencairan[9] == 2" />
                                </td>
                                <td class="text-center">
                                    <icon-status-0 v-if="usulan.pencairan[10] == 0" />
                                    <icon-status-1 v-if="usulan.pencairan[10] == 1" />
                                    <icon-status-2 v-if="usulan.pencairan[10] == 2" />
                                </td>
                                <td class="text-center">
                                    <icon-status-0 v-if="usulan.pencairan[11] == 0" />
                                    <icon-status-1 v-if="usulan.pencairan[11] == 1" />
                                    <icon-status-2 v-if="usulan.pencairan[11] == 2" />
                                </td>
                                <td class="text-center">
                                    <icon-status-0 v-if="usulan.pencairan[12] == 0" />
                                    <icon-status-1 v-if="usulan.pencairan[12] == 1" />
                                    <icon-status-2 v-if="usulan.pencairan[12] == 2" />
                                </td>
                                <td class="text-center">
                                    <button v-if="usulan.status_tombol_pencairan && lembaga_jabatan == 'BAK KEPALA'" class="btn btn-sm btn-primary" @click="handleCairkan(i)">Cairkan</button>
                                </td>
                            </tr>
                        </template>
                    </table-basic>
                </template>
            </card-basic>
        </template>
    </container-app>
</template>

<script>
import { lembagaJabatan } from "@/apis/user";
import {
    getSemester,
    getPencairan,
    postCairkan,
} from '@/apis/kewajibanTahunan'

import { getUserData } from "@/store";
export default {
    data() {
        return {
            user: getUserData(),
            lembaga_jabatan: "-",
            full_akses: false,
            data_jadwal_pengusulan: [],
            jadwal_id: null,
            tahun: '',
            data_usulan: [],
            base_url: process.env.VUE_APP_BASE_URL
        }
    },

    watch: {
        jadwal_id() {
            this.getPencairan();
        }
    },

    beforeMount() {
        localStorage.setItem("menu", "Penelitian dan PkM");
        localStorage.setItem("halaman", "Pencairan Kewajiban Tahunan");
        this.lembagaJabatan();
        this.getSemester();
    },

    methods: {
        async lembagaJabatan() {
            const res = await lembagaJabatan();

            this.lembaga_jabatan = res.data.lembaga_jabatan;

            if ( this.user.akses.includes('ALL') ) {
                this.full_akses = true;
            } else {
                this.full_akses = this.akses.includes(this.lembaga_jabatan) ? true : false;
            }
        },

        async getSemester() {
            const res = await getSemester();
            this.data_jadwal_pengusulan = res.data.data;
            this.jadwal_id = res.data.data[0].semester;
            this.tahun = this.jadwal_id.split('/')[0];
        },

        async getPencairan() {
            const res = await getPencairan(this.jadwal_id);    

            this.data_usulan = res.data.data;
        },

        handleCairkan(index) {
            const data = this.data_usulan[index];

            Swal.fire({
                title: "Cairkan Usulan?",
                text: "Usulan Pencairan Kewajiban Tahunan "+ data.dosen_nama +" akan dicairkan.",
                icon: "question",
                confirmButtonText: "Cairkan",
                confirmButtonColor: "#3085d6",
                showCancelButton: true,
                cancelButtonText: 'Batal',
                cancelButtonColor: "#d33",
            }).then( async e => {
                if ( e.isConfirmed ) {
                    const res = await postCairkan(data.id);

                    if( res.status == 200 ) {
                        Swal.fire({
                            icon: "success",
                            text: "Usulan Pencairan Kewajiban Tahunan "+ data.dosen_nama +" berhasil dicairkan",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }

                    this.getPencairan();
                }
            })
        }
    }
}
</script>