import { api, headers } from "./api";

const RESOURCE = "course-contract";

export const getStudentContracts = (nim, tahun_semester) =>
  api.get(
    RESOURCE + `/get-student-contracts?NIMHS=${nim}&THSMS=${tahun_semester}`,
    headers
  );
export const postStudentContracts = (id) =>
  api.post(RESOURCE + `/post-student-contract`, { id: id }, headers);

export const getNidn = (nodos) =>
  api.get(RESOURCE + `/get-nidn?nodos=${nodos}`, headers);

export const getLecturerContracts = (nidn, academic_year, item_per_page) =>
  api.get(
    RESOURCE +
      `/get-lecturer-contracts?nidn=${nidn}&academic_year=${academic_year}&item_per_page=${item_per_page}`,
    headers
  );

export const postLecturerContract = (id, path) =>
  api.post(
    RESOURCE + `/post-lecturer-contract`,
    { id: id, path: path },
    headers
  );

export const getAcademicYearsLecturer = () =>
  api.get(RESOURCE + `/get-academic-years`, headers);

export const getContractByMajor = (
  major,
  academic_year,
  item_per_page,
  page,
  course_name
) =>
  api.get(
    RESOURCE +
      `/get-contract-by-major?major=${major}&academic_year=${academic_year}&item_per_page=${item_per_page}&page=${page}${course_name}`,
    headers
  );

export const getContractDetail = (id) =>
  api.get(RESOURCE + `/get-contract-detail?id=${id}`, headers);

export const downloadRpsReport = (id) =>
  api.post(
    RESOURCE + `/download`,
    { id: id },
    { ...headers, responseType: "arraybuffer" }
  );
