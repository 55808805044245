<template>
    <container-app :container_loading="container_loading">
        <template #content>
            <container-double>
                <template #left>
                    
                    <card-basic class="d-none d-md-block">
                        <template #content>

                            <div class="p-5">
                                <label-basic :name="'Grafik Rekapitulasi Perwalian Tahun Akademik '+tahun_akademik" class="text-center mb-3 mb-5" />
                                <canvas id="renderChart"></canvas>
                            </div>

                        </template>
                    </card-basic>

                    <div class="d-block d-md-none">
                        <card-basic>
                            <template #content>
                                
                                <div class="p-5">
                                    <label-header label="Jenis Kegiatan" :value="perwalian.jenis_format + ' ' + perwalian.thsms">
                                        <template #control>
                                            <button class="btn btn-secondary btn-sm px-3 px-md-4" data-bs-toggle="modal" data-bs-target="#modalKegiatan">
                                                <icon-filter />
                                            </button>
                                            <button class="btn btn-secondary btn-sm px-3 px-md-4 ms-3" data-bs-toggle="modal"  data-bs-target="#modalFormPerwalian" @click="tambahPerwalian">
                                                <icon-plus />
                                            </button>
                                        </template>
                                    </label-header>
                                </div>

                            </template>
                        </card-basic>

                        <card-basic>
                            <template #content>

                                <div class="p-5">
                                    <div class="d-flex justify-content-between mb-2">
                                        <div>
                                            <span class="fs-8 text-gray-700 d-block">Tahun Akademik</span>
                                            <span class="fs-7 fw-bold text-gray-800">{{perwalian.thsms}}</span>
                                        </div>
                                        <button class="btn btn-secondary btn-sm px-3 px-md-4" data-bs-toggle="modal" data-bs-target="#modalFormPerwalian" @click="editPerwalian">
                                            <icon-edit />
                                        </button>
                                    </div>

                                    <div class="mb-2">
                                        <span class="fs-8 text-gray-700 d-block">Jenis kegiatan</span>
                                        <span class="fs-7 fw-bold text-gray-800">{{perwalian.jenis_format}}</span>
                                    </div>

                                    <div class="mb-2">
                                        <span class="fs-8 text-gray-700 d-block">Administrasi Keuangan</span>
                                        <span class="fs-7 fw-bold text-gray-800">Tahun Akademik {{perwalian.thsms_keuangan}}, {{perwalian.keuangan}}%</span>
                                    </div>

                                    <div class="mb-2">
                                        <span class="fs-8 text-gray-700 d-block">Waktu Mulai Kegiatan</span>
                                        <span class="fs-7 fw-bold text-gray-800">{{perwalian.waktu_mulai_format}}</span>
                                    </div>

                                    <div class="mb-2">
                                        <span class="fs-8 text-gray-700 d-block">Waktu Tutup Kegiatan</span>
                                        <span class="fs-7 fw-bold text-gray-800">{{perwalian.waktu_tutup_format}}</span>
                                    </div>
                                </div>

                            </template>
                        </card-basic>
                    </div>

                    <card-basic>
                        <template #content>
                            <div class="p-5 d-flex justify-content-between">
                                <label-basic :name="'Tabel Rekapitulasi Perwalian Tahun Akademik '+tahun_akademik" class="text-center mb-3" />
                                <a href="" class="bnt btn-link">Download</a>
                            </div>

                            <table-basic>
                                <template #thead>
                                    <tr class="bg-light-primary">
                                        <th></th>
                                        <th>Belum Perwalian</th>
                                        <th>Menunggu Validasi</th>
                                        <th>Sudah Divalidasi</th>
                                        <th class="pe-4">Total Mahasiswa</th>
                                    </tr>
                                </template>

                                <template #tbody>
                                    <tr v-for="(prodi, i) in rekap_perwalian.prodi" :key="i">
                                        <td class="ps-4">{{ prodi.nmps }}</td>
                                        <td>{{ rekap_perwalian.hasil.belum[i] }}</td>
                                        <td>{{ rekap_perwalian.hasil.sudah[i] }}</td>
                                        <td>{{ rekap_perwalian.hasil.valid[i] }}</td>
                                        <td class="pe-4">{{ rekap_perwalian.hasil.belum[i] + rekap_perwalian.hasil.sudah[i] + rekap_perwalian.hasil.valid[i] }}</td>
                                    </tr>
                                </template>
                            </table-basic>
                        </template>
                    </card-basic>

                </template>

                <template #right>
                    
                    <card-basic>
                        <template #content>
                            
                            <div class="p-5">
                                <label-header label="Jenis Kegiatan" :value="perwalian.jenis_format + ' ' + perwalian.thsms">
                                    <template #control>
                                        <button class="btn btn-secondary btn-sm px-3 px-md-4" data-bs-toggle="modal" data-bs-target="#modalKegiatan">
                                            <icon-filter />
                                        </button>
                                        <button class="btn btn-secondary btn-sm px-3 px-md-4 ms-3" data-bs-toggle="modal"  data-bs-target="#modalFormPerwalian" @click="tambahPerwalian">
                                            <icon-plus />
                                        </button>
                                    </template>
                                </label-header>
                            </div>

                        </template>
                    </card-basic>

                    <card-basic>
                        <template #content>

                            <div class="p-5">
                                <div class="d-flex justify-content-between mb-2">
                                    <div>
                                        <span class="fs-8 text-gray-700 d-block">Tahun Akademik</span>
                                        <span class="fs-7 fw-bold text-gray-800">{{perwalian.thsms}}</span>
                                    </div>
                                    <button class="btn btn-secondary btn-sm px-3 px-md-4" data-bs-toggle="modal" data-bs-target="#modalFormPerwalian" @click="editPerwalian">
                                        <icon-edit />
                                    </button>
                                </div>

                                <div class="d-flex justify-content-between mb-2">
                                    <div>
                                        <span class="fs-8 text-gray-700 d-block">Angkatan</span>
                                        <span class="fs-7 fw-bold text-gray-800">{{perwalian.angkatan}}</span>
                                    </div>
                                </div>

                                <div class="mb-2">
                                    <span class="fs-8 text-gray-700 d-block">Jenis kegiatan</span>
                                    <span class="fs-7 fw-bold text-gray-800">{{perwalian.jenis_format}}</span>
                                </div>

                                <div class="mb-2">
                                    <span class="fs-8 text-gray-700 d-block">Administrasi Keuangan</span>
                                    <span class="fs-7 fw-bold text-gray-800">Tahun Akademik {{perwalian.thsms_keuangan}}, {{perwalian.keuangan}}%</span>
                                </div>

                                <div class="mb-2">
                                    <span class="fs-8 text-gray-700 d-block">Waktu Mulai Kegiatan</span>
                                    <span class="fs-7 fw-bold text-gray-800">{{perwalian.waktu_mulai_format}}</span>
                                </div>

                                <div class="mb-2">
                                    <span class="fs-8 text-gray-700 d-block">Waktu Tutup Kegiatan</span>
                                    <span class="fs-7 fw-bold text-gray-800">{{perwalian.waktu_tutup_format}}</span>
                                </div>
                            </div>

                        </template>
                    </card-basic>
                    
                </template>
            </container-double>

        </template>
    </container-app>

    <!-- begin::modal -->
    <!-- begin::modalKegiatan -->
    <modal-basic id="modalKegiatan" title="Ganti Jenis Kegiatan">
        <template #content>
            <select class="form-select form-select-solid" id="filterKegiatan" v-model="_perwalian" >
                <option v-for="(perwalian,i) in daftar_perwalian" :key="i" :value="perwalian">{{ perwalian.jenis_format }} {{ perwalian.thsms }}</option>
            </select>
        </template>

        <template #button>
            <button-basic class="bg-primary text-light" name="Simpan" @click="handlePerwalian" />
        </template>
    </modal-basic>
    <!-- end::modalKegiatan -->

    <!-- begin::formPerwalian -->
    <modal-fullscreen-sm id="modalFormPerwalian" :title="formPerwalianTitle">
        <template #content>
            <form @submit.prevent="handleFormPerwalian">
                <div class="row mb-3">
                    <label class="form-label fs-7 fw-normal col-form-label col-md-5">Tahun Akademik</label>
                    <div class="col-md-7">
                        <input 
                            class="form-control fw-normal" 
                            type="text" v-model="form_perwalian.thsms" required />
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="form-label fs-7 fw-normal col-form-label col-md-5">Angkatan</label>
                    <div class="col-md-7">
                        <input 
                            class="form-control fw-normal" 
                            type="text" v-model="form_perwalian.angkatan" required />
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="form-label fs-7 fw-normal col-form-label col-md-5">Jenis Kegiatan</label>
                    <div class="col-md-7">
                        <select class="form-select fw-normal" v-model="form_perwalian.jenis" required >
                            <option value="PERWALIAN">Perwalian</option>
                            <option value="PERUBAHAN">Perubahan Perwalian</option>
                        </select>
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="form-label fs-7 fw-normal col-form-label col-md-5">Waktu Mulai</label>
                    <div class="col-md-7">
                        <input 
                            class="form-control fw-normal" 
                            type="datetime-local" v-model="form_perwalian.waktu_mulai" required />
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="form-label fs-7 fw-normal col-form-label col-md-5">Waktu Tutup</label>
                    <div class="col-md-7">
                        <input 
                            class="form-control fw-normal" 
                            type="datetime-local" v-model="form_perwalian.waktu_tutup" required />
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="form-label fs-7 fw-normal col-form-label col-md-5">Tahun Akademik Keuangan</label>
                    <div class="col-md-7">
                        <input 
                            class="form-control fw-normal" 
                            type="text" v-model="form_perwalian.thsms_keuangan" required />
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="form-label fs-7 fw-normal col-form-label col-md-5">Besaran Keuangan (%)</label>
                    <div class="col-md-7">
                        <input 
                            class="form-control fw-normal" 
                            type="text" v-model="form_perwalian.keuangan" required />
                    </div>
                </div>

                <div class="row mt-7">
                    <div class="col-md-5"></div>
                    <div class="col-md-7">
                        <input type="submit" value="Simpan" class="btn btn-primary">
                    </div>
                </div>
            </form>
        </template>
    </modal-fullscreen-sm>
    <!-- end::formPerwalian -->
    <!-- end::modal -->
</template>

<script>
    // apis
    import { getsPerwalian, storePerwalian, updatePerwalian, getsRekap } from '@/apis/perwalian';

    // helpers
    import { formatDate } from '@/helpers/waktu'
    export default {
        beforeMount() {
            localStorage.setItem('menu', 'AISnet');
            localStorage.setItem('halaman', 'Perwalian');
        },
        data() {
            return {
                container_loading: true,
                daftar_perwalian: [],
                perwalian: {
                    jenis_format: "",
                    thsms: ""
                },
                _perwalian: {},
                form_perwalian: {},
                formPerwalianTitle: '',
                rekap_perwalian: {},
                tahun_akademik: '',
            }
        },  
        async mounted() {
            await this.renderChart();
            await this.getsPerwalian();
            this.container_loading = false;
        },
        methods: {
            handlePerwalian(){
                this.perwalian = this._perwalian;
            },  
            getStatus(status){
                switch(status){
                    case "PERWALIAN" : return "Perwalian"
                    case "PERUBAHAN" : return "Perubahan Perwalian"
                }
            },  
            async getsPerwalian() {
                try {
                    let res = await getsPerwalian();

                    this.daftar_perwalian = res.data.data.map(e => ({...e, jenis_format: this.getStatus(e.jenis), waktu_mulai_format: formatDate(e.waktu_mulai), waktu_tutup_format: formatDate(e.waktu_tutup)}));
                    this.perwalian = this.daftar_perwalian[0];
                    this.tahun_akademik = this.perwalian.thsms;
                    this._perwalian = this.daftar_perwalian[0];
                } catch ( err ) {
                    console.error(err);
                }
            },
            tambahPerwalian() {
                this.form_perwalian = {};
                this.formPerwalianTitle = 'Tambah Jadwal Perwalian';
            },
            editPerwalian() {
                this.form_perwalian = this.perwalian;
                this.formPerwalianTitle = 'Edit Jadwal Perwalian';
            },
            handleFormPerwalian() {
                Swal.fire({
                    icon: 'question',
                    text: this.formPerwalianTitle,
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                    confirmButtonText: 'Ya',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-secondary'
                    }
                }).then( async result => {
                    if( result.isConfirmed ) {
                        try {
                            this.container_loading = true;

                            if( this.form_perwalian.id == undefined ) {
                                await storePerwalian(this.form_perwalian);
                            } else {
                                await updatePerwalian(this.form_perwalian.id, this.form_perwalian);
                            }      

                            Swal.fire({
                                icon: 'success',
                                text: 'Form perwalian berhasil disimpan',
                                showConfirmButton: false,
                                timer: 5000
                            })

                            await this.getsPerwalian();
                            this.container_loading = false;

                        } catch ( err ) {
                            console.error(err);

                            Swal.fire({
                                icon: 'error',
                                text: 'Form perwalian gagal disimpan',
                                showConfirmButton: false,
                                timer: 5000
                            })
                        }
                    }
                })
            },
            async renderChart() {
                try {
                    const res = await getsRekap();

                    this.rekap_perwalian = await res.data;

                    this.grafik = new Chart("renderChart", {
                        type: "bar",
                        data: {
                            labels: res.data.prodi.map( e => e.nmps ),
                            datasets: [
                                {
                                    label: 'Belum Perwalian',
                                    backgroundColor: "#e74c3c",
                                    data: res.data.hasil.belum
                                },
                                {
                                    label: 'Menunggu Validasi',
                                    backgroundColor: "#f39c12",
                                    data: res.data.hasil.sudah
                                },
                                {
                                    label: 'Sudah Divalidasi',
                                    backgroundColor: "#27ae60",
                                    data: res.data.hasil.valid
                                },
                            ]
                        },
                        options: {
                            responsive: true
                        }
                    });

                    this.grafik.render();

                } catch ( err ) {
                    console.error(err);
                }
            },
        }
    }
</script>

<style>
    canvas {
        height: 50vh;
    }
</style>