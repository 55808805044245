<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <div class="p-5">
            <div class="my-5">
              <label-header :label="title" value="Detail Penilaian UAS">
                <template #control>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#bobot_nilai"
                    type="button"
                    class="btn btn-primary btn-sm"
                  >
                    Bobot & Instrumen Penilaian
                  </button>
                  <button
                    v-if="isLocked || mataKuliah.expired"
                    @click="downloadDaftarNilaiUAS"
                    type="button"
                    class="btn btn-secondary btn-sm ms-2"
                  >
                    Unduh Laporan DPNA
                  </button>
                </template>
              </label-header>
            </div>
          </div>
          <table-basic>
            <template #thead>
              <tr class="fw-bolder bg-light fs-7">
                <th class="rounded-start ps-4 w-50px">No.</th>
                <th>NIM & Nama</th>
                <th class="text-center w-150px">Nilai UTS</th>
                <th class="text-center w-150px">Nilai Tugas</th>
                <th class="text-center w-150px">Absensi</th>
                <th class="text-center w-150px">Nilai UAS</th>
                <th class="text-center w-150px">Nilai Akhir</th>
                <th class="text-center w-100px">Nilai Mutu</th>
                <th class="text-center">Kehadiran</th>
                <th class="rounded-end pe-5 text-center"></th>
              </tr>
            </template>

            <template #tbody>
              <tr v-for="(item, i) in data" :key="i">
                <td class="text-center ps-5">{{ i + 1 }}</td>
                <td>
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.nama }}
                  </span>
                  <span class="text-muted text-muted d-block fs-8">
                    {{ item.nimhs }}
                  </span>
                </td>
                <td class="text-center align-middle">
                  <div class="form-floating">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Masukkan Nilai"
                      v-model="item.nilai_uts"
                      @change="hitungNilaiAkhir(item)"
                      :disabled="
                        item.is_locked || mataKuliah.expired || !isAdmin
                      "
                    />
                    <label for="nilai">UTS</label>
                  </div>
                </td>
                <td class="text-center align-middle">
                  <div class="form-floating">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Masukkan Nilai"
                      v-model="item.tugas"
                      @change="hitungNilaiAkhir(item)"
                      @input="checkValueTugas($event, i)"
                      :disabled="item.is_locked || mataKuliah.expired"
                    />
                    <label for="nilai">Tugas</label>
                  </div>
                </td>
                <td class="text-center align-middle">
                  <div class="form-floating">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Masukkan Nilai"
                      v-model="item.absen"
                      @change="hitungNilaiAkhir(item)"
                      :disabled="
                        item.is_locked || mataKuliah.expired || !isAdmin
                      "
                    />
                    <label for="nilai">Absensi</label>
                  </div>
                </td>
                <td class="text-center align-middle">
                  <div class="form-floating">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Masukkan Nilai"
                      v-model="item.nilai_uas"
                      :disabled="item.is_locked || mataKuliah.expired"
                      @input="checkValueUAS($event, i)"
                      @change="hitungNilaiAkhir(item)"
                    />
                    <label for="nilai">UAS</label>
                  </div>
                </td>
                <td class="text-center align-middle">
                  <div class="form-floating">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Masukkan Nilai"
                      :disabled="
                        item.is_locked || mataKuliah.expired || !isAdmin
                      "
                      v-model="item.nilai_akhir"
                      @change="hitungNilaiAkhir(item)"
                    />
                    <label for="nilai">Nilai Akhir</label>
                  </div>
                </td>
                <td class="text-center align-middle">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Masukkan Nilai"
                      v-model="item.nilai_huruf"
                      :disabled="
                        item.is_locked || mataKuliah.expired || !isAdmin
                      "
                    />
                    <label for="nilai">Mutu</label>
                  </div>
                </td>
                <td class="text-center align-middle">
                  <select
                    class="form-select"
                    data-placeholder="Pilih Kehadiran"
                    data-control="select2"
                    v-model="item.kehadiran"
                    :disabled="item.is_locked || mataKuliah.expired"
                  >
                    <option value="Hadir">Hadir</option>
                    <option value="Sakit">Sakit</option>
                    <option value="Izin">Izin</option>
                    <option value="Kendala Administrasi">
                      Kendala Administrasi
                    </option>
                    <option value="Tanpa Keterangan">Tanpa Keterangan</option>
                  </select>
                </td>
                <td class="align-middle">
                  <a
                    class="mt-5 mt-lg-0 btn btn-sm btn-secondary btn-active-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#logUAS"
                    @click="showLog(i)"
                  >
                    Log
                  </a>
                  <!-- <a class="mt-5 btn btn-sm btn-secondary btn-active-primary">
                    Validasi
                  </a>
                  <a class="mt-5 btn btn-sm btn-secondary btn-active-primary">
                    Edit
                  </a> -->
                </td>
              </tr>
            </template>
          </table-basic>
          <div class="text-end p-2">
            <button
              v-if="!isLocked"
              :disabled="mataKuliah.expired"
              type="button"
              class="btn btn-success ms-auto"
              @click="submitNilaiUAS"
            >
              Simpan
            </button>
            <button
              v-if="!isLocked"
              type="button"
              class="btn btn-primary ms-3"
              data-bs-toggle="modal"
              data-bs-target="#kunci_penilaian_uas"
            >
              Kunci Penilaian
            </button>
            <button
              v-if="isLocked && isAdmin"
              type="button"
              class="btn btn-danger ms-3"
              data-bs-toggle="modal"
              data-bs-target="#buka_penilaian_uas"
            >
              Buka Penilaian
            </button>
          </div>
        </template>
      </card-basic>
      <div class="modal fade" tabindex="-1" id="kunci_penilaian_uas">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Kunci Penilaian</h5>
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <icon-close />
              </div>
            </div>
            <div class="modal-body">
              <div>
                <h4 class="text-center">
                  Apakah anda yakin untuk melakukan kunci penilaian? Nilai tidak
                  dapat diubah apabila sudah dikunci.
                </h4>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Batal
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="kunciPenilaianUAS()"
              >
                Kunci
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" tabindex="-1" id="bobot_nilai">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Bobot & Instrumen Penilaian</h5>
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <icon-close />
              </div>
            </div>
            <div class="modal-body">
              <div class="mb-6 p-3 border">
                <label-header class="mb-5" value="Bobot Nilai" />
                <table-basic>
                  <template #thead>
                    <tr class="fw-bolder bg-light fs-7">
                      <th class="ps-4 w-50px rounded-start">No</th>
                      <th class="min-w-100px">Nilai Huruf</th>
                      <th class="rounded-end min-w-100px">Rentang Nilai</th>
                    </tr>
                  </template>

                  <template #tbody>
                    <tr v-for="(item, i) in daftarNilaiHuruf" :key="i">
                      <td class="text-center align-middle">{{ i + 1 }}</td>
                      <td class="align-middle">
                        {{ item.nilai_huruf }}
                      </td>
                      <td class="align-middle">
                        {{ item.batas_bawah }} {{ item.batas_bawah_simbol }}
                        {{ item.nilai_huruf }} {{ item.batas_atas_simbol }}
                        {{ item.batas_atas }}
                      </td>
                    </tr>
                  </template>
                </table-basic>
              </div>
              <div class="p-3 border">
                <label-header class="mb-5" value="Instrumen Penilaian" />
                <table-basic>
                  <template #thead>
                    <tr class="fw-bolder bg-light fs-7">
                      <th class="ps-4 w-50px rounded-start">No</th>
                      <th class="min-w-100px">Nama Instrumen Nilai</th>
                      <th class="rounded-end min-w-100px text-center">
                        Persentase
                      </th>
                    </tr>
                  </template>

                  <template #tbody>
                    <tr v-for="(item, i) in daftarInstrumentNilai" :key="i">
                      <td class="text-center align-middle">{{ i + 1 }}</td>
                      <td class="align-middle">
                        {{ item.instrumen_nilai }}
                      </td>
                      <td class="align-middle text-center">
                        {{ item.persentase }}%
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td class="fw-bolder">Total</td>
                      <td class="fw-bolder align-middle text-center">
                        {{ totalPersentase }}%
                      </td>
                      <td></td>
                    </tr>
                  </template>
                </table-basic>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- begin::Log Pengisian UAS -->
      <modal-fullscreen-sm id="logUAS" title="Log Pengisian Nilai Akhir">
        <template #content>
          <div class="card border mb-2" v-for="(item, i) in log" :key="i">
            <div class="card-body p-2 px-3">
              <span class="mb-1 d-block">
                Mengubah nilai UTS dari
                <span class="fw-bold">{{
                  item.old_uts ? item.old_uts : "-"
                }}</span>
                menjadi
                <span class="fw-bold"
                  >{{ item.new_uts ? item.new_uts : "-" }}
                </span>
              </span>
              <span class="mb-1 d-block">
                Mengubah nilai Tugas dari
                <span class="fw-bold">{{
                  item.old_tugas ? item.old_tugas : "-"
                }}</span>
                menjadi
                <span class="fw-bold"
                  >{{ item.new_tugas ? item.new_tugas : "-" }}
                </span>
              </span>
              <span class="mb-1 d-block">
                Mengubah nilai Absensi dari
                <span class="fw-bold">{{
                  item.old_absen ? item.old_absen : "-"
                }}</span>
                menjadi
                <span class="fw-bold"
                  >{{ item.new_absen ? item.new_absen : "-" }}
                </span>
              </span>
              <span class="mb-1 d-block">
                Mengubah nilai UAS dari
                <span class="fw-bold">{{
                  item.old_uas ? item.old_uas : "-"
                }}</span>
                menjadi
                <span class="fw-bold"
                  >{{ item.new_uas ? item.new_uas : "-" }}
                </span>
              </span>
              <span class="mb-1 d-block">
                Mengubah nilai Akhir dari
                <span class="fw-bold">{{
                  item.old_akhir ? item.old_akhir : "-"
                }}</span>
                menjadi
                <span class="fw-bold"
                  >{{ item.new_akhir ? item.new_akhir : "-" }}
                </span>
              </span>
              <span class="mb-1 d-block">
                Mengubah nilai Huruf dari
                <span class="fw-bold">{{
                  item.old_huruf ? item.old_huruf : "-"
                }}</span>
                menjadi
                <span class="fw-bold"
                  >{{ item.new_huruf ? item.new_huruf : "-" }}
                </span>
              </span>
              <small class="text-muted"
                >Author: {{ item.author }} [{{ item.timestamp }}]</small
              >
            </div>
          </div>
        </template>
      </modal-fullscreen-sm>
      <!-- end::Log Pengisian UAS  -->

      <!-- begin::Buka Penilaian UTS -->
      <div class="modal fade" tabindex="-1" id="buka_penilaian_uas">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Buka Penilaian</h5>
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <icon-close />
              </div>
            </div>
            <div class="modal-body">
              <div>
                <h4 class="text-center">
                  Apakah anda yakin untuk membuka kembali penilaian UAS ini?
                </h4>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Batal
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="bukaPenilaianUAS()"
              >
                Buka
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end::Buka Penilaian UTS  -->
    </template>
  </container-app>
</template>

<script>
// STORE
import { useMataKuliahStore } from "@/stores/mataKuliah";
import { getUserData } from "@/store";

// API
import {
  getPesertaUas,
  postNilaiPesertaUas,
  getNilaiHuruf,
  getInstrumenNilai,
  postKunciPenilaianUAS,
  postBukaPenilaianUAS,
  downloadDPNA,
} from "@/apis/penilaianUjian";

export default {
  setup() {
    const mataKuliah = useMataKuliahStore();
    mataKuliah.restoreFromLocalStorage();
    return {
      mataKuliah,
    };
  },

  data() {
    return {
      container_loading: false,
      title: "",
      data: [],
      log: [],
      user: getUserData(),
      isButtonDisable: true,
      daftarNilaiHuruf: [],
      daftarInstrumentNilai: [],
      isLocked: false,
      isAdmin: false,
      selectedRowIndex: null,
    };
  },

  mounted() {
    this.fetchInstrumenNilai();
    this.fetchNilaiHuruf();
    this.fetchPesertaUAS(
      this.mataKuliah.tahun_semester,
      this.mataKuliah.kode_mata_kuliah,
      this.mataKuliah.mata_kuliah,
      this.mataKuliah.kelas,
      this.mataKuliah.kelas_ujian
    );

    if (
      (this.user.akses && this.user.akses.includes("PENILAIAN_UJIAN_PRODI")) ||
      (this.user.peran && this.user.peran === "ADMIN")
    ) {
      this.isAdmin = true;
    }
  },

  methods: {
    async fetchNilaiHuruf() {
      try {
        this.container_loading = true;
        const response = await getNilaiHuruf();
        if (response.status === 200) {
          this.daftarNilaiHuruf = response.data.data;

          this.container_loading = false;
        } else if (response.status === 404) {
          Swal.fire({
            text: response.data.message,
            icon: "warning",
          });
          this.container_loading = false;
        }
      } catch (error) {
        this.container_loading = false;
        Swal.fire({
          text: error.response.data.message,
          icon: "error",
        });
      }
    },

    async fetchInstrumenNilai() {
      try {
        this.container_loading = true;
        const response = await getInstrumenNilai();
        if (response.status === 200) {
          this.daftarInstrumentNilai = response.data.data;

          this.container_loading = false;
        } else if (response.status === 404) {
          Swal.fire({
            text: response.data.message,
            icon: "warning",
          });
          this.container_loading = false;
        }
      } catch (error) {
        this.container_loading = false;
        Swal.fire({
          text: error.response.data.message,
          icon: "error",
        });
      }
    },

    async fetchPesertaUAS(
      tahun_semester,
      kode_mata_kuliah,
      mata_kuliah,
      kelas,
      kelas_ujian
    ) {
      try {
        this.container_loading = true;
        const response = await getPesertaUas(
          tahun_semester,
          kode_mata_kuliah,
          encodeURIComponent(mata_kuliah),
          kelas,
          kelas_ujian
        );
        if (response.status === 200) {
          this.title = response.data.data[0].nakmk;
          this.isLocked = response.data.data[0].is_locked;

          await Promise.all(
            response.data.data.map((element) => this.hitungNilaiAkhir(element))
          );

          this.data = response.data.data;
        } else if (response.status === 404) {
          Swal.fire({
            text: response.data.message,
            icon: "warning",
          });
        }
      } catch (error) {
        Swal.fire({
          text: error,
          icon: "error",
        });
      } finally {
        this.container_loading = false;
      }
    },

    async submitNilaiUAS() {
      try {
        const swalLoading = Swal.fire({
          title: "Menyimpan nilai UAS",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const nilaiPesertaUas = this.data
          .map((item) => ({
            nim: item.nimhs,
            nilai_uts: item.nilai_uts,
            absen: item.absen,
            tugas: item.tugas,
            nilai_uas: item.nilai_uas,
            nilai_akhir: item.nilai_akhir,
            nilai_huruf: item.nilai_huruf,
            kehadiran: item.kehadiran,
          }))
          .filter((item) => item.tugas !== null || item.nilai_uas !== null);

        const response = await postNilaiPesertaUas({
          tahun_semester: this.mataKuliah.tahun_semester,
          kode_mata_kuliah: this.mataKuliah.kode_mata_kuliah,
          mata_kuliah: this.mataKuliah.mata_kuliah,
          kelas: this.mataKuliah.kelas,
          kelas_ujian: this.mataKuliah.kelas_ujian,
          nilai_peserta_uas: nilaiPesertaUas,
        });

        swalLoading.close();

        if (response.status === 201) {
          this.fetchPesertaUAS(
            this.mataKuliah.tahun_semester,
            this.mataKuliah.kode_mata_kuliah,
            this.mataKuliah.mata_kuliah,
            this.mataKuliah.kelas,
            this.mataKuliah.kelas_ujian
          );
          Swal.fire({
            title: "Berhasil!",
            text: "Data penilaian UAS berhasil disimpan",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal menyimpan nilai UAS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat menyimpan nilai UAS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

    async bukaPenilaianUAS() {
      try {
        const swalLoading = Swal.fire({
          title: "Membuka nilai UAS",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const nilaiPesertaUas = this.data.map((item) => ({
          nim: item.nimhs,
        }));

        const response = await postBukaPenilaianUAS({
          tahun_semester: this.mataKuliah.tahun_semester,
          kode_mata_kuliah: this.mataKuliah.kode_mata_kuliah,
          mata_kuliah: this.mataKuliah.mata_kuliah,
          kelas: this.mataKuliah.kelas,
          kelas_ujian: this.mataKuliah.kelas_ujian,
          nilai_peserta_uas: nilaiPesertaUas,
        });

        swalLoading.close();

        if (response.status === 201) {
          this.fetchPesertaUAS(
            this.mataKuliah.tahun_semester,
            this.mataKuliah.kode_mata_kuliah,
            this.mataKuliah.mata_kuliah,
            this.mataKuliah.kelas,
            this.mataKuliah.kelas_ujian
          );

          $("#buka_penilaian_uas").modal("hide");
          Swal.fire({
            title: "Berhasil!",
            text: "Data penilaian UAS berhasil dibuka",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal membuka nilai UAS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat membuka nilai UAS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

    hitungNilaiAkhir(item) {
      let total = 0;
      let grade = "T";

      this.daftarInstrumentNilai.forEach((instrumen) => {
        switch (instrumen.instrumen_nilai) {
          case "Absensi":
            total += item.absen * (instrumen.persentase / 100);
            break;
          case "Tugas":
            total += item.tugas * (instrumen.persentase / 100);
            break;
          case "UTS":
            total += item.nilai_uts * (instrumen.persentase / 100);
            break;
          case "UAS":
            total += item.nilai_uas * (instrumen.persentase / 100);
            break;
          default:
            break;
        }
      });

      item.nilai_akhir = total.toFixed(2);

      this.daftarNilaiHuruf.forEach((konfigurasi) => {
        const batas_bawah = parseFloat(konfigurasi.batas_bawah);
        const batas_atas = parseFloat(konfigurasi.batas_atas);
        if (
          item.nilai_akhir >= batas_bawah &&
          (item.nilai_akhir < batas_atas || item.nilai_akhir <= batas_atas)
        ) {
          grade = konfigurasi.nilai_huruf;
        }
      });

      if (item.absen < 75) {
        grade = "E";
      }

      item.nilai_huruf = grade;
    },

    async kunciPenilaianUAS() {
      try {
        const swalLoading = Swal.fire({
          title: "Mengunci nilai UAS",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const nilaiPesertaUas = this.data.map((item) => ({
          nim: item.nimhs,
        }));

        const response = await postKunciPenilaianUAS({
          tahun_semester: this.mataKuliah.tahun_semester,
          kode_mata_kuliah: this.mataKuliah.kode_mata_kuliah,
          mata_kuliah: this.mataKuliah.mata_kuliah,
          kelas: this.mataKuliah.kelas,
          kelas_ujian: this.mataKuliah.kelas_ujian,
          nilai_peserta_uas: nilaiPesertaUas,
        });

        swalLoading.close();

        if (response.status === 201) {
          this.fetchPesertaUAS(
            this.mataKuliah.tahun_semester,
            this.mataKuliah.kode_mata_kuliah,
            this.mataKuliah.mata_kuliah,
            this.mataKuliah.kelas,
            this.mataKuliah.kelas_ujian
          );
          $("#kunci_penilaian_uas").modal("hide");
          Swal.fire({
            title: "Berhasil!",
            text: "Data penilaian UAS berhasil dikunci",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal mengunci nilai UAS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat mengunci nilai UAS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

    async downloadDaftarNilaiUAS() {
      try {
        const swalLoading = Swal.fire({
          title: "Mengunduh Nilai UAS",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await downloadDPNA({
          tahun_semester: this.mataKuliah.tahun_semester,
          kode_mata_kuliah: this.mataKuliah.kode_mata_kuliah,
          mata_kuliah: this.mataKuliah.mata_kuliah,
          kelas: this.mataKuliah.kelas,
          kelas_ujian: this.mataKuliah.kelas_ujian,
        });

        swalLoading.close();

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `dpna-${this.mataKuliah.kode_mata_kuliah}-${this.mataKuliah.mata_kuliah}-${this.mataKuliah.kelas}-${this.mataKuliah.kelas_ujian}.pdf`
          );
          document.body.appendChild(link);
          link.click();

          Swal.fire({
            title: "Berhasil!",
            text: "Penilaian UAS berhasil diunduh",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          throw new Error("Gagal mengunduh nilai UAS");
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);

        Swal.fire({
          title: "Gagal!",
          text: "Terjadi kesalahan saat mengunduh nilai UAS",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

    checkValueTugas(event, index) {
      const newValue = parseFloat(event.target.value);
      if (newValue < 0 || newValue > 100) {
        this.data[index].tugas = 0;
      }
    },

    checkValueUAS(event, index) {
      const newValue = parseFloat(event.target.value);
      if (newValue < 0 || newValue > 100) {
        this.data[index].nilai_uas = 0;
      }
    },

    showLog(index) {
      this.selectedRowIndex = index;
      this.log = JSON.parse(this.data[this.selectedRowIndex].log);
    },
  },

  computed: {
    totalPersentase() {
      let total = 0;
      for (let item of this.daftarInstrumentNilai) {
        total += parseInt(item.persentase);
      }
      return total;
    },
  },
};
</script>
