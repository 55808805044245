<template>
    <card-basic>
          <template #content>
            <div class="p-5">
              <label-header value="Konfigurasi Instrumen Nilai">
                <template #control>
                  <button 
                  data-bs-toggle="modal"
                  data-bs-target="#create_instrumen_nilai"
                  type="button" class="btn btn-primary ms-auto">
                    Tambah
                  </button>
                </template>
              </label-header>
            </div>
            <div
                v-if="data.length === 0"
                class="alert alert-primary d-flex align-items-center p-5"
              >
                <div class="d-flex flex-column">
                  <span>Instrumen Nilai Belum Tersedia</span>
                </div>
              </div>
            <table-basic v-else>
              <template #thead>
                <tr class="fw-bolder bg-light fs-7">
                  <th class="ps-4 w-50px rounded-start">No</th>
                  <th class="min-w-100px">Nama Instrumen Nilai</th>
                  <th class="min-w-100px text-center">Persentase</th>
                  <th class="min-w-100px text-center">Status</th>
                  <th class="rounded-end"></th>
                </tr>
              </template>
  
              <template #tbody>
                <tr v-for="(item, i) in data" :key="i">
                  <td class="text-center align-middle">{{ i + 1 }}</td>
                  <td class="align-middle">
                    {{ item.instrumen_nilai }}
                  </td>
                  <td class="align-middle text-center">
                    {{ item.persentase }}%
                  </td>
                  <td class="align-middle text-center">
                    <span class="badge" :class="item.status == 'Aktif' ? 'badge-light-success': 'badge-light-danger' ">{{ item.status }}</span>
                  </td>
                  <td class="align-middle">
                    <button data-bs-toggle="modal" data-bs-target="#update_instrumen_nilai_modal" type="button" class="btn btn-sm btn-secondary ms-auto" @click="() => {
                      selected_instrumen = item
                    }">
                    Ubah
                  </button>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td class="fw-bolder">Total</td>
                  <td class="fw-bolder align-middle text-center">{{ totalPersentase }}%</td>
                  <td></td>
                </tr>
              </template>
            </table-basic>
          </template>      
    </card-basic>
    <!-- TAMBAH instrumen nilai MODAL -->
    <div class="modal fade" tabindex="-1" id="create_instrumen_nilai">
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Tambah Instrumen Nilai</h5>
                <div
                  class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <icon-close />
                </div>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-floating mt-4">
                    <input type="text" class="form-control" placeholder="Masukkan Nilai" v-model="selected_instrumen.instrumen_nilai"/>
                    <label for="nilai">Nama Instrumen Nilai</label>
                  </div>
                  <div class="form-floating mt-2">
                    <input type="number" class="form-control" placeholder="Masukkan Nilai" v-model="selected_instrumen.persentase"/>
                    <label for="nilai">Persentase</label>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  Tutup
                </button>
                <button type="button" class="btn btn-success" @click="submitInstrumen"> Simpan </button>
              </div>
            </div>
          </div>
    </div>
    <!-- END TAMBAH instrumen nilai MODAL -->
  
    <!-- TAMBAH instrumen nilai MODAL -->
    <div class="modal fade" tabindex="-1" id="update_instrumen_nilai_modal">
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Ubah Instrumen Nilai</h5>
                <div
                  class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <icon-close />
                </div>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-floating mt-4">
                    <input type="text" class="form-control" placeholder="Masukkan Nilai" v-model="selected_instrumen.instrumen_nilai"/>
                    <label for="nilai">Nama Instrumen Nilai</label>
                  </div>
                  <div class="form-floating mt-4">
                    <input type="number" class="form-control" placeholder="Masukkan Nilai" v-model="selected_instrumen.persentase"/>
                    <label for="persentase">Persentase</label>
                    </div>
                  <div class="mt-4">
                    <select class="form-select" data-placeholder="Pilih Simbol" v-model="selected_instrumen.status" >
                      <option value='Aktif'>Aktif</option>
                      <option value='Tidak Aktif'>Tidak Aktif</option>
                    </select>
                    </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  Tutup
                </button>
                <button type="button" class="btn btn-success" @click="updateInstrumenNilai"> Update </button>
              </div>
            </div>
          </div>
    </div>
    <!-- END TAMBAH instrumen nilai MODAL -->
  </template>
  
  <script>
  
  
  // API
  
  import { postInstrumenNilai, putInstrumenNilai } from "@/apis/penilaianUjian";
  
  export default {
    props:['data', 'getData'],
    setup() {
        return {
        }
    },
    data() {
      return {
        selected_instrumen:{}
      };
    },
  
    methods: {
      async submitInstrumen() {
        try {
          const swalLoading = Swal.fire({
            title: "Menyimpan Instrumen Nilai",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
  
          const response = await postInstrumenNilai({
            instrumen_nilai: this.selected_instrumen.instrumen_nilai,
            persentase: this.selected_instrumen.persentase,
            status: 'Aktif'
          });
  
          swalLoading.close();
  
          if (response.status === 201) {
            this.getData()
            this.selected_instrumen = {}
            $("#create_instrumen_nilai").modal("hide");
            Swal.fire({
              title: "Berhasil!",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 3000
            });
          } else {
            throw new Error("Gagal menyimpan instrumen nilai");
          }
        } catch (error) {
          Swal.fire({
            title: "Gagal!",
            text: error,
            icon: "error",
            showConfirmButton: false,
            timer: 3000
          });
        }
      },
      async updateInstrumenNilai() {
        try {
          const swalLoading = Swal.fire({
            title: "Mengupdate instrumen nilai",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
  
          const response = await putInstrumenNilai({
            id: this.selected_instrumen.id,
            instrumen_nilai: this.selected_instrumen.instrumen_nilai,
            persentase: this.selected_instrumen.persentase,
            status: this.selected_instrumen.status
          });
  
          swalLoading.close();
  
          if (response.status === 201) {
            this.getData()
            this.selected_instrumen = {}
            $("#update_instrumen_nilai_modal").modal("hide");
            Swal.fire({
              title: "Berhasil!",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 3000
            });
          } else {
            throw new Error("Gagal mengupdate instrumen nilai");
          }
        } catch (error) {
          Swal.fire({
            title: "Gagal!",
            text: error,
            icon: "error",
            showConfirmButton: false,
            timer: 3000
          });
        }
      },
    },

    computed: {
    totalPersentase() {
      let total = 0;
      for (let item of this.data) {
        total += parseInt(item.persentase);
      }
      return total;
    }
  }
  };
  </script>
  