<template>
  <mahasiswa-table-kontrak-perkuliahan v-if="user.peran == 'MAHASISWA'" />
  <dosen-table-kontrak-perkuliahan v-if="user.peran == 'DOSEN'" />
</template>

<script>
// components
import MahasiswaTableKontrakPerkuliahan from "@/components/_kontrakPerkuliahan/MahasiswaTableKontrakPerkuliahan.vue";
import DosenTableKontrakPerkuliahan from "@/components/_kontrakPerkuliahan/DosenTableKontrakPerkuliahan.vue";

// state
import { getUserData } from "@/store";

export default {
  components: {
    MahasiswaTableKontrakPerkuliahan,
    DosenTableKontrakPerkuliahan,
  },
  data() {
    return {
      user: {
        peran: "-",
      },
    };
  },
  beforeMount() {
    localStorage.setItem("halaman", "Kontrak Perkuliahan");
  },
  mounted() {
    if (getUserData().peran == "ADMIN") {
      this.user.peran = getUserData().nama.toUpperCase();
    } else {
      this.user.peran = getUserData().peran;
    }
    localStorage.setItem(
      "menu",
      `${this.user.peran == "MAHASISWA" ? "Kuliah" : "AISnet"}`
    );
  },
  methods: {},
};
</script>
