<template>
    <!-- begin::contianer-app -->
    <container-app>
        <template #content>
            
            <!-- begin::container-double -->
            <container-double>
                <template #left>

                    <!-- begin::list -->
                    <card-basic>
                        <template #content>
                            <div class="p-5">
                                <form-icon>
                                    <template #icon>
                                        <icon-search />
                                    </template>

                                    <template #content>
                                        <input 
                                            type="text" 
                                            class="form-control ps-14 fw-normal"
                                            placeholder="Pencarian berdasarkan id atau nama..."
                                            autofocus
                                            v-model="keyword"
                                            >
                                    </template>
                                </form-icon>

                                <span class="d-block mt-1 text-gray-500 ms-14" v-if="keyword.length > 0">Tekan tombol enter untuk melakukan pencarian</span>
                            </div>     
                            
                            <table-basic>
                                <template #thead>
                                    <tr class="fw-bolder bg-light fs-7">
                                        <th class="rounded-start ps-5 w-md-50px">No.</th>
                                        <th>Program Studi</th>
                                        <th class="rounded-end pe-5 w-md-200px">Aksi</th>
                                    </tr>
                                </template>

                                <template #tbody>
                                    <tr class="fs-7" v-for="(program_studi, i) in data.program_studi" :key="i">
                                        <td class="text-end ps-5">{{ i+1 }}</td>
                                        <td>
                                            Jurusan : {{ program_studi.jurusan_nama }}<br>
                                            ( {{ program_studi.id }} ) {{ program_studi.nama }}
                                        </td>
                                        <td class="pe-5">
                                        </td>
                                    </tr>
                                </template>
                            </table-basic>
                        </template>
                    </card-basic>
                    <!-- end::list -->

                </template>
                
                <template #right>

                    <card-basic>
                        <template #content>
                            <div class="p-5">
                                <label-basic name="Form Program Studi" />

                                <form @submit.prevent="storeProgramStudi">
                                    <div class="form-group mb-2">
                                        <label for="dm_jurusan_id" class="col-form-label pb-2">Jurusan</label>
                                        <select name="dm_jurusan_id" id="dm_jurusan_id" class="form-select" v-model="form.dm_jurusan_id">
                                            <option v-for="(jurusan, index) in data.jurusan" :key="index" :value="jurusan.id">{{ jurusan.nama }}</option>
                                        </select>
                                    </div>

                                    <div class="form-group mb-2">
                                        <label for="id" class="col-form-label pb-2">ID Program Studi</label>
                                        <input type="text" name="id" id="id" class="form-control" v-model="form.id" required>
                                    </div>

                                    <div class="form-group mb-2">
                                        <label for="nama" class="col-form-label pb-2">Nama Program Studi</label>
                                        <textarea name="nama" id="nama" rows="3" class="form-control" v-model="form.nama" required></textarea>
                                    </div>

                                    <input type="submit" value="Simpan" class="btn btn-success w-100 mt-5" required>
                                </form>
                            </div>
                        </template>
                    </card-basic>

                </template>
            </container-double>
            <!-- end::container-double -->

        </template>
    </container-app>
    <!-- end::container-app -->
</template>

<script>
// apis
import {indexLembaga, storeLembaga} from '@/apis/lembaga';
import {indexJurusan} from '@/apis/jurusan';
import {indexProgramStudi, storeProgramStudi} from '@/apis/programStudi';

export default {
    beforeMount() {
        localStorage.setItem('menu', 'AISnet')
        localStorage.setItem('halaman', 'Program Studi')
    },
    data() {
        return {
            keyword: '',
            form: {},
            data: {}
        }
    },
    mounted() {
        this.indexJurusan();
        this.indexProgramStudi();
    },
    methods: {
        async indexProgramStudi() {
            try {
                const res = await indexProgramStudi();
                this.data.program_studi = res.data.data;
                
            } catch (err) {
                console.error(err);
            }
        },
        async indexJurusan() {
            try {
                const res = await indexJurusan();
                this.data.jurusan = res.data.data;
            } catch(err) {
                console.error(err);
            }
        },
        storeProgramStudi() {
            Swal.fire({
                icon: 'question',
                text: 'Simpan data program studi ?',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                confirmButtonText: 'Simpan',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-secondary'
                }
            }).then( async result => {
                if( result.isConfirmed ) {
                    try {
                        const response = await storeProgramStudi(this.form);

                        if( response.status == 200 ) {
                            Swal.fire({
                                icon: 'success',
                                text: 'Data program studi berhasil disimpan',
                                showConfirmButton: false,
                                timer: 5000
                            });

                            this.form = {};
                            
                            this.indexProgramStudi();
                        }
                    } catch ( err ) {
                        console.error( err );
                    }
                }
            })
        }
    }
}

</script>