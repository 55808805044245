<template>
    <container-app :container_loading="container_loading">
        <template #content>
            <div class="row">
                <div class="col-md-8 d-none d-md-block">
                    <card-basic>
                        <template #content>
                            <tables-transkrip :daftar_transkrip="daftar_transkrip" />
                        </template>
                    </card-basic>
                </div>

                <div class="col-12 col-md-4">
                    <card-basic>
                        <template #content>
                            <div class="p-5 pb-0">
                                <label-basic name="Info Grafis IPS" class="text-center" />
                            </div>
                            <div class="p-2 pt-0">
                                <canvas id="renderChart" height="150"></canvas>
                            </div>
                        </template>
                    </card-basic>

                    <card-basic>
                        <template #content>
                            <div class="p-5">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="d-flex align-items-center">
                                            <div class="bg-light-primary border border-1 border-dashed border-primary p-2 symbol symbol-40px">
                                                <icon-list />
                                            </div>
                                            <div class="d-flex flex-column ms-4">
                                                <span class="fw-bold text-muted fs-8">Total SKS</span>
                                                <span class="fw-bolder fs-7">{{ informasi.sks }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="d-flex align-items-center">
                                            <div class="bg-light-primary border border-1 border-dashed border-primary p-2 symbol symbol-40px">
                                                <icon-chart />
                                            </div>
                                            <div class="d-flex flex-column ms-4">
                                                <span class="fw-bold text-muted fs-8">IPK</span>
                                                <span class="fw-bolder fs-7">{{ informasi.ipk }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <tables-transkrip :daftar_transkrip="daftar_transkrip" class="d-block d-md-none" />
                        </template>
                    </card-basic>
                </div>
            </div>
        </template>
    </container-app>
</template>

<script>
// components
import TablesTranskrip from '@/components/_Transkrip/TablesTranskrip.vue';

// apis
import { getTranskrip } from '@/apis/hasilStudi';

// helpers
import { daftar_transkrip } from '@/helpers/transkrip';

import { getUserData } from '@/store';

export default {
    components: {
        TablesTranskrip
    },
    data() {
        return {
            mahasisa : {},
            informasi : {},
            daftar_transkrip : {},
            daftar_ips : [null, null, null, null, null, null, null, null],
            daftar_semester : ['SM1', 'SM2', 'SM3', 'SM4', 'SM5', 'SM6', 'SM7', 'SM8'],
            grafik : null,
            container_loading: true,
        }
    },  
    beforeMount() {
        this.mahasisa = getUserData();
        localStorage.setItem('menu', 'Hasil Studi');
        localStorage.setItem('halaman', 'Transkrip Nilai');
    },
    async mounted() {
        this.getTranskrip();
    },
    methods: {
        async getTranskrip() {
            try {
                const res = await getTranskrip(this.mahasisa.nim);
                this.informasi = {
                    ipk : res.data.ipk,
                    sks : res.data.sks,
                    mahasiswa_nim : res.data.mahasiswa_nim,
                    mahasiswa_foto : res.data.mahasiswa_foto,
                    mahasiswa_nama : res.data.mahasiswa_nama,
                }
                this.daftar_transkrip = daftar_transkrip(res.data.data);
                this.daftar_ips = await res.data.data_ips.map(e => {
                    return e.ips
                });

                this.renderChart();
                this.container_loading = false;
            }
            catch ( err ) {
                console.error( err );
            }
        },
        renderChart() {
            this.grafik = new Chart("renderChart", {
                type: "line",
                data: {
                    labels: this.daftar_semester,
                    datasets: [{
                        backgroundColor: "rgba(0, 158, 247, 0.1)",
                        borderColor: "rgba(25, 29, 52, 0.5)",
                        fill: true,
                        data: this.daftar_ips
                    }]
                },
                options: {
                    plugins: {
                        legend: {
                            display: false,
                        }
                    },
                    scales: {
                        y: {
                            min: 0,
                            max: 4.5,
                        }
                    }
                }
            });

            this.grafik.render();
        }
    }
}
</script>