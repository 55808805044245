<template>
    <div class="card mb-3 shadow-sm">
        <div class="card-body p-6">
            <div class="d-flex">
                <div style="
                                min-width: 82px; 
                                height: 82px; 
                                border-radius: 10px;
                        " class="
                            bg-light-primary 
                            border 
                            border-1
                            border-primary 
                            border-dashed 
                            d-inline-block 
                            text-center
                            d-flex
                            flex-column
                            align-items-center
                            justify-content-center
                            me-3
                        ">
                    <span class="fw-bolder fs-7">Jam</span>
                    <span class="text-gray-700 fs-8">{{ jadwal.jam }}</span>
                </div>
                <div class="d-flex flex-column justify-content-between w-100">
                    <div class="d-flex">
                        <div>
                            <icon-mark />
                        </div>
                        <div>
                            <span class="text-gray-900 fs-7 fw-bolder d-block pt-1">{{ jadwal.hari }}, {{ jadwal.tanggal }}</span>
                            <span class="text-gray-700 fs-8">{{ jadwal.nama_matkul }}</span>
                        </div>
                    </div>
                    <div class="text-gray-700 fs-8 w-100 d-flex justify-content-between">
                        <span>
                            <icon-map />Ruang {{ jadwal.ruang }}
                        </span>
                        <span 
                            data-bs-toggle="collapse" 
                            :href="'#'+jadwal.kode_matkul" 
                            role="button" 
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            @click="handleDetail"
                            >
                            Detail
                            <icon-arrow-bottom v-if="!detail" />
                            <icon-arrow-up v-else />
                        </span>
                    </div>
                </div>
            </div>
            <div class="collapse" :id="jadwal.kode_matkul">
                <div class="text-gray-700 mt-4 pt-3 border-top fs-8">
                    <div class="d-flex justify-content-between">
                        <span>Kelas Ujan {{ jadwal.kelas }}</span>
                        <span>{{ jadwal.kode_matkul }} - {{ jadwal.sks }} SKS</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['jadwal'],
    data() {
        return {
            detail : false
        }
    },
    methods: {
        handleDetail() {
            this.detail = !this.detail
        }
    }
}
</script>