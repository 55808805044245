import { api, headers } from "../api";

const RESOURCE = "penilaian";

export const getTahunSemester = () =>
  api.get(`course-contract/get-academic-years`, headers);

export const utsCekJadwalStatus = (tahun_semester) =>
  api.get(RESOURCE + `/uts/status?tahun_semester=${tahun_semester}`, headers);

export const utsDaftar = (tahun_semester, nama_mata_kuliah = null) => {
  const params = nama_mata_kuliah
    ? `/uts?tahun_semester=${tahun_semester}&nama_mata_kuliah=${encodeURIComponent(
        nama_mata_kuliah
      )}`
    : `/uts?tahun_semester=${tahun_semester}`;

  return api.get(`${RESOURCE}${params}`, headers);
};

export const utsDetail = (params) => {
  const {
    tahun_semester,
    kode_mata_kuliah,
    kelas,
    kelas_ujian,
    mahasiswa = null,
  } = params;

  const queryParams = new URLSearchParams({
    tahun_semester,
    kode_mata_kuliah,
    kelas,
    kelas_ujian,
  });

  if (mahasiswa) {
    queryParams.append("mahasiswa", mahasiswa);
  }

  return api.get(`${RESOURCE}/uts/detail?${queryParams.toString()}`, headers);
};

export const utsSimpan = (payload) => {
  return api.post(`${RESOURCE}/uts/detail/simpan`, payload, headers);
};

export const utsKunci = (payload) => {
  return api.post(`${RESOURCE}/uts/detail/kunci`, payload, headers);
};

export const utsLog = (t_nilai_id) => {
  return api.get(
    `${RESOURCE}/uts/detail/log?t_nilai_id=${t_nilai_id}`,
    headers
  );
};

export const utsDownload = (payload) =>
  api.post(`${RESOURCE}/uts/detail/download`, payload, {
    ...headers,
    responseType: "arraybuffer",
  });

export const utsMonitoring = (tahun_semester, filter = {}) => {
  const {
    nama = null,
    page = null,
    page_item = null,
    divalidasi = null,
    dikunci = null,
  } = filter;

  let params = `/uts/monitoring?tahun_semester=${tahun_semester}`;

  if (nama) params += `&nama=${encodeURIComponent(nama)}`;
  if (page !== null) params += `&page=${page}`;
  if (page_item !== null) params += `&page_item=${page_item}`;
  if (divalidasi !== null) params += `&divalidasi=${divalidasi}`;
  if (dikunci !== null) params += `&dikunci=${dikunci}`;

  return api.get(`${RESOURCE}${params}`, headers);
};

export const utsMonitoringBuka = (payload) => {
  return api.post(`${RESOURCE}/uts/monitoring/buka`, payload, headers);
};

export const utsMonitoringValidasi = (payload) => {
  return api.post(`${RESOURCE}/uts/monitoring/validasi`, payload, headers);
};

export const utsNilai = (tahun_semester) =>
  api.get(`${RESOURCE}/uts/hasil?tahun_semester=${tahun_semester}`, headers);

export const utsPencairan = (tahun_semester, filter = {}) => {
  const {
    nama = null,
    page = null,
    page_item = null,
    divalidasi = null,
    dikunci = null,
  } = filter;

  let params = `/uts/pencairan?tahun_semester=${tahun_semester}`;

  if (nama) params += `&nama=${encodeURIComponent(nama)}`;
  if (page !== null) params += `&page=${page}`;
  if (page_item !== null) params += `&page_item=${page_item}`;
  if (divalidasi !== null) params += `&divalidasi=${divalidasi}`;
  if (dikunci !== null) params += `&dikunci=${dikunci}`;

  return api.get(`${RESOURCE}${params}`, headers);
};
