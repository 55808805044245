<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <div class="p-5">
            <div
              v-if="!isStarted"
              class="alert alert-primary d-flex align-items-center p-5"
            >
              <div class="d-flex flex-column">
                <span>Penilaian Ujian Tengah Semester (UTS) belum dibuka</span>
              </div>
            </div>
            <alert-icon
              v-if="isStarted"
              :message="
                expire.expired
                  ? 'Penilaian Ujian Tengah Semester (UTS) telah ditutup pada tanggal '
                  : 'Penilaian Ujian Tengah Semester (UTS) ditutup pada tanggal '
              "
              :bold="
                $date(expire.tgl_tutup)
                  .locale('id')
                  .format('dddd, D MMMM YYYY [pukul] HH:mm [WIB]')
              "
              :type="expire.expired ? 'danger' : 'warning'"
            />
            <div
              v-if="daftarMataKuliahDosen.length === 0"
              class="alert alert-primary d-flex align-items-center p-5"
            >
              <div class="d-flex flex-column">
                <span
                  >Penilaian Ujian Tengah Semester (UTS) belum tersedia</span
                >
              </div>
            </div>
            <label-header value="Penilaian Ujian Tengah Semester (UTS) Susulan">
              <template #control>
                <select
                  class="form-select form-select-solid"
                  data-control="selected_tahun_semester"
                  data-placeholder="Select an option"
                  v-model="selected_tahun_semester"
                  @change="onSelectedAcademicChange($event)"
                >
                  <option
                    v-for="(item, i) in tahunAkademik"
                    :key="i"
                    :value="item.thsms"
                  >
                    Tahun {{ item.ket }}
                  </option>
                </select>
              </template>
            </label-header>
          </div>
          <table-basic>
            <template #thead>
              <tr class="fw-bolder bg-light fs-7">
                <th class="ps-4 w-50px rounded-start">No</th>
                <th class="min-w-100px">Mata Kuliah</th>
                <th class="min-w-100px">Program Studi</th>
                <th class="min-w-100px">Kelas</th>
                <th class="min-w-100px">Kelas Ujian</th>
                <th class="min-w-100px">Status</th>
                <th class="rounded-end"></th>
              </tr>
            </template>

            <template #tbody>
              <tr
                v-for="(item, i) in daftarMataKuliahDosen"
                :key="i"
                v-if="isStarted"
              >
                <td class="text-center align-middle">{{ i + 1 }}</td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.NAKMK }}
                  </span>
                  <span class="text-muted text-muted d-block fs-8">
                    {{ item.KDKMK }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.NMPST }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.KELAS }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bolder d-block mb-1 fs-7">
                    {{ item.kelas_ujian }}
                  </span>
                </td>
                <td class="align-middle">
                  <span class="text-dark fw-bold d-block mb-1 fs-7">
                    Dosen Mengunci Nilai:
                    <span v-if="item.isLocked" class="text-success">
                      <icon-done />
                    </span>
                    <span v-else class="text-danger"><icon-cross /></span>
                  </span>

                  <span class="text-dark fw-bold d-block mb-1 fs-7">
                    Validasi Program Studi:
                    <span v-if="item.isValidated" class="text-success">
                      <icon-done />
                    </span>
                    <span v-else class="text-danger"><icon-cross /></span>
                  </span>
                </td>
                <td class="align-middle">
                  <a
                    @click="
                      getDetailUTS(
                        item.THSMS,
                        item.KDKMK,
                        item.NAKMK,
                        item.KELAS,
                        item.kelas_ujian
                      )
                    "
                    class="mt-5 mt-lg-0 btn btn-sm btn-secondary btn-active-primary"
                  >
                    Detail
                  </a>
                </td>
              </tr>
            </template>
          </table-basic>
        </template>
      </card-basic>
    </template>
  </container-app>
</template>

<script>
import { getUserData } from "@/store";
import { useMataKuliahStore } from "@/stores/mataKuliah";
import "dayjs/locale/id";

// API
import { getNidn, getAcademicYearsLecturer } from "@/apis/kontrakPerkuliahan";

import { getUTSSusulan, getJadwalPengisianNilai } from "@/apis/penilaianUjian";

export default {
  setup() {
    const mataKuliah = useMataKuliahStore();

    return {
      mataKuliah,
    };
  },
  data() {
    return {
      container_loading: false,
      daftarMataKuliahDosen: [],
      tahunAkademik: [],
      selected_tahun_semester: "",
      selected_nidn: "",
      selected_id: null,
      expire: {},
      isStarted: false,
    };
  },

  mounted() {
    this.loadData();
  },

  methods: {
    async onSelectedAcademicChange(event) {
      await this.UTSSusulanFetch(this.selected_nidn, event.target.value);
      await this.fetchJadwalPengisianNilai(this.selected_tahun_semester, "UTS");
    },

    async loadData() {
      await this.getsTahunAkademik();
      await this.nidnFetch(getUserData().nodos);
      await this.UTSSusulanFetch(
        this.selected_nidn,
        this.selected_tahun_semester
      );
      this.fetchJadwalPengisianNilai(this.selected_tahun_semester, "UTS");
    },
    async getsTahunAkademik() {
      try {
        const response = await getAcademicYearsLecturer();
        this.tahunAkademik = response.data.data;
        this.selected_tahun_semester = response.data.data[0].thsms;
      } catch (err) {
        console.error(err);
      }
    },

    async nidnFetch(nodos) {
      try {
        const response = await getNidn(nodos);
        this.selected_nidn = response.data.data.nidn;
      } catch (err) {
        console.error(err);
      }
    },

    async UTSSusulanFetch(nidn, academic_year) {
      try {
        this.container_loading = true;
        const response = await getUTSSusulan(nidn, academic_year);
        this.daftarMataKuliahDosen = response.data.data;
        this.container_loading = false;
      } catch (err) {
        console.error(err);
        this.container_loading = false;
      }
    },

    getDetailUTS(
      tahun_semester,
      kode_mata_kuliah,
      mata_kuliah,
      kelas,
      kelas_ujian
    ) {
      this.mataKuliah.set(
        tahun_semester,
        kode_mata_kuliah,
        mata_kuliah,
        kelas,
        kelas_ujian,
        this.expire.expired
      );
      this.$router.push({
        path: `/penilaian-uts-susulan/detail`,
      });
    },

    async fetchJadwalPengisianNilai(tahun_semester, jenis_ujian) {
      try {
        this.container_loading = true;
        const response = await getJadwalPengisianNilai();
        if (response.status === 200) {
          this.daftarJadwalPengisianNilai = response.data.data;
          response.data.data.forEach((element) => {
            if (
              element.tahun_semester == tahun_semester &&
              element.jenis_ujian == jenis_ujian
            ) {
              this.expire = element;
              this.isStarted = true;
            }
          });
          this.container_loading = false;
        } else if (response.status === 404) {
          Swal.fire({
            text: response.data.message,
            icon: "warning",
          });
          this.container_loading = false;
        }
      } catch (error) {
        this.container_loading = false;
        Swal.fire({
          text: error.response.data.message,
          icon: "error",
        });
      }
    },
  },
};
</script>
