import {api, headers} from './api';

const RESOURCE = 'pengusulan_nilai';

export const dataPengusulanNilai = (tr_pengusulan_id) => api.get(RESOURCE+'/data/'+tr_pengusulan_id, headers);

export const storePengusulanNilai = (data) => api.post(RESOURCE, data, headers);

export const storeCatatanPengusulanNilai = (data) => api.post(RESOURCE+'/catatan', data, headers);

export const jumlahPengusulanNilai = (data) => api.post(RESOURCE+'/jumlah', data, headers);

export const kunciPengusulanNilai = (data) => api.post(RESOURCE+'/kunci', data, headers);