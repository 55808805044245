<template>
    <table-basic>
        <template #thead>
            <tr class="fw-bolder bg-light fs-7">
                <th class="ps-4 w-50px rounded-start">No</th>
                <th>Mata Kuliah</th>
                <th class="w-100px">Presentasi</th>
                <th class="pe-4 rounded-end">Aksi</th>
            </tr>
        </template>

        <template #tbody>
            <tr v-for="(absen, i) in daftar_absen" :key="i" class="fs-7">
                <td class="ps-4 text-end">{{ i+1 }}</td>
                <td>
                    <span class="text-dark fw-bolder d-block mb-1">{{ absen.nama_matkul }}</span>
                    <span class="text-muted d-block fs-8">{{ absen.kode_matkul }}</span>
                </td>
                <td>
                    <span class="fw-bolder">{{ absen.presentasi }} %</span>
                    <div class="progress h-6px w-100">
                        <div 
                            class="progress-bar" 
                            :class="absen.presentasi.replace(',', '.') > 75 ? 'bg-success' : absen.presentasi.replace(',', '.') < 75 ? 'bg-danger' : 'bg-warning'"
                            role="progressbar" :style="{'width': absen.presentasi.replace(',', '.')+'%'}" :aria-valuenow="absen.presentasi" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </td>
                <td>
                    <button-basic name="Detail" class="me-2 btn-sm bg-secondary" data-bs-toggle="modal" data-bs-target="#modalDetail" @click="modalDetail(absen.kode_matkul)" />
                </td>
            </tr>
        </template>
    </table-basic>
</template>

<script>
export default {
    props: ['daftar_absen'],
    emits: ['modalDetail'],
    methods: {
        modalDetail(kode_matkul) {
            this.$emit('modalDetail', kode_matkul)
        }
    }
}
</script>