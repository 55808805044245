<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs></defs>
    <g id="Abstract_Elements" data-name="Abstract Elements" fill="currentColor">
      <g id="abs001-049">
        <g id="abs031-040">
          <g id="abs037">
            <path
              class="cls-1"
              d="M2.2,10.05a10,10,0,0,1,9.08-8L8.86,4.45l2.28,2.63A5,5,0,0,0,7.61,9.63L4.54,12.39Zm17.24,1.54-2.91,2.46a5,5,0,0,1-3.69,2.87l2.3,2.62L12.71,22a10,10,0,0,0,9.09-8Z"
            />
            <path
              d="M14,2.2a10,10,0,0,1,8,9.08L19.56,8.87l-2.64,2.24a5,5,0,0,0-2.76-3.59l-2.56-3ZM12.4,19.46l-2.64-3A5,5,0,0,1,7.07,12.8L4.46,15.15,2,12.72a10,10,0,0,0,8,9.08Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<style>
.cls-1 {
  opacity: 0.3;
}
</style>
