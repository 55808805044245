<template>
    <container-app :container_loading="container_loading">
        <template #content>
            <container-double>
                <template #left>
                    <div class="d-block d-md-none">
                        <card-basic>
                            <template #content>
                                <div class="p-5">
                                    <label-header label="Semester/TA Sekarang" :value="ta.ket">
                                        <template #control>
                
                                            <button-icon name="Filter" class="btn-sm bg-secondary" data-bs-toggle="modal" data-bs-target="#modalFilter">
                                                <template #icon>
                                                    <icon-filter />
                                                </template>
                                            </button-icon>
                                            
                                        </template>
                                    </label-header>
                                </div>
                            </template>
                        </card-basic>
                    </div>

                    <card-basic>
                        <template #content>
                            <div class="p-5">
                                <div class="d-none d-md-block">
                                    <label-header label="Semester/TA Sekarang" :value="ta.ket">
                                        <template #control>
                
                                            <button-icon name="Filter" class="btn-sm bg-secondary" data-bs-toggle="modal" data-bs-target="#modalFilter">
                                                <template #icon>
                                                    <icon-filter />
                                                </template>
                                            </button-icon>
                                            
                                        </template>
                                    </label-header>
                                </div>


                                <div class="d-block d-md-none">
                                    <card-info :informasi="informasi" />
                                </div>
                            </div>

                            <tables-khs :daftar_khs="daftar_khs" />

                        </template>
                    </card-basic>
                </template>

                <template #right>
                    <card-basic>
                        <template #content>
                            <div class="p-5">
                                <card-info :informasi="informasi" />
                            </div>
                        </template>
                    </card-basic>
                </template>
            </container-double>
        </template>
    </container-app>

    <!-- begin::modal -->
    <modal-basic id="modalFilter" title="Filter">
        <template #content>
            <label for="basic-url" class="form-label">Tahun Akademik</label>
            <div class="input-group mb-5">
                <select class="form-select form-select-solid" v-model="filter.thsms">
                    <option v-for="(ta, index) in daftar_ta" :key="index" :value="ta.thsms">Tahun {{ ta.ket }}</option>
                </select>
            </div>
        </template>

        <template #button>
            <button-basic class="bg-primary text-light" name="Simpan" @click="processFilter" />
        </template>
    </modal-basic>
    <!-- end::modal -->
</template>

<script>
    // components
    import TablesKhs from '@/components/_khs/TablesKhs.vue';
    import CardInfo from '@/components/_khs/CardInfo.vue';

    // apis
    import { getsTahunAkademik } from '@/apis/tahun';
    import { getKhs } from '@/apis/hasilStudi';

    export default {
        components: {
            TablesKhs,
            CardInfo
        },
        data() {
            return {
                daftar_ta : [],
                ta : {},
                filter : {},
                informasi : {},
                daftar_khs : {},
                container_loading : true
            }
        },
        beforeMount() {
            localStorage.setItem('menu', 'Hasil Studi')
            localStorage.setItem('halaman', 'Kartu Hasil Studi')
        },
        mounted() {
            this.getData();
        },
        methods: {
            async getData() {
                await this.getsTahunAkademik();
                this.getKhs();
            },
            async getsTahunAkademik() {
                try {
                    const response = await getsTahunAkademik();
                    this.daftar_ta = response.data.data;
                    this.ta = this.daftar_ta[0];
                    this.filter.thsms = this.daftar_ta[0].thsms;
                    
                } catch ( err ) {
                    console.error( err );
                }
            },
            processFilter() {
                const filter = this.daftar_ta.filter( e => e.thsms == this.filter.thsms );
                this.ta = filter[0];
                this.getKhs();
            },
            async getKhs() {
                try {
                    const response = await getKhs( this.ta.thsms );
                    this.informasi = {
                        sks : response.data.sks,
                        ips : response.data.ips.toFixed(2)
                    };
                    this.daftar_khs = response.data.data;
                    this.container_loading = false;
                } catch ( err ) {
                    console.error( err );
                }
            }
        }
    }
</script>