<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <TabelKonfigurasiJadwal :data="daftarJadwalPengisianNilai" :getData="fetchJadwalPengisianNilai" />
    </template>
  </container-app>
</template>

<script>
// Components
import TabelKonfigurasiJadwal from "@/components/_penilaianUjian/monitoring/TabelKonfigurasiJadwal.vue";

// API
import { getJadwalPengisianNilai } from "@/apis/penilaianUjian";

export default {
  components:{
    TabelKonfigurasiJadwal
  },
  setup() {
      return {
      }
  },
  data() {
    return {
      container_loading: false,
      daftarJadwalPengisianNilai: []
    };
  },

  mounted() {
    this.fetchJadwalPengisianNilai()
  },

  methods: {
    async fetchJadwalPengisianNilai() {
        try {
          this.container_loading = true;
          const response = await getJadwalPengisianNilai();
          if (response.status === 200) {
            this.daftarJadwalPengisianNilai = response.data.data;
  
            this.container_loading = false;
          } else if (response.status === 404) {
            Swal.fire({
              text: response.data.message,
              icon: "warning",
            });
            this.container_loading = false;
          }
        } catch (error) {
          this.container_loading = false;
          Swal.fire({
            text: error.response.data.message,
            icon: "error",
          });
        }
    },
  }
};
</script>
