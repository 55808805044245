<template>
    <label-basic name="Riwayat Pembayaran" class="pb-3" />
    <div class="rounded bg-white p-2 shadow-sm mb-3" v-for="(riwayat, i) in daftar_riwayat" :key="i">
        <div class="d-flex align-items-center">
            <div class="bg-light-dark border border-1 border-dashed border-dark p-2 symbol symbol-40px">
                <icon-money />
            </div>
            <div class="d-flex flex-column ms-4 w-100">
                <span class="fw-bold fs-7">{{ riwayat.pembayaran }}</span>
                <span class="text-muted fs-8 d-flex justify-content-between">
                    <div>Rp. {{ riwayat.jumlah }}</div>
                    <div>Tanggal {{ riwayat.tanggal }}</div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['daftar_riwayat']
}
</script>