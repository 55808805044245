<template>
    <div style="background: linear-gradient(112.14deg, #3A7BD5 100%, #00D2FF 0%);">
        <div class="container">
            <div class="d-flex flex-column justify-content-center" style="min-height: 100vh; max-height: 100vh">
                <div class="row p-0 w-100 m-0">
                    <div class="col-md-8 d-none d-md-block">
                        <div class="ms-6 text-white">
                            <p class="fs-6 mb-0">Selamat datang di</p>
                            <p class="fs-3 fw-bold mb-3">AISnet Web Institut Teknologi Garut</p>
                        </div>
                    </div>
    
                    <div class="col-12 col-md-4">
                        <div class="mb-7 d-flex align-items-center justify-content-md-center justify-content-between">
                            <img src="@/assets/logo.png" class="h-40px" />
                            <button class="btn d-block text-light d-md-none p-0" data-bs-toggle="modal" data-bs-target="#modalLayanan">
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                    <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"/>
                                    <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
    
                <div class="row p-0 w-100 m-0">
                    <div class="col-md-8 d-none d-md-block">
                        <div class="rounded bg-white border border-secondary px-6 py-5">
                            <info-layanan />
                            <div class="mt-4">
                                <p class="mb-1">Unduh Aplikasi AISnet</p>
                                <div class="d-flex gap-3">
                                        <a href="https://play.google.com/store/apps/details?id=com.itg.aisnetforstudent&pcampaignid=web_share" target="_blank" class="btn btn-icon-dark btn-text-dark btn-primary">
                                            <span class="svg-icon svg-icon-1">
                                                <icon-logo-android />
                                            </span>
                                            AISnet for Student
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.itg.aisnetlecturer&pcampaignid=web_share" target="_blank" class="btn btn-icon-dark btn-text-dark btn-success">
                                            <span class="svg-icon svg-icon-1">
                                                <icon-logo-android />
                                            </span>
                                            AISnet for Lecturer
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.itg.aisnetparents&pcampaignid=web_share" target="_blank" class="btn btn-icon-dark btn-text-dark btn-danger">
                                            <span class="svg-icon svg-icon-1">
                                                <icon-logo-android />
                                            </span>
                                            AISnet for Parent
                                        </a>
                                </div>
                            </div>
    
                        </div>
                    </div>
    
                    <div class="col-12 col-md-4">
                        <form class="w-100" @submit.prevent="proccessLogin">
                            <div class="fv-row mb-7">
                                <label class="form-label fs-6 text-white fw-normal">Username</label>
                                <div class="position-relative">
                                    <div class="position-absolute translate-middle-y top-50 ms-3">
                                        <span class="svg-icon svg-icon-2hx">
                                            <icon-guard-user />
                                        </span>
                                    </div>
    
                                    <input 
                                        class="form-control form-control-lg fw-normal ps-15 border-0 rounded-2" 
                                        type="text" v-model="form.email"
                                        placeholder="Masukan username anda disini" autofocus />
                                </div>
                            </div>
    
                            <div class="fv-row mb-10 mb-md-15">
                                <label class="form-label fs-6 text-white fw-normal">Password</label>
                                <div class="position-relative">
                                    <div class="position-absolute translate-middle-y top-50 ms-3">
                                        <span class="svg-icon svg-icon-2hx">
                                            <icon-guard-password />
                                        </span>
                                    </div>
    
                                    <input 
                                        class="form-control form-control-lg fw-normal ps-15 border-0 rounded-2" 
                                        type="password" v-model="form.password" 
                                        placeholder="Masukan password anda disini" />
                                </div>
                            </div>
    
                            <button class="btn btn-primary w-100" type="submit" :disabled="loading">
                                <div v-if="loading">
                                    <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                    Tunggu Sebentar
                                </div>
                                <div v-else>
                                    Masuk
                                </div>
                            </button>
    
                            <div class="d-block d-md-none">
                                <p class="fs-6 mt-15 mb-2 text-center text-white">Atau unduh aplikasi AISnet</p>
        
                                <div class="d-flex flex-column gap-3">
                                    <a href="https://play.google.com/store/apps/details?id=com.itg.aisnetforstudent&pcampaignid=web_share" target="_blank" class="btn btn-icon-dark btn-text-dark btn-secondary w-100">
                                        <span class="svg-icon svg-icon-1">
                                            <icon-logo-android />
                                        </span>
                                        AISnet for Student
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.itg.aisnetlecturer&pcampaignid=web_share" target="_blank" class="btn btn-icon-dark btn-text-dark btn-secondary w-100">
                                        <span class="svg-icon svg-icon-1">
                                            <icon-logo-android />
                                        </span>
                                        AISnet for Lecturer
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.itg.aisnetparents&pcampaignid=web_share" target="_blank" class="btn btn-icon-dark btn-text-dark btn-secondary w-100">
                                        <span class="svg-icon svg-icon-1">
                                            <icon-logo-android />
                                        </span>
                                        AISnet for Parent
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- begin::modal -->
    <!-- begin::layanan -->
    <modal-fullscreen-sm id="modalLayanan" title="Layanan AISnet">
        <template #content>
            <info-layanan />
        </template>
    </modal-fullscreen-sm>
    <!-- end::layanan -->
    <!-- end::modal -->
</template>

<script>
    import {
        setState
    } from '@/store';

    // apis
    import {
        authLogin
    } from '@/apis/auth';

    export default {
        data() {
            return {
                form: {
                    email: '',
                    password: ''
                },
                loading: false
            }
        },
        methods: {
            async proccessLogin() {
                this.loading = true;
                try {
                    if (this.form.email.length == 0 || this.form.password.length == 0) {
                        Swal.fire({
                            icon: 'error',
                            text: 'Username atau Password tidak boleh kosong',
                            showConfirmButton: false,
                            timer: 5000
                        })
                    } else {
                        const data = await authLogin(this.form);
                        if (data.data.status == 'success') {
                            setState({
                                accessToken: data.data.content.access_token,
                            });

                            setState({
                                accessToken: data.data.content.access_token,
                                user: {
                                    nama: '',
                                    email: '',
                                    nim: '',
                                    peran: data.data.content.peran,
                                    akses: data.data.content.akses,
                                    foto: '/img/blank.aad363d0.png',
                                }
                            });

                            window.location.href = '/';
                        }
                    }
                } catch (err) {
                    Swal.fire({
                        icon: 'error',
                        text: 'Username atau Password salah',
                        showConfirmButton: false,
                        timer: 5000
                    })
                }
                this.loading = false;
            }
        }
    }
</script>