<template>
  <menu-dropdown nama="Kuliah" label="/jadwal">
    <template #icon>
      <icon-calender />
    </template>

    <template #items>
      <menu-item nama="Jadwal Kuliah" url="/jadwal-kuliah" />
      <menu-item
        v-if="this.user.peran == 'MAHASISWA'"
        nama="Kontrak Perkuliahan"
        url="/kontrak-perkuliahan-new"
      />
      <menu-item nama="Rekap Absen" url="/jadwal-kuliah-absen" />
      <menu-item nama="Jadwal UTS" url="/jadwal-uts" />
      <menu-item nama="Jadwal UAS" url="/jadwal-uas" />
    </template>
  </menu-dropdown>

  <menu-basic nama="Keuangan" url="/keuangan">
    <template #icon>
      <icon-wallet />
    </template>
  </menu-basic>

  <menu-basic 
    v-if="this.user.peran == 'MAHASISWA'"
    nama="Perwalian" url="/perwalian">
    <template #icon>
      <icon-bill />
    </template>
  </menu-basic>

  <menu-dropdown nama="Hasil Studi" label="/nilai">
    <template #icon>
      <icon-chart />
    </template>

    <template #items>
      <menu-item nama="Nilai UTS" url="/nilai-uts" />
      <menu-item nama="Nilai UAS" url="/nilai-uas" />
      <menu-item nama="Kartu Hasil Studi" url="/nilai-khs" />
      <menu-item nama="Transkrip Nilai" url="/nilai-transkrip" />
    </template>
  </menu-dropdown>
  <menu-dropdown nama="Ijazah" label="/ijazah" class="d-none">
    <template #icon>
      <icon-dokumen />
    </template>

    <template #items>
      <menu-item nama="Pengajuan Ijazah" url="/pengajuan-ijazah" />
      <!-- <menu-item nama="Wisuda" url="/nilai-transkrip" />
            <menu-item nama="Tracer Alumni" url="/nilai-transkrip" /> -->
    </template>
  </menu-dropdown>

  <menu-basic 
    v-if="this.user.peran == 'MAHASISWA'"
    nama="Hotline" url="/hotline">
    <template #icon>
      <icon-chat />
    </template>
  </menu-basic>
</template>

<script>
// store
import { getUserData } from '@/store';

export default {
    data() {
        return {
            user : {
                peran : '-'
            }
        }
    },
    mounted() {
        this.user.peran = getUserData().peran;
    }
}

</script>