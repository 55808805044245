import { api, headers } from "../api";

const RESOURCE = "penilaian";

export const getTahunSemester = () =>
  api.get(`course-contract/get-academic-years`, headers);

export const uasCekJadwalStatus = (tahun_semester) =>
  api.get(RESOURCE + `/uas/status?tahun_semester=${tahun_semester}`, headers);

export const uasDaftar = (tahun_semester, nama_mata_kuliah = null) => {
  const params = nama_mata_kuliah
    ? `/uas?tahun_semester=${tahun_semester}&nama_mata_kuliah=${encodeURIComponent(
        nama_mata_kuliah
      )}`
    : `/uas?tahun_semester=${tahun_semester}`;

  return api.get(`${RESOURCE}${params}`, headers);
};

export const uasDetail = (params) => {
  const {
    tahun_semester,
    kode_mata_kuliah,
    kelas,
    kelas_ujian,
    mahasiswa = null,
  } = params;

  const queryParams = new URLSearchParams({
    tahun_semester,
    kode_mata_kuliah,
    kelas,
    kelas_ujian,
  });

  if (mahasiswa) {
    queryParams.append("mahasiswa", mahasiswa);
  }

  return api.get(`${RESOURCE}/uas/detail?${queryParams.toString()}`, headers);
};

export const uasSimpan = (payload) => {
  return api.post(`${RESOURCE}/uas/detail/simpan`, payload, headers);
};

export const uasKunci = (payload) => {
  return api.post(`${RESOURCE}/uas/detail/kunci`, payload, headers);
};

export const uasLog = (t_nilai_id) => {
  return api.get(
    `${RESOURCE}/uas/detail/log?t_nilai_id=${t_nilai_id}`,
    headers
  );
};

export const uasDownload = (payload) =>
  api.post(`${RESOURCE}/uas/detail/download`, payload, {
    ...headers,
    responseType: "arraybuffer",
  });

export const uasMonitoring = (tahun_semester, filter = {}) => {
  const {
    nama = null,
    page = null,
    page_item = null,
    divalidasi = null,
    dikunci = null,
  } = filter;

  let params = `/uas/monitoring?tahun_semester=${tahun_semester}`;

  if (nama) params += `&nama=${encodeURIComponent(nama)}`;
  if (page !== null) params += `&page=${page}`;
  if (page_item !== null) params += `&page_item=${page_item}`;
  if (divalidasi !== null) params += `&divalidasi=${divalidasi}`;
  if (dikunci !== null) params += `&dikunci=${dikunci}`;

  return api.get(`${RESOURCE}${params}`, headers);
};

export const uasMonitoringBuka = (payload) => {
  return api.post(`${RESOURCE}/uas/monitoring/buka`, payload, headers);
};

export const uasMonitoringValidasi = (payload) => {
  return api.post(`${RESOURCE}/uas/monitoring/validasi`, payload, headers);
};

export const uasNilai = (tahun_semester) =>
  api.get(`${RESOURCE}/uas/hasil?tahun_semester=${tahun_semester}`, headers);

export const uasPencairan = (tahun_semester, filter = {}) => {
  const {
    nama = null,
    page = null,
    page_item = null,
    divalidasi = null,
    dikunci = null,
  } = filter;

  let params = `/uas/pencairan?tahun_semester=${tahun_semester}`;

  if (nama) params += `&nama=${encodeURIComponent(nama)}`;
  if (page !== null) params += `&page=${page}`;
  if (page_item !== null) params += `&page_item=${page_item}`;
  if (divalidasi !== null) params += `&divalidasi=${divalidasi}`;
  if (dikunci !== null) params += `&dikunci=${dikunci}`;

  return api.get(`${RESOURCE}${params}`, headers);
};
