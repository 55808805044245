<template>
    <button type="button" class="btn d-flex align-items-center px-3 px-md-4">
        <slot name="icon"></slot><span class="d-none d-md-inline ms-2">{{ name }}</span>
    </button>
</template>

<script>
export default {
    props: ['name']
}
</script>