<template>
    <perwalian-admin v-if="user.peran == 'ADMIN'" />
    <perwalian-dosen v-else-if="user.peran == 'DOSEN'" />
    <perwalian-mahasiswa v-else-if="user.peran == 'MAHASISWA'" />
    <perwalian-prodi v-else-if="prodi.includes(user.peran)" />
</template>

<script>

// views
import PerwalianAdmin from './PerwalianAdmin.vue';
import PerwalianDosen from './PerwalianDosen.vue';
import PerwalianMahasiswa from './PerwalianMahasiswa.vue';
import PerwalianProdi from './PerwalianProdi.vue'

// store
import { getUserData } from '@/store'

export default {
    components: {
        PerwalianAdmin,
        PerwalianDosen,
        PerwalianMahasiswa,
        PerwalianProdi
    },
    data() {
        return {
            user : {
                peran : '-'
            },
            prodi: [
                'TEKNIK INFORMATIKA',
                'TEKNIK INDUSTRI',
                'TEKNIK SIPIL',
                'SISTEM INFORMASI',
                'ARSITEKTUR'
            ]
        }
    },
    mounted() {
        if( getUserData().peran == 'ADMIN' ) {
            this.user.peran = getUserData().nama.toUpperCase();
        } else {
            this.user.peran = getUserData().peran
        }
    }
}

</script>