import { api, headers } from "./api";

const RESOURCE = 'ijazah';

export const getStatusForm = (nim) => api.get(RESOURCE+'/status?nim='+nim, headers);
export const getPermintaan = (nim) => api.get(RESOURCE+'/pengajuan?nim='+nim, headers);
export const updatePengajuan = (id, data) => api.post(RESOURCE+'/pengajuan/'+id, data, headers);
export const getPengajuanAll = (filter) => api.get(RESOURCE+'/pengajuan-all?filter='+filter, headers);
export const getActiveStatus = (nim) => api.get(RESOURCE+'/active?nim='+nim, headers);
export const getLogs = (nim) => api.get(RESOURCE+'/logs/'+nim, headers);
export const storeItemForm = (data) => api.post(RESOURCE+'/store', data, headers);
export const ajukan = (data) => api.post(RESOURCE+'/ajukan', data, headers);
export const verifikasi = (data) => api.post(RESOURCE+'/verifikasi', data, headers);
export const getPembayaranRincian = (tahun) => api.get(RESOURCE+'/'+tahun+'/rincian', headers);
export const getArsip = (nim) => api.get(RESOURCE+'/arsip', headers);
export const checkArsip = (data) => api.post(RESOURCE+'/arsip-check', data, headers);
export const storeArsip = (data) => api.post(RESOURCE+'/arsip', data, headers);
export const getPembayaranRiwayat = (tahun) => api.get(RESOURCE+'/'+tahun, headers);