<template>
    <div class="d-flex flex-column flex-root bg-dark" style="min-height: 100vh">
        <div class="d-flex flex-column flex-column-fluid">
            <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                <div class="w-100 w-lg-400px bg-body rounded shadow-sm p-8 p-lg-10 mx-auto">

                    <slot name="content"></slot>

                </div>
            </div>
        </div>
    </div>
</template>