import { api, headers } from "./api";

const RESOURCE = 'perwalian';

export const getMahasiswa = (nodos, tahun, kelas) => api.get(RESOURCE+'/mahasiswa?nodos='+nodos+'&tahun='+tahun+'&kelas='+kelas, headers);

export const getMahasiswaProdi = () => api.get(RESOURCE+'/mahasiswaProdi', headers);

export const searchMahasiswa = (nodos, keyword) => api.get(RESOURCE+'/mahasiswa-search?keyword='+keyword+'&nodos='+nodos, headers);

export const getMahasiswaTahun = (nodos)=> api.get(RESOURCE+'/mahasiswa/tahun?nodos='+nodos, headers);

export const getMahasiswaKelas = (nodos, tahun)=> api.get(RESOURCE+'/mahasiswa/kelas?nodos='+nodos+'&tahun='+tahun, headers);

export const getActive = (mahasiswa) => api.get(RESOURCE+'/active?nim='+mahasiswa.nim, headers);

export const getActiveDosen = () => api.get(RESOURCE+'/active?nim=DOSEN', headers);

export const getsMatakuliah = (nim, semester) => api.get(RESOURCE+'/matakuliah?nim='+nim+'&semester='+semester, headers);

export const getsPerwalian = () => api.get(RESOURCE, headers);

export const getsRekap = () => api.get(RESOURCE+'/recap', headers);

export const storePerwalian = (data) => api.post(RESOURCE, data, headers);

export const updatePerwalian = (id, data) => api.put(RESOURCE+'/'+id, data, headers);