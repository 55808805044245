import axios from "axios";
import { getAccessToken } from "@/store";

const BASE_URL = process.env.VUE_APP_BASE_URL;

export const api = axios.create({
    baseURL: BASE_URL
});

export const headers = {
    headers: {
        'Authorization' : `Bearer ${getAccessToken()}`
    }
}