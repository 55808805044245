<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <div
            id="kartu-header"
            class="p-5 d-flex align-items-center justify-content-between"
          >
            <div id="judul">
              <span class="text-gray-700 mb-2 fw-normal fs-8 d-block">
                Penilaian Hasil Belajar
              </span>
              <span class="fw-bolder fs-5"
                >Monitoring Pencairan UAS Susulan</span
              >
            </div>
            <div id="aksi">
              <select
                class="form-select form-select-solid"
                data-placeholder="Pilih Tahun Semester"
                v-model="selected.tahun_semester"
                @change="onSelectedTahunSemesterChange($event)"
              >
                <option
                  v-for="(item, i) in tahun_semester"
                  :key="i"
                  :value="item.thsms"
                >
                  Tahun {{ item.ket }}
                </option>
              </select>
            </div>
          </div>
          <div id="pencarian" class="mx-5 mb-5">
            <form-icon>
              <template #icon>
                <icon-search />
              </template>
              <template #content>
                <input
                  type="text"
                  class="form-control ps-14 fw-normal"
                  placeholder="Pencarian berdasarkan Mata Kuliah atau nama Dosen"
                  v-model="filters.nama"
                  @keydown.enter="onSearchMataKuliah"
                />
              </template>
            </form-icon>
            <small class="form-text text-muted">
              Tekan Enter untuk memulai pencarian</small
            >
          </div>
          <div id="filter-group" class="form mx-5 mb-5 row">
            <div class="col-2">
              <div class="form-label">Dosen Mengunci Nilai</div>
              <select
                class="form-select form-select-solid"
                data-placeholder="Pilih filter"
                v-model="filters.dikunci"
                @change="onSearchMataKuliah"
              >
                <option
                  v-for="(item, i) in filter_options"
                  :key="i"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
            <div class="col-2">
              <div class="form-label">Validasi Program Studi</div>
              <select
                class="form-select form-select-solid"
                data-placeholder="Pilih filter"
                v-model="filters.divalidasi"
                @change="onSearchMataKuliah"
              >
                <option
                  v-for="(item, i) in filter_options"
                  :key="i"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
          </div>
          <div id="dosen-list" class="m-5">
            <div class="accordion" id="accordion-dosen-list">
              <div
                v-for="(item, i) in data.data"
                :key="item.nidn"
                class="accordion-item"
              >
                <h2 class="accordion-header" :id="'nidn-' + item.nidn">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapse-' + item.nidn"
                    aria-expanded="true"
                    :aria-controls="'collapse-' + item.nidn"
                  >
                    {{ item.nama_dosen }}
                  </button>
                </h2>
                <div
                  :id="'collapse-' + item.nidn"
                  class="accordion-collapse collapse show"
                  :aria-labelledby="'nidn-' + item.nidn"
                  data-bs-parent="#accordion-dosen-list"
                >
                  <div class="accordion-body">
                    <div id="tabel">
                      <table-basic v-if="data.result > 0">
                        <template #thead>
                          <tr class="fw-bolder bg-light fs-7">
                            <th class="rounded-start w-50px ps-3">No</th>
                            <th class="min-w-100px">Mata Kuliah</th>
                            <th class="min-w-50px">Kelas</th>
                            <th class="min-w-50px">Kelas Ujian</th>
                            <th class="min-w-200px">Info</th>
                            <th class="min-w-100px">Status</th>
                            <th class="rounded-end"></th>
                          </tr>
                        </template>
                        <template #tbody>
                          <tr v-for="(item, i) in item.mata_kuliah" :key="i">
                            <td class="text-center align-middle">
                              {{ i + 1 }}
                            </td>
                            <td class="align-middle">
                              <span
                                class="text-dark fw-bolder d-block mb-1 fs-7"
                              >
                                {{ item.nama_mata_kuliah }}
                              </span>
                              <span class="text-muted text-muted d-block fs-8">
                                {{ item.kode_mata_kuliah }}
                              </span>
                              <span class="text-muted text-muted d-block fs-8">
                                Semester {{ item.semester }}
                              </span>
                              <span class="text-muted text-muted d-block fs-8">
                                {{ item.program_studi }}
                              </span>
                            </td>
                            <td class="align-middle">
                              <span class="text-dark fw-bold d-block mb-1 fs-7">
                                {{ item.kelas }}
                              </span>
                            </td>
                            <td class="align-middle">
                              <span class="text-dark fw-bold d-block mb-1 fs-7">
                                {{ item.kelas_ujian }}
                              </span>
                            </td>
                            <td class="align-middle">
                              <span class="text-dark fw-bolder row">
                                <div class="col-4">Peserta</div>
                                <div class="col-2">:</div>
                                <div class="col-6">
                                  {{ item.jumlah_peserta }}
                                </div>
                              </span>
                              <span class="text-dark fw-bolder row">
                                <div class="col-4">Nilai diisi</div>
                                <div class="col-2">:</div>
                                <div class="col-6">{{ item.nilai_diisi }}</div>
                              </span>
                              <span class="text-dark fw-bolder row">
                                <div class="col-4">Progress</div>
                                <div class="col-2">:</div>
                                <div class="col-6">{{ item.progress }}%</div>
                              </span>
                            </td>
                            <td class="align-middle">
                              <span class="text-dark fw-bold d-block mb-1 fs-7">
                                Dosen Mengunci Nilai:
                                <span v-if="item.dikunci" class="text-success">
                                  <icon-done />
                                </span>
                                <span v-else class="text-danger"
                                  ><icon-cross
                                /></span>
                              </span>

                              <span class="text-dark fw-bold d-block mb-1 fs-7">
                                Validasi Program Studi:
                                <span
                                  v-if="item.divalidasi"
                                  class="text-success"
                                >
                                  <icon-done />
                                </span>
                                <span v-else class="text-danger"
                                  ><icon-cross
                                /></span>
                              </span>
                            </td>
                          </tr>
                        </template>
                      </table-basic>

                      <!-- START CARD EMPTY STATE -->
                      <div
                        v-else
                        class="alert alert-primary d-flex align-items-center mx-5 p-5"
                      >
                        <div class="d-flex flex-column">
                          <span> Data Pencairan Tidak Tersedia! </span>
                        </div>
                      </div>
                      <!-- END CARD EMPTY STATE -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between p-5">
            <div
              class="d-flex align-items-center justify-content-between align-items-center"
            >
              <div>
                <select
                  class="form-select form-select-solid"
                  data-placeholder="Pilih Tahun Semester"
                  v-model="filters.page_item"
                  @change="onSearchMataKuliah($event)"
                >
                  <option
                    v-for="(item, i) in page_items"
                    :key="i"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </div>
              <p class="text-muted m-0 ms-2">
                Halaman
                <span class="fw-bolder">{{ data.current_page }}</span>
                dari
                <span class="fw-bolder">{{ data.last_page }} </span>
                halaman. Total
                <span class="fw-bolder">{{ data.result }}</span>
                data.
              </p>
            </div>
            <div>
              <a
                @click="onPrevPage"
                class="btn btn-sm btn-secondary me-2"
                :class="data.current_page == 1 ? 'disabled' : ''"
              >
                Sebelumnya
              </a>
              <a
                @click="onNextPage"
                class="btn btn-sm btn-secondary"
                :class="data.last_page == data.current_page ? 'disabled' : ''"
              >
                Selanjutnya
              </a>
            </div>
          </div>
        </template>
      </card-basic>
    </template>
  </container-app>
</template>

<script>
// API
import {
  getTahunSemester,
  uasPencairan,
} from "../../../apis/PenilaianHasilBelajar/uasSusulan";

// Store
import { useMatkulStore } from "@/stores/matkul";

export default {
  beforeMount() {
    localStorage.setItem("menu", "AISnet");
    localStorage.setItem("halaman", "Penilaian Hasil Belajar");
  },
  async mounted() {
    await this.tahunSemesterFetch();
    await this.pencairanFetch(this.selected.tahun_semester);
  },
  setup() {
    const matkul = useMatkulStore();

    return {
      matkul,
    };
  },
  data() {
    return {
      container_loading: false,
      selected: {},
      filters: {
        page_item: 10,
        dikunci: null,
        divalidasi: null,
      },
      data: [],
      tahun_semester: [],
      page_items: [
        {
          label: 10,
          value: 10,
        },
        {
          label: 20,
          value: 20,
        },
        {
          label: 50,
          value: 50,
        },
        {
          label: 100,
          value: 100,
        },
        {
          label: 500,
          value: 500,
        },
        {
          label: "Semua",
          value: 1000,
        },
      ],
      filter_options: [
        {
          label: "Semua",
          value: null,
        },
        {
          label: "Sudah",
          value: true,
        },
        {
          label: "Belum",
          value: false,
        },
      ],
    };
  },

  methods: {
    async pencairanFetch(tahun_semester, filters) {
      this.container_loading = true;
      const { data } = await uasPencairan(tahun_semester, filters);

      if (data.success) {
        this.data = data;
        this.container_loading = false;
      } else {
        this.data = null;
        this.container_loading = false;
      }
    },

    async tahunSemesterFetch() {
      try {
        const response = await getTahunSemester();
        this.tahun_semester = response.data.data;
        this.selected.tahun_semester = response.data.data[0].thsms;
      } catch (err) {
        console.error(err);
      }
    },

    onNextPage() {
      this.filters.page = this.data.current_page + 1;
      this.pencairanFetch(this.selected.tahun_semester, this.filters);
    },

    onPrevPage() {
      this.filters.page = this.data.current_page - 1;
      this.pencairanFetch(this.selected.tahun_semester, this.filters);
    },

    onSelectedTahunSemesterChange(event) {
      this.pencairanFetch(event.target.value);
    },
    onSearchMataKuliah() {
      this.pencairanFetch(this.selected.tahun_semester, this.filters);
    },
  },
};
</script>
