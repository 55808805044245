<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs></defs>
    <g id="Abstract_Elements" data-name="Abstract Elements" fill="currentColor">
      <g id="abs001-049">
        <g id="abs031-040">
          <g id="abs035">
            <path
              class="cls-1"
              d="M9.11,7,6.23,12H2A10,10,0,0,1,7,3.34ZM17,3.34,14.89,7l2.88,5H22A10,10,0,0,0,17,3.34ZM14.89,17H9.11L7,20.66a10,10,0,0,0,10,0Z"
            />
            <path
              d="M17,3.34,14.89,7H9.11L7,3.34a10,10,0,0,1,10,0ZM17.77,12l-2.88,5L17,20.66A10,10,0,0,0,22,12ZM6.23,12H2a10,10,0,0,0,5,8.66L9.11,17Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<style>
.cls-1 {
  opacity: 0.3;
}
</style>
