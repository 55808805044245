<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs></defs>
    <g id="Abstract_Elements" data-name="Abstract Elements" fill="currentColor">
      <g id="abs001-049">
        <g id="abs031-040">
          <g id="abs034">
            <path
              class="cls-1"
              d="M22,12a10,10,0,0,1-1.34,5L14.89,7h5.77A10,10,0,0,1,22,12ZM3.34,7l2.89,5L12,2A10,10,0,0,0,3.34,7Zm0,10A10,10,0,0,0,12,22l2.89-5Z"
            />
            <path
              d="M20.66,7H9.11L12,2A10,10,0,0,1,20.66,7ZM3.34,7a10,10,0,0,0,0,10H9.11Zm14.43,5L12,22a10,10,0,0,0,8.66-5Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<style>
.cls-1 {
  opacity: 0.3;
}
</style>
