<template>
    <table-basic>
        <template #thead>
            <tr class="fw-bolder bg-light fs-7">
                <th class="rounded-start ps-4 w-auto w-md-50px ">No</th>
                <th>Mahasiswa</th>
                <th>Prodi</th>
                <th class="w-auto w-md-100px" :class="!showMenu('VER_IJAZAH_BAAK') ? 'd-none' : ''">Status Pengambilan</th>
                <th class="w-auto w-md-100px" :class="!showMenu('VER_IJAZAH_LP3B') ? 'd-none' : ''">Status LP3B</th>
                <th class="w-auto w-md-100px" :class="!showMenu('VER_IJAZAH_BAAK') ? 'd-none' : ''">Status BAAK</th>
                <th class="w-auto w-md-100px" :class="!showMenu('VER_IJAZAH_KEUANGAN') ? 'd-none' : ''">Status Keuangan</th>
                <th class="w-auto w-md-100px" :class="!showMenu('VER_IJAZAH_PERPUS') ? 'd-none' : ''">Status Perpustakaan
                </th>
                <th class="w-auto w-md-100px" :class="!showMenu('VER_IJAZAH_PRODI') ? 'd-none' : ''">Status Program Studi
                </th>
                <th class="w-auto w-md-100px" :class="!showMenu('VER_IJAZAH_CDC') ? 'd-none' : ''">Status CDC</th>
                <th class="w-auto w-md-100px" :class="!showMenu('VER_IJAZAH_LSIPD') ? 'd-none' : ''">Status SKPI</th>
                <th class="rounded-end w-auto w-md-100px">Aksi</th>
            </tr>
        </template>

        <template #tbody>
            <tr v-for="(mahasiswa, index) in daftar_pengajuan" :key="index">
                <td class="text-end fs-7">{{ index + 1 }}</td>
                <td>
                    <span class="text-dark fw-bolder d-block mb-1 fs-7">{{ mahasiswa.nama }}</span>
                    <span class="text-muted fw-bold text-muted d-block fs-8">({{ mahasiswa.nimhs }})</span>
                </td>
                <td>
                    {{ mahasiswa.prodi }}
                </td>
                <td :class="!showMenu('VER_IJAZAH_BAAK') ? 'd-none' : ''">
                    <icon-status-2 v-if="mahasiswa.status_permohonan.head.status == 1" />
                    <icon-status-3 v-else-if="mahasiswa.status_permohonan.head.status == 0" />
                    <icon-status-1 v-else-if="mahasiswa.status_permohonan.head.status == 3" />
                    <icon-status-0 v-else />
                </td>
                <td :class="!showMenu('VER_IJAZAH_LP3B') ? 'd-none' : ''">
                    <icon-status-2 v-if="mahasiswa.status_permohonan.LP3B.head.status == 1" />
                    <icon-status-3 v-else-if="mahasiswa.status_permohonan.LP3B.head.status == 0" />
                    <icon-status-1 v-else-if="mahasiswa.status_permohonan.LP3B.head.status == 3" />
                    <icon-status-0 v-else />
                </td>
                <td :class="!showMenu('VER_IJAZAH_BAAK') ? 'd-none' : ''">
                    <icon-status-2 v-if="mahasiswa.status_permohonan.BAAK.head.status == 1" />
                    <icon-status-3 v-else-if="mahasiswa.status_permohonan.BAAK.head.status == 0" />
                    <icon-status-1 v-else-if="mahasiswa.status_permohonan.BAAK.head.status == 3" />
                    <icon-status-0 v-else />
                </td>
                <td :class="!showMenu('VER_IJAZAH_KEUANGAN') ? 'd-none' : ''">
                    <icon-status-2 v-if="mahasiswa.status_permohonan.KEUANGAN.head.status == 1" />
                    <icon-status-3 v-else-if="mahasiswa.status_permohonan.KEUANGAN.head.status == 0" />
                    <icon-status-1 v-else-if="mahasiswa.status_permohonan.KEUANGAN.head.status == 3" />
                    <icon-status-0 v-else />
                </td>
                <td :class="!showMenu('VER_IJAZAH_PERPUS') ? 'd-none' : ''">
                    <icon-status-2 v-if="mahasiswa.status_permohonan.PERPUSTAKAAN.head.status == 1" />
                    <icon-status-3 v-else-if="mahasiswa.status_permohonan.PERPUSTAKAAN.head.status == 0" />
                    <icon-status-1 v-else-if="mahasiswa.status_permohonan.PERPUSTAKAAN.head.status == 3" />
                    <icon-status-0 v-else />
                </td>
                <td :class="!showMenu('VER_IJAZAH_PRODI') ? 'd-none' : ''">
                    <icon-status-2 v-if="mahasiswa.status_permohonan.PROGRAM_STUDI.head.status == 1" />
                    <icon-status-3 v-else-if="mahasiswa.status_permohonan.PROGRAM_STUDI.head.status == 0" />
                    <icon-status-1 v-else-if="mahasiswa.status_permohonan.PROGRAM_STUDI.head.status == 3" />
                    <icon-status-0 v-else />
                </td>
                <td :class="!showMenu('VER_IJAZAH_CDC') ? 'd-none' : ''">
                    <icon-status-2 v-if="mahasiswa.status_permohonan.CDC.head.status == 1" />
                    <icon-status-3 v-else-if="mahasiswa.status_permohonan.CDC.head.status == 0" />
                    <icon-status-1 v-else-if="mahasiswa.status_permohonan.CDC.head.status == 3" />
                    <icon-status-0 v-else />
                </td>
                <td :class="!showMenu('VER_IJAZAH_LSIPD') ? 'd-none' : ''">
                    <icon-status-2 v-if="mahasiswa.status_permohonan.LSIPD.head.status == 1" />
                    <icon-status-3 v-else-if="mahasiswa.status_permohonan.LSIPD.head.status == 0" />
                    <icon-status-1 v-else-if="mahasiswa.status_permohonan.LSIPD.head.status == 3" />
                    <icon-status-0 v-else />
                </td>
                <td class="pe-4">
                    <div class="d-flex">
                        <button-icon name="Validasi" class="bg-secondary me-2 btn-sm" data-bs-toggle="modal"
                            @click="setSelected(mahasiswa)" data-bs-target="#modalValidasi">
                            <template #icon>
                                <icon-check />
                            </template>
                        </button-icon>
                        <button-icon name="Logs" class="bg-secondary me-2 btn-sm" data-bs-toggle="modal"
                            @click="getLogs(mahasiswa.nimhs)" data-bs-target="#modalLogs">
                            <template #icon>
                                <icon-history />
                            </template>
                        </button-icon>
                    </div>
                </td>
            </tr>
        </template>
    </table-basic>
    <modal-fullsceen-lg id="modalLogs" title="Logs">
        <template #content v-if="!isLoadingLogs">
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Logs Pengajuan Mahasiswa</h5>
                    <div class="table-responsive" style="max-height: 60vh; overflow-y: scroll;">
                        <table class="table  gs-0 gy-4 table-row-dashed fs-7">
                            <thead>
                                <tr class=" ">
                                    <th class="rounded-start  fw-bolder ps-4  text-right">Petugas/Pemohon</th>
                                    <th class="fw-bolder ps-4  text-right">Tanggal</th>
                                    <th class="fw-bolder ps-4  text-right">Aksi</th>
                                    <th class="fw-bolder ps-4  text-right">Status</th>
                                    <th class=" fw-bolder ps-4  text-right">Catatan</th>

                                </tr>
                                <tr class=" " v-for="(log, index) in logs" :key="index">
                                    <th class="rounded-start rounded-end  ps-4  text-right">{{ log?.user?.name }}</th>
                                    <th class=" rounded-end  ps-4  text-right">{{ log.created_at }}</th>
                                    <th class="  ps-4  text-right"
                                        :class="log.status == 1 ? 'text-success' : 'text-danger'">{{ log.keterangan }}</th>
                                    <th class="  ps-4  text-right"
                                        :class="log.status == 1 ? 'text-success' : 'text-danger'">
                                        <icon-status-2 v-if="log.status == 1" />
                                        <icon-status-3 v-else-if="log.status == 0" />
                                        <icon-status-0 v-else />
                                    </th>
                                    <th class="rounded-end  ps-4  text-right">{{ log?.catatan }}</th>

                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </template>
        <template v-else #content>
            <div class="d-flex justify-content-center align-items-center text-gray-700 mb-7">
                <div class="spinner-border me-2" role="status" style="width: 1.3rem; height: 1.3rem">
                    <span class="visually-hidden"></span>
                </div>
                <span class="fs-4 fw-bold">
                    Tunggu Sebentar
                </span>
            </div>

        </template>
    </modal-fullsceen-lg>



    <modal-fullsceen-lg id="modalValidasi" title="Validasi">
        <template #content v-if="!isLoading">
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Informasi Mahasiswa</h5>
                    <div class="table-responsive">
                        <table class="table  gs-0 gy-4 table-row-dashed fs-7">
                            <thead>
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">Nama</th>
                                    <td v-if="!isUpdate" class="rounded-end pe-4  text-right">{{ selected?.nama }}</td>
                                    <td v-else class="rounded-end pe-4  text-right">
                                        <input type="text" v-bind:value="selected?.nama"
                                            @input="e => informasi_mhs.nama = e.target.value"
                                            class="form-control form-control-sm">
                                    </td>
                                </tr>
                                <!-- <tr class=" ">
                    <th class="rounded-start fw-bolder ps-4  text-right">NIK</th>
                    <td class="rounded-end pe-4  text-right">-</td>
                </tr> -->
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">NIM</th>
                                    <td class="rounded-end pe-4  text-right">{{ selected?.nimhs }}</td>

                                </tr>
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">Program Studi</th>
                                    <td class="rounded-end pe-4  text-right">{{ selected?.prodi }}</td>

                                </tr>
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">Tanggal Sidang</th>
                                    <td v-if="!isUpdate" class="rounded-end pe-4  text-right">
                                        {{ selected?.jadwal_sidang }}</td>
                                    <td v-else class="rounded-end pe-4  text-right">
                                        <input type="date" v-bind:value="selected?.jadwal_sidang"
                                            @input="e => informasi_mhs.jadwal_sidang = e.target.value"
                                            class="form-control form-control-sm">
                                    </td>
                                </tr>
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">Judul B.Indonesia</th>
                                    <td v-if="!isUpdate" class="rounded-end pe-4  text-right">{{ selected?.judul_ind }}
                                    </td>
                                    <td v-else class="rounded-end pe-4  text-right">
                                        <input type="text" v-bind:value="selected?.judul_ind"
                                            @input="e => informasi_mhs.judul_ind = e.target.value"
                                            class="form-control form-control-sm">
                                    </td>
                                </tr>
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">Judul B.Inggris</th>
                                    <td v-if="!isUpdate" class="rounded-end pe-4  text-right">{{ selected?.judul_eng }}
                                    </td>
                                    <td v-else class="rounded-end pe-4  text  -right">
                                        <input type="text" v-bind:value="selected?.judul_eng"
                                            @input="e => informasi_mhs.judul_eng = e.target.value"
                                            class="form-control form-control-sm">
                                    </td>
                                </tr>
                                <tr class=" ">
                                    <th class="rounded-start fw-bolder ps-4  text-right">Pembimbing</th>
                                    <td class="rounded-end pe-4  text-right">
                                        <span>
                                            {{ selected?.pembimbing1 }}
                                        </span>
                                        <br>
                                        <span>
                                            {{ selected?.pembimbing2 }}
                                        </span>
                                    </td>

                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="card-footer bg-secondary text-end"
                        :class="!showMenu('EDIT_PROFILE_IJAZAH') ? 'd-none' : ''">
                        <div v-if="isUpdate" class="d-flex flex-row justify-content-end align-items-center">
                            <button class="btn btn-success  " @click="handleUpdate">Simpan</button>
                            <button class="btn btn-danger ms-2 " @click="isUpdate = !isUpdate">Batal</button>
                        </div>
                        <button v-else @click="isUpdate = !isUpdate" class="btn btn-primary">Update</button>
                    </div>
                </div>
            </div>

            <div class="card" :class="!showMenu('VER_IJAZAH_LP3B') ? 'd-none' : ''">
                <div class="card-body">
                    <h5 class="card-title">LP3B</h5>
                    <div class="table-responsive">
                        <table class="table   table-row-dashed fs-7">
                            <thead>
                                <tr class="fw-bolder bg-light">
                                    <th class="rounded-start  ps-4 w-auto  text-right">Uraian</th>
                                    <th>Status</th>
                                    <th class="">Catatan</th>
                                    <th class="rounded-end pe-4  text-right w-30">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="ps-4">
                                        Judul Bahasa Inggris
                                    </td>

                                    <td>
                                        <icon-status-2
                                            v-if="selected.status_permohonan?.LP3B.item?.judul_inggris?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.LP3B.item?.judul_inggris?.status == 0" />
                                        <icon-status-1
                                            v-else-if="selected.status_permohonan?.LP3B.item?.judul_inggris?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">{{ selected.status_permohonan?.LP3B.item?.judul_inggris?.catatan }}
                                    </td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.LP3B.item?.judul_inggris, 'LP3B')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>

                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
            <div class="card mt-2" :class="!showMenu('VER_IJAZAH_BAAK') ? 'd-none' : ''">
                <div class="card-body">
                    <h5 class="card-title">Kelengkapan Dokumen</h5>
                    <div class="table-responsive">
                        <table class="table   table-row-dashed fs-7">
                            <thead>
                                <tr class="fw-bolder bg-light">
                                    <th class="rounded-start  ps-4 w-auto  text-right">Uraian</th>
                                    <th>Dokumen</th>
                                    <th>Status</th>
                                    <th class="">Catatan</th>
                                    <th class="rounded-end pe-4  text-right w-30">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="ps-4">
                                        Ijazah Terakhir (SMU/SMK/Sederajat)
                                    </td>
                                    <td class="w-auto">

                                        <div v-if="!selected.status_permohonan?.BAAK.item?.ijazah" class="input-group mb-3">
                                            <div>Dokumen Belum diajukan</div>
                                        </div>
                                        <div v-else>
                                            <a target="_blank"
                                                :href="selected.status_permohonan?.BAAK.item?.ijazah?.dokumen"
                                                class="btn btn-info btn-sm p-3">Lihat Dokumen</a>
                                        </div>
                                    </td>
                                    <td>
                                        <icon-status-2 v-if="selected.status_permohonan?.BAAK.item?.ijazah?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.BAAK.item?.ijazah?.status == 0" />
                                        <icon-status-1
                                            v-else-if="selected.status_permohonan?.BAAK.item?.ijazah?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">{{ selected.status_permohonan?.BAAK.item?.ijazah?.catatan }}</td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.BAAK.item?.ijazah, 'BAAK')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-4">
                                        Fotokopi KTP 1 lembar
                                    </td>
                                    <td class="w-40">
                                        <div v-if="!selected.status_permohonan?.BAAK.item?.ktp?.dokumen"
                                            class="input-group mb-3">
                                            <div>Dokumen Belum diajukan</div>
                                        </div>
                                        <div v-else>
                                            <a target="_blank" :href="selected.status_permohonan?.BAAK.item?.ktp?.dokumen"
                                                class="btn btn-info btn-sm p-3">Lihat Dokumen</a>
                                        </div>
                                    </td>
                                    <td>
                                        <icon-status-2 v-if="selected.status_permohonan?.BAAK.item?.ktp?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.BAAK.item?.ktp?.status == 0" />
                                        <icon-status-0 v-else />
                                    </td>

                                    <td class="w-30">{{ selected.status_permohonan?.BAAK.item?.ktp?.catatan }}</td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.BAAK.item?.ktp, 'BAAK')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
            <div class="card mt-2" :class="!showMenu('VER_IJAZAH_KEUANGAN') ? 'd-none' : ''">
                <div class="card-body">
                    <h5 class="card-title">Keuangan</h5>
                    <div class="table-responsive">
                        <table class="table   table-row-dashed fs-7">
                            <thead>
                                <tr class="fw-bolder bg-light">
                                    <th class="rounded-start  ps-4  text-right">Uraian</th>
                                    <th>Status</th>
                                    <th>Catatan</th>
                                    <th class="rounded-end pe-4  text-right w-30">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="ps-4">
                                        Administrasi Keuangan
                                    </td>
                                    <td>
                                        <icon-status-2
                                            v-if="selected.status_permohonan?.KEUANGAN.item?.keuangan?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.KEUANGAN.item?.keuangan?.status == 0" />
                                        <icon-status-1
                                            v-else-if="selected.status_permohonan?.KEUANGAN.item?.keuangan?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">{{ selected.status_permohonan?.KEUANGAN.item?.keuangan?.catatan }}
                                    </td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.KEUANGAN?.item?.keuangan, 'KEUANGAN')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-4">
                                        Biaya Wisuda
                                    </td>
                                    <td>
                                        <icon-status-2
                                            v-if="selected.status_permohonan?.KEUANGAN.item?.wisuda?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.KEUANGAN.item?.wisuda?.status == 0" />
                                        <icon-status-1
                                            v-else-if="selected.status_permohonan?.KEUANGAN.item?.wisuda?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">{{ selected.status_permohonan?.KEUANGAN.item?.wisuda?.catatan }}</td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.KEUANGAN?.item?.wisuda, 'KEUANGAN')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-4">
                                        Pencetakan Ijazah
                                    </td>

                                    <td>
                                        <icon-status-2
                                            v-if="selected.status_permohonan?.KEUANGAN.item?.ijazah?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.KEUANGAN.item?.ijazah?.status == 0" />
                                        <icon-status-1
                                            v-else-if="selected.status_permohonan?.KEUANGAN.item?.ijazah?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">{{ selected.status_permohonan?.KEUANGAN.item?.ijazah?.catatan }}</td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.KEUANGAN?.item?.ijazah, 'KEUANGAN')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-4">
                                        Biaya Penerbitan Jurnal
                                    </td>

                                    <td>
                                        <icon-status-2
                                            v-if="selected.status_permohonan?.KEUANGAN.item?.jurnal?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.KEUANGAN.item?.jurnal?.status == 0" />
                                        <icon-status-1
                                            v-else-if="selected.status_permohonan?.KEUANGAN.item?.jurnal?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">{{ selected.status_permohonan?.KEUANGAN.item?.jurnal?.catatan }}</td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.KEUANGAN?.item?.jurnal, 'KEUANGAN')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
            <div class="card mt-2" :class="!showMenu('VER_IJAZAH_PRODI') ? 'd-none' : ''">
                <div class="card-body">
                    <h5 class="card-title">Program Studi</h5>
                    <div class="table-responsive">
                        <table class="table   table-row-dashed fs-7">
                            <thead>
                                <tr class="fw-bolder bg-light">
                                    <th class="rounded-start  ps-4  text-right">Uraian</th>
                                    <th>Dokumen</th>
                                    <th>Status</th>
                                    <th>Catatan</th>
                                    <th class="rounded-end pe-4  text-right w-30">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="ps-4">
                                        Laporan Tugas Akhir
                                    </td>
                                    <td class="w-40">
                                        <div v-if="!selected?.status_permohonan?.PROGRAM_STUDI?.item?.laporan_ta?.dokumen || selected?.status_permohonan?.PROGRAM_STUDI?.item?.laporan_ta?.status === 2"
                                            class="input-group mb-3">
                                            <div>Dokumen Belum diajukan</div>
                                        </div>
                                        <div v-else>
                                            <a target="_blank"
                                                :href="selected?.status_permohonan?.PROGRAM_STUDI?.item?.laporan_ta?.dokumen"
                                                class="btn btn-info btn-sm p-3">Lihat Dokumen</a>
                                        </div>
                                    </td>
                                    <td>
                                        <icon-status-2
                                            v-if="selected.status_permohonan?.PROGRAM_STUDI.item?.laporan_ta?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.PROGRAM_STUDI.item?.laporan_ta?.status == 0" />
                                        <icon-status-1
                                            v-else-if="selected.status_permohonan?.PROGRAM_STUDI.item?.laporan_ta?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">
                                        {{ selected?.status_permohonan?.PROGRAM_STUDI?.item?.laporan_ta?.catatan }}
                                    </td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.PROGRAM_STUDI?.item?.laporan_ta, 'PROGRAM_STUDI')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-4">
                                        Jurnal Penelitian
                                    </td>
                                    <td class="w-40">
                                        <div v-if="!selected?.status_permohonan?.PROGRAM_STUDI?.item?.jurnal?.dokumen || selected?.status_permohonan?.PROGRAM_STUDI?.item?.jurnal?.status === 2"
                                            class="input-group mb-3">
                                            <div>Dokumen Belum diajukan</div>
                                        </div>
                                        <div v-else>
                                            <a target="_blank"
                                                :href="selected?.status_permohonan?.PROGRAM_STUDI?.item?.jurnal?.dokumen"
                                                class="btn btn-info btn-sm p-3">Lihat Dokumen</a>
                                        </div>
                                    </td>
                                    <td>
                                        <icon-status-2
                                            v-if="selected.status_permohonan?.PROGRAM_STUDI.item?.jurnal?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.PROGRAM_STUDI.item?.jurnal?.status == 0" />
                                        <icon-status-1
                                            v-else-if="selected.status_permohonan?.PROGRAM_STUDI.item?.jurnal?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">
                                        {{ selected?.status_permohonan?.PROGRAM_STUDI?.item?.jurnal?.catatan }}
                                    </td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.PROGRAM_STUDI?.item?.jurnal, 'PROGRAM_STUDI')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-4">
                                        Link Jurnal Publikasi
                                    </td>
                                    <td class="w-40">
                                        <div v-if="!selected?.status_permohonan?.PROGRAM_STUDI?.item?.link_jurnal?.dokumen || selected?.status_permohonan?.PROGRAM_STUDI?.item?.link_jurnal?.status === 2"
                                            class="input-group mb-3">
                                            <div>Dokumen Belum diajukan</div>
                                        </div>
                                        <div v-else>
                                            <a target="_blank"
                                                :href="selected?.status_permohonan?.PROGRAM_STUDI?.item?.link_jurnal?.dokumen"
                                                class=" p-3">{{
                                                    selected?.status_permohonan?.PROGRAM_STUDI?.item?.link_jurnal?.dokumen
                                                }}</a>

                                        </div>
                                    </td>
                                    <td>
                                        <icon-status-2
                                            v-if="selected.status_permohonan?.PROGRAM_STUDI.item?.link_jurnal?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.PROGRAM_STUDI.item?.link_jurnal?.status == 0" />
                                        <icon-status-1
                                            v-else-if="selected.status_permohonan?.PROGRAM_STUDI.item?.link_jurnal?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">
                                        {{ selected?.status_permohonan?.PROGRAM_STUDI?.item?.link_jurnal?.catatan }}
                                    </td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.PROGRAM_STUDI?.item?.link_jurnal, 'PROGRAM_STUDI')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
            <div class="card mt-2" :class="!showMenu('VER_IJAZAH_PERPUS') ? 'd-none' : ''">
                <div class="card-body">
                    <h5 class="card-title">Perpustakaan</h5>
                    <div class="table-responsive">
                        <table class="table   table-row-dashed fs-7">
                            <thead>
                                <tr class="fw-bolder bg-light">
                                    <th class="rounded-start  ps-4  text-right">Uraian</th>
                                    <th>Status</th>
                                    <th>Catatan</th>
                                    <th class="rounded-end pe-4  text-right w-30">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="ps-4">
                                        Keterangan Bebas Peminjaman Buku
                                    </td>
                                    <td>
                                        <icon-status-2
                                            v-if="selected.status_permohonan?.PERPUSTAKAAN.item?.bebas_peminjaman?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.PERPUSTAKAAN.item?.bebas_peminjaman?.status == 0" />
                                        <icon-status-1
                                            v-else-if="selected.status_permohonan?.PERPUSTAKAAN.item?.bebas_peminjaman?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">
                                        {{ selected?.status_permohonan?.PERPUSTAKAAN?.item?.bebas_peminjaman?.catatan }}
                                    </td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.PERPUSTAKAAN?.item?.bebas_peminjaman, 'PERPUSTAKAAN')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-4">
                                        Sumbangan Buku
                                    </td>
                                    <td>
                                        <icon-status-2
                                            v-if="selected.status_permohonan?.PERPUSTAKAAN.item?.sumbangan_buku?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.PERPUSTAKAAN.item?.sumbangan_buku?.status == 0" />
                                        <icon-status-1
                                            v-else-if="selected.status_permohonan?.PERPUSTAKAAN.item?.sumbangan_buku?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">
                                        {{ selected?.status_permohonan?.PERPUSTAKAAN?.item?.sumbangan_buku?.catatan }}
                                    </td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.PERPUSTAKAAN?.item?.sumbangan_buku, 'PERPUSTAKAAN')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>

                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
            <div class="card mt-2" :class="!showMenu('VER_IJAZAH_CDC') ? 'd-none' : ''">
                <div class="card-body">
                    <h5 class="card-title">CDC</h5>
                    <div class="table-responsive">
                        <table class="table   table-row-dashed fs-7">
                            <thead>
                                <tr class="fw-bolder bg-light">
                                    <th class="rounded-start  ps-4  text-right">Uraian</th>
                                    <th>Dokumen</th>
                                    <th>Status</th>
                                    <th>Catatan</th>
                                    <th class="rounded-end pe-4  text-right w-30">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="ps-4">
                                        Pengisian Form untuk Tracer Studi
                                    </td>
                                    <td class="w-40">
                                        <div v-if="!selected?.status_permohonan?.CDC?.item?.CDC?.dokumen || selected?.status_permohonan?.CDC?.item?.CDC?.status === 2"
                                            class="input-group mb-3">
                                            <div>Dokumen Belum diajukan</div>
                                        </div>
                                        <div v-else>
                                            <a target="_blank" :href="selected?.status_permohonan?.CDC?.item?.CDC?.dokumen"
                                                class="btn btn-info btn-sm p-3">Lihat Dokumen</a>
                                        </div>
                                    </td>
                                    <td>
                                        <icon-status-2 v-if="selected.status_permohonan?.CDC.item?.CDC?.status == 1" />
                                        <icon-status-3 v-else-if="selected.status_permohonan?.CDC.item?.CDC?.status == 0" />
                                        <icon-status-1 v-else-if="selected.status_permohonan?.CDC.item?.CDC?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">
                                        {{ selected?.status_permohonan?.CDC?.item?.CDC?.catatan }}
                                    </td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.CDC?.item?.CDC, 'CDC')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
            <div class="card mt-2" :class="!showMenu('VER_IJAZAH_LSIPD') ? 'd-none' : ''">
                <div class="card-body">
                    <h5 class="card-title">SKPI</h5>
                    <div class="table-responsive">
                        <table class="table   table-row-dashed fs-7">
                            <thead>
                                <tr class="fw-bolder bg-light">
                                    <th class="rounded-start  ps-4  text-right">Uraian</th>
                                    <th>Dokumen</th>
                                    <th>Status</th>
                                    <th>Catatan</th>
                                    <th class="rounded-end pe-4  text-right w-30">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="ps-4">
                                        Form SKPI
                                    </td>
                                    <td class="w-40">
                                        <div v-if="!selected?.status_permohonan?.LSIPD?.item?.LSIPD?.dokumen || !selected?.status_permohonan?.LSIPD?.item?.LSIPD?.status == 2"
                                            class="input-group mb-3">
                                            <div>Dokumen Belum diajukan</div>
                                        </div>
                                        <div v-else>
                                            <a target="_blank"
                                                :href="selected?.status_permohonan?.LSIPD?.item?.LSIPD?.dokumen"
                                                class="btn btn-info btn-sm p-3">Lihat Dokumen</a>
                                        </div>
                                    </td>
                                    <td>
                                        <icon-status-2 v-if="selected.status_permohonan?.LSIPD.item?.LSIPD?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.LSIPD.item?.LSIPD?.status == 0" />
                                        <icon-status-1
                                            v-else-if="selected.status_permohonan?.LSIPD.item?.LSIPD?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">{{ selected?.status_permohonan?.LSIPD?.item?.LSIPD?.catatan }}</td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.LSIPD?.item?.LSIPD, 'LSIPD')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-4">
                                        Link Drive Sertifikat
                                    </td>
                                    <td class="w-40">
                                        <div v-if="!selected?.status_permohonan?.LSIPD?.item?.link_sertifikat?.dokumen || selected?.status_permohonan?.LSIPD?.item?.link_sertifikat?.status === 2"
                                            class="input-group mb-3">
                                            <div>Dokumen Belum diajukan</div>
                                        </div>
                                        <div v-else>
                                            <a target="_blank"
                                                :href="selected?.status_permohonan?.LSIPD?.item?.link_sertifikat?.dokumen"
                                                class=" p-3">{{
                                                    selected?.status_permohonan?.LSIPD?.item?.link_sertifikat?.dokumen }}</a>

                                        </div>
                                    </td>
                                    <td>
                                        <icon-status-2
                                            v-if="selected.status_permohonan?.LSIPD.item?.link_sertifikat?.status == 1" />
                                        <icon-status-3
                                            v-else-if="selected.status_permohonan?.LSIPD.item?.link_sertifikat?.status == 0" />
                                        <icon-status-1
                                            v-else-if="selected.status_permohonan?.LSIPD.item?.link_sertifikat?.status == 3" />
                                        <icon-status-0 v-else />
                                    </td>
                                    <td class="w-30">
                                        {{ selected?.status_permohonan?.LSIPD?.item?.link_sertifikat?.catatan }}
                                    </td>
                                    <td>
                                        <button-icon name="" class="bg-secondary me-2 btn-sm"
                                            @click="validasi(selected.status_permohonan?.LSIPD?.item?.link_sertifikat, 'LSIPD')">
                                            <template #icon>
                                                <icon-check />
                                            </template>
                                        </button-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
        <template v-else #content>
            <div class="d-flex justify-content-center align-items-center text-gray-700 mb-7">
                <div class="spinner-border me-2" role="status" style="width: 1.3rem; height: 1.3rem">
                    <span class="visually-hidden"></span>
                </div>
                <span class="fs-4 fw-bold">
                    Tunggu Sebentar
                </span>
            </div>

        </template>
    </modal-fullsceen-lg>
</template>

<script>
import { getUserData } from '@/store';
import { verifikasi, getPermintaan, updatePengajuan, getLogs } from '@/apis/ijazah'
import { toFormData, closeModal } from '@/helpers/main';
import { formatDate } from '@/helpers/waktu';


export default {
    props: ['daftar_pengajuan'],
    data() {
        return {
            selected: {},
            user: getUserData(),
            isUpdate: false,
            isLoading: false,
            isLoadingLogs: false,
            logs: [],
            informasi_mhs: {
                nama: "",
                jadwal_sidang: "",
                judul_ind: "",
                judul_eng: "",
            }
        }
    },
    mounted() {



        const containerClaimReqModal = document.getElementById("modalValidasi");
        const claimReqModal = new bootstrap.Modal(containerClaimReqModal, { focus: false });
    },
    methods: {
        async handleUpdate() {

            let fd = new FormData()
            Object.keys(this.informasi_mhs).map((e) => {
                if (this.informasi_mhs[e]) {
                    fd.append(e, this.informasi_mhs[e]);
                }
            })

            let res = await updatePengajuan(this.selected.id, fd);
            this.setSelected({ nimhs: this.selected.nimhs })
        },
        async setSelected(data) {
            this.isLoading = true;
            let res = await getPermintaan(data.nimhs);
            this.selected = res.data.data
            this.informasi_mhs = {
                nama: "",
                jadwal_sidang: "",
                judul_ind: "",
                judul_eng: "",
            }
            this.isLoading = false;
        },
        async getLogs(nim) {
            this.isLoadingLogs = true;
            let res = await getLogs(nim);
            this.logs = res.data.data.map(e => ({ ...e, created_at: formatDate(e.created_at) }))

            this.isLoadingLogs = false;
        },

        showMenu(akses) {
            if (!this.user.akses.includes('ALL')) {
                return this.user.akses.includes(akses);
            } else {
                return true;
            }
        },

        getStatus(status) {
            switch (status) {
                case 0: return "text-gray-500";
                case 1: return "text-success";
                case 2: return "text-danger";
                case 3: return "text-warning";
                default: return "text-gray-500";
            }
        },
        validasi(_data, head) {
            console.log(this.selected);
            Swal.fire({
                title: "Validasi Pengajuan Ijazah",
                text: "Tambahkan catatan bila ada ",
                input: 'text',
                returnInputValueOnDeny: true,
                showCancelButton: true,
                buttonsStyling: false,
                showDenyButton: true,
                denyButtonText: "Tolak",
                cancelButtonText: "Batal",
                confirmButtonText: "Verifikasi",
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-secondary",
                    denyButton: "btn btn-danger"
                }

            }).then(async (result) => {
                let data = {
                    catatan: result.value,
                    type: _data.name,
                    id_item: _data.id ? _data.id : false,
                    nim: this.selected.nimhs,
                    head: head
                    // head: this.selected
                }
                console.log(result);
                if (result.isConfirmed) {
                    Swal.fire({
                        title: 'Memverifikasi!',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        timerProgressBar: true,
                        imageUrl: '/assets/img/check.gif',
                        imageHeight: 100,
                        imageWidth: 100,
                        onBeforeOpen: () => {
                            Swal.showLoading()
                        },
                    });
                    const res = await verifikasi(toFormData({ ...data, status: 1 }));
                    Swal.close();
                    if (res.status) {
                        Swal.fire('Berhasil melakukan verifikasi', '', 'success').then(() => {
                            this.setSelected({ nimhs: data.nim })
                        })
                    } else {
                        Swal.fire('gagal melakukan verifikasi', '', 'danger')
                    }

                    // closeModal("modalValidasi")
                } else if (result.isDenied) {
                    Swal.fire({
                        title: 'Memverifikasi!',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        timerProgressBar: true,
                        imageUrl: '/assets/img/check.gif',
                        imageHeight: 100,
                        imageWidth: 100,
                        onBeforeOpen: () => {
                            Swal.showLoading()
                        },
                    });
                    const res = await verifikasi(toFormData({ ...data, status: 2 }));
                    Swal.close();
                    if (res.status) {
                        Swal.fire('Berhasil melakukan verifikasi', '', 'success')
                            .then(() => {
                                this.setSelected({ nimhs: data.nim })
                            })
                    } else {
                        Swal.fire('gagal melakukan verifikasi', '', 'danger')
                    }

                    // closeModal("modalValidasi")
                }

            });
        }
    }
}
</script>