<template>
    <form class="position-relative" autocomplete="off" @submit.prevent="">
        <div class="position-absolute translate-middle-y top-50 ms-3">
            <span class="svg-icon svg-icon-2x">
                <slot name="icon"></slot>
            </span>
        </div>

        <slot name="content"></slot>
    </form>
</template>