<template>
    <div class="card mb-3 shadow-sm" v-for="(absen, i) in daftar_absen" :key="i">
        <div class="card-body p-6">
            <div class="d-flex">
                <div style="
                                min-width: 82px; 
                                height: 82px; 
                                border-radius: 10px;
                        " class="
                            border 
                            border-1
                            border-dashed 
                            d-inline-block 
                            text-center
                            d-flex
                            flex-column
                            align-items-center
                            justify-content-center
                            me-3
                        "
                        :class="absen.presentasi.replace(',', '.') > 75 ? 'bg-light-success border-success' : absen.presentasi.replace(',', '.') < 75 ? 'bg-light-danger border-danger' : 'bg-light-warning border-warning'"
                        >
                    <span class="fw-bolder fs-4">{{ absen.presentasi }} %</span>
                </div>
                <div class="d-flex flex-column justify-content-between w-100">
                    <div class="d-flex">
                        <div>
                            <icon-mark />
                        </div>
                        <div>
                            <span class="text-gray-900 fs-7 fw-bolder d-block pt-1">{{ absen.nama_matkul }}</span>
                            <span class="text-gray-700 fs-8">{{ absen.kode_matkul }}</span>
                        </div>
                    </div>
                    <div class="text-end">
                        <button class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#modalDetail" @click="modalDetail(absen.kode_matkul)">Detail</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['daftar_absen'],
    emits: ['modalDetail'],
    methods: {
        modalDetail(kode_matkul) {
            this.$emit('modalDetail', kode_matkul)
        }
    }
}
</script>