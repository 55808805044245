<template>
  <container-app :container_loading="container_loading">
    <template #content>
      <card-basic>
        <template #content>
          <div class="p-5">
            <label-header label="Penilaian Hasil Belajar" value="UAS">
              <template #control>
                <select
                  class="form-select form-select-solid"
                  data-placeholder="Pilih Tahun Semester"
                  v-model="selected.tahun_semester"
                  @change="onSelectedTahunSemesterChange($event)"
                >
                  <option
                    v-for="(item, i) in tahun_semester"
                    :key="i"
                    :value="item.thsms"
                  >
                    Tahun {{ item.ket }}
                  </option>
                </select>
              </template>
            </label-header>
          </div>
          <div class="m-5">
            <TabelNilaiUAS
              title="Ujian Akhir Semester (UAS) Reguler"
              :daftar_nilai="uas"
            />
            <TabelNilaiUAS
              title="Ujian Akhir Semester (UAS) Susulan"
              :daftar_nilai="uas_susulan"
            />
          </div>
        </template>
      </card-basic>
    </template>
  </container-app>
</template>

<script>
// components
import TabelNilaiUAS from "@/components/_penilaianUjian/nilaiUtsMahasiwa/TabelNilaiUAS.vue";

// apis
import { getTahunSemester, uasNilai } from "@/apis/PenilaianHasilBelajar/uas";
import { uasSusulanNilai } from "@/apis/PenilaianHasilBelajar/uasSusulan";

export default {
  components: {
    TabelNilaiUAS,
  },
  data() {
    return {
      container_loading: false,
      selected: {},
      uas: [],
      uas_susulan: [],
      tahun_semester: [],
    };
  },
  beforeMount() {
    localStorage.setItem("menu", "Hasil Studi");
    localStorage.setItem("halaman", "Nilai Ujian Akhir Semester (UAS)");
  },
  async mounted() {
    await this.tahunSemesterFetch();
    await this.uasFetch(this.selected.tahun_semester);
    await this.uasSusulanFetch(this.selected.tahun_semester);
  },
  methods: {
    async tahunSemesterFetch() {
      try {
        const response = await getTahunSemester();
        this.tahun_semester = response.data.data;
        this.selected.tahun_semester = response.data.data[0].thsms;
      } catch (err) {
        console.error(err);
      }
    },
    async uasFetch(tahun_semester) {
      this.container_loading = true;
      const { data } = await uasNilai(tahun_semester);

      if (data.success) {
        this.uas = data.data;
        this.container_loading = false;
      } else {
        this.uas = null;
        this.container_loading = false;
      }
    },
    async uasSusulanFetch(tahun_semester) {
      this.container_loading = true;
      const { data } = await uasSusulanNilai(tahun_semester);

      if (data.success) {
        this.uas_susulan = data.data;
        this.container_loading = false;
      } else {
        this.uas_susulan = null;
        this.container_loading = false;
      }
    },
    onSelectedTahunSemesterChange(event) {
      this.uasFetch(event.target.value);
      this.uasSusulanFetch(event.target.value);
    },
  },
};
</script>
